import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import {
    Typography,
    Container,
    Avatar,
    Icon,
    Grid,
    Button,
    Paper,
    makeStyles,
    InputAdornment,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';

import AppointmentApi, { STATUS } from '../../api/AppointmentApi';
import MasterForm from '../../component/MasterForm';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { CheckboxInput, DateTimeInput, ListInput, ObjectInput, TextInput } from '../../component/control';
import { validateForm } from '../../util/ValidationManager';
import DoctorApi from '../../api/DoctorApi';
import PatientApi from '../../api/PatientApi';
import BranchApi from '../../api/BranchApi';
import { PATIENT_TABLE_FIELDS } from '../registration/Patient';
import { BRANCH_TABLE_FIELDS } from '../setup/Branch';
import { DOCTOR_TABLE_FIELDS } from '../registration/Doctor';
import AppointmentTreatmentForm from '../../fragment/AppointmentTreatmentForm';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
    form: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1, 0),
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    accodionRoot: {
        width: '100%',
    },
    expansionIcon: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
}));

const AppointmentDetail = (props) => {
    const classes = styles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isUpdate, setUpdate] = useState(id > 0);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };
    const [form, setForm] = useState(() => {
        if (id.length === 36) {
            AppointmentApi.getById(id)
                .then((data) => {
                    setForm(data);
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }
        return {};
    });

    const handleBranchData = async (currentPage, pageSize, sort, search) => {
        return await BranchApi.getOtherPaging(currentPage, pageSize, sort, search);
    };

    const handlePatientData = async (currentPage, pageSize, sort, search) => {
        return await PatientApi.getActivePaging(currentPage, pageSize, sort, search);
    };

    const handleDoctorData = async (currentPage, pageSize, sort, search) => {
        return await DoctorApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleCopyToClipboard = (data) => {
        navigator.clipboard.writeText(data);
        dispatch({
            type: FLASH_REDUX_ACTIONS.SHOW,
            flash: { type: 'info', message: `Copied : ${data}` },
        });
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        const isValid = validateForm(
            form,
            [
                { fieldId: 'date', required: true },
                { fieldId: 'fromTime', required: true },
                { fieldId: 'patient', required: true },
                { fieldId: 'doctor', required: true },
                { fieldId: 'items', required: true, message: 'Please add a treatments.' },
            ],
            handleError,
        );
        if (!isValid) {
            return;
        }

        try {
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            let message = '';
            if (isUpdate) {
                const response = await AppointmentApi.modifyById(id, form);
                message = `Appointment(${response.code}) has been updated successfully.`;
            } else {
                const response = await AppointmentApi.addNew(form);
                message = `Appointment(${response.code}) has been created successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });

            history.push('/appointment');
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">medication</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Appointment Form
                    </Typography>
                    <MasterForm>
                        <Grid container direction="column" className={classes.form}>
                            {isUpdate ? (
                                <Grid direction="row" spacing={3} container>
                                    <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                        <TextInput
                                            id="code"
                                            icon="code"
                                            label="Code"
                                            value={form?.code}
                                            disabled={true}
                                            onChange={(event) => setForm({ ...form, code: event.target.value })}
                                            inputAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton color="default" onClick={() => handleCopyToClipboard(form?.code)} aria-label="Copy">
                                                        <Icon>content_copy</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            required
                                        />
                                    </Grid>
                                    <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                        <ListInput
                                            id="status"
                                            label="Status"
                                            data={STATUS}
                                            value={form?.status}
                                            onChange={(event) => setForm({ ...form, status: event.target.value })}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid direction="row" spacing={3} container>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <DateTimeInput
                                        type="date"
                                        id="date"
                                        icon="calendar_today"
                                        label="Date"
                                        value={form?.date}
                                        onChange={(event) => setForm({ ...form, date: event.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <DateTimeInput
                                        type="time"
                                        id="fromTime"
                                        icon="schedule"
                                        label="From Time"
                                        value={form?.fromTime}
                                        onChange={(event) => setForm({ ...form, fromTime: event.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <DateTimeInput
                                        type="time"
                                        id="tomTime"
                                        icon="schedule"
                                        label="To Time"
                                        value={form?.toTime}
                                        onChange={(event) => setForm({ ...form, toTime: event.target.value })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid direction="row" spacing={3} container>
                                <Grid direction="row" lg={5} md={5} sm={8} xs={12} item container justifyContent="center">
                                    <ObjectInput
                                        variant="outlined"
                                        id="patient"
                                        label="Patient"
                                        icon="accessible"
                                        onLoadData={handlePatientData}
                                        onLoadItem={(item) => `${item.fullName} (${item.code})`}
                                        onChange={(event) => setForm({ ...form, patient: event.target.value })}
                                        value={form?.patient}
                                        fields={PATIENT_TABLE_FIELDS}
                                        required={true}
                                    />
                                </Grid>
                                <Grid lg={5} md={5} sm={12} item container justifyContent="center">
                                    <ObjectInput
                                        variant="outlined"
                                        id="transferBranch"
                                        label="Transfer Branch"
                                        icon="medication"
                                        onLoadData={handleBranchData}
                                        onLoadItem={(item) => item.name}
                                        onChange={(event) => setForm({ ...form, transferBranch: event.target.value })}
                                        value={form?.transferBranch}
                                        fields={BRANCH_TABLE_FIELDS}
                                    />
                                </Grid>
                                <Grid lg={2} md={2} sm={4} xs={12} item container justifyContent="center">
                                    <CheckboxInput
                                        label="Walk In?"
                                        value={form?.walkIn}
                                        onChange={(event) => {
                                            setForm({ ...form, walkIn: event.target.checked });
                                        }}
                                        checked={form?.walkIn || false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid direction="row" spacing={3} container>
                                <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                    <ObjectInput
                                        variant="outlined"
                                        id="doctor"
                                        label="Appointment Doctor"
                                        icon="medication"
                                        onLoadData={handleDoctorData}
                                        onLoadItem={(item) => item.fullName}
                                        onChange={(event) => setForm({ ...form, doctor: event.target.value, treatmentDoctor: event.target.value })}
                                        value={form?.doctor}
                                        fields={DOCTOR_TABLE_FIELDS}
                                        required={true}
                                    />
                                </Grid>
                                <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                    <ObjectInput
                                        variant="outlined"
                                        id="doctor"
                                        label="Treatment Doctor"
                                        icon="medication"
                                        onLoadData={handleDoctorData}
                                        onLoadItem={(item) => item.fullName}
                                        onChange={(event) => setForm({ ...form, treatmentDoctor: event.target.value })}
                                        value={form?.treatmentDoctor}
                                        fields={DOCTOR_TABLE_FIELDS}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextInput
                                    id="note"
                                    label="Note"
                                    rows={4}
                                    multiline={true}
                                    value={form?.note}
                                    onChange={(event) => setForm({ ...form, note: event.target.value })}
                                />
                            </Grid>
                            {form.status === 'FINISHED' ? (
                                <Grid item>
                                    <TextInput
                                        id="operationNote"
                                        label="Operation Note"
                                        rows={4}
                                        multiline={true}
                                        value={form?.operationNote}
                                        onChange={(event) => setForm({ ...form, operationNote: event.target.value })}
                                    />
                                </Grid>
                            ) : null}
                            <Accordion className={classes.accodionRoot} defaultExpanded={true}>
                                <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="mail-content" id="mail-header">
                                    <Icon className={classes.expansionIcon}>category</Icon>
                                    <Typography>Treatments</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AppointmentTreatmentForm
                                        treatments={form?.items}
                                        onChange={(data) => setForm({ ...form, items: data })}
                                        onError={handleError}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid justifyContent="flex-end" container>
                            <GobackButton />
                            {form.status === 'FINISHED' ? null : (
                                <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.submit}>
                                    <Icon color="action">save</Icon> Save
                                </Button>
                            )}
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(AppointmentDetail);
