import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Button, Container, Grid, Icon, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import PdfReportViewer from '../../component/report/PdfReportViewer';
import { DateTimeInput, ObjectInput } from '../../component/control';
import BranchApi from '../../api/BranchApi';
import { BRANCH_TABLE_FIELDS } from '../setup/Branch';
import FormatManager from '../../util/FormatManager';

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    filterForm: {
        margin: theme.spacing(2, 0),
    },
}));

const TO_DATE = new Date();
const FROM_DATE = new Date(TO_DATE.getFullYear(), TO_DATE.getMonth(), 1, TO_DATE.getHours(), TO_DATE.getMinutes());

const ReportViewer = (props) => {
    const { name } = useParams();

    const classes = styles();
    const [form, setForm] = useState({
        fromDate: FROM_DATE.getTime(),
        toDate: TO_DATE.getTime(),
        selectedBranches: [],
    });

    const [reportParams, setReportParams] = useState(null);
    const [isNewReport, setNewReport] = useState(true);
    const user = useSelector((state) => state.user);
    const isHead = user?.roles?.findIndex((r) => r.id === 1) >= 0;
    const reportName = `${name}_${isHead ? 'for_head' : 'for_branch'}`;

    useEffect(() => {
        if (!isNewReport) {
            setNewReport(true);
        }

        //eslint-disable-next-line
    }, [name]);

    const handleBranchData = async (currentPage, pageSize, sort, search) => {
        const result = await BranchApi.getPaging(currentPage, pageSize, sort, search);
        console.log('Branch Data => ', result);
        return result;
    };

    const handleSubmit = () => {
        if (isNewReport) {
            setNewReport(false);
        }
        if (isHead) {
            setReportParams({
                fromDate: form.fromDate,
                toDate: form.toDate,
                branchIdList: form.selectedBranches?.map((branch) => branch.id),
                branchNameList: form.selectedBranches?.map((branch) => branch.name),
            });
        } else {
            setReportParams({
                fromDate: form.fromDate,
                toDate: form.toDate,
                branchId: user.branch?.id,
                branchName: user.branch?.name,
            });
        }
    };

    return (
        <>
            <Container component="main">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon>home</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        ReportViewer
                    </Typography>
                    <Grid container className={classes.filterForm}>
                        <Grid direction="row" spacing={3} container>
                            <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                <DateTimeInput
                                    type="date"
                                    id="fromDate"
                                    icon="event"
                                    label="From"
                                    value={form?.fromDate}
                                    onChange={(event) => setForm({ ...form, fromDate: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                <DateTimeInput
                                    type="date"
                                    id="toDate"
                                    icon="event"
                                    label="To"
                                    value={form?.toDate}
                                    onChange={(event) => setForm({ ...form, toDate: event.target.value })}
                                    required
                                />
                            </Grid>
                        </Grid>
                        {isHead ? (
                            <Grid item container justifyContent="center">
                                <ObjectInput
                                    variant="outlined"
                                    id="fromBranch"
                                    label="Branches"
                                    icon="medication"
                                    multi={true}
                                    onLoadData={handleBranchData}
                                    onLoadItem={(item) => item.name}
                                    onChange={(event) => setForm({ ...form, selectedBranches: event.target.value })}
                                    values={form?.selectedBranches}
                                    fields={BRANCH_TABLE_FIELDS}
                                />
                            </Grid>
                        ) : null}
                        <Grid item lg={12} md={12} sm={12}>
                            <Button type="button" fullWidth variant="contained" color="primary" onClick={() => handleSubmit()}>
                                <Icon color="action">playlist_play</Icon> Run Report
                            </Button>
                        </Grid>
                    </Grid>
                    {isNewReport ? null : (
                        <PdfReportViewer
                            scaleSize={1}
                            title={FormatManager.snakeToReadable(name)}
                            reportParams={reportParams}
                            reportId={reportName}
                            onError={(error) => console.warn(error)}
                        />
                    )}
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(ReportViewer);
