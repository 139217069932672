import React, { useState, useEffect, useRef } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Typography,
    makeStyles,
    withStyles,
    IconButton,
    Badge,
    Avatar,
    Menu,
    MenuItem,
    ListItemIcon,
    Icon,
    ListItemText,
    Tooltip,
    Grid,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import FormatManager from '../../util/FormatManager';

export interface NotificationMenuProps {
    name: string;
    icon: string;
    anchorVertical: 'top' | 'bottom';
    anchorHorizontal: 'left' | 'right';
    badge: Integer;
    onLoadMore: object;
    onItemClick: object;
}

/**
 * DATA FIELDS
 * ==========
 * id,image,date,title,description,isRead
 */

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.error.main,
        padding: '0 4px',
        border: '1px solid ' + theme.palette.error.dark,
    },
}))(Badge);

const itemStyles = makeStyles((theme) => ({
    avatar: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    menuIcon: {
        color: theme.palette.text.primary,
        display: 'inline-flex',
        flexShrink: 0,
        minWidth: theme.spacing(5),
        paddingRight: theme.spacing(1),
    },
    menuText: {
        color: theme.palette.text.primary,
    },
    menuItem: (props) => ({
        padding: theme.spacing(0.5, 1.5),
        borderBottom: '1px solid ' + theme.palette.divider,
        backgroundColor: !props.isRead ? theme.palette.primary.highlight : theme.palette.background.paper,
    }),
    messageDate: {
        color: theme.palette.text.hint,
        fontSize: 8,
        float: 'right',
    },
}));

const NotificationMenuItem = (props) => {
    const { item, onClick, index } = props;
    const classes = itemStyles({ isRead: item.isRead });

    let imageUrl = item.image;

    return (
        <MenuItem disableGutters className={classes.menuItem} key={(item ? item.id : 'id') + '-' + index} onClick={() => onClick(item)}>
            <ListItemIcon className={classes.menuIcon}>
                {imageUrl ? (
                    <Avatar className={classes.avatar} alt={item.title} src={imageUrl} />
                ) : (
                    <Avatar className={classes.avatar}>{item.title[0]}</Avatar>
                )}
            </ListItemIcon>
            <ListItemText
                className={classes.menuText}
                primary={
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" gutterBottom>{`${item.title}`}</Typography>
                        <Typography variant="body1" className={classes.messageDate}>
                            {`${FormatManager.fromNow(item.createdAt)}`}
                        </Typography>
                    </Grid>
                }
                secondary={
                    <>
                        <Typography variant="caption">{item.description}</Typography>
                    </>
                }
            ></ListItemText>
        </MenuItem>
    );
};

const styles = makeStyles((theme) => ({
    notiBox: {
        maxHeight: 360,
        minWidth: 320,
        maxWidth: 480,
        top: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
    },
}));

const NotificationMenu = (props: NotificationMenuProps) => {
    const classes = styles();

    const { name, icon, anchorVertical, anchorHorizontal, badge, onLoadMore, onReadAll, onItemClick } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [paging, setPaging] = useState({
        total: 0,
        currentPage: -1,
        pageSize: 10,
        data: [],
    });

    const resetNotification = useRef(false);
    const [notiBadge, setNotiBadge] = useState('');
    const [showSkeleton, setShowSkeleton] = useState(false);

    const setPagingData = (result) => {
        if (result.data) {
            setPaging({
                ...result,
                data: [...paging.data, ...result.data],
            });
        }
    };

    useEffect(() => {
        loadMoreNotification(paging);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resetNotification.current) {
            resetNotification.current = false;
            loadMoreNotification(paging);
        }
        // eslint-disable-next-line
    }, [paging]);

    useEffect(() => {
        setNotiBadge(badge);

        resetNotification.current = true;
        let initPaging = {
            total: 0,
            currentPage: -1,
            pageSize: 20,
            data: [],
        };
        setPaging(initPaging);
    }, [badge]);

    const loadMoreNotification = async (newPaging) => {
        if (onLoadMore) {
            setShowSkeleton(true);
            const result = await onLoadMore({
                ...newPaging,
            });

            setPagingData(result);
            setShowSkeleton(false);
        }
    };

    let anchorOrigin = {
        vertical: anchorVertical || 'top',
        horizontal: anchorHorizontal || 'right',
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (item) => {
        if (onItemClick) onItemClick(item);
        handleClose();
    };

    const createSkeleton = (count) => {
        const result = [];
        for (let i = 0; i < count; i++) {
            result.push(
                <MenuItem key={`skeleton-${i}`}>
                    <ListItemIcon className={classes.menuIcon}>
                        <Skeleton variant="circle" width={32} height={32} />
                    </ListItemIcon>
                    <ListItemText>
                        <Skeleton />
                        <Skeleton height={10} width={200} />
                    </ListItemText>
                </MenuItem>,
            );
        }
        return showSkeleton ? result : null;
    };

    let badgeContent = {};
    if (notiBadge)
        badgeContent = {
            badgeContent: notiBadge,
        };

    return (
        <>
            <Tooltip title={name} aria-label={name}>
                <IconButton
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        if (onReadAll) {
                            onReadAll();
                            setNotiBadge('');
                        }
                    }}
                >
                    <StyledBadge anchorOrigin={anchorOrigin} {...badgeContent} color="secondary">
                        <Icon color="action">{icon || 'notifications'}</Icon>
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <Menu
                id="notification-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                elevation={6}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!paging.data ||
                    (paging.data.length <= 0 && (
                        <MenuItem key={'skeleton-01'} disabled={true}>
                            <ListItemText style={{ textAlign: 'center' }}>There is no data.</ListItemText>
                        </MenuItem>
                    ))}
                <div id="notiScroll" className={classes.notiBox}>
                    <InfiniteScroll
                        scrollableTarget="notiScroll"
                        style={{ width: '100%' }}
                        dataLength={paging.data?.length}
                        hasMore={paging.data?.length < paging.total - 1}
                        loader={<Typography variant="caption">Loading ...</Typography>}
                        next={() => {
                            loadMoreNotification({ ...paging });
                        }}
                    >
                        {paging.data.map((item, index) => (
                            <NotificationMenuItem key={`noti-item-${index}`} item={item} index={index} onClick={handleClick} />
                        ))}
                    </InfiniteScroll>
                </div>
            </Menu>
        </>
    );
};

NotificationMenu.defaultProps = {
    name: 'Notification',
    icon: 'notifications',
    badge: 0,
};

export default NotificationMenu;
