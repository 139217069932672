import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import PatientApi from '../../api/PatientApi';
import { useTheme } from '@material-ui/core';

export const PATIENT_TABLE_FIELDS = [
    {
        name: 'id',
        align: 'center',
        label: 'Id',
        sortable: true,
    },
    {
        name: 'profileImage',
        align: 'center',
        label: 'Profile',
        type: 'image',
    },
    {
        name: 'code',
        align: 'left',
        label: 'Code',
        sortable: true,
    },
    {
        name: 'fullName',
        align: 'left',
        label: 'Name',
        sortable: true,
    },
    {
        name: 'gender',
        align: 'center',
        label: 'Gender',
        sortable: true,
    },
    {
        name: 'status',
        label: 'Status',
        sortable: true,
    },
];

const Patient = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await PatientApi.getPaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        if (removeData && removeData.id) {
            return PatientApi.removeById(removeData.id);
        } else if (Array.isArray(removeData) && removeData.length > 0) {
            const removeIds = removeData.map((item) => item.id);
            return PatientApi.removeAll(removeIds);
        }
    };

    const handleDetail = (item) => {
        let url = '/patient/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    const handleAction = (item, data) => {
        if (item.id === 'patientCheckup') {
            history.push(`/patient/checkup/${data.id}`);
        }
    };

    return (
        <>
            <MasterTable
                title="Patients"
                fields={PATIENT_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onAddNew={() => handleDetail(null)}
                onRemove={handleRemoveData}
                onError={handleError}
                onItemAction={handleAction}
                hideImportMenu={true}
                moreActions={[
                    {
                        id: 'patientCheckup',
                        label: 'Checkup',
                        icon: 'monitor_heart',
                        color: theme.palette.info.main,
                    },
                ]}
            />
        </>
    );
};

export default withRouter(Patient);
