import React from 'react';
import { Button, Grid, Icon, Link, Paper, Typography, makeStyles } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import FormatManager from '../../util/FormatManager';
import DataAction from '../../component/table/DataAction';
import { defaultActions } from '../../component/MasterTable';
import FileImage from '../../component/file/FileImage';

//Carousel CSS Loader
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const itemStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        padding: theme.spacing(0.5, 1),
    },
    title: {
        width: 180,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    body: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        flex: 2,
        padding: theme.spacing(0.5, 2),
    },
    footer: {
        padding: theme.spacing(1),
    },
    footerButton: {
        width: '45%',
    },
    contentText: {},
    slideShow: {
        height: 150,
    },
    readMore: {
        color: theme.palette.info.main,
    },
    thumbnail: {
        width: '100%',
        height: 150,
        objectFit: 'contain',
    },
}));

export interface NewsfeedGridItemProps {
    onEdit: (data: Object) => void;
    onRemove: (data: Object) => void;
    onComment: (data: Object) => void;
    onReact: (data: Object) => void;
}

export const READ_MORE = {
    MAX_CHAR: 180,
    MAX_LINE: 5,
};

export const NewsfeedGridItem = (props: NewsfeedGridItemProps) => {
    const { onEdit, onRemove, onComment, onReact, ...item } = props;
    const classes = itemStyles();
    const { text: displayText, readMore } = FormatManager.compressText(item.content, READ_MORE.MAX_CHAR, READ_MORE.MAX_LINE);

    const handleDataAction = (item, data) => {
        if (item.id === 'edit' && onEdit) {
            onEdit(data);
        } else if (item.id === 'remove' && onRemove) {
            onRemove(data);
        }
    };

    const handleReadMore = (e) => {
        e.preventDefault();
        onEdit(item);
    };

    const renderImageList = () => {
        if (item.images && item.images.length > 1) {
            return (
                <Carousel showThumbs={false} className={classes.slideShow}>
                    {item.images.map((img, idx) => (
                        <div key={`image_${idx}`}>
                            <FileImage
                                className={classes.thumbnail}
                                file={img.image}
                                defaultImage="/images/default-image.png"
                                alt={img.name || 'Uploaded Image'}
                            />
                        </div>
                    ))}
                </Carousel>
            );
        } else if (item.images && item.images.length === 1) {
            const img = item.images[0];
            return (
                <Grid container justifyContent="center" alignItems="center">
                    <FileImage
                        className={classes.thumbnail}
                        file={img.image}
                        defaultImage="/images/default-image.png"
                        alt={img.name || 'Uploaded Image'}
                    />
                </Grid>
            );
        }

        return null;
    };

    return (
        <Grid container item xs={12} sm={6} md={4} lg={3}>
            <Paper className={classes.root}>
                <div className={classes.header} onDoubleClick={() => handleDataAction({ id: 'edit', item })}>
                    <Grid container justifyContent="space-between" alignItems="center" direction="row">
                        <div>
                            <Typography variant="subtitle1" className={classes.title}>
                                {item.author}
                            </Typography>
                            <Typography variant="caption">{FormatManager.formatDate(item.publishedAt, 'd MMMM, yyyy')}</Typography>
                        </div>
                        <DataAction onMenuItemClick={handleDataAction} actions={defaultActions} data={item} />
                    </Grid>
                </div>
                <div className={classes.body}>
                    <Grid className={classes.content} container>
                        {renderImageList()}
                        <Typography className={classes.contentText}>
                            {displayText}
                            {readMore ? (
                                <>
                                    {' '}
                                    <Link href="#" className={classes.readMore} underline="none" onClick={handleReadMore}>
                                        {'more detail ...'}
                                    </Link>
                                </>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid className={classes.footer} direction="row" justifyContent="space-between" alignItems="center" container item>
                        <Button className={classes.footerButton} onClick={() => onComment(item)}>
                            <Icon>comment</Icon>
                            {item.comments ? FormatManager.formatNumeral(item.comments, ' 0.0 a') : ''}
                        </Button>
                        <Button className={classes.footerButton} onClick={() => onReact(item)}>
                            <Icon>thumbs_up_down</Icon>
                            {item.reacts ? FormatManager.formatNumeral(item.reacts, ' 0.0 a') : ''}
                        </Button>
                    </Grid>
                </div>
            </Paper>
        </Grid>
    );
};
