import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import VoucherApi from '../../api/VoucherApi';
import FormatManager from '../../util/FormatManager';
import { useTheme } from '@material-ui/core';

export const VOUCHER_TABLE_FIELDS = [
    {
        name: 'code',
        label: 'Voucher Number',
        sortable: true,
    },
    {
        name: 'date',
        align: 'center',
        label: 'Date',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.date, 'dd/MM/yyyy'),
    },
    {
        name: 'appointment',
        align: 'left',
        label: 'Appointment',
        sortable: true,
        onLoad: (data, rowIdx) => data.appointment?.code ?? '',
    },
    {
        name: 'patient',
        align: 'left',
        label: 'Patient',
        sortable: true,
        onLoad: (data, rowIdx) => data.patient?.fullName ?? '',
    },
    {
        name: 'netAmount',
        align: 'right',
        label: 'Amount (MMK)',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.thousandSeparator(data.totalAmount - data.discountAmount),
    },
    {
        name: 'paidAmount',
        align: 'right',
        label: 'Paid (MMK)',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.thousandSeparator(data.paidAmount),
    },
    {
        name: 'netAmount',
        align: 'right',
        label: 'Left (MMK)',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.thousandSeparator(data.totalAmount - data.discountAmount - data.paidAmount),
    },
    {
        name: 'status',
        align: 'right',
        label: 'Status',
        sortable: true,
    },
];

const Voucher = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await VoucherApi.getPaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleDetail = (item) => {
        let url = '/voucher/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <MasterTable
                title="Vouchers"
                fields={VOUCHER_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onRemove={() => {}}
                onAddNew={() => handleDetail(null)}
                onError={handleError}
                hideCRUD={true}
                hideActionMenu={true}
                hideImportMenu={true}
                moreActions={[
                    {
                        id: 'edit',
                        label: 'Detail',
                        icon: 'receipt',
                    },
                ]}
                onRowColor={(data, rowIdx) => {
                    switch (data.status) {
                        case 'READY_TO_PAY':
                            return theme.palette.primary.light;
                        case 'PAYMENT_PROCESSING':
                            return theme.palette.warning.light;
                        case 'CLOSED':
                            return theme.palette.success.light;
                        default:
                            return theme.palette.background.default;
                    }
                }}
            />
        </>
    );
};

export default withRouter(Voucher);
