import React, { useState } from 'react';
import { Icon, makeStyles, useTheme, IconButton, TableHead, Table, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import type { GridProps } from '@material-ui/core';
import FormatManager from '../util/FormatManager';
import { AVAILABLE_CURRENICES } from '../config/Constant';
import { validateForm } from '../util/ValidationManager';

const styles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    tableHead: {
        borderBottom: theme.palette.divider,
    },
    tableInput: {
        width: '100%',
        backgroundColor: theme.palette.background.default,
        border: 'none',
        fontWeight: 'bolder',
        fontSize: '1em',
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5, 0),
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            color: theme.palette.action.disabled,
        },
    },
}));

export interface VoucherItemTableProps extends GridProps {
    data?: Array<Object>;
    onChange: (Array<Object>) => void;
    onError: (error: string | Object) => void;
}

const DEFAULT_VOUCHER_ITEM = {
    name: '',
    qty: 1,
    price: 0,
    exchangeRate: 1,
    currency: 'MMK',
    amount: 0,
};

const VoucherItemTable = (props: VoucherItemTableProps) => {
    const theme = useTheme();
    const classes = styles();
    const { data, onChange, onError, ...rest } = props;
    const [tempItem, setTempItem] = useState(DEFAULT_VOUCHER_ITEM);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleAdd = () => {
        const isValid = validateForm(
            tempItem,
            [
                { fieldId: 'name', required: true, message: 'Please enter description.' },
                { fieldId: 'qty', required: true, message: 'Please enter quantity.' },
                { fieldId: 'price', required: true, message: 'Please enter price.' },
                { fieldId: 'exchangeRate', required: true, message: 'Please enter exchange rate.' },
            ],
            onError,
        );

        if (isValid) {
            const newData = [...data, tempItem];
            if (onChange) {
                onChange(newData);
            }
            setTempItem(DEFAULT_VOUCHER_ITEM);
        }
    };

    const handleSelectRow = (event, rowIdx) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, rowIdx]);
        } else {
            const updateSelectedRows = selectedRows.filter((idx) => rowIdx !== idx);
            setSelectedRows(updateSelectedRows);
        }
    };

    const handleClearSelectedRows = (event) => {
        if (!event.target.checked) {
            setSelectedRows([]);
        }
    };

    const checkPriceRange = (event, rowIdx) => {
        const price = event.target.value;
        const item = data[rowIdx];
        if (item && item.min && item.max) {
            if (item.min > item.price || item.price > item.max) {
                handleRowDataChange(rowIdx, 'price', item.price < item.min ? item.min : item.max);
                onError(`Price must be between ${item.min} and ${item.max}`);
                event.preventDefault();
                return false;
            }
        }
        return true;
    };

    const handleRowDataChange = (rowIdx, key, value) => {
        const isMultiChange = selectedRows && selectedRows.length > 0 && selectedRows.findIndex((i) => i === rowIdx) >= 0;

        const newData = data.map((item, idx) => {
            if (isMultiChange && selectedRows.findIndex((i) => i === idx) >= 0) {
                item[key] = value;
            } else if (idx === rowIdx) {
                item[key] = value;
            }
            return item;
        });
        if (onChange) {
            onChange(newData);
        }
    };

    const preventArrowKey = (event) => {
        //prevent down arrow and up arrow
        if([38, 40].includes(event.keyCode)){
            event.preventDefault();
        }
    }

    //prevent scroll wheel on number input
    const preventScroll = (event) => {
        event.target.blur();
        event.stopPropagation();

        setTimeout(() => {
            event.target.focus();
        }, 0);
    };

    const handleRemove = (idx) => {
        const newData = data.filter((_, i) => i !== idx);
        if (onChange) {
            onChange(newData);
        }
    };

    const renderRow = (row, dataIdx) => {
        const amountInMMK = (row.qty || 1) * (row.price || 0) * (row.exchangeRate || 1);
        return (
            <TableRow key={dataIdx}>
                <TableCell>{dataIdx + 1}</TableCell>
                <TableCell>{row.name || `${row.treatmentType?.name} ${row.toothCode ? '(' + row.toothCode + ')' : ''}`}</TableCell>
                <TableCell>
                    <input
                        disabled={row.isTreament ?? false}
                        type="number"
                        className={classes.tableInput}
                        value={row.isTreament ? 1 : row.qty}
                        onChange={(event) => handleRowDataChange(dataIdx, 'qty', event.target.value)}
                        onKeyDown={(event) => {
                            preventArrowKey(event);
                            if (event.keyCode === 13) {
                                handleRowDataChange(dataIdx, 'qty', event.target.value);
                            }
                        }}
                        onWheel={preventScroll}
                    />
                </TableCell>
                {row.foc ? (
                    <TableCell colSpan={3}>FOC</TableCell>
                ) : (
                    <>
                        <TableCell>
                            <input
                                type="number"
                                className={classes.tableInput}
                                value={row.price}
                                onChange={(event) => handleRowDataChange(dataIdx, 'price', event.target.value)}
                                onKeyDown={(event) => {
                                    preventArrowKey(event);
                                    if (event.keyCode === 13) {
                                        // checkPriceRange(event, dataIdx);
                                        handleRowDataChange(dataIdx, 'price', event.target.value);
                                    }
                                }}
                                onWheel={preventScroll}
                                // onBlur={(event) => checkPriceRange(event, dataIdx)}
                            />
                        </TableCell>
                        <TableCell>
                            <select
                                className={classes.tableInput}
                                onChange={(event) => handleRowDataChange(dataIdx, 'currency', event.target.value)}
                                value={row.currency}
                            >
                                {AVAILABLE_CURRENICES.map((item) => (
                                    <option key={item.code} value={item.code}>
                                        {item.code}
                                    </option>
                                ))}
                            </select>
                        </TableCell>
                        <TableCell>
                            <input
                                type="number"
                                className={classes.tableInput}
                                value={row.exchangeRate || ''}
                                onChange={(event) => handleRowDataChange(dataIdx, 'exchangeRate', event.target.value)}
                                onKeyDown={(event) => {
                                    preventArrowKey(event);
                                    if (event.keyCode === 13) {
                                        handleRowDataChange(dataIdx, 'exchangeRate', event.target.value);
                                    }
                                }}
                                onWheel={preventScroll}
                            />
                        </TableCell>
                    </>
                )}
                <TableCell align="right">{FormatManager.thousandSeparator(amountInMMK)} MMK</TableCell>
                <TableCell align="center">
                    {row.disableRemove ? (
                        <Checkbox
                            size="small"
                            checked={selectedRows.findIndex((i) => i === dataIdx) >= 0}
                            onChange={(e) => handleSelectRow(e, dataIdx)}
                        />
                    ) : (
                        <IconButton size="small" onClick={() => handleRemove(dataIdx)}>
                            <Icon color="error">delete</Icon>
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Table size="medium" className={classes.root}>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell width={50}>No.</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell width={80}>Qty</TableCell>
                    <TableCell width={150}>Price</TableCell>
                    <TableCell width={80}>Currency</TableCell>
                    <TableCell width={110}>Ex. Rate</TableCell>
                    <TableCell align="right" width={150}>
                        Amount (MMK)
                    </TableCell>
                    <TableCell width={50} align="center">
                        <Checkbox size="small" checked={selectedRows && selectedRows.length > 0} onChange={(e) => handleClearSelectedRows(e)} />
                    </TableCell>
                </TableRow>
            </TableHead>
            {data.length > 0 ? (
                <TableBody>
                    {data.map(renderRow)}
                    <TableRow key={'new_item'}>
                        <TableCell>{data.length + 1}</TableCell>
                        <TableCell>
                            <input
                                type="text"
                                id="new_item_name"
                                className={classes.tableInput}
                                value={tempItem?.name || ''}
                                onChange={(event) => setTempItem({ ...tempItem, name: event.target.value })}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        setTempItem({ ...tempItem, name: event.target.value });
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <input
                                type="number"
                                className={classes.tableInput}
                                value={tempItem?.qty || ''}
                                onChange={(event) => setTempItem({ ...tempItem, qty: event.target.value })}
                                onKeyDown={(event) => {
                                    preventArrowKey(event);
                                    if (event.keyCode === 13) {
                                        setTempItem({ ...tempItem, qty: event.target.value });
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <input
                                type="number"
                                className={classes.tableInput}
                                value={tempItem.price || ''}
                                onChange={(event) => setTempItem({ ...tempItem, price: event.target.value })}
                                onKeyDown={(event) => {
                                    preventArrowKey(event);
                                    if (event.keyCode === 13) {
                                        setTempItem({ ...tempItem, price: event.target.value });
                                        handleAdd();
                                        document.getElementById('new_item_name').focus();
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell align="right" colSpan={3}>
                            {FormatManager.thousandSeparator(tempItem.qty * tempItem.price * tempItem.exchangeRate)} MMK
                        </TableCell>
                        <TableCell align="center">
                            <IconButton size="small" onClick={() => handleAdd()}>
                                <Icon color="primary">add</Icon>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            ) : null}
        </Table>
    );
};

VoucherItemTable.defaultProps = {
    data: [],
    onChange: (data) => console.log('Voucher Item Data Changed => ', data),
    onError: (error) => console.warn('Voucher Item Error => ', error),
};

export default VoucherItemTable;
