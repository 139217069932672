import React, { useRef, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Typography, Container, Avatar, Icon, Grid, Button, Paper, makeStyles } from '@material-ui/core';

import PatientApi, { STATUS } from '../../api/PatientApi';
import MasterForm from '../../component/MasterForm';
import { GENDERS } from '../../config/Constant';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { DateTimeInput, ImageInput, ListInput, ObjectInput, TextInput } from '../../component/control';
import ContactForm2 from '../../fragment/ContactForm2';
import { validateForm } from '../../util/ValidationManager';
import FileManager from '../../component/file/FileManager';
import BankInfoForm from '../../fragment/BankInfoForm';
import { PATIENT_TYPE_TABLE_FIELDS } from '../setup/PatientType';
import PatientTypeApi from '../../api/PatientTypeApi';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
    form: {
        marginTop: theme.spacing(3),
    },
}));

const PatientDetail = (props) => {
    const classes = styles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isUpdate, setUpdate] = useState(false);
    const infoForm = useRef();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };
    const [form, setForm] = useState(() => {
        if (id > 0) {
            PatientApi.getById(id)
                .then((data) => {
                    setForm({ ...data, image: data.profileImage });
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }
        return {};
    });

    const handlePatientTypeData = async (currentPage, pageSize, sort, search) => {
        return await PatientTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }
        const isValid = validateForm(
            form,
            [
                { fieldId: 'fullName', required: true },
                { fieldId: 'dateOfBirth', required: true },
                { fieldId: 'gender', required: true },
                { fieldId: 'address', required: true },
                { fieldId: 'phones', required: true },
            ],
            handleError,
        );

        if (!isValid) {
            return;
        }
        try {
            let patient = { ...form };
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            if (form.image && form.image.id) {
                patient.profileImage = form.image;
            } else if (form.image) {
                const fileResponse = await PatientApi.fileUpload(form.image, 'profileImage');
                if (fileResponse) {
                    patient.profileImage = fileResponse;
                }
            } else {
                patient.profileImage = null;
            }

            let message = '';
            if (isUpdate) {
                const response = await PatientApi.modifyById(id, patient);
                message = `Patient ${response.code} has been updated successfully.`;
            } else {
                const response = await PatientApi.addNew(patient);
                message = `Patient ${response.code} has been registered successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });
            history.push('/patient');
        } catch (error) {
            handleError(error);
        }
    };

    const renderInfoForm = () => {
        return (
            <Grid container direction="column">
                <Grid direction="row" spacing={3} container>
                    <Grid lg={3} md={4} sm={6} xs={12} item>
                        <Grid container justifyContent="center">
                            <ImageInput
                                size={{ width: 180, height: 180 }}
                                id="profileImage"
                                enableFilePicker={false}
                                required={true}
                                guild="PATIENT"
                                value={form?.image}
                                onChange={(event) => setForm({ ...form, image: event.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid lg={9} md={8} sm={6} xs={12} direction="column" container item>
                        <Grid direction="row" spacing={2} container>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextInput id="code" icon="fingerprint" label="Code" value={form?.code} disabled={true} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <ObjectInput
                                    variant="outlined"
                                    id="patientType"
                                    label="Patient Type"
                                    icon="category"
                                    onLoadData={handlePatientTypeData}
                                    onLoadItem={(item) => `${item.name} (${item.discountPercent}%)`}
                                    onChange={(event) => setForm({ ...form, patientType: event.target.value })}
                                    value={form?.patientType}
                                    fields={PATIENT_TYPE_TABLE_FIELDS}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextInput
                                id="fullName"
                                icon="face"
                                label="Full Name"
                                value={form?.fullName}
                                onChange={(event) => setForm({ ...form, fullName: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid spacing={2} container item>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <DateTimeInput
                                    type="date"
                                    id="dateOfBirth"
                                    icon="event"
                                    label="Date of birth"
                                    value={form?.dateOfBirth}
                                    onChange={(event) => setForm({ ...form, dateOfBirth: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <ListInput
                                    id="gender"
                                    label="Gender"
                                    icon="wc"
                                    data={GENDERS}
                                    value={form?.gender}
                                    onChange={(event) => setForm({ ...form, gender: event.target.value })}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <ListInput
                                    id="status"
                                    label="Status"
                                    data={STATUS}
                                    value={form?.status}
                                    onChange={(event) => setForm({ ...form, status: event.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextInput
                        id="occupation"
                        label="Occupation"
                        value={form?.occupation}
                        onChange={(event) => setForm({ ...form, occupation: event.target.value })}
                    />
                </Grid>
                <Grid item>
                    <TextInput
                        id="criticalInformation"
                        label="Critical Information"
                        rows={4}
                        multiline={true}
                        value={form?.criticalInformation}
                        onChange={(event) => setForm({ ...form, criticalInformation: event.target.value })}
                    />
                </Grid>
                <Grid item>
                    <TextInput
                        id="note"
                        label="Note"
                        rows={4}
                        multiline={true}
                        value={form?.note}
                        onChange={(event) => setForm({ ...form, note: event.target.value })}
                    />
                </Grid>
            </Grid>
        );
    };

    const renderContacts = () => {
        return (
            <ContactForm2
                onChangeAddress={(data) => setForm({ ...form, address: data })}
                address={form.address}
                onChangePhones={(data) => setForm({ ...form, phones: data })}
                phones={form.phones}
                onChangeEmails={(data) => setForm({ ...form, emails: data })}
                emails={form.emails}
                onError={handleError}
            />
        );
    };

    const renderBankInfos = () => {
        return <BankInfoForm onChange={(data) => setForm({ ...form, bankInfos: data })} data={form.bankInfos} onError={handleError} />;
    };

    let tabFields = [
        {
            label: 'Info',
            icon: 'info',
            content: renderInfoForm(),
        },
        {
            label: 'Contacts',
            icon: 'contacts',
            content: renderContacts(),
        },
        {
            label: 'Bank Infos',
            icon: 'account_balance',
            content: renderBankInfos(),
        },
    ];

    if (isUpdate) {
        tabFields.push({
            label: 'Documents',
            icon: 'folder',
            content: <FileManager guild={`PATIENT_${id}_DOCS`} accept={'*'} />,
        });
    }

    console.log('History => ', history.length);

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">accessible</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Patient Registration
                    </Typography>
                    <MasterForm className={classes.form} type="tab" fields={tabFields}>
                        <Grid justifyContent="flex-end" container>
                            <GobackButton windowClose={true} />
                            {isUpdate ? (
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        history.push(`/patient/checkup/${id}`);
                                    }}
                                    className={classes.submit}
                                >
                                    <Icon color="action">monitor_heart</Icon> Checkup
                                </Button>
                            ) : null}
                            <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.submit}>
                                <Icon color="action">save</Icon> Save
                            </Button>
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(PatientDetail);
