import ApiManager from '../util/ApiManager';
import { faker } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';

export const POST_TYPES = {
    newsfeed: {
        title: 'Newsfeed',
    },
    announcement: {
        title: 'Announcement',
    },
};

class PostApi extends ApiManager {
    constructor() {
        super('/cms/posts');
    }

    async getPagingByType(isAnnouncement, page, size = 10, sort = 'modifiedAt:DESC', filter = '') {
        let url = `/announcement-${isAnnouncement ? 't' : 'f'}?page=${page}&size=${size}`;
        if (sort && sort !== '') url += '&sort=' + sort;
        if (filter && filter !== '') url += '&filter=' + filter;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async uploadImage(file, caption, priority = 0) {
        let headers = this.getHeaders(true);
        headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append('caption', caption);
        formData.append('priority', priority);
        if (file.name) {
            formData.append('image', file);
        }

        const response = await this.post('/image/upload', formData, headers);
        return response;
    }

    fakeImage() {
        const rndImage = faker.number.int({ min: 1, max: 100 });
        const dim = faker.datatype.boolean() ? '600x400' : '400x600';
        return {
            id: uuid(),
            image: {
                preview: `https://source.unsplash.com/random/${dim}?sig=${rndImage}`,
                type: 'image/jpg',
            },
            caption: faker.lorem.lines(),
        };
    }

    fakeReact() {
        return {
            id: uuid(),
            patient: {
                id: faker.number.int({ min: 1, max: 100 }),
            },
        };
    }

    fakeNewsfeed() {
        let images = [];
        const hasImage = faker.datatype.boolean();
        if (hasImage) {
            for (let i = 0; i < faker.number.int({ min: 1, max: 5 }); i++) {
                const image = this.fakeImage();
                images.push(image);
            }
        }

        const tags = faker.lorem.words({ min: 0, max: 10 });

        return {
            content: faker.lorem.paragraphs({ min: 1, max: 3 }),
            author: faker.person.fullName(),
            images: hasImage ? images : null,
            publishedAt: faker.date.past().getTime(),
            externalUrl: faker.internet.url(),
            relatedPost: faker.number.int({ max: 100 }),
            tags: tags.replace(' ', ','),
            smsNoti: faker.datatype.boolean(0.5),
            emailNoti: faker.datatype.boolean(0.2),
            appNoti: faker.datatype.boolean(0.7),
            endDate: faker.date.future().getTime(),
            repostCount: faker.number.int({ max: 10 }),
            isAnnouncement: false,
            reacts: {
                total: faker.number.int({ max: 99999 }),
                currentPage: 1,
                data: [],
                pageSize: 10,
                sort: 'modifiedAt:DESC',
            },
            comments: {
                total: faker.number.int({ max: 99999 }),
                currentPage: 1,
                data: [],
                pageSize: 10,
                sort: 'modifiedAt:DESC',
            },
        };
    }

    fakeAnnouncement() {
        return {
            content: faker.lorem.lines(1),
            backgroundColor: faker.color.rgb(),
            textColor: faker.color.rgb(),
            author: faker.person.fullName(),
            publishedAt: faker.date.past().getTime(),
            externalUrl: faker.internet.url(),
            relatedPost: faker.number.int({ max: 100 }),
            smsNoti: faker.datatype.boolean(0.5),
            emailNoti: faker.datatype.boolean(0.2),
            appNoti: faker.datatype.boolean(0.7),
            endDate: faker.date.future().getTime(),
            repostCount: faker.number.int({ max: 10 }),
            isAnnouncement: true,
            reacts: {
                total: faker.number.int({ max: 99999 }),
                currentPage: 1,
                data: [],
                pageSize: 10,
                sort: 'modifiedAt:DESC',
            },
            comments: {
                total: faker.number.int({ max: 99999 }),
                currentPage: 1,
                data: [],
                pageSize: 10,
                sort: 'modifiedAt:DESC',
            },
        };
    }

    fakeList(type, count) {
        let data = [];
        for (let i = 0; i < count; i++) {
            let fakeData = type === 'announcement' ? this.fakeAnnouncement() : this.fakeNewsfeed();
            fakeData.id = i + 1;
            data.push(fakeData);
        }
        return data;
    }

    async fakePaging(type, page = 0, size = 24, sort = 'createdAt:DESC', filter = '') {
        let data = this.fakeList(type, size);
        return {
            total: size * (page + 1),
            currentPage: page,
            data: data,
            pageSize: size,
            sort: 'id:ASC',
        };
    }
}

export default new PostApi();
