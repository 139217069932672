import React, { useEffect, useState } from 'react';
import { useParams, withRouter, useHistory } from 'react-router-dom';
import PostApi, { POST_TYPES } from '../../api/PostApi';
import { Button, ButtonGroup, Grid, Icon, Paper, Table, TableFooter, TableRow, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { SearchInput } from '../../component/control';
import PaginationBar from '../../component/PaginationBar';
import { AnnouncementGridItem } from '../../fragment/cms/AnnouncementGridItem';
import { NewsfeedGridItem } from '../../fragment/cms/NewsfeedGridItem';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import FormatManager from '../../util/FormatManager';
import { useDispatch } from 'react-redux';

export const POST_TABLE_FIELDS = [
    {
        name: 'publishedAt',
        align: 'center',
        type: 'date',
        label: 'Published',
        minWidth: 120,
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.publishedAt, 'yyyy-MM-dd HH:mm'),
    },
    {
        name: 'author',
        label: 'Author',
        minWidth: 150,
        sortable: false,
    },
    {
        name: 'content',
        label: 'Content',
        onLoad: (data, rowIdx) => {
            const { text: displayText, readMore } = FormatManager.compressText(data.content, 250, 3);
            return displayText + (readMore ? ' ...' : '');
        },
    },
    {
        name: 'announcement',
        type: 'bool',
        align: 'center',
        label: 'Announce?',
    },
];

const styles = makeStyles((theme) => ({
    root: {},
    header: {
        flex: 1,
        // backgroundColor:theme.palette.primary.main,
        borderBottom: '1px solid' + theme.palette.divider,
        padding: theme.spacing(0, 1),
    },
    searchBox: {
        width: theme.spacing(32),
        marginRight: theme.spacing(1),
    },
    toolbar: {
        marginLeft: theme.spacing(1),
    },
    gridContainer: {
        width: '100%',
        background: theme.palette.background.default,
        padding: theme.spacing(1.5, 1),
        margin: theme.spacing(1, 0),
    },
    footer: {
        background: theme.palette.background.paper,
    },
}));

const Post = () => {
    const { postType } = useParams();
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const loadPosts = async (currentPage, pageSize, sort) => {
        if (Object.keys(POST_TYPES).includes(postType)) {
            try {
                const resp = await PostApi.getPagingByType(postType === 'announcement', currentPage, pageSize, sort, search);
                setPaging(resp);
            } catch (error) {
                handleError(error);
                setPaging({});
            }
        }
    };

    useEffect(() => {
        loadPosts(0, paging.pageSize, paging.sort);
        //eslint-disable-next-line
    }, [postType, search]);

    const [paging, setPaging] = useState(() => {
        loadPosts(0, 24);
        return {
            currentPage: 0,
            pageSize: 24,
            total: 0,
            data: [],
            sort: 'modifiedAt:DESC',
        };
    });

    const handleReload = () => {
        loadPosts(0, paging.pageSize);
    };

    const handleRemove = (item) => {
        console.log('Remove item => ', item);
    };

    const handleComments = (item) => {
        const id = item ? item.id : 0;
        history.push(`/cms/post/${postType}/${id}/comments`);
    };

    const handleReacts = (item) => {
        const id = item ? item.id : 0;
        history.push(`/cms/post/${postType}/${id}/reacts`);
    };

    const handleDetail = (item) => {
        let url = `/cms/post/${postType}/`;
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <Paper className={classes.root} elevation={3}>
                <Grid container className={classes.header}>
                    <Grid container item lg={5} md={5} sm={6} xs={12} alignItems="center" alignContent="center" justifyContent="flex-start">
                        <SearchInput className={classes.searchBox} value={search} onSearch={(value) => setSearch(value)} placeholder="Search" />
                    </Grid>
                    <Grid container item lg={2} md={2} sm={6} xs={12} alignItems="center" alignContent="center" justifyContent="center">
                        <Typography variant="h6" component="h1" noWrap>
                            {Object.keys(POST_TYPES).includes(postType) ? POST_TYPES[postType].title : 'Unknown'}
                        </Typography>
                    </Grid>
                    <Grid container item lg={5} md={5} sm={12} xs={12} alignItems="center" alignContent="center" justifyContent="flex-end">
                        <ButtonGroup disableElevation className={classes.toolbar}>
                            <Tooltip title="Reload">
                                <Button onClick={handleReload} variant="contained" color="primary" aria-label="Add New">
                                    <Icon>cached</Icon>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Create New">
                                <Button onClick={() => handleDetail(null)} variant="contained" color="primary" aria-label="Add New">
                                    <Icon>add</Icon>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid container className={classes.gridContainer} spacing={2}>
                        {paging.data && paging.data.length > 0 ? (
                            paging.data.map((item, index) => {
                                if (postType === 'announcement') {
                                    return (
                                        <AnnouncementGridItem
                                            key={item.id + '-' + index}
                                            onEdit={handleDetail}
                                            onRemove={handleRemove}
                                            onComment={handleComments}
                                            onReact={handleReacts}
                                            {...item}
                                        />
                                    );
                                }

                                return (
                                    <NewsfeedGridItem
                                        key={item.id + '-' + index}
                                        onEdit={handleDetail}
                                        onRemove={handleRemove}
                                        onComment={handleComments}
                                        onReact={handleReacts}
                                        {...item}
                                    />
                                );
                            })
                        ) : (
                            <Grid item>
                                <Typography variant="body2">There is no data.</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Table className={classes.footer}>
                        <TableFooter>
                            <TableRow>
                                <PaginationBar
                                    rowsPerPage={[12, 24, 36, 72]}
                                    total={paging.total}
                                    pageSize={paging.pageSize}
                                    currentPage={paging.currentPage}
                                    onPageChange={(newPage) => loadPosts(newPage, paging.pageSize, paging.sort)}
                                    onPageSizeChange={(size) => loadPosts(0, size, paging.sort)}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Grid>
            </Paper>
        </>
    );
};

export default withRouter(Post);
