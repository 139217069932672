import { Grid } from '@material-ui/core';
import React from 'react';
import { ChipInput, TextInput } from '../../component/control';
import NewsfeedImagesInput from './NewsfeedImagesInput';

export interface NewsfeedFormProps {
    data: Object;
    onChange: (Object) => void;
    onError: (Object) => void;
}

const NewsfeedForm = (props: NewsfeedFormProps) => {
    const { data, onChange, onError } = props;

    const handleInputChange = (key, value) => {
        if (onChange) {
            onChange(key, value);
        }
    };

    return (
        <>
            <Grid container item>
                <TextInput
                    id="content"
                    label="Content"
                    rows={8}
                    multiline={true}
                    value={data?.content}
                    onChange={(event) => handleInputChange('content', event.target.value)}
                    required
                />
            </Grid>
            <Grid container item>
                <NewsfeedImagesInput
                    values={data?.images}
                    id="images"
                    name="images"
                    onChange={(event) => handleInputChange('images', event.target.value)}
                />
            </Grid>
            <Grid container item>
                <TextInput
                    id="author"
                    icon="person"
                    label="Author"
                    value={data?.author}
                    onChange={(event) => handleInputChange('author', event.target.value)}
                />
            </Grid>
            <Grid container item>
                <ChipInput
                    variant="outlined"
                    id="tags"
                    label="Tags"
                    icon="local_offer"
                    value={data?.tags}
                    onChange={(event) => handleInputChange('tags', event.target.value)}
                />
            </Grid>
        </>
    );
};

export default NewsfeedForm;
