import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Container, FormControlLabel, Icon, Paper, Switch, ThemeProvider } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import ToothTable from '../component/tooth/ToothTable';
import { TextInput } from '../component/control';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../util/AlertManager';
import FormatManager from '../util/FormatManager';
import MasterForm from '../component/MasterForm';
import AppointmentTreatmentForm from '../fragment/AppointmentTreatmentForm';
import VoucherApi from '../api/VoucherApi';
import AppointmentApi from '../api/AppointmentApi';
import AppointmentHistory from '../fragment/AppointmentHistory';
import ToothTreatmentModal from '../fragment/ToothTreatmentModal';
import { v4 as uuid } from 'uuid';
import { ErrorTheme } from '../config/Theme';

const styles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2, 3, 0),
        minWidth: 900 + theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infoGrid: {
        width: '100%',
    },
    infoRow: {
        padding: theme.spacing(1, 0),
    },
    infoLabel: {
        padding: theme.spacing(2, 0, 0),
        width: 80,
    },
    infoSeperator: {
        padding: theme.spacing(2, 0, 0),
        width: 20,
    },
    infoValue: {
        textAlign: 'left',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        color: theme.palette.primary.dark,
        width: 200,
        padding: theme.spacing(2, 1, 0),
    },
    info: {
        margin: theme.spacing(2, 0),
    },
    form: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1, 0),
    },
    linkButton: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        cursor: 'pointer',
    },
    submit: {},
}));

const CHILD_TOOTH_REGEX = /[\\+|\\-]?[A-E]{1}[\\+|\\-]?/g;

const Operation = (props) => {
    const classes = styles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };
    const [voucher, setVoucher] = useState(null);
    const [isChild, setChild] = useState(false);
    const [patientHistory, setPatientHistory] = useState(null);
    const [operationNote, setOperationNote] = useState('');
    const [appointmentCode, setAppointmentCode] = useState('');
    const [treatments, setTreatments] = useState([]);
    const [toothCode, setToothCode] = useState('');

    const handleCheckVoucher = async () => {
        try {
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            const response = await VoucherApi.getVoucherForOpertion(appointmentCode);
            if (response) {
                setVoucher(response);
                if (response.appointment) {
                    const latestAppointment = await AppointmentApi.getHistory(response.appointment.id);
                    if (latestAppointment.id !== response.appointment.id) {
                        setPatientHistory(latestAppointment);
                    }
                    //Get appointment treatments
                    const treatments = response.appointment?.items || [];
                    const childTooth = treatments.filter((item) => item.toothCode?.match(CHILD_TOOTH_REGEX));
                    setChild(childTooth.length > 0);
                    setTreatments(
                        treatments.map((t) => {
                            const id = uuid();
                            return { ...t, id };
                        }),
                    );
                }
                dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleSave = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }
        dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });

        try {
            const operatedTreatments = treatments.filter((t) => t.operated).map((t) => ({ treatmentType: t.treatmentType, toothCode: t.toothCode }));

            if (operatedTreatments.length <= 0) {
                handleError('You need to finish at least one treatement.');
                return;
            }

            const saveVoucher = { ...voucher };
            //Set voucher treatments if operated
            saveVoucher.treatments = operatedTreatments;

            //Set operation info to appointment
            voucher.appointment.treatments = treatments;
            voucher.appointment.operationNote = operationNote;
            await VoucherApi.operationFinished(saveVoucher);

            dispatch({
                type: ALERT_REDUX_ACTIONS.SHOW,
                alert: { type: 'Finished', message: `Operation completed for appointment : ${appointmentCode}` },
            });

            setVoucher(null);
            setAppointmentCode('');
            setOperationNote('');
            setTreatments([]);
        } catch (error) {
            handleError(error);
        }
    };

    const handleToothClick = (toothCode) => {
        const treatmentsCount = treatments.filter((t) => t.toothCode === toothCode);
        if (treatmentsCount.length === 1) {
            const newData = treatments.map((t, i) => {
                if (t.toothCode === toothCode) {
                    t.operated = true;
                }
                return t;
            });
            setTreatments(newData);
        } else if (treatmentsCount.length > 1) {
            setToothCode(toothCode);
        } else {
            console.log('No treatment');
        }
    };

    const renderToothTable = () => {
        let toothTreatments = [];
        //Reduce Treatments
        treatments
            .filter((t) => t.toothCode?.length > 0)
            .forEach((t) => {
                if (toothTreatments.indexOf(t.toothCode) === -1) {
                    toothTreatments.push(t);
                }
            });

        //Map for toothtable
        const tooths = toothTreatments.map((t) => {
            const fixedTooths = treatments.filter((x) => x.toothCode === t.toothCode && !x.operated);
            return {
                code: t.toothCode,
                type: fixedTooths.length > 0 ? 'marked' : 'fixed',
            };
        });
        return (
            <Grid direction="column" container>
                <ToothTreatmentModal
                    show={toothCode?.length > 0}
                    toothCode={toothCode}
                    data={treatments}
                    onChange={setTreatments}
                    onClose={() => setToothCode('')}
                />
                <Grid>
                    <FormControlLabel
                        control={<Switch checked={isChild} onChange={(event) => setChild(event.target.checked)} name="checkedB" color="primary" />}
                        label="Child Table"
                    />
                </Grid>
                <ToothTable isChild={isChild} onItemClick={handleToothClick} data={tooths} />
            </Grid>
        );
    };

    const renderTreatments = () => {
        return <AppointmentTreatmentForm treatments={treatments} onChange={setTreatments} onError={handleError} isOperation={true} />;
    };

    let tabFields = [
        { label: 'Treatments', icon: 'task', content: renderTreatments() },
        { label: 'Tooths Table', icon: 'table_chart', content: renderToothTable() },
    ];
    if (patientHistory) {
        tabFields.push({ label: 'Previous History', icon: 'history', content: <AppointmentHistory data={patientHistory} /> });
    }

    //Build Branch Info with Transfer
    let branchInfo = voucher?.branch?.code || '';
    if (voucher?.appointment?.transferBranch) {
        branchInfo = voucher?.appointment?.transferBranch?.code + ' --> ' + voucher?.branch?.code;
    }

    //Combine Appointment Doctor and Treatment Doctor
    let doctorInfo = voucher?.doctor?.fullName || '';
    if (voucher?.appointment?.treatmentDoctor?.id && voucher?.doctor?.id !== voucher?.appointment?.treatmentDoctor?.id) {
        doctorInfo += `(${voucher?.appointment?.treatmentDoctor.fullName})`;
    }

    return (
        <Container className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <Grid container alignItems="center" justifyContent="center">
                    <TextInput
                        id="code"
                        icon="code"
                        label="Appointment No."
                        value={appointmentCode}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                handleCheckVoucher();
                            }
                        }}
                        onChange={(event) => setAppointmentCode(event.target.value)}
                    />
                </Grid>
                <Grid className={classes.info} container direction="row" justifyContent="space-between">
                    <Grid lg={5} md={5} sm={12} xs={12} item>
                        <table className={classes.infoGrid}>
                            <tbody>
                                <tr className={classes.infoRow}>
                                    <td className={classes.infoLabel}>Branch</td>
                                    <td className={classes.infoSeperator}>:</td>
                                    <th className={classes.infoValue}>{branchInfo}</th>
                                </tr>
                                <tr className={classes.infoRow}>
                                    <td className={classes.infoLabel}>Doctor</td>
                                    <td className={classes.infoSeperator}>:</td>
                                    <th className={classes.infoValue}>{doctorInfo}</th>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid lg={5} md={5} sm={12} xs={12} item>
                        <table className={classes.infoGrid}>
                            <tbody>
                                <tr className={classes.infoRow}>
                                    <td className={classes.infoLabel}>Date</td>
                                    <td className={classes.infoSeperator}>:</td>
                                    <th className={classes.infoValue}>{FormatManager.formatDate(voucher?.date, 'dd MMM, yyyy')}</th>
                                </tr>
                                <tr className={classes.infoRow}>
                                    <td className={classes.infoLabel}>Patient</td>
                                    <td className={classes.infoSeperator}>:</td>
                                    <th className={classes.infoValue}>
                                        <a
                                            className={classes.linkButton}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const url = `/patient/detail/${voucher?.patient?.id}`;
                                                const patient_window = window.open(url, '_blank');
                                                patient_window.focus();
                                            }}
                                        >
                                            {voucher?.patient?.fullName}
                                        </a>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
                <MasterForm className={classes.form} type="tab" fields={tabFields}>
                    <Grid direction="column" container>
                        <TextInput
                            id="operationNote"
                            label="Operation Note"
                            value={operationNote}
                            onChange={(event) => setOperationNote(event.target.value)}
                            multiline={true}
                            rows={4}
                        />
                        <Grid direction="row" spacing={3} alignItems="center" container>
                            <Grid lg={6} md={6} sm={12} item>
                                <ThemeProvider theme={ErrorTheme}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        disabled={voucher == null || appointmentCode.length <= 0}
                                        fullWidth
                                        onClick={() => {
                                            const url = `/patient/behaviors/${voucher?.appointment?.id}`;
                                            const behavior_window = window.open(url, '_blank');
                                            behavior_window.focus();
                                        }}
                                        className={classes.submit}
                                    >
                                        <Icon>psychology</Icon> Behaviors
                                    </Button>
                                </ThemeProvider>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} item>
                                <Button
                                    type="button"
                                    disabled={voucher == null || appointmentCode.length <= 0}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSave()}
                                    className={classes.submit}
                                >
                                    <Icon color="action">verified</Icon> Finished
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </MasterForm>
            </Paper>
        </Container>
    );
};

export default withRouter(Operation);
