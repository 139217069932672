import React, { useState, createRef, useEffect } from 'react';
import { IconButton, makeStyles, Icon } from '@material-ui/core';
import ListPicker from './ListPicker';
import FileManagerPicker from '../file/FileManagerPicker';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import type { HTMLProps } from 'react';
import FileImage from '../file/FileImage';

export interface ImageSize {
    width: number;
    height: number;
}

export interface ImageInputProps extends HTMLProps {
    size: ?ImageSize;
    enableFilePicker?: boolean;
    disabledUpload?: boolean;
    disabledRemove?: boolean;
    value: object | string;
    guild: string;
    onUpload?: () => void;
    onRemove?: () => void;
    onChange: (image: object | string) => void;
}

const styles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        position: 'relative',
        border: '3px solid ' + theme.palette.divider,
        display: 'inline-flex',
        margin: theme.spacing(2, 1),
    },
    removeButton: {
        position: 'absolute',
        top: -12,
        right: -12,
        backgroundColor: theme.palette.error.main,
        color: 'white',
        padding: 0,
        width: 25,
        height: 25,
        fontSize: 12,
        border: 'none',
        cursor: 'pointer',
        borderRadius: 40,
    },
    hiddenInput: {
        display: 'none',
        zIndex: -9999,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.background.paper,
        border: 0,
    },
    image: {
        backgroundColor: theme.palette.common.lightGray,
    },
}));

const MENU_LIST_ITEMS = [
    {
        id: 'gallery',
        icon: 'photo',
        label: 'Choose From Gallery',
    },
    {
        id: 'upload',
        icon: 'cloud_upload',
        label: 'Upload New Image',
    },
];

const ImageInput = (props: ImageInputProps) => {
    const classes = styles();
    const { id, guild, name, size, value, enableFilePicker, disabledUpload, disabledRemove, onChange, onRemove, required, ...rest } = props;
    const dispatch = useDispatch();

    const [showMenu, setShowMenu] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [image, setImage] = useState(value);
    const inputUpload = createRef();

    useEffect(() => {
        if (value && inputUpload.current) {
            handleChange(value);
        } else if (!value || Object.entries(value).length === 0) {
            setImage(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (result) => {
        setImage(result);
        if (onChange) {
            const obj = {
                target: {
                    id: id || name,
                    name: id || name,
                    value: result,
                    type: 'image',
                },
            };
            onChange(obj);
        }
    };

    const loadLocalFile = async (file) => {
        try {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onloadend = (e) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
                handleChange(file);
            };
        } catch (error) {
            console.warn('File Load Error: ', error);
        }
    };

    const handleImageClick = () => {
        if (!disabledUpload && enableFilePicker) {
            setShowMenu(true);
        } else if (disabledUpload && enableFilePicker) {
            setShowFile(true);
        } else if (!enableFilePicker) {
            inputUpload.current.click();
        }
    };

    const handleCloseMenu = (item) => {
        if (item.id === 'gallery') {
            setShowFile(true);
        } else if (item.id === 'upload') {
            inputUpload.current.click();
        }

        setShowMenu(false);
    };

    const handleCloseFile = (result) => {
        setShowFile(false);
        if (result === false) {
            return;
        }
        handleChange(result);
    };

    const handleError = (error) => {
        setShowFile(false);
        setShowMenu(false);
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error,
        });
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            loadLocalFile(file);
        }
    };

    const handleRemove = (event) => {
        URL.revokeObjectURL(image);
        if (onRemove) {
            onRemove();
        }
        handleChange(null);
    };

    const visibility = !disabledRemove && image ? 'visible' : 'hidden';

    return (
        <>
            <FileManagerPicker guild={guild} show={showFile} onClose={handleCloseFile} title="Choose File" />
            <ListPicker show={showMenu} onClose={handleCloseMenu} data={MENU_LIST_ITEMS} title="Choose Action" />
            <div {...rest} className={[classes.container]}>
                <IconButton style={{ visibility: visibility }} onClick={handleRemove} className={classes.removeButton}>
                    <Icon>close</Icon>
                </IconButton>
                <FileImage
                    onClick={handleImageClick}
                    defaultImage="/images/upload.png"
                    file={image}
                    className={classes.image}
                    size="medium"
                    alt={image?.name || 'Uploaded Image'}
                    style={{
                        objectFit: 'contain',
                        maxWidth: size.width,
                        maxHeight: size.height,
                        width: size.width,
                        height: size.height,
                    }}
                />
                <input
                    className={classes.hiddenInput}
                    id={id || name}
                    name={name || id}
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                    ref={inputUpload}
                    {...rest}
                />
            </div>
        </>
    );
};

ImageInput.defaultProps = {
    size: { width: 128, height: 128 },
    enableFilePicker: false,
    disabledUpload: false,
    disabledRemove: false,
    guild: '',
};

export default ImageInput;
