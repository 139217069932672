import ApiManager from '../util/ApiManager';
import { faker } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';

export const REACT_TYPES = ['LOVE', 'LIKE', 'SCARE'];

class PostReactApi extends ApiManager {
    constructor() {
        super('/cms/post/reacts');
    }

    fakeOne() {
        return {
            id: uuid(),
            patient: {
                id: faker.number.int({ min: 1, max: 100 }),
                full_name: faker.person.fullName(),
            },
            react: faker.helpers.arrayElement(REACT_TYPES),
            createdAt: faker.date.past({ years: 1 }).getTime(),
            modifiedAt: faker.date.past({ years: 1 }).getTime(),
        };
    }

    fakeList(count) {
        let data = [];
        for (let i = 0; i < count; i++) {
            let fakeData = this.fakeOne();
            data.push(fakeData);
        }
        return data;
    }

    async fakePaging(page = 0, size = 10, sort = 'createdAt:DESC', filter = '') {
        let data = this.fakeList(size);
        return {
            total: size * (page + 1),
            currentPage: page,
            data: data,
            pageSize: size,
            sort: 'id:ASC',
        };
    }
}

export default new PostReactApi();
