import React, { useState } from 'react';
import { Icon, Grid, Button, makeStyles, useTheme, IconButton } from '@material-ui/core';
import DataTable from '../component/table';
import { TextInput, ListInput, ObjectInput } from '../component/control';
import { useDispatch } from 'react-redux';
import { validateForm } from '../util/ValidationManager';
import type { GridProps } from '@material-ui/core';
import TreatmentTypeApi from '../api/TreatmentTypeApi';
import { TREATMENT_TYPE_TABLE_FIELDS } from '../page/setup/TreatmentType';
import { TREATMENT_TYPE_GRADE } from '../api/DoctorApi';

export const DOCTOR_SPECIALIZE_TABLE_FIELDS = [
    {
        name: 'treatmentTypeId',
        label: 'Treatment Type',
        type: 'raw',
        onLoad: (data, index) => `${data?.treatmentType?.name} (${data?.treatmentType?.code})`,
    },
    {
        name: 'grade',
        label: 'Grade',
        align: 'center',
    },
    {
        name: 'note',
        label: 'note',
    },
];

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

export interface DoctorSpecializeFormProps extends GridProps {
    data?: Array<Object>;
    onChange: (Array<Object>) => void;
    onError: (error: string) => void;
}

const DoctorSpecializeForm = (props: DoctorSpecializeFormProps) => {
    const theme = useTheme();
    const classes = styles();
    const { data, onChange, onError, ...rest } = props;
    const dispatch = useDispatch();
    const [specialize, setSpecialize] = useState({});
    const [selectedIdx, setSelectedIdx] = useState(-1);

    const handleSave = () => {
        console.log('Save Specialize', specialize);
        const isValid = validateForm(
            specialize,
            [
                { fieldId: 'treatmentType', required: true },
                { fieldId: 'grade', required: true },
            ],
            onError,
        );
        if (!isValid) {
            return;
        }

        if (onChange) {
            if (selectedIdx >= 0) {
                data[selectedIdx] = specialize;
                onChange(data);
            } else {
                onChange([...data, specialize]);
            }
        }

        setSpecialize({});
        setSelectedIdx(-1);
    };

    const handleInputChange = (key, value) => {
        console.log('Changed => ', key, value);
        setSpecialize({ ...specialize, [key]: value });
    };

    const handleRemove = (index) => {
        if (onChange) {
            data.splice(index, 1);
            onChange(data);
        }
    };

    const handleTreatmentData = async (currentPage, pageSize, sort, search) => {
        return await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    return (
        <>
            <Grid className={classes.root} container spacing={4} {...rest}>
                <Grid container>
                    <Grid container direction="row" spacing={2}>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <ObjectInput
                                variant="outlined"
                                id="treatmentType"
                                label="Treatment Type"
                                icon="category"
                                onLoadData={handleTreatmentData}
                                onLoadItem={(item) => `${item.name}(${item.code})`}
                                onChange={(event) => handleInputChange('treatmentType', event.target.value)}
                                value={specialize?.treatmentType}
                                fields={TREATMENT_TYPE_TABLE_FIELDS}
                                required={true}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <ListInput
                                id="grade"
                                label="Grade"
                                data={TREATMENT_TYPE_GRADE}
                                value={specialize?.grade}
                                onChange={(event) => handleInputChange('grade', event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item lg={10} md={9} sm={8} xs={12}>
                            <TextInput
                                id="note"
                                label="Note"
                                value={specialize?.note}
                                onChange={(event) => {
                                    handleInputChange('note', event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container item direction="row" lg={2} md={3} sm={4} xs={12} justifyContent="flex-end" alignItems="center">
                            <Button type="submit" className={classes.submit} size="large" variant="contained" color="primary" onClick={handleSave}>
                                <Icon>{selectedIdx < 0 ? 'add' : 'save'}</Icon> {selectedIdx < 0 ? 'Add' : 'Update'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        title="Doctor Specializes"
                        disablePaging={true}
                        items={data}
                        onEdit={(item, index) => {
                            setSpecialize(item);
                            setSelectedIdx(index);
                        }}
                        fields={[
                            ...DOCTOR_SPECIALIZE_TABLE_FIELDS,
                            {
                                name: 'contact_remove',
                                align: 'center',
                                label: '@',
                                minWidth: 50,
                                type: 'raw',
                                onLoad: (item, index) => (
                                    <>
                                        <IconButton size="small" onClick={() => handleRemove(index)}>
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
};

DoctorSpecializeForm.defaultProps = {
    data: [],
    onChange: (data) => console.log('Specialize Data Changed => ', data),
    onError: (error) => console.warn('Warning => ', error),
};

export default DoctorSpecializeForm;
