import React, { useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import FormatManager from '../../util/FormatManager';
import PostReactApi from '../../api/PostReactApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import DataTable from '../../component/table';
import { Button, ButtonGroup, Grid, Icon, Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { SearchInput } from '../../component/control';

const REACT_ICONS = {
    LOVE: 'favorite',
    LIKE: 'thumb_up',
    SCARE: 'sick',
};

export const POST_REACT_TABLE_FIELDS = [
    {
        name: 'createdAt',
        align: 'center',
        type: 'date',
        label: 'Date',
        minWidth: 120,
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.createdAt, 'yyyy-MM-dd HH:mm'),
    },
    {
        name: 'patient',
        label: 'Patient',
        minWidth: 150,
        onLoad: (data, rowIdx) => data.patient.full_name,
        sortable: false,
    },
    {
        name: 'react',
        label: 'React',
        onLoad: (data, rowIdx) => <Icon color="primary">{REACT_ICONS[data.react]}</Icon>,
    },
];

const styles = makeStyles((theme) => ({
    root: {},
    header: {
        flex: 1,
        // backgroundColor:theme.palette.primary.main,
        borderBottom: '1px solid' + theme.palette.divider,
        padding: theme.spacing(0, 1),
    },
    searchBox: {
        width: theme.spacing(32),
        marginRight: theme.spacing(1),
    },
    toolbar: {
        marginLeft: theme.spacing(1),
    },
}));

const PostReact = () => {
    const { id, postType } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();
    const classes = styles();
    const [search, setSearch] = useState('');
    const [showQuestion, setShowQuestion] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const loadReacts = async (currentPage, pageSize, sort) => {
        try {
            const resp = await PostReactApi.fakePaging(currentPage, pageSize, sort, search);
            setPaging(resp);
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const [paging, setPaging] = useState(() => {
        loadReacts(0);
        return {
            currentPage: 0,
            pageSize: 10,
            total: 0,
            data: [],
            sort: 'modifiedAt:DESC',
        };
    });

    const handlePageChange = (pagination) => {
        loadReacts(pagination.page, pagination.pageSize, pagination.sort);
    };

    const handleReload = () => {
        setSearch('');
        loadReacts(0, paging.pageSize, paging.sort);
    };

    return (
        <>
            <Paper className={classes.root} elevation={3}>
                <Grid container className={classes.header}>
                    <Grid container item lg={5} md={4} sm={6} xs={12} alignItems="center" alignContent="center" justifyContent="flex-start">
                        <SearchInput className={classes.searchBox} value={search} onSearch={(value) => setSearch(value)} placeholder="Search" />
                    </Grid>
                    <Grid container item lg={2} md={2} sm={6} xs={12} alignItems="center" alignContent="center" justifyContent="center">
                        <Typography variant="h6" component="h1" noWrap>
                            Post Reacts
                        </Typography>
                    </Grid>
                    <Grid container item lg={5} md={6} sm={12} xs={12} alignItems="center" alignContent="center" justifyContent="flex-end">
                        <ButtonGroup disableElevation className={classes.toolbar}>
                            <Tooltip title="Back">
                                <Button
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                    variant="contained"
                                    aria-label="Go back"
                                >
                                    <Icon>arrow_back</Icon>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Reload">
                                <Button onClick={handleReload} variant="contained" color="primary" aria-label="Add New">
                                    <Icon>cached</Icon>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container item>
                    <DataTable
                        multi={false}
                        items={paging.data}
                        fields={POST_REACT_TABLE_FIELDS}
                        total={paging.total}
                        pageSize={paging.pageSize}
                        currentPage={paging.currentPage}
                        sort={paging.sort}
                        selectedData={selectedData}
                        onPageChange={handlePageChange}
                        onSelectionChange={(data) => setSelectedData(data)}
                    />
                </Grid>
            </Paper>
        </>
    );
};

export default withRouter(PostReact);
