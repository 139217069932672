/* @flow */
import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import type { GridProps } from '@material-ui/core';
import FileImage from './FileImage';

const itemStyles = makeStyles((theme) => ({
    root: (props) => ({
        cursor: 'pointer',
        width: '100%',
        padding: theme.spacing(0.5),
        backgroundColor: props.selected ? theme.palette.primary.main : theme.palette.background.paper,
        border: '1px solid ' + (props.selected ? theme.palette.primary.main : theme.palette.divider),
    }),
    image: {
        width: '100%',
        height: 150,
        objectFit: 'cover',
        objectPosition: 'center',
    },
    title: (props) => ({
        padding: theme.spacing(0.5, 0),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: props.selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
    }),
}));

export interface FileGridItemProps {
    onClick: () => void;
    multi: boolean;
    isMarked: boolean;
    name: string;
    extension: string;
    size: string;
    type: string;
}

export const FileGridItem = (props: FileGridItemProps) => {
    const { onClick, multi, isMarked, ...item } = props;
    const classes = itemStyles({ selected: isMarked });
    const isImage = item.type.startsWith('image');

    return (
        <Grid container item xs={12} sm={6} md={4} lg={3} onClick={onClick}>
            <Grid container item className={classes.root} direction="row" alignContent="flex-start">
                <FileImage className={classes.image} file={item} size="small" defaultImage="/images/file.png" alt={item.name} />
                <Typography variant="subtitle2" align="center" className={classes.title}>
                    {item.name + '.' + item.extension}
                </Typography>
            </Grid>
        </Grid>
    );
};

export interface FileGridProps extends GridProps {
    data: Array<Object>;
    selectedData: Array<Object>;
    multi: boolean;
    onClickItem?: (file: object) => void;
}

const FileGrid = (props: FileGridProps) => {
    const { data, selectedData, multi, onClickItem, ...gridProps } = props;

    return (
        <Grid container {...gridProps} spacing={2}>
            {data && data.length > 0 ? (
                data.map((item, index) => {
                    const isMarked = multi
                        ? selectedData.findIndex((x) => x.id === item.id) >= 0
                        : selectedData && selectedData.id && selectedData.id === item.id;

                    let itemResult = (
                        <FileGridItem multi={multi} isMarked={isMarked} key={item.id + '-' + index} onClick={() => onClickItem(item)} {...item} />
                    );

                    return itemResult;
                })
            ) : (
                <Grid item>
                    <Typography variant="body2">There is no file.</Typography>
                </Grid>
            )}
        </Grid>
    );
};

FileGrid.defaultProps = {
    data: [],
    multi: false,
    onClickItem: (file) => console.warn('Undefined onSelectionChange => ', file),
};

export default FileGrid;
