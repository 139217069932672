import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import BannerApi from '../../api/BannerApi';
import FormatManager from '../../util/FormatManager';

export const BANNER_TABLE_FIELDS = [
    {
        name: 'banner',
        align: 'center',
        label: 'Image',
        type: 'image',
    },
    {
        name: 'caption',
        label: 'Caption',
        sortable: true,
    },
    {
        name: 'startDate',
        align: 'center',
        label: 'Active Date',
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.startDate, 'yyyy-MM-dd'),
        sortable: true,
    },
    {
        name: 'endDate',
        align: 'center',
        label: 'To',
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.endDate, 'yyyy-MM-dd'),
        sortable: true,
    },
];

const Banner = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await BannerApi.fakePaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        if (removeData && removeData.id) {
            return BannerApi.removeById(removeData.id);
        } else if (Array.isArray(removeData) && removeData.length > 0) {
            const removeIds = removeData.map((item) => item.id);
            return BannerApi.removeAll(removeIds);
        }
    };

    const handleDetail = (item) => {
        let url = '/cms/banner/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <MasterTable
                title="Banners"
                fields={BANNER_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onAddNew={() => handleDetail(null)}
                onRemove={handleRemoveData}
                onError={handleError}
                hideImportMenu={true}
            />
        </>
    );
};

export default withRouter(Banner);
