import React from 'react';
import { Grid, Paper, makeStyles, type PaperProps, Badge, withStyles } from '@material-ui/core';
import DataTable from '../../component/table';

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    title: {
        ...theme.typography.button,
        fontWeight: 'bolder',
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(1, 0),
    },
    tableContainer: {
        height: 300,
        overflowY: 'auto',
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

const CountBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.info.main,
        padding: '0 4px',
        border: `1px solid ${theme.palette.info.light}`,
        color: theme.palette.info.contrastText,
        right: theme.spacing(-2),
        top: theme.spacing(2),
    },
}))(Badge);

export interface AppointmentTableProps extends PaperProps {
    fields: Array<Object>;
    paging: Object;
    onRowClick: (item, index) => void;
}

export default function AppointmentTable(props: AppointmentTableProps) {
    const classes = styles();
    const { title, paging, fields, onRowClick, ...paperProps } = props;

    return (
        <Paper className={classes.root} {...paperProps}>
            <Grid item container justifyContent="center" alignItems="center">
                <CountBadge badgeContent={paging.total}>
                    <div className={classes.title}>{title}</div>
                </CountBadge>
            </Grid>
            <Grid item container className={classes.tableContainer}>
                <DataTable
                    multi={false}
                    items={paging.data}
                    fields={fields}
                    total={paging.total}
                    pageSize={paging.pageSize}
                    currentPage={paging.currentPage}
                    sort={paging.sort}
                    onRowClick={onRowClick}
                    disablePaging={true}
                />
            </Grid>
        </Paper>
    );
}
