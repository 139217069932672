import React, { useState } from 'react';
import { AppBar, Tooltip, Button, Icon, Typography, Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { withRouter } from 'react-router';
import ReportApi from '../../api/ReportApi';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const styles = makeStyles((theme) => ({
    root: {
        overflowX: 'auto',
        overflowY: 'auto',
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
    },
    menuBar: {
        background: theme.palette.background,
        padding: theme.spacing(1),
    },
    menuButton: {
        minWidth: theme.spacing(3),
        margin: theme.spacing(0, 0.2),
    },
    menuButtonImage: {
        width: 18,
        height: 18,
        margin: 2,
    },
    appBarText: {
        color: theme.palette.action,
        padding: theme.spacing(0, 1),
    },
    reportContainer: {
        minHeight: theme.spacing(4),
        padding: 0,
        background: theme.palette.background.default,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    reportContent: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    reportLoading: {
        padding: theme.spacing(3, 0),
    },
}));

export interface PdfReportViewerProps {
    title: string;
    reportId: string;
    reportParams: object;
    scaleSize: number;
    onFilter: () => void;
    onError: (error: string | Object) => void;
}

const PdfReportViewer = (props: PdfReportViewerProps) => {
    const { title, reportId, reportParams, scaleSize, onError, onFilter } = props;
    const classes = styles();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [scale, setScale] = useState(scaleSize);
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [excelUrl, setExcelUrl] = useState('');

    const clearReport = () => {
        //Clear Other Report URL
        if (pdfUrl && pdfUrl.length > 0) {
            console.log('cleaned report...');
            URL.revokeObjectURL(pdfUrl);
            setPdfUrl('');
            setPageCount(0);
            setCurrentPage(1);
        }

        if (excelUrl && excelUrl.length > 0) {
            URL.revokeObjectURL(excelUrl);
            setExcelUrl('');
        }
    };

    React.useEffect(() => {
        loadReport();

        return () => {
            clearReport();
        };
        //eslint-disable-next-line
    }, [reportId, reportParams]);

    const loadReport = async () => {
        setLoading(true);
        try {
            const pdf_file = await ReportApi.export(reportId, reportParams);
            const url = URL.createObjectURL(pdf_file);
            setPdfUrl(url);
        } catch (e) {
            onError(e);
        } finally {
            setLoading(false);
        }
    };

    const onDocumentLoadSuccess = (obj) => {
        setPageCount(obj._pdfInfo.numPages);
    };

    const handleFilter = () => {
        if (onFilter) {
            onFilter();
        }
    };

    const downloadFile = (url, fileName) => {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    const handleDownloadPdf = () => {
        if (pdfUrl && pdfUrl.length > 0) {
            downloadFile(pdfUrl, `${title}.pdf`);
        }
    };

    const handleDownloadExcel = () => {
        ReportApi.export(reportId, reportParams, 'EXCEL')
            .then((file) => {
                const url = URL.createObjectURL(file);
                setExcelUrl(url);
                console.log('Downloading excel...');
                downloadFile(url, `${title}.xlsx`);
            })
            .catch(onError);
    };

    const handlePrint = () => {
        if (pdfUrl && pdfUrl.length > 0) {
            const printWindow = window.open(pdfUrl, '_blank');
            printWindow.focus();
            printWindow.print();
        }
    };

    const addPageNumber = (value) => {
        let pageNum = currentPage;
        if (pageNum + value <= 0) {
            pageNum = pageCount;
        } else if (pageNum + value > pageCount) {
            pageNum = 1;
        } else {
            pageNum += value;
        }

        setCurrentPage(pageNum);
    };

    const handleReload = () => {
        clearReport();
        loadReport();
    };

    const addScale = (value) => {
        if ((value < 0 && scale < 0.7) || (value > 0 && scale > 2.0)) {
            return;
        }
        setScale(scale + value);
    };

    const renderReportPaging = () => {
        const isDisable = !pdfUrl || loading || pageCount <= 0;
        return (
            <>
                <Button
                    className={classes.menuButton}
                    onClick={() => {
                        setCurrentPage(1);
                    }}
                    disabled={isDisable}
                >
                    <Tooltip title="First">
                        <Icon>first_page</Icon>
                    </Tooltip>
                </Button>
                <Button className={classes.menuButton} onClick={() => addPageNumber(-1)} disabled={isDisable}>
                    <Tooltip title="Previous">
                        <Icon>keyboard_arrow_left</Icon>
                    </Tooltip>
                </Button>
                <Typography variant="subtitle2" component="p" className={classes.appBarText} noWrap>
                    {`${currentPage} / ${pageCount}`}
                </Typography>
                <Button className={classes.menuButton} onClick={() => addPageNumber(1)} disabled={isDisable}>
                    <Tooltip title="Next">
                        <Icon>keyboard_arrow_right</Icon>
                    </Tooltip>
                </Button>
                <Button
                    className={classes.menuButton}
                    onClick={() => {
                        setCurrentPage(pageCount);
                    }}
                    disabled={isDisable}
                >
                    <Tooltip title="Last">
                        <Icon>last_page</Icon>
                    </Tooltip>
                </Button>
            </>
        );
    };

    const renderReportMenu = () => {
        const isDisable = !pdfUrl || loading || pageCount <= 0;
        return (
            <>
                <Button className={classes.menuButton} onClick={() => handleReload()}>
                    <Tooltip title="Reload">
                        <Icon>autorenew</Icon>
                    </Tooltip>
                </Button>
                <Button className={classes.menuButton} disabled={isDisable} onClick={() => addScale(-0.1)}>
                    <Tooltip title="Zoom Out">
                        <Icon>zoom_out</Icon>
                    </Tooltip>
                </Button>
                <Button className={classes.menuButton} disabled={isDisable} onClick={() => addScale(0.1)}>
                    <Tooltip title="Zoom In">
                        <Icon>zoom_in</Icon>
                    </Tooltip>
                </Button>
                <Button className={classes.menuButton} disabled={isDisable} onClick={handleDownloadPdf}>
                    <Tooltip title="Download PDF">
                        <img src="/images/pdf.png" className={classes.menuButtonImage} alt="PDF" />
                    </Tooltip>
                </Button>
                <Button className={classes.menuButton} disabled={isDisable} onClick={handleDownloadExcel}>
                    <Tooltip title="Download Excel">
                        <img src="/images/xls.png" className={classes.menuButtonImage} alt="Excel" />
                    </Tooltip>
                </Button>
                <Button className={classes.menuButton} disabled={isDisable} onClick={handlePrint}>
                    <Tooltip title="Print">
                        <Icon>print</Icon>
                    </Tooltip>
                </Button>
                {onFilter && (
                    <Button className={classes.menuButton} onClick={handleFilter}>
                        <Tooltip title="Filter">
                            <Icon>tune</Icon>
                        </Tooltip>
                    </Button>
                )}
            </>
        );
    };

    const renderLoading = () => {
        return (
            <Grid item container direction="column" className={classes.reportLoading} alignItems="center" justifyContent="center">
                <CircularProgress color="primary" size={32} />
                <Typography variant="caption">loading ....</Typography>
            </Grid>
        );
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="transparent" className={classes.menuBar}>
                <Grid container>
                    <Grid className={classes.appBarText} container item sm={12} md={4} lg={4} direction="row" alignItems="center">
                        <Icon>pie_chart</Icon>
                        <Typography variant="h6" component="h1" noWrap>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid direction="row" item container sm={12} md={4} lg={4} alignItems="center" justifyContent="center">
                        {renderReportPaging()}
                    </Grid>

                    <Grid direction="row" item container sm={12} md={4} lg={4} alignItems="center" justifyContent="flex-end">
                        {renderReportMenu()}
                    </Grid>
                </Grid>
            </AppBar>
            <Grid className={classes.reportContainer} container alignItems="center" justifyContent="center">
                {loading ? (
                    renderLoading()
                ) : (
                    <Grid item className={classes.reportContent}>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={(pdfInfo) => onDocumentLoadSuccess(pdfInfo)}
                            loading={() => <></>}
                            noData="There is no data."
                        >
                            <Page pageNumber={currentPage} scale={scale} />
                        </Document>
                    </Grid>
                )}
            </Grid>
            <AppBar position="static" color="transparent" className={classes.menuBar} elevation={0}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid direction="row" item container sm={12} md={4} lg={4} alignItems="center" justifyContent="center">
                        {renderReportPaging()}
                    </Grid>
                </Grid>
            </AppBar>
        </div>
    );
};

PdfReportViewer.defaultProps = {
    title: 'Report Viewer',
    scaleSize: 1.3,
};

export default withRouter(PdfReportViewer);
