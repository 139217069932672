import { error } from './Theme';

export const APP_NAME = 'Robodent';
export const APP_VERSION = '2.0';

// const URLS = {
//     development: 'http://localhost:8080/',
//     production: 'https://roboapi.thegranddental.com/robodent-test/',
// };

// export const API = process.env.NODE_ENV === 'development' ? URLS.development : URLS.production;
export const API = process.env.REACT_APP_API_URL ?? 'http://localhost:8080/';

export const SESSION_TIMEOUT = process.env.REACT_APP_SESSION_TIMEOUT ?? (60 * 20); //20 Minutes

export const GOOGLE_API_KEYS = '';

export const GOOGLE_AUTH_OPTIONS = {
    scopes: ['email'],
    webClientId: '332664396318-vef0mc89a6pk1cuiq6m2qj0j8r7f0evf.apps.googleusercontent.com',
};

export const ONE_SIGNAL_OPTIONS = {
    appId: '03a6f5e6-9ada-42e1-9ce2-fc459794825f',
    safari_web_id: 'web.onesignal.auto.01b883ce-5cfa-4aca-8569-bf08de600615',
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
};

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyCUPjgHiIgC6FZ1AbtUNsC5p8sbr6r2lcQ',
    authDomain: 'robodent-4b41f.firebaseapp.com',
    projectId: 'robodent-4b41f',
    storageBucket: 'robodent-4b41f.appspot.com',
    messagingSenderId: '243370860776',
    appId: '1:243370860776:web:0540dfab4b33e089e3a95d',
    measurementId: 'G-ZG4B44980H',
};

export const FCM_VAPID_KEY = 'BNlE9xqJGBGWWsPwRHsCfTxSATjxCmOoS5BJaWC9JUP0mImeJBFq7L3UO9nnkJiwhRJF5I0GGkYGAlN3A_Jiu3g';

export const TINY_EDITOR_API_KEY = 'ec0wbk0avdc86337zqf3fsd3vo23e5li82g9ia4009w0e71n';

export const STORAGE_KEYS = {
    CURRENT_USER: '@com.sdm.CURRENT_USER',
    DEVICE_ID: '@com.sdm.DEVICE_ID',
    MENU: '@com.sdm.MAIN_MENU',
    TABLE_SESSION: '@com.sdm.TABLE_SESS',
    THEME: '@com.sdm.THEME',
    MESSAGING_TOKEN: '@com.sdm.MESSAGING_TOKEN',
};

export const GENDERS = ['MALE', 'FEMALE', 'OTHER'];

export const SUPPORTED_LANGUAGE = [
    { code: 'en', label: 'English' },
    { code: 'mm_uni', label: 'Myanmar_Unicode' },
    { code: 'mm_zg', label: 'Myanmar_Zawgyi' },
    { code: 'cn', label: 'Chinese' },
];

export const FACEBOOK = {
    LOGIN: false,
    APP_ID: '799977977040882',
    API_VERSION: '',
};

export const AVAILABLE_CURRENICES = [
    {
        code: 'MMK',
        name: 'Myanmar Kyat',
    },
    {
        code: 'USD',
        name: 'US Dollar',
    },
    {
        code: 'SGD',
        name: 'Singapore Dollar',
    },
    {
        code: 'THB',
        name: 'Thai Baht',
    },
];

export const BATCH_IMPORT_MENU = [
    {
        id: 'import_csv',
        label: 'CSV Import',
        icon: 'grid_on',
    },
    {
        id: 'import_json',
        label: 'JSON Import',
        icon: 'code',
    },
];

export const BATCH_ACTION_MENU = [
    {
        id: 'uncheck_all',
        label: 'Uncheck all',
        icon: 'check_box_outline_blank',
    },
    {
        id: 'export_csv',
        label: 'CSV Export',
        icon: 'grid_on',
    },
    {
        id: 'export_json',
        label: 'JSON Export',
        icon: 'code',
    },
    {
        id: 'remove',
        label: 'Remove All',
        icon: 'delete',
        color: error.main,
    },
];

export const ADMIN_MENU = {
    id: 'admin',
    label: 'Administration',
    icon: 'supervisor_account',
    open: true,
    items: [
        {
            id: 'admin-user',
            label: 'User',
            icon: 'person',
            path: '/user',
        },
        {
            id: 'admin-role',
            label: 'Role',
            icon: 'group',
            path: '/role',
        },
        {
            id: 'admin-menu',
            label: 'System Menu',
            icon: 'link',
            path: '/menu',
        },
        {
            id: 'sys-file',
            label: 'File Manager',
            icon: 'storage',
            path: '/file',
            divider: true,
        },
    ],
};

export const DEVELOPER_MENU = {
    id: 'developer',
    label: 'Developer',
    icon: 'code',
    items: [
        {
            id: 'admin-route',
            label: 'Api Permission',
            icon: 'router',
            path: '/permission',
        },
        {
            id: 'admin-setting',
            label: 'Settings',
            icon: 'settings',
            path: '/setting',
        },
        {
            id: 'sys-report',
            label: 'Report Manager',
            icon: 'bar_chart',
            path: '/report',
            divider: true,
        },
        {
            id: 'profile-3',
            label: 'API Tools',
            icon: 'api',
            path: '/api-debug',
        },
    ],
};

export const USER_PROFILE_MENU = {
    id: 'me',
    label: 'Me',
    icon: 'face',
    open: true,
    items: [
        {
            id: 'profile-1',
            label: 'Profile',
            icon: 'account_circle',
            path: '/profile',
        },
        {
            id: 'profile-2',
            label: 'Security',
            icon: 'security',
            path: '/security',
        },
    ],
};

export const SETUP_MENU = {
    id: 'setup-1',
    label: 'Setup',
    icon: 'store',
    open: true,
    items: [
        {
            id: 'treatment-price',
            label: 'Treatment Price',
            icon: 'sell',
            path: '/treatmentPrice',
        },
        {
            id: 'treatment-type',
            label: 'Treatment Type',
            icon: 'category',
            path: '/treatmentType',
        },
        {
            id: 'patient-type',
            label: 'Patient Type',
            icon: 'category',
            path: '/patientType',
        },
        {
            id: 'behavior',
            label: 'Behavior',
            icon: 'psychology',
            path: '/behavior',
        },
        {
            id: 'branch',
            label: 'Branch Setup',
            icon: 'store',
            path: '/branch',
        },
    ],
};

export const REGISTRATION_MENU = {
    id: 'registration-1',
    label: 'Registration',
    icon: 'how_to_reg',
    open: true,
    items: [
        {
            id: 'patient-registeration',
            label: 'Patient',
            icon: 'accessible',
            path: '/patient',
        },
        {
            id: 'doctor',
            label: 'Doctor',
            icon: 'medication',
            path: '/doctor',
        },
    ],
};

export const CASHIER_MENU = {
    id: 'voucher-1',
    label: 'Cashier',
    icon: 'countertops',
    open: true,
    items: [
        {
            id: 'voucher-create',
            label: 'New Voucher',
            icon: 'receipt',
            path: '/voucher/create',
        },
        {
            id: 'voucher-list',
            label: 'Voucher List',
            icon: 'receipt_long',
            path: '/voucher',
        },
        {
            id: 'voucher-payment',
            label: 'Voucher Payment',
            icon: 'payments',
            path: '/voucher/payment',
        },
    ],
};

export const CMS_MENU = {
    id: 'cms-1',
    label: 'CMS',
    icon: 'dynamic_feed',
    open: true,
    items: [
        {
            id: 'cms-newsfeed',
            label: 'Newsfeed',
            icon: 'feed',
            path: '/cms/post/newsfeed',
        },
        {
            id: 'cms-announcement',
            label: 'Announcement',
            icon: 'campaign',
            path: '/cms/post/announcement',
        },
        {
            id: 'cms-banner',
            label: 'Banner',
            icon: 'slideshow',
            path: '/cms/banner',
        },
    ],
};

export const MARKETING_MENU = {
    id: 'marketing-1',
    label: 'Marketing',
    icon: 'tips_and_updates',
    open: true,
    items: [
        {
            id: 'marketing-discount',
            label: 'Discounts',
            icon: 'discount',
            path: '/marketing/discount',
        },
        {
            id: 'marketing-coupons',
            label: 'Coupons',
            icon: 'confirmation_number',
            path: '/marketing/coupon',
        },
    ],
};

export const MESSAGING_MENU = {
    id: 'messagin-1',
    label: 'Messaging',
    icon: 'support_agent',
    open: true,
    items: [
        {
            id: 'messaging-channel',
            label: 'Channel',
            icon: 'podcasts',
            path: '/messaging/channel',
        },
        {
            id: 'messaging-email',
            label: 'Send Email',
            icon: 'email',
            path: '/messaging/email',
        },
        {
            id: 'messaging-sms',
            label: 'Send SMS',
            icon: 'sms',
            path: '/messaging/sms',
        },
        {
            id: 'messaging-push',
            label: 'Push Noti',
            icon: 'cloud_upload',
            path: '/messaging/push_noti',
        },
    ],
};

export const REPORT_MENU = {
    id: 'report-1',
    label: 'Reports',
    icon: 'bar_chart',
    open: true,
    items: [
        {
            id: 'report-daily-income-summary',
            label: 'Daily Income Summary',
            icon: 'savings',
            path: '/report/view/daily_income_summary',
        },
        {
            id: 'report-appointment-detail',
            label: 'Appointment Detail',
            icon: 'event_available',
            path: '/report/view/appointment_detail',
        },
        {
            id: 'report-voucher-payment',
            label: 'Voucher Payment',
            icon: 'payments',
            path: '/report/view/voucher_payment',
        },
        {
            id: 'debt_payment',
            label: 'Debt Payment',
            icon: 'price_check',
            path: '/report/view/debt_payment',
        },
    ],
};
export const DEFAULT_SIDE_MENU = [
    {
        id: 'appointment',
        label: 'Appointment',
        icon: 'event_available',
        path: '/appointment',
        divider: true,
    },
    {
        id: 'operation',
        label: 'Operation',
        icon: 'vaccines',
        path: '/operation/1',
        divider: true,
    },
    USER_PROFILE_MENU,
];

export const DEVELOPMENT_SIDE_MENU = [
    {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'dashboard',
        path: '/',
        divider: true,
    },
    {
        id: 'appointment',
        label: 'Appointment',
        icon: 'event_available',
        path: '/appointment',
        divider: true,
    },
    {
        id: 'operation',
        label: 'Operation',
        icon: 'vaccines',
        path: '/operation/1',
        divider: true,
    },
    CASHIER_MENU,
    SETUP_MENU,
    REGISTRATION_MENU,
    CMS_MENU,
    MARKETING_MENU,
    MESSAGING_MENU,
    REPORT_MENU,
    ADMIN_MENU,
    DEVELOPER_MENU,
    USER_PROFILE_MENU,
];

export const GENERAL_STATUS = ['ACTIVE', 'PENDING', 'CANCEL'];
