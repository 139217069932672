import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import AppointmentApi from '../../api/AppointmentApi';
import FormatManager from '../../util/FormatManager';
import { useTheme } from '@material-ui/core';

export const APPOINTMENT_TABLE_FIELDS = [
    {
        name: 'code',
        align: 'left',
        label: 'Code',
        sortable: true,
    },
    {
        name: 'patient',
        label: 'Patient',
        type: 'raw',
        onLoad: (data, rowIdx) => `${data.patient?.fullName}(${data.patient?.code})`,
    },
    {
        name: 'doctor',
        align: 'left',
        label: 'Doctor',
        type: 'raw',
        onLoad: (data, rowIdx) => data.doctor?.fullName,
    },
    {
        name: 'status',
        label: 'Status',
        sortable: true,
    },
    {
        name: 'date',
        align: 'center',
        label: 'Date',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.date, 'dd MMM, yyyy'),
    },
];

const Appointment = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await AppointmentApi.getPaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: "Appointment can't be deleted! Please cancel the Appointment.",
        });
        return null;
    };

    const handleDetail = (item) => {
        let url = '/appointment/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <MasterTable
                title="Appointments"
                fields={APPOINTMENT_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onRemove={() => {}}
                onAddNew={() => handleDetail(null)}
                onError={handleError}
                onRowColor={(data, rowIdx) => {
                    switch (data.status) {
                        case 'CONFIRM':
                            return theme.palette.primary.light;
                        case 'ARRIVED':
                            return theme.palette.secondary.light;
                        case 'CANCEL':
                            return theme.palette.warning.light;
                        case 'PROCESSING':
                            return theme.palette.info.light;
                        case 'FINISHED':
                            return theme.palette.success.light;
                        default:
                            return theme.palette.background.default;
                    }
                }}
                hideCRUD={true}
                hideActionMenu={true}
                hideImportMenu={true}
                moreActions={[
                    {
                        id: 'edit',
                        label: 'Detail',
                        icon: 'receipt',
                    },
                ]}
            />
        </>
    );
};

export default withRouter(Appointment);
