import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import DiscountApi from '../../api/DiscountApi';
import FormatManager from '../../util/FormatManager';
import { useTheme } from '@material-ui/core';

export const COUPON_TABLE_FIELDS = [
    {
        name: 'couponCode',
        align: 'center',
        label: 'Coupon',
    },
    {
        name: 'couponLimit',
        align: 'right',
        label: 'Limit',
        onLoad: (data, rowIdx) => FormatManager.formatNumeral(data.couponLimit, '0,0'),
    },
    {
        name: 'fromDate',
        align: 'center',
        label: 'From',
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.fromDate, 'yyyy-MM-dd'),
        sortable: true,
    },
    {
        name: 'toDate',
        align: 'center',
        label: 'To',
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.toDate, 'yyyy-MM-dd'),
        sortable: true,
    },
    {
        name: 'fromAmount',
        align: 'right',
        label: 'Amount',
        onLoad: (data, rowIdx) =>
            FormatManager.formatNumeral(data.fromAmount, '0,0') + ' - ' + FormatManager.formatNumeral(data.toAmount, '0,0') + data.currency,
    },
    {
        name: 'discountAmount',
        align: 'right',
        label: 'Discount',
        onLoad: (data, rowIdx) => {
            if (data.discountType === 'AMOUNT') {
                return data.discountAmount;
            }
            return data.discountPercentage + '%';
        },
    },
];

const Coupon = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await DiscountApi.fakePaging(true, currentPage, pageSize, sort, search);
            console.log('Discounts => ', result);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        if (removeData && removeData.id) {
            return DiscountApi.removeById(removeData.id);
        } else if (Array.isArray(removeData) && removeData.length > 0) {
            const removeIds = removeData.map((item) => item.id);
            return DiscountApi.removeAll(removeIds);
        }
    };

    const handleDetail = (item) => {
        let url = `/marketing/coupon/detail/`;
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <MasterTable
                title="Coupons"
                fields={COUPON_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onAddNew={() => handleDetail(null)}
                onRemove={handleRemoveData}
                onError={handleError}
                hideImportMenu={true}
            />
        </>
    );
};

export default withRouter(Coupon);
