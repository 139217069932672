import { Avatar, Button, Container, Grid, Icon, Paper, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import MasterForm from '../../component/MasterForm';
import MessagingSettingForm from '../../fragment/MessagingSettingForm';
import { TextInput } from '../../component/control';

const styles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1, 0),
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const SmsMessaging = () => {
    const classes = styles();
    const history = useHistory();
    const [form, setForm] = useState(null);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleSubmit = () => {
        console.log('Send SMS Now');
    };

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">sms</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create SMS
                    </Typography>
                    <MasterForm>
                        <Grid container direction="column" className={classes.form}>
                            <TextInput
                                id="message"
                                label="Message"
                                multiline={true}
                                rows={4}
                                value={form?.message}
                                onChange={(event) => setForm({ ...form, message: event.target.value })}
                            />
                            <MessagingSettingForm
                                data={form}
                                onChange={(e) => console.log('Email Messaging Setting Changed => ', e)}
                                onError={handleError}
                            />
                        </Grid>
                        <Grid justifyContent="flex-end" spacing={1} container>
                            <Button type="button" className={classes.submit} variant="contained" color="primary" onClick={() => handleSubmit()}>
                                <Icon color="action">send</Icon> Submit
                            </Button>
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(SmsMessaging);
