import React, { useRef, useState } from 'react';
import { Icon, Typography, Grid, Button, makeStyles, useTheme, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import DataTable from '../component/table';
import { TextInput, ListInput, CheckboxInput, EmailInput } from '../component/control';
import { useDispatch } from 'react-redux';
import { validateForm } from '../util/ValidationManager';
import type { GridProps } from '@material-ui/core';
import { getRegions, getTownships } from '../config/Address';

const MM_REGIONS = getRegions();

export const PHONE_TABLE_FIELDS = [
    {
        name: 'label',
        label: 'Label',
        type: 'text',
    },
    {
        name: 'phoneNumber',
        label: 'PhoneNumber',
    },
    {
        name: 'primaryNumber',
        label: 'Primary?',
        align: 'center',
        type: 'bool',
    },
];

export const EMAIL_TABLE_FIELDS = [
    {
        name: 'label',
        label: 'Label',
    },
    {
        name: 'email',
        label: 'E-mail',
    },
    {
        name: 'primaryEmail',
        label: 'Primary?',
        align: 'center',
        type: 'bool',
    },
];

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    accodionRoot: {
        width: '100%',
    },
    expansionIcon: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
}));

export interface ContactForm2Props extends GridProps {
    address: Object;
    phones: Array<Object>;
    emails: Array<Object>;
    onChangeAddress: (data) => void;
    onChangeEmails: (Array<Object>) => void;
    onChangePhones: (Array<Object>) => void;
    onError: (error) => void;
}

const ContactForm2 = (props: ContactForm2Props) => {
    const theme = useTheme();
    const classes = styles();
    const { address, phones, emails, onChangeAddress, onChangeEmails, onChangePhones, onError, ...rest } = props;
    const dispatch = useDispatch();
    const [townships, setTownships] = useState([]);
    const [phone, setPhone] = useState({});
    const [email, setEmail] = useState({});
    const [selectedPhoneIdx, setSelectedPhoneIdx] = useState(-1);
    const [selectedEmailIdx, setSelectedEmailIdx] = useState(-1);
    const phoneNumber = useRef();

    const handleAddressChange = (key, value) => {
        onChangeAddress({ ...address, [key]: value });
    };

    const handleAddNewPhone = () => {
        const isValid = validateForm(
            phone,
            [
                { fieldId: 'phoneNumber', required: true, rules: [{ type: 'phone' }] },
                { fieldId: 'label', required: true },
            ],
            onError,
        );
        if (!isValid) {
            return;
        }

        let newPhones = phones;
        if (phone.primaryNumber) {
            newPhones = newPhones.map((p) => {
                p.primaryNumber = false;
                return p;
            });
        }

        if (selectedPhoneIdx >= 0) {
            newPhones[selectedPhoneIdx] = phone;
            onChangePhones(newPhones);
        } else {
            onChangePhones([...newPhones, phone]);
        }

        setSelectedPhoneIdx(-1);
        setPhone({});
    };
    const handleRemovePhone = (idx) => {
        const newPhones = phones.filter((_, i) => i !== idx);
        onChangePhones(newPhones);
    };

    const handleAddNewEmail = () => {
        const isValid = validateForm(
            email,
            [
                { fieldId: 'email', required: true, rules: [{ type: 'email' }] },
                { fieldId: 'label', required: true },
            ],
            onError,
        );
        if (!isValid) {
            return;
        }

        let newEmails = emails;
        if (email.primaryEmail) {
            newEmails = newEmails.map((m) => {
                m.primaryEmail = false;
                return m;
            });
        }

        if (selectedEmailIdx >= 0) {
            newEmails[selectedEmailIdx] = email;
            onChangeEmails(newEmails);
        } else {
            onChangeEmails([...newEmails, email]);
        }

        setSelectedEmailIdx(-1);
        setEmail({});
    };
    const handleRemoveEmail = (idx) => {
        const newEmails = emails.filter((_, i) => i !== idx);
        onChangeEmails(newEmails);
    };

    return (
        <>
            <Grid className={classes.root} container {...rest}>
                <Grid container>
                    <Grid container direction="row" spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <ListInput
                                id="region"
                                icon="terrain"
                                label="Region"
                                data={MM_REGIONS}
                                value={address.region}
                                onChange={(event) => {
                                    handleAddressChange('region', event.target.value);
                                    setTownships(getTownships(event.target.value));
                                }}
                                required
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <ListInput
                                id="township"
                                icon="apartment"
                                label="Township"
                                data={townships}
                                value={address.township}
                                onChange={(event) => {
                                    handleAddressChange('township', event.target.value);
                                }}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextInput
                            id="addressDetail"
                            icon="signpost"
                            label="Address Detail"
                            value={address.addressDetail}
                            onChange={(event) => {
                                handleAddressChange('addressDetail', event.target.value);
                            }}
                            required
                        />
                    </Grid>
                </Grid>
                <Accordion className={classes.accodionRoot} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="phone-content" id="phone-header">
                        <Icon className={classes.expansionIcon}>phone</Icon>
                        <Typography>Phone Numbers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container direction="row" spacing={2}>
                                <Grid item lg={3} md={3} sm={5} xs={12}>
                                    <TextInput
                                        id="phoneLabel"
                                        icon="bookmark"
                                        label="Label"
                                        value={phone?.label}
                                        onChange={(event) => {
                                            setPhone({ ...phone, label: event.target.value });
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item lg={5} md={4} sm={7} xs={12}>
                                    <TextInput
                                        id="phoneNumber"
                                        icon="phone"
                                        label="Phone Number"
                                        inputRef={phoneNumber}
                                        value={phone?.phoneNumber}
                                        onChange={(event) => {
                                            setPhone({ ...phone, phoneNumber: event.target.value });
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid container item lg={2} md={3} sm={8} xs={12} alignItems="center" justifyContent="center">
                                    <CheckboxInput
                                        label="Primary?"
                                        value={phone?.primaryNumber || false}
                                        onChange={(event) => {
                                            setPhone({ ...phone, primaryNumber: event.target.checked });
                                        }}
                                        checked={phone?.primaryNumber || false}
                                    />
                                </Grid>
                                <Grid lg={2} md={2} sm={4} xs={12} alignItems="center" container item>
                                    <Button type="button" variant="contained" fullWidth color="primary" onClick={handleAddNewPhone}>
                                        <Icon>{selectedPhoneIdx < 0 ? 'add' : 'save'}</Icon> {selectedPhoneIdx < 0 ? 'Add' : 'Update'}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <DataTable
                                    title="Phone Numbers"
                                    disablePaging={true}
                                    items={phones}
                                    onEdit={(item, index) => {
                                        setPhone(item);
                                        setSelectedPhoneIdx(index);
                                    }}
                                    fields={[
                                        ...PHONE_TABLE_FIELDS,
                                        {
                                            name: 'contact_remove',
                                            align: 'center',
                                            label: '@',
                                            minWidth: 50,
                                            type: 'raw',
                                            onLoad: (item, index) => (
                                                <>
                                                    <IconButton size="small" onClick={() => handleRemovePhone(index)}>
                                                        <Icon color="error">delete</Icon>
                                                    </IconButton>
                                                </>
                                            ),
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accodionRoot} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="mail-content" id="mail-header">
                        <Icon className={classes.expansionIcon}>mail</Icon>
                        <Typography>E-mail Addresses</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container direction="row" spacing={2}>
                                <Grid item lg={3} md={3} sm={5} xs={12}>
                                    <TextInput
                                        id="emailLabel"
                                        icon="bookmark"
                                        label="Label"
                                        value={email?.label}
                                        onChange={(event) => {
                                            setEmail({ ...email, label: event.target.value });
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item lg={5} md={4} sm={7} xs={12}>
                                    <EmailInput
                                        id="email"
                                        label="E-mail"
                                        value={email?.email}
                                        onChange={(event) => {
                                            setEmail({ ...email, email: event.target.value });
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid container item lg={2} md={3} sm={8} xs={12} alignItems="center" justifyContent="center">
                                    <CheckboxInput
                                        label="Primary?"
                                        id="primaryEmail"
                                        value={email?.primaryEmail || false}
                                        onChange={(event) => {
                                            setEmail({ ...email, primaryEmail: event.target.checked });
                                        }}
                                        checked={email?.primaryEmail || false}
                                    />
                                </Grid>
                                <Grid lg={2} md={2} sm={4} xs={12} alignItems="center" container item>
                                    <Button type="button" variant="contained" fullWidth color="primary" onClick={handleAddNewEmail}>
                                        <Icon>{selectedEmailIdx < 0 ? 'add' : 'save'}</Icon> {selectedEmailIdx < 0 ? 'Add' : 'Update'}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <DataTable
                                    title="Email Address"
                                    disablePaging={true}
                                    items={emails}
                                    onEdit={(item, index) => {
                                        setEmail(item);
                                        setSelectedEmailIdx(index);
                                    }}
                                    fields={[
                                        ...EMAIL_TABLE_FIELDS,
                                        {
                                            name: 'contact_remove',
                                            align: 'center',
                                            label: '@',
                                            minWidth: 50,
                                            type: 'raw',
                                            onLoad: (item, index) => (
                                                <>
                                                    <IconButton size="small" onClick={() => handleRemoveEmail(index)}>
                                                        <Icon color="error">delete</Icon>
                                                    </IconButton>
                                                </>
                                            ),
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </>
    );
};

ContactForm2.defaultProps = {
    address: {},
    phones: [],
    emails: [],
    onChangeAddress: (data) => console.log('Address Data Changed => ', data),
    onChangeEmails: (data) => console.log('Emails Data Changed => ', data),
    onChangePhones: (data) => console.log('Phones Data Changed => ', data),
    onError: (error) => console.warn('Warning => ', error),
};

export default ContactForm2;
