import React, { useState } from 'react';
import { Icon, Grid, Button, makeStyles, useTheme, IconButton } from '@material-ui/core';
import DataTable from '../component/table';
import { TextInput } from '../component/control';
import { useDispatch } from 'react-redux';
import { validateForm } from '../util/ValidationManager';
import type { GridProps } from '@material-ui/core';

export const BANK_INFO_TABLE_FIELDS = [
    {
        name: 'bankName',
        label: 'Bank',
    },
    {
        name: 'bankAccountCode',
        label: 'Account',
    },
    {
        name: 'bankAccountName',
        label: 'Name',
    },
    {
        name: 'note',
        label: 'note',
    },
];

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

export interface BankInfoFormProps extends GridProps {
    data?: Array<Object>;
    onChange: (Array<Object>) => void;
    onError: (error: string | Object) => void;
}

const BankInfoForm = (props: BankInfoFormProps) => {
    const theme = useTheme();
    const classes = styles();
    const { data, onChange, onError, ...rest } = props;
    const dispatch = useDispatch();
    const [bankInfo, setBankInfo] = useState({});
    const [selectedIdx, setSelectedIdx] = useState(-1);

    const handleSave = () => {
        const isValid = validateForm(
            bankInfo,
            [
                { fieldId: 'bankName', required: true },
                { fieldId: 'bankAccountCode', required: true },
                { fieldId: 'bankAccountName', require: true },
            ],
            onError,
        );
        if (!isValid) {
            return;
        }

        if (onChange) {
            if (selectedIdx >= 0) {
                data[selectedIdx] = bankInfo;
                onChange(data);
            } else {
                onChange([...data, bankInfo]);
            }
        }

        setBankInfo({});
        setSelectedIdx(-1);
    };

    const handleRemove = (idx) => {
        const newData = data.filter((_, i) => i !== idx);
        onChange(newData);
    };

    const handleInputChange = (key, value) => {
        setBankInfo({ ...bankInfo, [key]: value });
    };

    return (
        <>
            <Grid className={classes.root} container {...rest}>
                <Grid container>
                    <Grid container direction="row" spacing={2}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <TextInput
                                id="bankName"
                                label="Bank"
                                icon="account_balance"
                                value={bankInfo?.bankName}
                                onChange={(event) => {
                                    handleInputChange('bankName', event.target.value);
                                }}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <TextInput
                                id="bankAccountCode"
                                label="Account"
                                icon="account_balance_wallet"
                                value={bankInfo?.bankAccountCode}
                                onChange={(event) => {
                                    handleInputChange('bankAccountCode', event.target.value);
                                }}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <TextInput
                                id="bankAccountName"
                                label="Name"
                                icon="face"
                                value={bankInfo?.bankAccountName}
                                onChange={(event) => {
                                    handleInputChange('bankAccountName', event.target.value);
                                }}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Grid item lg={10} md={9} sm={8} xs={12}>
                            <TextInput
                                id="note"
                                label="note"
                                value={bankInfo?.note}
                                onChange={(event) => {
                                    handleInputChange('note', event.target.value);
                                }}
                                required
                            />
                        </Grid>
                        <Grid container item direction="row" lg={2} md={3} sm={4} xs={12} justifyContent="flex-end" alignItems="center">
                            <Button type="submit" className={classes.submit} size="large" variant="contained" color="primary" onClick={handleSave}>
                                <Icon>{selectedIdx < 0 ? 'add' : 'save'}</Icon> {selectedIdx < 0 ? 'Add' : 'Update'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        title="Bank infos"
                        disablePaging={true}
                        items={data}
                        onEdit={(item, index) => {
                            setBankInfo(item);
                            setSelectedIdx(index);
                        }}
                        fields={[
                            ...BANK_INFO_TABLE_FIELDS,
                            {
                                name: 'contact_remove',
                                align: 'center',
                                label: '@',
                                minWidth: 50,
                                type: 'raw',
                                onLoad: (item, index) => (
                                    <>
                                        <IconButton size="small" onClick={() => handleRemove(index)}>
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
};

BankInfoForm.defaultProps = {
    data: [],
    onChange: (data) => console.log('Bank Info Data Changed => ', data),
    onError: (error) => console.warn('Bank Info Error => ', error),
};

export default BankInfoForm;
