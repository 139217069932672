import React from 'react';
import { Button, Grid, Icon, Paper, Typography, makeStyles } from '@material-ui/core';
import FormatManager from '../../util/FormatManager';
import DataAction from '../../component/table/DataAction';
import { defaultActions } from '../../component/MasterTable';

const itemStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        padding: theme.spacing(0.5, 1),
    },
    title: {
        width: 180,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    body: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        flex: 2,
        padding: theme.spacing(4, 2),
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        padding: theme.spacing(1),
    },
    footerButton: {
        width: '45%',
    },
    contentText: {
        overflow: 'hidden',
        margin: theme.spacing(0, 2),
        textAlign: 'center',
        fontSize: 17,
    },
}));

export interface AnnouncementGridItemProps {
    onEdit: (data: Object) => void;
    onRemove: (data: Object) => void;
    onComment: (data: Object) => void;
    onReact: (data: Object) => void;
}

export const AnnouncementGridItem = (props: AnnouncementGridItemProps) => {
    const { onEdit, onRemove, onComment, onReact, ...item } = props;
    const classes = itemStyles();

    const handleDataAction = (item, data) => {
        if (item.id === 'edit' && onEdit) {
            onEdit(data);
        } else if (item.id === 'remove' && onRemove) {
            onRemove(data);
        }
    };

    return (
        <Grid container item xs={12} sm={6} md={4} lg={3}>
            <Paper className={classes.root}>
                <div className={classes.header} onDoubleClick={() => handleDataAction({ id: 'edit', item })}>
                    <Grid container justifyContent="space-between" alignItems="center" direction="row">
                        <div>
                            <Typography variant="subtitle1" className={classes.title}>
                                {item.author || 'Anonymous'}
                            </Typography>
                            <Typography variant="caption">{FormatManager.formatDate(item.publishedAt, 'd MMMM, yyyy')}</Typography>
                        </div>
                        <DataAction onMenuItemClick={handleDataAction} actions={defaultActions} data={item} />
                    </Grid>
                </div>
                <div className={classes.body}>
                    <div className={classes.content} style={{ backgroundColor: item.backgroundColor }}>
                        <Typography className={classes.contentText} style={{ color: item.textColor }}>
                            {item.content}
                        </Typography>
                    </div>
                    <Grid className={classes.footer} direction="row" justifyContent="space-between" alignItems="center" container item>
                        <Button className={classes.footerButton} onClick={() => onComment(item)}>
                            <Icon>comment</Icon>
                            {item.comments ? FormatManager.formatNumeral(item.comments, ' 0.0 a') : ''}
                        </Button>
                        <Button className={classes.footerButton} onClick={() => onReact(item)}>
                            <Icon>thumbs_up_down</Icon>
                            {item.reacts ? FormatManager.formatNumeral(item.reacts, ' 0.0 a') : ''}
                        </Button>
                    </Grid>
                </div>
            </Paper>
        </Grid>
    );
};
