import React, { useEffect, useState } from 'react';
import { Container, Paper, Icon, Grid, Button, makeStyles, useTheme } from '@material-ui/core';
import DataTable from '../../component/table';
import { TextInput, ObjectInput, NumberInput } from '../../component/control';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { validateForm } from '../../util/ValidationManager';
import FormatManager from '../../util/FormatManager';
import { withRouter } from 'react-router-dom';
import PatientApi from '../../api/PatientApi';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import BehaviorApi from '../../api/BehaviorApi';
import { BEHAVIOR_TABLE_FIELDS } from '../setup/Behavior';
import AppointmentApi from '../../api/AppointmentApi';
import GobackButton from '../../component/control/GobackButton';

export const PATIENT_BEHAVIOR_TABLE_FIELDS = [
    {
        name: 'doctor',
        label: 'Doctor',
        onLoad: (data, rowIdx) => `${data.doctor?.fullName} (${data.doctor?.licenseNumber})`,
    },
    {
        name: 'behavior',
        label: 'Behavior',
        type: 'image',
        onLoad: (data) => data.behavior.image,
    },
    {
        name: 'priority',
        label: 'Priority',
        align: 'right',
        sortable: true,
    },
    {
        name: 'createdAt',
        label: 'Recorded',
        type: 'raw',
        align: 'center',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.createdAt, 'dd, MMM, yyyy HH:mm a'),
    },
];

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
    actionFooter: {
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
    },
}));

const PatientBehavior = () => {
    const theme = useTheme();
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { appointmentId } = useParams();
    const [paging, setPaging] = useState({
        currentPage: 0,
        pageSize: 10,
        total: 0,
        data: [],
        sort: 'createdAt:DESC',
    });
    const [form, setForm] = useState({});

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [appointment, setAppointment] = useState(() => {
        console.log('Load appointment ... => ', appointmentId);
        AppointmentApi.getById(appointmentId)
            .then((data) => {
                console.log('getAppiontment => ', data);
                setAppointment(data);
            })
            .catch(handleError);
        return null;
    });

    const handleBehaviorData = async (currentPage, pageSize, sort, search) => {
        return await BehaviorApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const patientId = appointment?.patient?.id || 0;
            const result = await PatientApi.getBehaviors(patientId, currentPage, pageSize, sort);
            setPaging(result);
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    useEffect(() => {
        handleLoadData(paging.currentPage, paging.pageSize, paging.sort, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId]);

    const handleSave = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        const isValid = validateForm(
            form,
            [
                { fieldId: 'behavior', required: true },
                { fieldId: 'priority', required: true },
            ],
            handleError,
        );
        if (!isValid || !appointment) {
            return;
        }
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW_LOADING,
        });
        try {
            const info = {
                patientId: appointment.patient.id,
                doctor: appointment.doctor,
                ...form,
            };
            const response = await PatientApi.addBehavior(appointment.patient.id, info);
            handleLoadData(0, paging.pageSize, paging.sort, '');
            dispatch({
                type: ALERT_REDUX_ACTIONS.HIDE,
            });
            setForm({});
        } catch (error) {
            handleError(error);
        }
    };

    const handleInputChange = (key, value) => {
        setForm({ ...form, [key]: value });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <Grid className={classes.root} container spacing={3}>
                    <Grid direction="row" spacing={3} container>
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <TextInput
                                variant="outlined"
                                id="patient"
                                label="Patient"
                                icon="accessible"
                                value={`${appointment?.patient?.fullName} (${appointment?.patient?.code})`}
                                disabled
                            />
                        </Grid>
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <TextInput
                                variant="outlined"
                                id="doctor"
                                label="Doctor"
                                icon="accessible"
                                value={`${appointment?.doctor?.fullName} (${appointment?.doctor?.licenseNumber})`}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container direction="row" spacing={2}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <ObjectInput
                                    variant="outlined"
                                    id="behavior"
                                    label="Behavior"
                                    icon="psychology"
                                    onLoadData={handleBehaviorData}
                                    onLoadItem={(item) => item.name}
                                    onChange={(event) => {
                                        handleInputChange('behavior', event.target.value);
                                    }}
                                    value={form?.behavior}
                                    fields={BEHAVIOR_TABLE_FIELDS}
                                    required={true}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <NumberInput
                                    id="priority"
                                    label="Priority"
                                    icon="sort"
                                    value={form?.priority}
                                    type="integer"
                                    autoFocus={true}
                                    onChange={(event) => {
                                        handleInputChange('priority', event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={10} md={9} sm={8} xs={12}>
                                <TextInput
                                    id="note"
                                    label="Note"
                                    value={form?.note}
                                    onChange={(event) => {
                                        handleInputChange('note', event.target.value);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid container item direction="row" lg={2} md={3} sm={4} xs={12} justifyContent="flex-end" alignItems="center">
                                <Button
                                    type="submit"
                                    className={classes.submit}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    <Icon>archive</Icon> Record
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <DataTable
                            title="Records"
                            total={paging.total}
                            pageSize={paging.pageSize}
                            currentPage={paging.currentPage}
                            sort={paging.sort}
                            items={paging?.data || []}
                            fields={PATIENT_BEHAVIOR_TABLE_FIELDS}
                            onPageChange={(pagination) => {
                                handleLoadData(pagination.page, pagination.pageSize, pagination.sort, '');
                            }}
                        />
                    </Grid>
                    <Grid className={classes.actionFooter} container>
                        <GobackButton windowClose={true} />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default withRouter(PatientBehavior);
