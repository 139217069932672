import React, { useEffect, useState } from 'react';
import FileApi from '../../api/FileApi';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    loading: {
        padding: theme.spacing(1),
    },
}));

export interface FileImageProps extends HTMLImageElement {
    file?: Object;
    defaultImage?: String;
    size?: 'thumb' | 'small' | 'medium' | 'large' | 'original';
}

const FileImage = (props: FileImageProps) => {
    const { file, defaultImage, size, ...rest } = props;
    const classes = styles();

    const loadImage = async () => {
        if (file) {
            setLoading(true);
            try {
                const resp = await FileApi.getPreview(file, size);

                if (resp && resp.preview) {
                    setPreviewUrl(resp.preview);
                } else {
                    setPreviewUrl(defaultImage || './images/logo.png');
                }
            } catch (error) {
                setPreviewUrl(defaultImage || './images/logo.png');
            }
            setLoading(false);
        }
    };

    const [loading, setLoading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(() => {
        loadImage();
        return defaultImage;
    });

    useEffect(() => {
        loadImage();

        //eslint-disable-next-line
    }, [file]);

    if (loading) {
        return (
            <Grid className={classes.loading} container item alignItems="center" justifyContent="center">
                <CircularProgress size={size === 'thumb' ? 20 : 40} thickness={size === 'thumb' ? 3 : 3.6} />
            </Grid>
        );
    }

    return <img src={previewUrl} {...rest} />;
};

FileImage.defaultProps = {
    size: 'small',
};

export default FileImage;
