import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Typography, Container, Avatar, Icon, Grid, Button, Paper, makeStyles } from '@material-ui/core';

import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import { DateTimeInput, ImageInput, ObjectInput, TextInput } from '../../component/control';
import FormatManager from '../../util/FormatManager';
import PostApi, { POST_TYPES } from '../../api/PostApi';
import { POST_TABLE_FIELDS } from './Post';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(2, 0),
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const BannerDetail = () => {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isUpdate, setUpdate] = useState(id > 0);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [form, setForm] = useState({});

    const handlePostData = async (currentPage, pageSize = 10, sort = 'publishedAt:DESC', search) => {
        return await PostApi.fakePaging(POST_TYPES.newsfeed, currentPage, pageSize, sort, search);
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        console.log('Submit Banner');
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <Icon>slideshow</Icon>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Banner Setup
                </Typography>
                <Grid container direction="row" spacing={3} className={classes.form}>
                    <Grid lg={12} md={12} sm={6} xs={12} container item justifyContent="center" alignItems="center">
                        <ImageInput
                            size={{ width: 800, height: 430 }}
                            id="image"
                            enableFilePicker={true}
                            guild="TREATMENT_TYPE"
                            value={form?.tempImage}
                            onChange={(event) => setForm({ ...form, tempImage: event.target.value })}
                        />
                    </Grid>
                    <Grid container item>
                        <TextInput
                            id="caption"
                            label="Caption"
                            rows={4}
                            multiline={true}
                            value={form?.caption}
                            onChange={(event) => setForm('caption', event.target.value)}
                            required
                        />
                    </Grid>
                    <Grid direction="row" container item spacing={3}>
                        <Grid lg={3} sm={6} xs={12} item>
                            <DateTimeInput
                                type="date"
                                id="startDate"
                                icon="event"
                                label="Start"
                                value={form?.startDate}
                                onChange={(event) => setForm({ ...form, startDate: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid lg={3} sm={6} xs={12} item>
                            <DateTimeInput
                                type="date"
                                id="endDate"
                                icon="event"
                                label="End"
                                value={form?.endDate}
                                onChange={(event) => setForm({ ...form, endDate: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid lg={6} sm={12} xs={12} item>
                            <ObjectInput
                                variant="outlined"
                                id="post"
                                label="Related Post"
                                icon="feed"
                                onLoadData={handlePostData}
                                onLoadItem={(item) => {
                                    const { text, readMore } = FormatManager.compressText(item.content, 30, 1);
                                    return text + (readMore ? '...' : '');
                                }}
                                onChange={(event) => setForm({ ...form, post: event.target.value })}
                                value={form?.post}
                                fields={POST_TABLE_FIELDS}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <GobackButton />
                    <Button type="submit" variant="contained" color="primary" className={classes.submit} onClick={() => handleSubmit()}>
                        <Icon>save</Icon> Save
                    </Button>
                </Grid>
            </Paper>
        </Container>
    );
};

export default withRouter(BannerDetail);
