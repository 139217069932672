import ApiManager from '../util/ApiManager';

export const TREATMENT_TYPE_CATEGORIES = ['GENERAL_PRACTICE', 'ORAL_HYGIENE', 'ORTHODONTIC', 'AESTHETIC', 'SURGERY', 'OTHER'];

class TreatmentTypeApi extends ApiManager {
    constructor() {
        super('/clinic/treatment_types');
    }
}

export default new TreatmentTypeApi();
