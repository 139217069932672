import React, { useState } from 'react';
import { Grid, Button, makeStyles, useTheme, IconButton, Icon } from '@material-ui/core';
import DataTable from '../component/table';
import { TextInput, ListInput, DateTimeInput, ObjectInput } from '../component/control';
import { useDispatch } from 'react-redux';
import { validateForm } from '../util/ValidationManager';
import type { GridProps } from '@material-ui/core';
import FormatManager from '../util/FormatManager';
import DoctorApi from '../api/DoctorApi';
import { DOCTOR_TABLE_FIELDS } from '../page/registration/Doctor';
import { DAY_OF_WEEKS } from './OpenHoursForm';

export const BRANCH_DOCTOR_TABLE_FIELDS = [
    {
        name: 'doctor',
        label: 'Doctor',
        type: 'raw',
        onLoad: (data, index) => data?.doctor?.fullName || '-',
    },
    {
        name: 'dayOfWeek',
        label: 'Day of Week',
    },
    {
        name: 'fromTime',
        label: 'From Time',
        type: 'date',
        align: 'center',
        onLoad: (data, index) => FormatManager.formatDate(data?.fromTime, 'HH:mm'),
    },
    {
        name: 'toTime',
        label: 'To Time',
        type: 'date',
        align: 'center',
        onLoad: (data, index) => FormatManager.formatDate(data?.toTime, 'HH:mm'),
    },
];

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

export interface BranchDoctorFormProps extends GridProps {
    data?: Array<Object>;
    onChange: (Array<Object>) => void;
    onError: (error: string | Object) => void;
}

const BranchDoctorForm = (props: BranchDoctorFormProps) => {
    const theme = useTheme();
    const classes = styles();
    const { data, onChange, onError } = props;
    const dispatch = useDispatch();
    const [doctorDuty, setDoctorDuty] = useState({});
    const [selectedIdx, setSelectedIdx] = useState(-1);

    const handleDoctorData = async (currentPage, pageSize, sort, search) => {
        return await DoctorApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleSave = () => {
        const isValid = validateForm(
            doctorDuty,
            [
                { fieldId: 'doctor', required: true },
                { fieldId: 'dayOfWeek', required: true },
                { fieldId: 'fromTime', required: true },
                { fieldId: 'toTime', required: true },
            ],
            onError,
        );
        if (!isValid) {
            return;
        }
        const doctor = {
            ...doctorDuty,
            fromTime: doctorDuty.fromTime,
            toTime: doctorDuty.toTime,
        };
        if (onChange) {
            if (selectedIdx >= 0) {
                data[selectedIdx] = doctor;
                onChange(data);
            } else {
                onChange([...data, doctor]);
            }
        }

        setDoctorDuty({});
        setSelectedIdx(-1);
    };

    const handleRemove = (idx) => {
        setSelectedIdx(-1);
        const newData = data.filter((_, i) => i !== idx);
        onChange(newData);
    };

    return (
        <>
            <Grid className={classes.root} container spacing={4}>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <ObjectInput
                            variant="outlined"
                            id="doctor"
                            label="Doctor"
                            icon="medication"
                            onLoadData={handleDoctorData}
                            onLoadItem={(item) => item.fullName}
                            onChange={(event) => setDoctorDuty({ ...doctorDuty, doctor: event.target.value })}
                            value={doctorDuty?.doctor}
                            fields={DOCTOR_TABLE_FIELDS}
                            required={true}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <ListInput
                                id="dayOfWeek"
                                label="Day of Week"
                                data={DAY_OF_WEEKS}
                                onLoadItem={(item) => item.label}
                                value={
                                    typeof doctorDuty?.dayOfWeek === 'string'
                                        ? DAY_OF_WEEKS.find((dow) => dow.dow === doctorDuty?.dayOfWeek)
                                        : doctorDuty?.dayOfWeek
                                }
                                onChange={(event) => setDoctorDuty({ ...doctorDuty, dayOfWeek: event.target.value.dow })}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <DateTimeInput
                                type="time"
                                id="fromTime"
                                icon="schedule"
                                label="From Time"
                                value={doctorDuty?.fromTime}
                                onChange={(event) => setDoctorDuty({ ...doctorDuty, fromTime: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <DateTimeInput
                                type="time"
                                id="tomTime"
                                icon="schedule"
                                label="To Time"
                                value={doctorDuty?.toTime}
                                onChange={(event) => setDoctorDuty({ ...doctorDuty, toTime: event.target.value })}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item lg={10} md={9} sm={8} xs={12}>
                            <TextInput
                                id="note"
                                label="Note"
                                value={doctorDuty?.note}
                                rows={4}
                                onChange={(event) => setDoctorDuty({ ...doctorDuty, note: event.target.value })}
                                multiline
                            />
                        </Grid>
                        <Grid container item direction="row" lg={2} md={3} sm={4} xs={12} justifyContent="flex-end" alignItems="center">
                            <Button type="submit" className={classes.submit} size="large" variant="contained" color="primary" onClick={handleSave}>
                                <Icon>{selectedIdx < 0 ? 'add' : 'save'}</Icon> {selectedIdx < 0 ? 'Add' : 'Update'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        items={data}
                        onEdit={(item, index) => {
                            setDoctorDuty(item);
                            setSelectedIdx(index);
                        }}
                        fields={[
                            ...BRANCH_DOCTOR_TABLE_FIELDS,
                            {
                                name: 'data_remove',
                                align: 'center',
                                label: '@',
                                minWidth: 50,
                                type: 'raw',
                                onLoad: (item, index) => (
                                    <>
                                        <IconButton size="small" onClick={() => handleRemove(index)}>
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
};

BranchDoctorForm.defaultProps = {
    data: [],
    onChange: (data) => console.log('Branch Doctor Data Changed => ', data),
};

export default BranchDoctorForm;
