import React, { useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import FormatManager from '../../util/FormatManager';
import PostCommentApi from '../../api/PostCommentApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { ErrorTheme } from '../../config/Theme';
import DataTable from '../../component/table';
import { Button, ButtonGroup, Grid, Icon, Paper, ThemeProvider, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { SearchInput } from '../../component/control';
import { QuestionDialog } from '../../component/message';

export const POST_COMMENT_TABLE_FIELDS = [
    {
        name: 'createdAt',
        align: 'center',
        type: 'date',
        label: 'Date',
        minWidth: 120,
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.createdAt, 'yyyy-MM-dd HH:mm'),
    },
    {
        name: 'patient',
        label: 'Patient',
        minWidth: 150,
        onLoad: (data, rowIdx) => data.patient.full_name,
        sortable: false,
    },
    {
        name: 'comment',
        label: 'Comment',
    },
];

const styles = makeStyles((theme) => ({
    root: {},
    header: {
        flex: 1,
        // backgroundColor:theme.palette.primary.main,
        borderBottom: '1px solid' + theme.palette.divider,
        padding: theme.spacing(0, 1),
    },
    searchBox: {
        width: theme.spacing(32),
        marginRight: theme.spacing(1),
    },
    toolbar: {
        marginLeft: theme.spacing(1),
    },
}));

const PostComment = () => {
    const { id, postType } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();
    const classes = styles();
    const [search, setSearch] = useState('');
    const [showQuestion, setShowQuestion] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const loadComments = async (currentPage, pageSize, sort) => {
        try {
            const resp = await PostCommentApi.fakePaging(currentPage, pageSize, sort, search);
            setPaging(resp);
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const [paging, setPaging] = useState(() => {
        loadComments(0);
        return {
            currentPage: 0,
            pageSize: 10,
            total: 0,
            data: [],
            sort: 'modifiedAt:DESC',
        };
    });

    const handlePageChange = (pagination) => {
        loadComments(pagination.page, pagination.pageSize, pagination.sort);
    };

    const handleReload = () => {
        setSearch('');
        loadComments(0, paging.pageSize, paging.sort);
    };

    //Remove Data if confirmation is Yes
    const handleQuestionDialog = (status) => {
        if (status && selectedData) {
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            const removeIds = selectedData.map((item) => item.id);
            PostCommentApi.removeAll(removeIds)
                .then((data) => {
                    setSelectedData([]);
                    loadComments(0, paging.pageSize, paging.sort);
                    dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
                })
                .catch((error) => handleError(error));
        }
        setShowQuestion(false);
    };

    return (
        <>
            <QuestionDialog show={showQuestion} title="Confirm?" message={'Are you sure to remove these data?'} onClose={handleQuestionDialog} />
            <Paper className={classes.root} elevation={3}>
                <Grid container className={classes.header}>
                    <Grid container item lg={5} md={4} sm={6} xs={12} alignItems="center" alignContent="center" justifyContent="flex-start">
                        <SearchInput className={classes.searchBox} value={search} onSearch={(value) => setSearch(value)} placeholder="Search" />
                    </Grid>
                    <Grid container item lg={2} md={2} sm={6} xs={12} alignItems="center" alignContent="center" justifyContent="center">
                        <Typography variant="h6" component="h1" noWrap>
                            Post Comments
                        </Typography>
                    </Grid>
                    <Grid container item lg={5} md={6} sm={12} xs={12} alignItems="center" alignContent="center" justifyContent="flex-end">
                        <ThemeProvider theme={ErrorTheme}>
                            <Button
                                onClick={() => {
                                    setShowQuestion(true);
                                }}
                                variant="contained"
                                color="primary"
                                aria-label="Remove Selected Items"
                                disabled={selectedData.length <= 0}
                            >
                                <Icon>delete</Icon>
                                Remove {selectedData.length} comment(s).
                            </Button>
                        </ThemeProvider>
                        <ButtonGroup disableElevation className={classes.toolbar}>
                            <Tooltip title="Back">
                                <Button
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                    variant="contained"
                                    aria-label="Go back"
                                >
                                    <Icon>arrow_back</Icon>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Reload">
                                <Button onClick={handleReload} variant="contained" color="primary" aria-label="Add New">
                                    <Icon>cached</Icon>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container item>
                    <DataTable
                        multi={true}
                        items={paging.data}
                        fields={POST_COMMENT_TABLE_FIELDS}
                        total={paging.total}
                        pageSize={paging.pageSize}
                        currentPage={paging.currentPage}
                        sort={paging.sort}
                        selectedData={selectedData}
                        onPageChange={handlePageChange}
                        onSelectionChange={(data) => setSelectedData(data)}
                    />
                </Grid>
            </Paper>
        </>
    );
};

export default withRouter(PostComment);
