import { Grid, Icon, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { SuccessTheme } from '../config/Theme';
import { TextInput } from '../component/control';
import FormatManager from '../util/FormatManager';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    formHeader: {
        padding: theme.spacing(2, 3),
    },
    formBody: {
        marginTop: theme.spacing(1),
    },
    infoGrid: {
        width: '100%',
    },
    infoRow: {
        padding: theme.spacing(1, 0),
    },
    infoLabel: {
        padding: theme.spacing(2, 0, 0),
        fontWeight: 'bolder',
        width: 60,
    },
    infoSeperator: {
        padding: theme.spacing(2, 0, 0),
        width: theme.spacing(2),
    },
    infoValue: {
        textAlign: 'left',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        padding: theme.spacing(2, 0, 0),
    },
}));

export interface AppointmentTreatmentFormProps {
    data: String;
    onError: (error: string | Object) => void;
}

const AppointmentHistory = (props: AppointmentTreatmentFormProps) => {
    const classes = styles();
    const { data } = props;

    //Build Branch Info with Transfer
    let branchInfo = data?.branch?.code || '';
    if (data?.transferBranch) {
        branchInfo = data?.transferBranch?.code + ' --> ' + data?.branch?.code;
    }

    //Combine Appointment Doctor and Treatment Doctor
    let doctorInfo = data?.doctor?.fullName || '';
    if (data?.appointment?.treatmentDoctor?.id && data?.doctor?.id !== data?.appointment?.treatmentDoctor?.id) {
        doctorInfo += `(${data?.appointment?.treatmentDoctor.fullName})`;
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid className={classes.formHeader} container direction="row" justifyContent="space-between">
                <Grid lg={5} md={5} sm={12} xs={12} item>
                    <table className={classes.infoGrid}>
                        <tbody>
                            <tr className={classes.infoRow}>
                                <td className={classes.infoLabel}>Code</td>
                                <td className={classes.infoSeperator}>:</td>
                                <th className={classes.infoValue}>{data.code}</th>
                            </tr>
                            <tr className={classes.infoRow}>
                                <td className={classes.infoLabel}>Doctor</td>
                                <td className={classes.infoSeperator}>:</td>
                                <th className={classes.infoValue}>{doctorInfo}</th>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <Grid lg={5} md={5} sm={12} xs={12} item>
                    <table className={classes.infoGrid}>
                        <tbody>
                            <tr className={classes.infoRow}>
                                <td className={classes.infoLabel}>Date</td>
                                <td className={classes.infoSeperator}>:</td>
                                <th className={classes.infoValue}>{FormatManager.formatDate(data?.date, 'dd MMM, yyyy')}</th>
                            </tr>
                            <tr className={classes.infoRow}>
                                <td className={classes.infoLabel}>Branch</td>
                                <td className={classes.infoSeperator}>:</td>
                                <th className={classes.infoValue}>{data?.branch?.name}</th>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
            <Grid container className={classes.formBody}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="right" width={50}>
                                    No.
                                </TableCell>
                                <TableCell width={250}>Type</TableCell>
                                <TableCell width={50} align="center">
                                    Tooth
                                </TableCell>
                                <TableCell align="center" width={80}>
                                    Operated
                                </TableCell>
                                <TableCell>Note</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.items?.map((item, index) => {
                                console.log('treatment => ', item);
                                return (
                                    <TableRow key={'row_' + index}>
                                        <TableCell align="right">{index + 1}</TableCell>
                                        <TableCell>
                                            {item.treatmentType.name} ({item.treatmentType.code})
                                        </TableCell>
                                        <TableCell align="center">{item.toothCode}</TableCell>
                                        <TableCell align="center">
                                            <ThemeProvider theme={SuccessTheme}>
                                                <Icon color={item.operated ? 'primary' : 'disabled'}>{item.operated ? 'done' : 'block'}</Icon>
                                            </ThemeProvider>
                                        </TableCell>
                                        <TableCell>{item.note}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container>
                <TextInput id="operationNote" disabled={true} label="Operation Note" value={data.operationNote} multiline={true} rows={4} />
            </Grid>
        </Grid>
    );
};

AppointmentHistory.defaultProps = {
    treatments: [],
    tableRowActions: [],
    isOperation: false,
};

export default AppointmentHistory;
