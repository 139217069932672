import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { IconButton, Icon, Tooltip, makeStyles, AppBar, Toolbar, Typography, Box, CssBaseline, Drawer, Divider, useTheme } from '@material-ui/core';
import { PrivateRoute } from '../../config/Route';
import clsx from 'clsx';
import ScrollBar from '../control/ScrollBar';
import {
    SESSION_TIMEOUT,
    STORAGE_KEYS,
    APP_NAME,
    APP_VERSION,
    DEFAULT_SIDE_MENU,
    DEVELOPMENT_SIDE_MENU,
    USER_PROFILE_MENU,
    ONE_SIGNAL_OPTIONS,
} from '../../config/Constant';
import { Copyright } from '../control';
import SideMenu from './SideMenu';
import NotificationMenu from './NotificationMenu';
import DrawerHeader from './DrawerHeader';
import Reducer, { ACTIONS } from './Reducer';
import FileApi from '../../api/FileApi';
import UserMenu from './UserMenu';
import ProfileApi from '../../api/ProfileApi';
import NotificationApi from '../../api/NotificationApi';
import { useDispatch, useSelector } from 'react-redux';
import { USER_REDUX_ACTIONS } from '../../util/UserManager';
import type { HTMLProps } from 'react';
import OneSignalReact from 'react-onesignal';
import MenuApi from '../../api/MenuApi';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { onMessageListener, requestToken } from '../../util/FirebaseManager';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';

const DRAWER_FULL_SIZE: number = window.innerWidth > 1400 ? 300 : 260;
const MIN_WIDTH_TO_HIDE = 1180;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: DRAWER_FULL_SIZE,
        width: `calc(100% - ${DRAWER_FULL_SIZE}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: DRAWER_FULL_SIZE,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        padding: theme.spacing(2),
    },
    logo: {
        borderRadius: 3,
        // border: '1px solid ' + theme.palette.common.gray,
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.contrastText,
        padding: 2,
    },
    copyRight: {
        marginBottom: theme.spacing(3),
    },
}));

export interface LayoutProps extends HTMLProps {
    onToggleMode: () => void;
    mode: 'LIGHT' | 'DARK';
}

const Layout = (props: LayoutProps) => {
    const { onToggleMode, mode } = props;

    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [userProfileImage, setUserProfileImage] = useState(() => {
        FileApi.getPreview(user.profileImage, 'small')
            .then((file) => {
                if (file && file.preview) {
                    setUserProfileImage(file.preview);
                }
            })
            .catch(handleError);
        return null;
    });

    const granted = user && user.currentToken && user.currentToken.length > 0;
    const idleTimer = useRef(null);

    const [fcmReady, setFcmReady] = useState(false);

    const [badge, setBadge] = useState(() => {
        console.log('Check notifications ...');
        if (user) {
            NotificationApi.getUnreadCount()
                .then((count) => {
                    setBadge(count.unreand);
                })
                .catch(handleError);
        }
        return 0;
    });

    const handleRefreshMessagingToken = async (token) => {
        try {
            const existItem = localStorage.getItem(STORAGE_KEYS.MESSAGING_TOKEN);
            if (existItem !== token) {
                console.log('Refreshed Messaging Token => ', token);
                localStorage.setItem(STORAGE_KEYS.MESSAGING_TOKEN, token);
                const userInfo = await ProfileApi.refreshToken(token);
                if (userInfo) {
                    dispatch({
                        type: USER_REDUX_ACTIONS.UPDATE,
                        profile: userInfo,
                    });
                }
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleReceivedNotification = async (title, message) => {
        if (badge) {
            const count = await NotificationApi.getUnreadCount();
            setBadge(count.unread);
        }

        dispatch({
            type: FLASH_REDUX_ACTIONS.SHOW,
            flash: { type: 'info', title: title, message: message },
        });
    };

    const setupOneSingalMessaging = async () => {
        console.log('Init One Signal ....');
        try {
            await OneSignalReact.init(ONE_SIGNAL_OPTIONS);

            const isEnabled = await OneSignalReact.isPushNotificationsEnabled();
            if (!isEnabled) {
                await Notification.requestPermission();
            }

            const playerId = await OneSignalReact.getUserId();
            if (playerId) {
                handleRefreshMessagingToken(playerId);
            }

            //Set User Info
            if (user && user.id) {
                //Set External UserID
                await OneSignalReact.setExternalUserId(user.id);

                await OneSignalReact.sendTags(
                    {
                        app_type: 'panel',
                    },
                    (event) => {
                        console.log('Subscribed tags ...');
                    },
                );
            }

            OneSignalReact.on('notificationDisplay', (notification) => {
                handleReceivedNotification(notification.heading, notification.content);
            });
        } catch (error) {
            handleError(error);
        }
    };

    const setupFirebaseMessaging = () => {
        const permission = Notification.permission;

        //Initialize Firebase Web Push Notification
        if (permission === 'granted') {
            console.log('Init Firebase Messaging ...');
            requestToken()
                .then((token) => {
                    handleRefreshMessagingToken(token);
                })
                .catch(handleError);
            setFcmReady(true);
        } else {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        setupFirebaseMessaging();
                    }
                })
                .catch(handleError);
        }
        return null;
    };

    const loadMenu = async () => {
        if (!granted) return;

        const menus = await MenuApi.getCurrentUserMenu();
        if (menus && menus.data.length > 0) {
            const uniqueMenus = menus.data.reduce((unique, o) => {
                if (!unique.some((obj) => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            let mainMenu = uniqueMenus.filter((m) => !m.parentId);

            //Recurrsively sort menus
            const sortMenu = (items) =>
                items.sort((a, b) => {
                    if (a.items && a.items.length > 0) {
                        sortMenu(a.items);
                    }
                    return a.priority - b.priority;
                });

            sortMenu(mainMenu);

            setState({
                type: ACTIONS.LOAD,
                payload: {
                    ...state,
                    menus: process.env.NODE_ENV === 'development' ? DEVELOPMENT_SIDE_MENU : [...mainMenu, USER_PROFILE_MENU, logoutMenu],
                },
            });
        }
    };

    useEffect(() => {
        loadMenu();
        // setupOneSingalMessaging();
        setupFirebaseMessaging();

        // eslint-disable-next-line
    }, []);

    if (fcmReady) {
        //Message Received Handler
        onMessageListener()
            .then((payload) => {
                console.log('Received Message => ', payload);
                handleReceivedNotification(payload.notification?.title, payload.notification?.body);
            })
            .catch(handleError);
    }

    const handleLogout = () => {
        dispatch({
            type: USER_REDUX_ACTIONS.LOGOUT,
        });
        sessionStorage.clear();
        localStorage.clear();
        history.push('/login');
    };

    const logoutMenu = {
        id: 'sys-logout',
        label: 'Logout',
        icon: 'lock',
        divider: true,
        onClick: handleLogout,
    };

    const [state, setState] = useReducer(Reducer, {
        menus: [...DEFAULT_SIDE_MENU, logoutMenu],
        openIds: [],
        hideMenu: window.innerWidth < MIN_WIDTH_TO_HIDE,
    });

    const onItemClick = async (item) => {
        console.log('item click', item);
        //Need to Control
    };

    const readAllNotification = () => {
        NotificationApi.readAllMyNotifications();
    };

    const loadMoreNotification = async (paging) => {
        if (!user) {
            return {};
        }
        const result = await NotificationApi.getMyNotifications(paging.currentPage + 1, paging.pageSize);
        if (result.data) {
            for (let i = 0; i < result.data.length; i++) {
                result.data[i] = {
                    id: result.data[i].id,
                    image: result.data[i].imageUrl,
                    date: result.data[i].sentAt,
                    title: result.data[i].title,
                    description: result.data[i].description,
                    referenceId: result.data[i].data ? result.data[i].data.referenceId : null,
                    isRead: result.data[i].readAt,
                    ...result.data[i],
                };
            }

            return result;
        }
        return {};
    };

    if (!granted) {
        return <Redirect to="/login" />;
    }

    //Calculate session timeout
    const getSessionTimeOut = () => {
        if(SESSION_TIMEOUT){
            return parseInt(SESSION_TIMEOUT) * 1000;
        }
        return (1000 * 60 * 20);
    }

    return (
        <div className={classes.root}>
            <IdleTimer ref={idleTimer} element={document} onIdle={handleLogout} debounce={1000} timeout={getSessionTimeOut()} />
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, !state.hideMenu && classes.appBarShift)} elevation={3}>
                <Toolbar className={classes.toolbar}>
                    <img src={`/${'images/logo.png'}`} height={theme.spacing(5)} alt="SUNDEW MYANMAR" className={classes.logo} />
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {APP_NAME} ({APP_VERSION})
                    </Typography>
                    <div />
                    <div>
                        <NotificationMenu
                            name="Notifications"
                            badge={badge}
                            onLoadMore={loadMoreNotification}
                            onItemClick={onItemClick}
                            onReadAll={readAllNotification}
                        />
                        <Tooltip title={mode === 'DARK' ? 'Toggle Light Mode' : 'Toggle Dark Mode'}>
                            <IconButton
                                aria-label="Dark Mode"
                                onClick={() => {
                                    if (onToggleMode) {
                                        onToggleMode(mode === 'DARK' ? 'LIGHT' : 'DARK');
                                    }
                                }}
                            >
                                <Icon color="action">{mode === 'DARK' ? 'brightness_7' : 'brightness_4'}</Icon>
                            </IconButton>
                        </Tooltip>
                        <UserMenu
                            image={user.profileImage}
                            name={user.displayName || user.email}
                            role={
                                user.roles &&
                                user.roles
                                    .map(function (elem) {
                                        return elem.name;
                                    })
                                    .join(', ')
                            }
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, state.hideMenu && classes.drawerPaperClose),
                }}
                open={!state.hideMenu}
            >
                {state.hideMenu ? <div className={classes.appBarSpacer} /> : null}
                <DrawerHeader
                    hideMenu={state.hideMenu}
                    image={userProfileImage}
                    name={user.displayName}
                    onMenuClick={() => setState({ type: ACTIONS.SIZE_CHNGE })}
                />
                <Divider className={classes.divider} />
                <SideMenu state={state} dispatch={setState} />
            </Drawer>
            <main className={classes.content}>
                <ScrollBar>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.container}>
                        {PrivateRoute.map((route, index) => {
                            return (
                                <Route
                                    exact
                                    key={index}
                                    path={route.path}
                                    render={(props) => (granted ? <route.page {...props} /> : <Redirect to="/login" />)}
                                />
                            );
                        })}
                    </div>
                    <Box pt={4} className={classes.copyRight}>
                        <Copyright />
                    </Box>
                </ScrollBar>
            </main>
        </div>
    );
};

export default Layout;
