import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Typography, Container, Avatar, Icon, Grid, Button, Paper, makeStyles } from '@material-ui/core';

import TreatmentTypeApi, { TREATMENT_TYPE_CATEGORIES } from '../../api/TreatmentTypeApi';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { validateForm } from '../../util/ValidationManager';
import { CheckboxInput, ImageInput, ListInput, TextInput } from '../../component/control';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(2, 0),
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const TreatmentTypeDetail = () => {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isUpdate, setUpdate] = useState(false);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [form, setForm] = useState(() => {
        if (id > 0) {
            TreatmentTypeApi.getById(id)
                .then((data) => {
                    setForm({ ...data, tempImage: data.image });
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }
        return {};
    });

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        const isValid = validateForm(
            form,
            [
                { fieldId: 'category', required: true },
                { fieldId: 'code', required: true },
                { fieldId: 'name', required: true },
            ],
            handleError,
        );

        if (!isValid) {
            return;
        }

        try {
            let treatmentType = { ...form };
            if (form.tempImage && form.tempImage.id) {
                treatmentType.image = form.tempImage;
            } else if (form.tempImage) {
                const fileResponse = await TreatmentTypeApi.fileUpload(form.tempImage, 'image');
                if (fileResponse) {
                    treatmentType.image = fileResponse;
                }
            } else {
                treatmentType.image = null;
            }

            let message = '';
            if (isUpdate) {
                const response = await TreatmentTypeApi.modifyById(id, treatmentType);
                message = `Treatment type ${response.name} has been updated successfully.`;
            } else {
                const response = await TreatmentTypeApi.addNew(treatmentType);
                message = `Treatment type ${response.name} has been registered successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });
            history.push('/treatmentType');
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <Icon>category</Icon>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Treatment Type Setup
                </Typography>
                <Grid container direction="row" spacing={3} className={classes.form}>
                    <Grid lg={5} md={5} sm={6} xs={12} container item justifyContent="center" alignItems="center">
                        <ImageInput
                            size={{ width: 230, height: 230 }}
                            id="image"
                            enableFilePicker={true}
                            guild="TREATMENT_TYPE"
                            value={form?.tempImage}
                            onChange={(event) => setForm({ ...form, tempImage: event.target.value })}
                        />
                    </Grid>
                    <Grid direction="column" container lg={7} md={7} sm={6} xs={12} item>
                        <Grid item>
                            <ListInput
                                id="category"
                                label="Category"
                                icon="category"
                                data={TREATMENT_TYPE_CATEGORIES}
                                value={form?.category}
                                onChange={(event) => setForm({ ...form, category: event.target.value })}
                            />
                        </Grid>
                        <Grid item>
                            <TextInput
                                id="code"
                                icon="code"
                                label="Code"
                                value={form?.code}
                                onChange={(event) => setForm({ ...form, code: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextInput
                                id="name"
                                icon="edit_note"
                                label="Name"
                                value={form?.name}
                                onChange={(event) => setForm({ ...form, name: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <CheckboxInput
                                id="requiredToothInfo"
                                label="Require tooth info?"
                                value={form?.requiredToothInfo}
                                onChange={(event) => setForm({ ...form, requiredToothInfo: event.target.checked })}
                                checked={form?.requiredToothInfo ?? false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <GobackButton />
                    <Button type="submit" variant="contained" color="primary" className={classes.submit} onClick={() => handleSubmit()}>
                        <Icon>save</Icon> Save
                    </Button>
                </Grid>
            </Paper>
        </Container>
    );
};

export default withRouter(TreatmentTypeDetail);
