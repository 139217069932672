import ApiManager from '../util/ApiManager';

class FileApi extends ApiManager {
    constructor() {
        super('files');
    }

    async getPagingByFolder(folder, page, size, sort, filter, isPublic, isHidden, guild) {
        if (!folder) folder = 0;

        let url = '/' + folder + '/folder?page=' + page + '&size=' + size;
        if (sort && sort !== '') url += '&sort=' + sort;
        if (filter && filter !== '') url += '&filter=' + filter;
        if (isPublic && isPublic !== '') url += '&public=' + isPublic;
        if (isHidden && isHidden !== '') url += '&hidden=' + isHidden;
        if (guild && guild !== '') url += '&guild=' + guild;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async getPreview(file, size = 'small') {
        if (file && file.preview) {
            return file;
        }
        if (file && file.id && file.name && file.extension) {
            let url = '';
            if (file.publicAccess && file.urls.public) {
                url = process.env.NODE_ENV === 'production' ? file.urls.public : file.urls.public.replace('https://', 'http://');
            } else {
                const response = await this.download(file, size);
                url = URL.createObjectURL(response);
            }
            Object.assign(file, { preview: url });
        } else if (file && file.type.startsWith('image')) {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
        }
        return file;
    }

    async download(file, size = 'original') {
        if (file && file.id && file.name && file.extension) {
            const response = await this.get(`/download/${file.id}/${file.name}.${file.extension}?size=${size}`, this.getHeaders(true), 'blob');
            return response;
        }
        return null;
    }

    async upload(files, isPublic, isHidden, folder, guild) {
        let headers = this.getHeaders(true);
        headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (files.name) {
            formData.append('uploadedFile', files);
        } else {
            for (let i = 0; i < files.length; i++) {
                formData.append('uploadedFile', files[i]);
            }
        }

        formData.append('isPublic', isPublic);
        if (isHidden) formData.append('isHidden', isHidden);
        if (folder) formData.append('folder', folder);
        if (guild) formData.append('guild', guild);

        const response = await this.post('/upload', formData, headers);
        if (response.count === 1) {
            return response.data[0];
        }

        return response;
    }
}

export default new FileApi();
