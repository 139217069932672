import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import DoctorApi from '../../api/DoctorApi';
import FormatManager from '../../util/FormatManager';

export const DOCTOR_TABLE_FIELDS = [
    {
        name: 'id',
        align: 'center',
        label: 'Id',
        sortable: true,
    },
    {
        name: 'profileImage',
        align: 'center',
        label: 'Profile',
        type: 'image',
    },
    {
        name: 'licenseNumber',
        align: 'left',
        label: 'License',
        sortable: true,
    },
    {
        name: 'fullName',
        align: 'left',
        label: 'Name',
        sortable: true,
    },
    {
        name: 'gender',
        align: 'center',
        label: 'Gender',
        sortable: true,
    },
    {
        name: 'position',
        label: 'Position',
        sortable: true,
    },
    {
        name: 'startWorkingDate',
        align: 'center',
        label: 'SW-Date',
        onLoad: (data, index) => FormatManager.formatDate(data?.startWorkingDate, 'dd/MMM/yyyy'),
        sortable: true,
    },
    {
        name: 'status',
        label: 'Status',
        sortable: true,
    },
];

const Doctor = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await DoctorApi.getPaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        if (removeData && removeData.id) {
            return DoctorApi.removeById(removeData.id);
        } else if (Array.isArray(removeData) && removeData.length > 0) {
            const removeIds = removeData.map((item) => item.id);
            return DoctorApi.removeAll(removeIds);
        }
    };

    const handleDetail = (item) => {
        let url = '/doctor/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <MasterTable
                title="Doctors"
                fields={DOCTOR_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onAddNew={() => handleDetail(null)}
                onRemove={handleRemoveData}
                onError={handleError}
                hideImportMenu={true}
            />
        </>
    );
};

export default withRouter(Doctor);
