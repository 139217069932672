import * as React from 'react';
import { Icon, Paper, makeStyles, FormControl, InputLabel, Grid, IconButton, Typography, Chip, FormHelperText, useTheme } from '@material-ui/core';
import FormatManager from '../../util/FormatManager';
import type { FormControlProps } from '@material-ui/core';
import ToothPicker from './ToothPicker';

export interface ToothInputProps extends FormControlProps {
    values?: Array<Object>;
    value?: object;
    icon?: string;
    multi?: boolean;
    required: boolean;
    label: string;
    disableRemove?: boolean;
    disabledLoad?: boolean;
    hidePlaceHolder: boolean;
    onLoadItem?: (item: object) => string;
    onValidate?: (event: React.SyntheticEvent<HTMLInputElement>) => string;
    onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

const styles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'inherit',
    },
    label: (props) => ({
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        color: props.invalid ? theme.palette.error.main : theme.palette.text.primary,
    }),
    content: (props) => ({
        backgroundColor: 'inherit',
        minHeight: theme.spacing(6),
        padding: theme.spacing(0.5, 0, 0.5, 1.5),
        borderColor: props.invalid ? theme.palette.error.main : theme.palette.common.gray,
        '&:hover': {
            borderColor: props.invalid ? theme.palette.error.main : theme.palette.primary.main,
        },
    }),
    disabledOpenIcon: {
        color: theme.palette.common.gray,
    },
    chip: {
        margin: theme.spacing(0.5, 0.5, 0.5, 0),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    placeholder: {
        color: theme.palette.text.hint,
    },
    actionButton: {},
    hiddenInput: {
        position: 'absolute',
        zIndex: -9999,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.background.paper,
        marginLeft: theme.spacing(5),
        border: 0,
        width: 0,
    },
}));

const ToothInput = (props: ToothInputProps) => {
    const {
        hidePlaceHolder,
        visible,
        variant,
        height,
        values,
        label,
        id,
        name,
        inputRef,
        value,
        icon,
        placeholder,
        multi,
        fields,
        disableRemove,
        disabledLoad,
        onValidate,
        onChange,
        onLoadItem,
        ...rest
    } = props;
    const [show, setShow] = React.useState(false);
    const [error, setError] = React.useState('');
    const [invalid, setInvalid] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState(FormatManager.defaultNull(multi ? values : value));
    const classes = styles({ invalid });
    const theme = useTheme();
    const currentInput = inputRef || React.createRef();

    //Set value if props.value changed.
    React.useEffect(() => {
        const inputData = FormatManager.defaultNull(multi ? values : value);
        if (selectedData !== inputData) {
            setSelectedData(inputData);
            if (onChange) {
                onChange({
                    target: {
                        name: id || name,
                        value: inputData,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, value]);

    const handleClose = (result) => {
        setShow(false);
        if (result === false) {
            return;
        }
        setSelectedData(result);
        const hasData = result && (result.code || result.length > 0);

        let errorText = '';
        if (props.required && !hasData) {
            errorText = label + " can't be blank.";
        } else if (onValidate) {
            errorText = onValidate(
                {
                    target: {
                        name: id || name,
                        value: result,
                    },
                },
                result,
            );
        }
        setError(errorText);
        currentInput.current.setCustomValidity(errorText);
        setInvalid(errorText.length > 0);

        if (onChange) {
            onChange({
                target: {
                    name: id || name,
                    value: result,
                },
            });
        }
    };

    const handleRemove = (item) => {
        if (!multi) {
            handleClose(null);
            return;
        }
        let updateSelection = selectedData.filter((x) => x.code !== item.code);
        handleClose(updateSelection);
    };

    const handleError = (error) => {
        setShow(false);
        setError(error);
    };

    const displayBox = () => {
        const hasData = selectedData && (selectedData.code || selectedData.length > 0);
        if (!hasData) {
            return (
                <Typography className={classes.placeholder} variant="body1">
                    {hidePlaceHolder || placeholder || 'Choose ' + label}
                </Typography>
            );
        }
        if (multi) {
            return (
                <>
                    {selectedData.map((item, index) => {
                        const display = onLoadItem(item);

                        return (
                            <Chip
                                {...heightProps}
                                onDelete={disableRemove ? null : () => handleRemove(item)}
                                className={classes.chip}
                                key={item.code ? item.code : index}
                                label={display}
                            />
                        );
                    })}
                </>
            );
        }

        const display = onLoadItem(selectedData);
        if (typeof display === 'object') {
            return (
                <Chip
                    onDelete={disableRemove ? null : () => handleRemove(selectedData)}
                    className={classes.chip}
                    icon={<Icon>{display.icon}</Icon>}
                    label={display.label}
                />
            );
        }

        if (disableRemove) {
            return <Typography variant="body2">{display}</Typography>;
        }

        return <Chip onDelete={disableRemove ? null : () => handleRemove(selectedData)} className={classes.chip} label={display} />;
    };

    let variantProps = { variant: variant, elevation: 0 };
    let heightProps = {};
    if (variant !== 'standard') {
        variantProps = { ...variantProps, elevation: 1 };
    }

    if (variant === 'standard' && height) heightProps = { style: { height: height } };

    let visibility = {};
    if (!visible) {
        visibility = { display: 'none' };
    }
    return (
        <>
            <ToothPicker show={show} multi={multi} onClose={handleClose} data={selectedData} />
            <FormControl style={{ ...visibility }} {...rest} variant="outlined" margin="normal" fullWidth className={classes.root}>
                <InputLabel className={classes.label} shrink htmlFor="bootstrap-input">
                    {label}
                </InputLabel>
                <Paper {...variantProps} classes={{ root: classes.content }}>
                    <Grid container>
                        <Grid container item xs={10} sm={10} className={classes.chipContainer} alignItems="center">
                            {icon ? (
                                <Icon color={theme.palette.type === 'dark' ? 'default' : 'primary'} className={classes.icon}>
                                    {icon}
                                </Icon>
                            ) : null}
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="hidden"
                                    className={classes.hiddenInput}
                                    defaultValue={selectedData && (selectedData.code || selectedData.length > 0) ? JSON.stringify(selectedData) : ''}
                                    ref={currentInput}
                                />
                                {displayBox()}
                            </div>
                        </Grid>
                        <Grid className={classes.actionButton} container item xs={2} sm={2} justifyContent="flex-end" alignItems="center">
                            {
                                <IconButton
                                    disableRipple
                                    color="default"
                                    onClick={() => {
                                        disabledLoad || setShow(true);
                                    }}
                                    className={!disabledLoad ? null : classes.disabledOpenIcon}
                                    aria-label="Choose"
                                >
                                    <Icon>open_in_new</Icon>
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Paper>
                {invalid ? <FormHelperText error={invalid}>{error}</FormHelperText> : null}
            </FormControl>
        </>
    );
};

ToothInput.defaultProps = {
    onLoadItem: (item) => {
        console.warn('Undefined OnLoadItem => ', item);
        return item.code;
    },
    multi: true,
    variant: 'outlined',
    disableRemove: false,
    disabledLoad: false,
    visible: true,
    hidePlaceHolder: false,
};

export default ToothInput;
