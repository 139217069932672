import React from 'react';
import { Button, Icon, type ButtonProps } from '@material-ui/core';

export interface GobackButtonProps extends ButtonProps {
    windowClose: boolean;
}

export default function GobackButton(props: GobackButtonProps) {
    const { windowClose, ...buttonProps } = props;

    if (history.length <= 1) {
        if (windowClose) {
            return (
                <Button type="button" variant="contained" color="default" onClick={() => window.close()} {...buttonProps}>
                    <Icon>close</Icon> Close
                </Button>
            );
        }

        return null;
    }

    return (
        <Button type="button" variant="contained" color="default" onClick={() => history.back()} {...buttonProps}>
            <Icon>arrow_back</Icon> Go back
        </Button>
    );
}

GobackButton.defaultProps = {
    windowClose: false,
};
