import ApiManager from '../util/ApiManager';

class RoleApi extends ApiManager {
    constructor() {
        super('clinic/dashboard');
    }

    async getTodaySummary(date = new Date()) {
        const response = await this.get(`/summary?date=${date.getTime()}`, this.getHeaders(true));
        return response;
    }

    async getBalance(type, params: Object) {
        let url = `/balance/${type}`;
        if (Object.keys(params).length > 0) {
            const key = Object.keys(params)[0];
            url += `?${key}=${params[key]}`;
        }
        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async getAppointments(type, fromDate, toDate, page = 0, size = 10) {
        let url = `/appointments/${type}?page=${page}&size=${size}`;
        if (fromDate && fromDate !== '') url += '&fromDate=' + fromDate;
        if (toDate && toDate !== '') url += '&toDate=' + toDate;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async getTopDoctors(date = new Date()) {
        const response = await this.get(`/top/d?date=${date.getTime()}`, this.getHeaders(true));
        return response;
    }

    async getTopPatients(date = new Date()) {
        const response = await this.get(`/top/p?date=${date.getTime()}`, this.getHeaders(true));
        return response;
    }

    async getTopTreatments(date = new Date()) {
        const response = await this.get(`/top/t?date=${date.getTime()}`, this.getHeaders(true));
        return response;
    }
}

export default new RoleApi();
