import ApiManager from '../util/ApiManager';

export const STATUS = ['BOOKED', 'CONFIRM', 'CANCEL', 'ARRIVED', 'PROCESSING', 'FINISHED'];

class AppointmentApi extends ApiManager {
    constructor() {
        super('/clinic/appointments');
    }

    async getHistory(id) {
        const response = await this.get(`/history/${id}`, this.getHeaders(true));
        return response;
    }
}

export default new AppointmentApi();
