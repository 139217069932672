import { faker } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';
import ApiManager from '../util/ApiManager';

class BannerApi extends ApiManager {
    constructor() {
        super('/cms/banners');
    }

    fakeOne() {
        const rndImage = faker.number.int({ min: 1, max: 100 });

        return {
            banner: {
                id: uuid(),
                preview: `https://source.unsplash.com/random/150x100?sig=${rndImage}`,
                type: 'image/jpg',
            },
            caption: faker.lorem.sentence(),
            startDate: faker.date.past({ months: 3 }).getTime(),
            endDate: faker.date.future({ months: 1 }).getTime(),
            postId: faker.number.int({ min: 0, max: 10000 }),
        };
    }

    fakeList(count) {
        let data = [];
        for (let i = 0; i < count; i++) {
            let fakeData = this.fakeOne();
            fakeData.id = i + 1;
            data.push(fakeData);
        }
        return data;
    }

    async fakePaging(page = 0, size = 10, sort = 'createdAt:DESC', filter = '') {
        let data = this.fakeList(size);
        return {
            total: size * (page + 1),
            currentPage: page,
            data: data,
            pageSize: size,
            sort: 'id:ASC',
        };
    }
}

export default new BannerApi();
