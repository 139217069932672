import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    Zoom,
    makeStyles,
    Icon,
    Tooltip,
    IconButton,
    useTheme,
    ThemeProvider,
} from '@material-ui/core';
import type { DialogProps } from '@material-ui/core';
import DataTable from '../component/table';
import { APPOINTMENT_TREATMENT_TABLE_FIELDS } from './AppointmentTreatmentForm';
import { SuccessTheme } from '../config/Theme';

export interface ToothPickerProps extends DialogProps {
    show: boolean;
    toothCode: String;
    data?: Array<Object> | Object;
    onChange: (data: Array<Object>) => void;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom in ref={ref} {...props} />;
});

const styles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid ' + theme.palette.divider,
        borderBottom: '1px solid ' + theme.palette.divider,
        padding: 0,
    },
    header: {
        padding: theme.spacing(1, 2),
    },
    table: {
        width: '100%',
    },
    closeButton: {
        color: theme.palette.text.primary,
    },
}));

const ToothTreatmentModal = (props: ToothPickerProps) => {
    const classes = styles();
    const theme = useTheme();
    const { show, data, toothCode, onChange, onClose } = props;

    const handleIsFinished = (item, index) => {
        const finishedData = data.map((t) => {
            if (t.id === item.id) {
                return { ...t, operated: true };
            }
            return t;
        });
        if (onChange) {
            onChange(finishedData);
        }
    };

    const handleClose = (action) => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog maxWidth="md" onClose={() => handleClose(false)} open={show} TransitionComponent={Transition} fullWidth>
            <DialogTitle className={classes.header}>
                <Grid container>
                    <Grid container item lg={8} md={8} sm={8} xs={8} alignItems="center" justifyContent="flex-start">
                        <Typography variant="h6" component="h1" noWrap>
                            Treatments for {toothCode}
                        </Typography>
                    </Grid>
                    <Grid container item lg={4} md={4} sm={4} xs={4} alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Close Dialog">
                            <IconButton size="small" className={classes.closeButton} onClick={() => handleClose(false)} aria-label="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <DataTable
                    className={classes.table}
                    disablePaging={true}
                    items={data.filter((t) => t.toothCode === toothCode)}
                    fields={[
                        ...APPOINTMENT_TREATMENT_TABLE_FIELDS,
                        {
                            name: 'operation_completed',
                            align: 'center',
                            label: '@',
                            minWidth: 50,
                            type: 'raw',
                            onLoad: (item, index) =>
                                item.operated ? (
                                    <ThemeProvider theme={SuccessTheme}>
                                        <Icon color="primary" fontSize="medium" style={{ margin: theme.spacing(1) }}>
                                            verified
                                        </Icon>
                                    </ThemeProvider>
                                ) : (
                                    <IconButton size="medium" onClick={() => handleIsFinished(item, index)}>
                                        <Icon fontSize="medium">published_with_changes</Icon>
                                    </IconButton>
                                ),
                        },
                    ]}
                />
            </DialogContent>
        </Dialog>
    );
};

ToothTreatmentModal.defaultProps = {
    show: false,
    toothCode: 'tooth',
    data: [],
    onChange: (data) => console.log('Changed => ', data),
    onClose: (result) => {
        console.log('Selected Data => ', result);
    },
};

export default ToothTreatmentModal;
