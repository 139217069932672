import Login from '../page/auth/Login';
import Register from '../page/auth/Register';
import ForgetPassword from '../page/auth/ForgetPassword';
import ResetPassword from '../page/auth/ResetPassword';

import Profile from '../page/me/Profile';
import MultiFactorAuthDetail from '../page/me/MultiFactorAuthDetail';
import Security from '../page/me/Security';

import Dashboard from '../page/Dashboard';
import User from '../page/admin/User';
import UserDetail from '../page/admin/UserDetail';
import Role from '../page/admin/Role';
import RoleDetail from '../page/admin/RoleDetail';
import Menu from '../page/admin/Menu';
import ApiPermission from '../page/admin/ApiPermission';
import File from '../page/file/File';
import Developer from '../page/Developer';
import SettingManager from '../page/admin/SettingManager';
import PatientDetail from '../page/registration/PatientDetail';
import Patient from '../page/registration/Patient';
import Doctor from '../page/registration/Doctor';
import DoctorDetail from '../page/registration/DoctorDetail';
import TreatementType from '../page/setup/TreatmentType';
import TreatmentTypeDetail from '../page/setup/TreatmentTypeDetail';
import Branch from '../page/setup/Branch';
import BranchDetail from '../page/setup/BranchDetail';
import Appointment from '../page/appointment/Appointment';
import AppointmentDetail from '../page/appointment/AppointmentDetail';
import Voucher from '../page/voucher/Voucher';
import VoucherCreate from '../page/voucher/VoucherCreate';
import VoucherPayment from '../page/voucher/VoucherPayment';
import TreatmentPrice from '../page/setup/TreatmentPrice';
import TreatmentPriceDetail from '../page/setup/TreatmentPriceDetail';
import Operation from '../page/Operation';
import PatientCheckup from '../page/appointment/PatientCheckup';
import VoucherDetail from '../page/voucher/VoucherDetail';
import PatientType from '../page/setup/PatientType';
import PatientTypeDetail from '../page/setup/PatientTypeDetail';
import Report from '../page/reporting/Report';
import ReportDetail from '../page/reporting/ReportDetail';
import Post from '../page/cms/Post';
import PostDetail from '../page/cms/PostDetail';
import PostComment from '../page/cms/PostComment';
import BannerDetail from '../page/cms/BannerDetail';
import Banner from '../page/cms/Banner';
import PostReact from '../page/cms/PostReact';
import Discount from '../page/setup/Discount';
import DiscountDetail from '../page/setup/DiscountDetail';
import Coupon from '../page/setup/Coupon';
import Channel from '../page/messaging/Channel';
import ChannelDetail from '../page/messaging/ChannelDetail';
import Behavior from '../page/setup/Behavior';
import BehaviorDetail from '../page/setup/BehaviorDetail';
import PatientBehavior from '../page/appointment/PatientBehavior';
import EmailMessaging from '../page/messaging/EmailMessaging';
import SmsMessaging from '../page/messaging/SmsMessaging';
import PushNotiMessaging from '../page/messaging/PushNotiMessaging';
import ReportViewer from '../page/reporting/ReportViewer';

export const PrivateRoute = [
    { path: '/', page: Dashboard },
    { path: '/profile', page: Profile },
    { path: '/mfa/setup', page: MultiFactorAuthDetail },
    { path: '/security', page: Security },
    { path: '/user', page: User },
    { path: '/user/detail/:id', page: UserDetail },
    { path: '/role', page: Role },
    { path: '/role/detail/:id', page: RoleDetail },
    { path: '/menu', page: Menu },
    { path: '/permission/:roleId?', page: ApiPermission },
    { path: '/file', page: File },
    { path: '/report', page: Report },
    { path: '/report/detail/:id', page: ReportDetail },
    { path: '/report/view/:name', page: ReportViewer },
    { path: '/api-debug', page: Developer },
    { path: '/setting', page: SettingManager },
    { path: '/patientType', page: PatientType },
    { path: '/patientType/detail/:id', page: PatientTypeDetail },
    { path: '/patient', page: Patient },
    { path: '/patient/detail/:id', page: PatientDetail },
    { path: '/patient/checkup/:patientId', page: PatientCheckup },
    { path: '/patient/behaviors/:appointmentId', page: PatientBehavior },
    { path: '/doctor', page: Doctor },
    { path: '/doctor/detail/:id', page: DoctorDetail },
    { path: '/treatmentType', page: TreatementType },
    { path: '/treatmentType/detail/:id', page: TreatmentTypeDetail },
    { path: '/treatmentPrice', page: TreatmentPrice },
    { path: '/treatmentPrice/detail/:id', page: TreatmentPriceDetail },
    { path: '/branch', page: Branch },
    { path: '/branch/detail/:id', page: BranchDetail },
    { path: '/behavior', page: Behavior },
    { path: '/behavior/detail/:id', page: BehaviorDetail },
    { path: '/appointment', page: Appointment },
    { path: '/appointment/detail/:id', page: AppointmentDetail },
    { path: '/voucher', page: Voucher },
    { path: '/voucher/create', page: VoucherCreate },
    { path: '/voucher/detail/:id', page: VoucherDetail },
    { path: '/voucher/payment', page: VoucherPayment },
    { path: '/operation/:id', page: Operation },
    { path: '/cms/post/:postType', page: Post },
    { path: '/cms/post/:postType/:id', page: PostDetail },
    { path: '/cms/post/:postType/:id/comments', page: PostComment },
    { path: '/cms/post/:postType/:id/reacts', page: PostReact },
    { path: '/cms/banner', page: Banner },
    { path: '/cms/banner/detail/:id', page: BannerDetail },
    { path: '/marketing/discount', page: Discount },
    { path: '/marketing/coupon', page: Coupon },
    { path: '/marketing/:type/detail/:id', page: DiscountDetail },
    { path: '/messaging/channel', page: Channel },
    { path: '/messaging/channel/detail/:id', page: ChannelDetail },
    { path: '/messaging/email', page: EmailMessaging },
    { path: '/messaging/sms', page: SmsMessaging },
    { path: '/messaging/push_noti', page: PushNotiMessaging },
];

export const PublicRoute = [
    {
        path: '/login',
        page: Login,
    },
    {
        path: '/register',
        page: Register,
    },
    {
        path: '/auth/forgetPassword',
        page: ForgetPassword,
    },
    {
        path: '/auth/resetPassword',
        page: ResetPassword,
    },
];

export default {
    private: PrivateRoute,
    public: PublicRoute,
};
