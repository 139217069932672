import React, { useRef, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Typography, Container, Avatar, Icon, Grid, Button, Paper, makeStyles } from '@material-ui/core';

import DoctorApi from '../../api/DoctorApi';
import MasterForm from '../../component/MasterForm';
import { GENDERS } from '../../config/Constant';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { CheckboxInput, DateTimeInput, ImageInput, ListInput, TextInput, TinyEditorInput } from '../../component/control';
import ContactForm2 from '../../fragment/ContactForm2';
import { validateForm } from '../../util/ValidationManager';
import FileManager from '../../component/file/FileManager';
import BankInfoForm from '../../fragment/BankInfoForm';
import DoctorSpecializeForm from '../../fragment/DoctorSpecializeForm';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const DoctorDetail = (props) => {
    const classes = styles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isUpdate, setUpdate] = useState(false);
    const infoForm = useRef();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };
    const [form, setForm] = useState(() => {
        if (id > 0) {
            DoctorApi.getById(id)
                .then((data) => {
                    setForm({ ...data, image: data.profileImage });
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }
        return {};
    });
    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        const isValid = validateForm(
            form,
            [
                { fieldId: 'fullName', required: true },
                { fieldId: 'licenseNumber', required: true },
                { fieldId: 'shortBio', required: true },
                { fieldId: 'dateOfBirth', required: true },
                { fieldId: 'gender', required: true },
                { fieldId: 'address', required: true },
                { fieldId: 'phones', required: true },
                { fieldId: 'emails', required: true },
            ],
            handleError,
        );

        if (!isValid) {
            return;
        }

        try {
            let doctor = { ...form };
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            if (form.image && form.image.id) {
                doctor.profileImage = form.image;
            } else if (form.image) {
                const fileResponse = await DoctorApi.fileUpload(form.image, 'profileImage');
                if (fileResponse) {
                    doctor.profileImage = fileResponse;
                }
            } else {
                doctor.profileImage = null;
            }

            if (!('status' in doctor)) {
                doctor.status = 'INACTIVE';
            }

            let message = '';
            if (isUpdate) {
                const response = await DoctorApi.modifyById(id, doctor);
                message = `Dodctor ${response.name} has been updated successfully.`;
            } else {
                const response = await DoctorApi.addNew(doctor);
                message = `Doctor ${response.name} has been registered successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });
            history.push('/doctor');
        } catch (error) {
            handleError(error);
        }
    };

    const renderInfoForm = () => {
        return (
            <Grid container direction="column">
                <Grid direction="row" spacing={3} container>
                    <Grid lg={3} md={4} sm={6} xs={12} item>
                        <Grid container justifyContent="center">
                            <ImageInput
                                size={{ width: 180, height: 180 }}
                                id="profileImage"
                                enableFilePicker={false}
                                required={true}
                                guild="DOCTOR"
                                value={form?.image}
                                onChange={(event) => setForm({ ...form, image: event.target.value })}
                            />
                            <CheckboxInput
                                id="status"
                                label="Is active?"
                                value={form?.status}
                                onChange={(event) => {
                                    setForm({ ...form, status: event.target.checked ? 'ACTIVE' : 'INACTIVE' });
                                }}
                                checked={form.status?.toUpperCase() === 'ACTIVE' ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Grid lg={9} md={8} sm={6} xs={12} direction="column" container item>
                        <Grid spacing={2} container item direction="row">
                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                <TextInput
                                    id="fullName"
                                    icon="face"
                                    label="Full Name"
                                    value={form?.fullName}
                                    onChange={(event) => setForm({ ...form, fullName: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={12}>
                                <ListInput
                                    id="gender"
                                    label="Gender"
                                    data={GENDERS}
                                    value={form?.gender}
                                    onChange={(event) => setForm({ ...form, gender: event.target.value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} container item direction="row">
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextInput
                                    id="licenseNumber"
                                    icon="workspace_premium"
                                    label="License Number"
                                    value={form?.licenseNumber}
                                    onChange={(event) => setForm({ ...form, licenseNumber: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextInput
                                    id="position"
                                    icon="badge"
                                    label="Position"
                                    value={form?.position}
                                    onChange={(event) => setForm({ ...form, position: event.target.value })}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} container item>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <DateTimeInput
                                    type="date"
                                    id="dob"
                                    icon="event"
                                    label="Date of birth"
                                    value={form?.dateOfBirth}
                                    onChange={(event) => setForm({ ...form, dateOfBirth: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <DateTimeInput
                                    type="date"
                                    id="startWorkingDate"
                                    icon="event_available"
                                    label="Start working date"
                                    value={form?.startWorkingDate}
                                    onChange={(event) => setForm({ ...form, startWorkingDate: event.target.value })}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextInput
                        id="shortBio"
                        label="Short Biography"
                        rows={3}
                        multiline={true}
                        value={form?.shortBio}
                        onChange={(event) => setForm({ ...form, shortBio: event.target.value })}
                    />
                </Grid>
                <Grid item>
                    <TextInput
                        id="note"
                        label="Note"
                        rows={4}
                        multiline={true}
                        value={form?.note}
                        onChange={(event) => setForm({ ...form, note: event.target.value })}
                    />
                </Grid>
            </Grid>
        );
    };

    const renderFullBiography = () => {
        return (
            <TinyEditorInput
                id="fullBio"
                name="fullBio"
                value={form?.fullBio}
                type="DEFAULT"
                onChange={(event) => {
                    setForm({
                        ...form,
                        fullBio: event.target.value,
                    });
                }}
            />
        );
    };

    const renderSpecialize = () => {
        return (
            <DoctorSpecializeForm
                onChange={(data) => setForm({ ...form, specializeInfos: data })}
                data={form.specializeInfos}
                onError={handleError}
            />
        );
    };

    const renderContacts = () => {
        return (
            <ContactForm2
                onChangeAddress={(data) => setForm({ ...form, address: data })}
                address={form.address}
                onChangePhones={(data) => setForm({ ...form, phones: data })}
                phones={form.phones}
                onChangeEmails={(data) => setForm({ ...form, emails: data })}
                emails={form.emails}
                onError={handleError}
            />
        );
    };

    const renderBankInfos = () => {
        return <BankInfoForm onChange={(data) => setForm({ ...form, bankInfos: data })} data={form.bankInfos} />;
    };

    const tabFields = [
        {
            label: 'Info',
            icon: 'info',
            content: renderInfoForm(),
        },
        {
            label: 'Specialize',
            icon: 'stars',
            content: renderSpecialize(),
        },
        {
            label: 'Full Bio',
            icon: 'contact_page',
            content: renderFullBiography(),
        },
        {
            label: 'Contacts',
            icon: 'contacts',
            content: renderContacts(),
        },
        {
            label: 'Bank Infos',
            icon: 'account_balance',
            content: renderBankInfos(),
        },
    ];

    if (isUpdate) {
        tabFields.push({
            label: 'Documents',
            icon: 'folder',
            content: <FileManager guild={`DOCTOR_${id}_DOCS`} accept={'*'} />,
        });
    }

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">medication</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Doctor Registration
                    </Typography>
                    <MasterForm className={classes.form} type="tab" fields={tabFields}>
                        <Grid justifyContent="flex-end" container>
                            <GobackButton />
                            <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.submit}>
                                <Icon color="action">save</Icon> Save
                            </Button>
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(DoctorDetail);
