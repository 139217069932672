export const MATERIAL_ICONS = {
    categories: [
        {
            name: 'action',
            key: 'action',
            icons: [
                {
                    id: 'ic_3d_rotation',
                    name: '3d rotation',
                    group_id: 'action',
                    keywords: ['action, 3d, rotation'],
                    ligature: '3d_rotation',
                    codepoint: 'E84D',
                },
                {
                    id: 'ic_accessibility',
                    name: 'accessibility',
                    group_id: 'action',
                    keywords: ['action, accessibility'],
                    ligature: 'accessibility',
                    codepoint: 'E84E',
                },
                {
                    id: 'ic_accessible',
                    name: 'accessible',
                    group_id: 'action',
                    keywords: ['action, accessible'],
                    ligature: 'accessible',
                    codepoint: 'E914',
                    is_new: true,
                },
                {
                    id: 'ic_account_balance',
                    name: 'account balance',
                    group_id: 'action',
                    keywords: ['action, account, balance'],
                    ligature: 'account_balance',
                    codepoint: 'E84F',
                },
                {
                    id: 'ic_account_balance_wallet',
                    name: 'account balance wallet',
                    group_id: 'action',
                    keywords: ['action, account, balance, wallet'],
                    ligature: 'account_balance_wallet',
                    codepoint: 'E850',
                },
                {
                    id: 'ic_account_box',
                    name: 'account box',
                    group_id: 'action',
                    keywords: ['action, account, box'],
                    ligature: 'account_box',
                    codepoint: 'E851',
                },
                {
                    id: 'ic_account_circle',
                    name: 'account circle',
                    group_id: 'action',
                    keywords: ['action, account, circle'],
                    ligature: 'account_circle',
                    codepoint: 'E853',
                },
                {
                    id: 'ic_add_shopping_cart',
                    name: 'add shopping cart',
                    group_id: 'action',
                    keywords: ['action, add, shopping, cart'],
                    ligature: 'add_shopping_cart',
                    codepoint: 'E854',
                },
                {
                    id: 'ic_alarm',
                    name: 'alarm',
                    group_id: 'action',
                    keywords: ['action, alarm'],
                    ligature: 'alarm',
                    codepoint: 'E855',
                },
                {
                    id: 'ic_alarm_add',
                    name: 'alarm add',
                    group_id: 'action',
                    keywords: ['action, alarm, add'],
                    ligature: 'alarm_add',
                    codepoint: 'E856',
                },
                {
                    id: 'ic_alarm_off',
                    name: 'alarm off',
                    group_id: 'action',
                    keywords: ['action, alarm, off'],
                    ligature: 'alarm_off',
                    codepoint: 'E857',
                },
                {
                    id: 'ic_alarm_on',
                    name: 'alarm on',
                    group_id: 'action',
                    keywords: ['action, alarm, on'],
                    ligature: 'alarm_on',
                    codepoint: 'E858',
                },
                {
                    id: 'ic_all_out',
                    name: 'all out',
                    group_id: 'action',
                    keywords: ['action, all, out'],
                    ligature: 'all_out',
                    codepoint: 'E90B',
                    is_new: true,
                },
                {
                    id: 'ic_android',
                    name: 'android',
                    group_id: 'action',
                    keywords: ['action, android'],
                    ligature: 'android',
                    codepoint: 'E859',
                },
                {
                    id: 'ic_announcement',
                    name: 'announcement',
                    group_id: 'action',
                    keywords: ['action, announcement'],
                    ligature: 'announcement',
                    codepoint: 'E85A',
                },
                {
                    id: 'ic_aspect_ratio',
                    name: 'aspect ratio',
                    group_id: 'action',
                    keywords: ['action, aspect, ratio'],
                    ligature: 'aspect_ratio',
                    codepoint: 'E85B',
                },
                {
                    id: 'ic_assessment',
                    name: 'assessment',
                    group_id: 'action',
                    keywords: ['action, assessment'],
                    ligature: 'assessment',
                    codepoint: 'E85C',
                },
                {
                    id: 'ic_assignment',
                    name: 'assignment',
                    group_id: 'action',
                    keywords: ['action, assignment'],
                    ligature: 'assignment',
                    codepoint: 'E85D',
                },
                {
                    id: 'ic_assignment_ind',
                    name: 'assignment ind',
                    group_id: 'action',
                    keywords: ['action, assignment, ind'],
                    ligature: 'assignment_ind',
                    codepoint: 'E85E',
                },
                {
                    id: 'ic_assignment_late',
                    name: 'assignment late',
                    group_id: 'action',
                    keywords: ['action, assignment, late'],
                    ligature: 'assignment_late',
                    codepoint: 'E85F',
                },
                {
                    id: 'ic_assignment_return',
                    name: 'assignment return',
                    group_id: 'action',
                    keywords: ['action, assignment, return'],
                    ligature: 'assignment_return',
                    codepoint: 'E860',
                },
                {
                    id: 'ic_assignment_returned',
                    name: 'assignment returned',
                    group_id: 'action',
                    keywords: ['action, assignment, returned'],
                    ligature: 'assignment_returned',
                    codepoint: 'E861',
                },
                {
                    id: 'ic_assignment_turned_in',
                    name: 'assignment turned in',
                    group_id: 'action',
                    keywords: ['action, assignment, turned, in'],
                    ligature: 'assignment_turned_in',
                    codepoint: 'E862',
                },
                {
                    id: 'ic_autorenew',
                    name: 'autorenew',
                    group_id: 'action',
                    keywords: ['action, autorenew'],
                    ligature: 'autorenew',
                    codepoint: 'E863',
                },
                {
                    id: 'ic_backup',
                    name: 'backup',
                    group_id: 'action',
                    keywords: ['action, backup'],
                    ligature: 'backup',
                    codepoint: 'E864',
                },
                {
                    id: 'ic_book',
                    name: 'book',
                    group_id: 'action',
                    keywords: ['action, book'],
                    ligature: 'book',
                    codepoint: 'E865',
                },
                {
                    id: 'ic_bookmark',
                    name: 'bookmark',
                    group_id: 'action',
                    keywords: ['action, bookmark'],
                    ligature: 'bookmark',
                    codepoint: 'E866',
                },
                {
                    id: 'ic_bookmark_border',
                    name: 'bookmark border',
                    group_id: 'action',
                    keywords: ['action, bookmark, border'],
                    ligature: 'bookmark_border',
                    codepoint: 'E867',
                },
                {
                    id: 'ic_bug_report',
                    name: 'bug report',
                    group_id: 'action',
                    keywords: ['action, bug, report'],
                    ligature: 'bug_report',
                    codepoint: 'E868',
                },
                {
                    id: 'ic_build',
                    name: 'build',
                    group_id: 'action',
                    keywords: ['action, build'],
                    ligature: 'build',
                    codepoint: 'E869',
                },
                {
                    id: 'ic_cached',
                    name: 'cached',
                    group_id: 'action',
                    keywords: ['action, cached'],
                    ligature: 'cached',
                    codepoint: 'E86A',
                },
                {
                    id: 'ic_camera_enhance',
                    name: 'camera enhance',
                    group_id: 'action',
                    keywords: ['action, camera, enhance'],
                    ligature: 'camera_enhance',
                    codepoint: 'E8FC',
                },
                {
                    id: 'ic_card_giftcard',
                    name: 'card giftcard',
                    group_id: 'action',
                    keywords: ['action, card, giftcard'],
                    ligature: 'card_giftcard',
                    codepoint: 'E8F6',
                },
                {
                    id: 'ic_card_membership',
                    name: 'card membership',
                    group_id: 'action',
                    keywords: ['action, card, membership'],
                    ligature: 'card_membership',
                    codepoint: 'E8F7',
                },
                {
                    id: 'ic_card_travel',
                    name: 'card travel',
                    group_id: 'action',
                    keywords: ['action, card, travel'],
                    ligature: 'card_travel',
                    codepoint: 'E8F8',
                },
                {
                    id: 'ic_change_history',
                    name: 'change history',
                    group_id: 'action',
                    keywords: ['action, change, history'],
                    ligature: 'change_history',
                    codepoint: 'E86B',
                },
                {
                    id: 'ic_check_circle',
                    name: 'check circle',
                    group_id: 'action',
                    keywords: ['action, check, circle'],
                    ligature: 'check_circle',
                    codepoint: 'E86C',
                },
                {
                    id: 'ic_chrome_reader_mode',
                    name: 'chrome reader mode',
                    group_id: 'action',
                    keywords: ['action, chrome, reader, mode'],
                    ligature: 'chrome_reader_mode',
                    codepoint: 'E86D',
                },
                {
                    id: 'ic_class',
                    name: 'class',
                    group_id: 'action',
                    keywords: ['action, class'],
                    ligature: 'class',
                    codepoint: 'E86E',
                },
                {
                    id: 'ic_code',
                    name: 'code',
                    group_id: 'action',
                    keywords: ['action, code'],
                    ligature: 'code',
                    codepoint: 'E86F',
                },
                {
                    id: 'ic_compare_arrows',
                    name: 'compare arrows',
                    group_id: 'action',
                    keywords: ['action, compare, arrows'],
                    ligature: 'compare_arrows',
                    codepoint: 'E915',
                    is_new: true,
                },
                {
                    id: 'ic_copyright',
                    name: 'copyright',
                    group_id: 'action',
                    keywords: ['action, copyright'],
                    ligature: 'copyright',
                    codepoint: 'E90C',
                    is_new: true,
                },
                {
                    id: 'ic_credit_card',
                    name: 'credit card',
                    group_id: 'action',
                    keywords: ['action, credit, card'],
                    ligature: 'credit_card',
                    codepoint: 'E870',
                },
                {
                    id: 'ic_dashboard',
                    name: 'dashboard',
                    group_id: 'action',
                    keywords: ['action, dashboard'],
                    ligature: 'dashboard',
                    codepoint: 'E871',
                },
                {
                    id: 'ic_date_range',
                    name: 'date range',
                    group_id: 'action',
                    keywords: ['action, date, range'],
                    ligature: 'date_range',
                    codepoint: 'E916',
                    is_new: true,
                },
                {
                    id: 'ic_delete',
                    name: 'delete',
                    group_id: 'action',
                    keywords: ['action, delete'],
                    ligature: 'delete',
                    codepoint: 'E872',
                },
                {
                    id: 'ic_description',
                    name: 'description',
                    group_id: 'action',
                    keywords: ['action, description'],
                    ligature: 'description',
                    codepoint: 'E873',
                },
                {
                    id: 'ic_dns',
                    name: 'dns',
                    group_id: 'action',
                    keywords: ['action, dns'],
                    ligature: 'dns',
                    codepoint: 'E875',
                },
                {
                    id: 'ic_done',
                    name: 'done',
                    group_id: 'action',
                    keywords: ['action, done'],
                    ligature: 'done',
                    codepoint: 'E876',
                },
                {
                    id: 'ic_done_all',
                    name: 'done all',
                    group_id: 'action',
                    keywords: ['action, done, all'],
                    ligature: 'done_all',
                    codepoint: 'E877',
                },
                {
                    id: 'ic_donut_large',
                    name: 'donut large',
                    group_id: 'action',
                    keywords: ['action, donut, large'],
                    ligature: 'donut_large',
                    codepoint: 'E917',
                    is_new: true,
                },
                {
                    id: 'ic_donut_small',
                    name: 'donut small',
                    group_id: 'action',
                    keywords: ['action, donut, small'],
                    ligature: 'donut_small',
                    codepoint: 'E918',
                    is_new: true,
                },
                {
                    id: 'ic_eject',
                    name: 'eject',
                    group_id: 'action',
                    keywords: ['action, eject'],
                    ligature: 'eject',
                    codepoint: 'E8FB',
                },
                {
                    id: 'ic_event',
                    name: 'event',
                    group_id: 'action',
                    keywords: ['action, event'],
                    ligature: 'event',
                    codepoint: 'E878',
                },
                {
                    id: 'ic_event_seat',
                    name: 'event seat',
                    group_id: 'action',
                    keywords: ['action, event, seat'],
                    ligature: 'event_seat',
                    codepoint: 'E903',
                },
                {
                    id: 'ic_exit_to_app',
                    name: 'exit to app',
                    group_id: 'action',
                    keywords: ['action, exit, to, app'],
                    ligature: 'exit_to_app',
                    codepoint: 'E879',
                },
                {
                    id: 'ic_explore',
                    name: 'explore',
                    group_id: 'action',
                    keywords: ['action, explore'],
                    ligature: 'explore',
                    codepoint: 'E87A',
                },
                {
                    id: 'ic_extension',
                    name: 'extension',
                    group_id: 'action',
                    keywords: ['action, extension'],
                    ligature: 'extension',
                    codepoint: 'E87B',
                },
                {
                    id: 'ic_face',
                    name: 'face',
                    group_id: 'action',
                    keywords: ['action, face'],
                    ligature: 'face',
                    codepoint: 'E87C',
                },
                {
                    id: 'ic_favorite',
                    name: 'favorite',
                    group_id: 'action',
                    keywords: ['action, favorite'],
                    ligature: 'favorite',
                    codepoint: 'E87D',
                },
                {
                    id: 'ic_favorite_border',
                    name: 'favorite border',
                    group_id: 'action',
                    keywords: ['action, favorite, border'],
                    ligature: 'favorite_border',
                    codepoint: 'E87E',
                },
                {
                    id: 'ic_feedback',
                    name: 'feedback',
                    group_id: 'action',
                    keywords: ['action, feedback'],
                    ligature: 'feedback',
                    codepoint: 'E87F',
                },
                {
                    id: 'ic_find_in_page',
                    name: 'find in page',
                    group_id: 'action',
                    keywords: ['action, find, in, page'],
                    ligature: 'find_in_page',
                    codepoint: 'E880',
                },
                {
                    id: 'ic_find_replace',
                    name: 'find replace',
                    group_id: 'action',
                    keywords: ['action, find, replace'],
                    ligature: 'find_replace',
                    codepoint: 'E881',
                },
                {
                    id: 'ic_fingerprint',
                    name: 'fingerprint',
                    group_id: 'action',
                    keywords: ['action, fingerprint'],
                    ligature: 'fingerprint',
                    codepoint: 'E90D',
                    is_new: true,
                },
                {
                    id: 'ic_flight_land',
                    name: 'flight land',
                    group_id: 'action',
                    keywords: ['action, flight, land'],
                    ligature: 'flight_land',
                    codepoint: 'E904',
                },
                {
                    id: 'ic_flight_takeoff',
                    name: 'flight takeoff',
                    group_id: 'action',
                    keywords: ['action, flight, takeoff'],
                    ligature: 'flight_takeoff',
                    codepoint: 'E905',
                },
                {
                    id: 'ic_flip_to_back',
                    name: 'flip to back',
                    group_id: 'action',
                    keywords: ['action, flip, to, back'],
                    ligature: 'flip_to_back',
                    codepoint: 'E882',
                },
                {
                    id: 'ic_flip_to_front',
                    name: 'flip to front',
                    group_id: 'action',
                    keywords: ['action, flip, to, front'],
                    ligature: 'flip_to_front',
                    codepoint: 'E883',
                },
                {
                    id: 'ic_gavel',
                    name: 'gavel',
                    group_id: 'action',
                    keywords: ['action, gavel'],
                    ligature: 'gavel',
                    codepoint: 'E90E',
                    is_new: true,
                },
                {
                    id: 'ic_get_app',
                    name: 'get app',
                    group_id: 'action',
                    keywords: ['action, get, app'],
                    ligature: 'get_app',
                    codepoint: 'E884',
                },
                {
                    id: 'ic_gif',
                    name: 'gif',
                    group_id: 'action',
                    keywords: ['action, gif'],
                    ligature: 'gif',
                    codepoint: 'E908',
                },
                {
                    id: 'ic_grade',
                    name: 'grade',
                    group_id: 'action',
                    keywords: ['action, grade'],
                    ligature: 'grade',
                    codepoint: 'E885',
                },
                {
                    id: 'ic_group_work',
                    name: 'group work',
                    group_id: 'action',
                    keywords: ['action, group, work'],
                    ligature: 'group_work',
                    codepoint: 'E886',
                },
                {
                    id: 'ic_help',
                    name: 'help',
                    group_id: 'action',
                    keywords: ['action, help'],
                    ligature: 'help',
                    codepoint: 'E887',
                },
                {
                    id: 'ic_help_outline',
                    name: 'help outline',
                    group_id: 'action',
                    keywords: ['action, help, outline'],
                    ligature: 'help_outline',
                    codepoint: 'E8FD',
                },
                {
                    id: 'ic_highlight_off',
                    name: 'highlight off',
                    group_id: 'action',
                    keywords: ['action, highlight, off'],
                    ligature: 'highlight_off',
                    codepoint: 'E888',
                },
                {
                    id: 'ic_history',
                    name: 'history',
                    group_id: 'action',
                    keywords: ['action, history'],
                    ligature: 'history',
                    codepoint: 'E889',
                },
                {
                    id: 'ic_home',
                    name: 'home',
                    group_id: 'action',
                    keywords: ['action, home'],
                    ligature: 'home',
                    codepoint: 'E88A',
                },
                {
                    id: 'ic_hourglass_empty',
                    name: 'hourglass empty',
                    group_id: 'action',
                    keywords: ['action, hourglass, empty'],
                    ligature: 'hourglass_empty',
                    codepoint: 'E88B',
                },
                {
                    id: 'ic_hourglass_full',
                    name: 'hourglass full',
                    group_id: 'action',
                    keywords: ['action, hourglass, full'],
                    ligature: 'hourglass_full',
                    codepoint: 'E88C',
                },
                {
                    id: 'ic_http',
                    name: 'http',
                    group_id: 'action',
                    keywords: ['action, http'],
                    ligature: 'http',
                    codepoint: 'E902',
                },
                {
                    id: 'ic_https',
                    name: 'https',
                    group_id: 'action',
                    keywords: ['action, https'],
                    ligature: 'https',
                    codepoint: 'E88D',
                },
                {
                    id: 'ic_important_devices',
                    name: 'important devices',
                    group_id: 'action',
                    keywords: ['action, important, devices'],
                    ligature: 'important_devices',
                    codepoint: 'E912',
                    is_new: true,
                },
                {
                    id: 'ic_info',
                    name: 'info',
                    group_id: 'action',
                    keywords: ['action, info'],
                    ligature: 'info',
                    codepoint: 'E88E',
                },
                {
                    id: 'ic_info_outline',
                    name: 'info outline',
                    group_id: 'action',
                    keywords: ['action, info, outline'],
                    ligature: 'info_outline',
                    codepoint: 'E88F',
                },
                {
                    id: 'ic_input',
                    name: 'input',
                    group_id: 'action',
                    keywords: ['action, input'],
                    ligature: 'input',
                    codepoint: 'E890',
                },
                {
                    id: 'ic_invert_colors',
                    name: 'invert colors',
                    group_id: 'action',
                    keywords: ['action, invert, colors'],
                    ligature: 'invert_colors',
                    codepoint: 'E891',
                },
                {
                    id: 'ic_label',
                    name: 'label',
                    group_id: 'action',
                    keywords: ['action, label'],
                    ligature: 'label',
                    codepoint: 'E892',
                },
                {
                    id: 'ic_label_outline',
                    name: 'label outline',
                    group_id: 'action',
                    keywords: ['action, label, outline'],
                    ligature: 'label_outline',
                    codepoint: 'E893',
                },
                {
                    id: 'ic_language',
                    name: 'language',
                    group_id: 'action',
                    keywords: ['action, language'],
                    ligature: 'language',
                    codepoint: 'E894',
                },
                {
                    id: 'ic_launch',
                    name: 'launch',
                    group_id: 'action',
                    keywords: ['action, launch'],
                    ligature: 'launch',
                    codepoint: 'E895',
                },
                {
                    id: 'ic_lightbulb_outline',
                    name: 'lightbulb outline',
                    group_id: 'action',
                    keywords: ['action, lightbulb, outline'],
                    ligature: 'lightbulb_outline',
                    codepoint: 'E90F',
                    is_new: true,
                },
                {
                    id: 'ic_line_style',
                    name: 'line style',
                    group_id: 'action',
                    keywords: ['action, line, style'],
                    ligature: 'line_style',
                    codepoint: 'E919',
                    is_new: true,
                },
                {
                    id: 'ic_line_weight',
                    name: 'line weight',
                    group_id: 'action',
                    keywords: ['action, line, weight'],
                    ligature: 'line_weight',
                    codepoint: 'E91A',
                    is_new: true,
                },
                {
                    id: 'ic_list',
                    name: 'list',
                    group_id: 'action',
                    keywords: ['action, list'],
                    ligature: 'list',
                    codepoint: 'E896',
                },
                {
                    id: 'ic_lock',
                    name: 'lock',
                    group_id: 'action',
                    keywords: ['action, lock'],
                    ligature: 'lock',
                    codepoint: 'E897',
                },
                {
                    id: 'ic_lock_open',
                    name: 'lock open',
                    group_id: 'action',
                    keywords: ['action, lock, open'],
                    ligature: 'lock_open',
                    codepoint: 'E898',
                },
                {
                    id: 'ic_lock_outline',
                    name: 'lock outline',
                    group_id: 'action',
                    keywords: ['action, lock, outline'],
                    ligature: 'lock_outline',
                    codepoint: 'E899',
                },
                {
                    id: 'ic_loyalty',
                    name: 'loyalty',
                    group_id: 'action',
                    keywords: ['action, loyalty'],
                    ligature: 'loyalty',
                    codepoint: 'E89A',
                },
                {
                    id: 'ic_markunread_mailbox',
                    name: 'markunread mailbox',
                    group_id: 'action',
                    keywords: ['action, markunread, mailbox'],
                    ligature: 'markunread_mailbox',
                    codepoint: 'E89B',
                },
                {
                    id: 'ic_motorcycle',
                    name: 'motorcycle',
                    group_id: 'action',
                    keywords: ['action, motorcycle'],
                    ligature: 'motorcycle',
                    codepoint: 'E91B',
                    is_new: true,
                },
                {
                    id: 'ic_note_add',
                    name: 'note add',
                    group_id: 'action',
                    keywords: ['action, note, add'],
                    ligature: 'note_add',
                    codepoint: 'E89C',
                },
                {
                    id: 'ic_offline_pin',
                    name: 'offline pin',
                    group_id: 'action',
                    keywords: ['action, offline, pin'],
                    ligature: 'offline_pin',
                    codepoint: 'E90A',
                },
                {
                    id: 'ic_opacity',
                    name: 'opacity',
                    group_id: 'action',
                    keywords: ['action, opacity'],
                    ligature: 'opacity',
                    codepoint: 'E91C',
                    is_new: true,
                },
                {
                    id: 'ic_open_in_browser',
                    name: 'open in browser',
                    group_id: 'action',
                    keywords: ['action, open, in, browser'],
                    ligature: 'open_in_browser',
                    codepoint: 'E89D',
                },
                {
                    id: 'ic_open_in_new',
                    name: 'open in new',
                    group_id: 'action',
                    keywords: ['action, open, in, new'],
                    ligature: 'open_in_new',
                    codepoint: 'E89E',
                },
                {
                    id: 'ic_open_with',
                    name: 'open with',
                    group_id: 'action',
                    keywords: ['action, open, with'],
                    ligature: 'open_with',
                    codepoint: 'E89F',
                },
                {
                    id: 'ic_pageview',
                    name: 'pageview',
                    group_id: 'action',
                    keywords: ['action, pageview'],
                    ligature: 'pageview',
                    codepoint: 'E8A0',
                },
                {
                    id: 'ic_pan_tool',
                    name: 'pan tool',
                    group_id: 'action',
                    keywords: ['action, pan, tool'],
                    ligature: 'pan_tool',
                    codepoint: 'E925',
                    is_new: true,
                },
                {
                    id: 'ic_payment',
                    name: 'payment',
                    group_id: 'action',
                    keywords: ['action, payment'],
                    ligature: 'payment',
                    codepoint: 'E8A1',
                },
                {
                    id: 'ic_perm_camera_mic',
                    name: 'perm camera mic',
                    group_id: 'action',
                    keywords: ['action, perm, camera, mic'],
                    ligature: 'perm_camera_mic',
                    codepoint: 'E8A2',
                },
                {
                    id: 'ic_perm_contact_calendar',
                    name: 'perm contact calendar',
                    group_id: 'action',
                    keywords: ['action, perm, contact, calendar'],
                    ligature: 'perm_contact_calendar',
                    codepoint: 'E8A3',
                },
                {
                    id: 'ic_perm_data_setting',
                    name: 'perm data setting',
                    group_id: 'action',
                    keywords: ['action, perm, data, setting'],
                    ligature: 'perm_data_setting',
                    codepoint: 'E8A4',
                },
                {
                    id: 'ic_perm_device_information',
                    name: 'perm device information',
                    group_id: 'action',
                    keywords: ['action, perm, device, information'],
                    ligature: 'perm_device_information',
                    codepoint: 'E8A5',
                },
                {
                    id: 'ic_perm_identity',
                    name: 'perm identity',
                    group_id: 'action',
                    keywords: ['action, perm, identity'],
                    ligature: 'perm_identity',
                    codepoint: 'E8A6',
                },
                {
                    id: 'ic_perm_media',
                    name: 'perm media',
                    group_id: 'action',
                    keywords: ['action, perm, media'],
                    ligature: 'perm_media',
                    codepoint: 'E8A7',
                },
                {
                    id: 'ic_perm_phone_msg',
                    name: 'perm phone msg',
                    group_id: 'action',
                    keywords: ['action, perm, phone, msg'],
                    ligature: 'perm_phone_msg',
                    codepoint: 'E8A8',
                },
                {
                    id: 'ic_perm_scan_wifi',
                    name: 'perm scan wifi',
                    group_id: 'action',
                    keywords: ['action, perm, scan, wifi'],
                    ligature: 'perm_scan_wifi',
                    codepoint: 'E8A9',
                },
                {
                    id: 'ic_pets',
                    name: 'pets',
                    group_id: 'action',
                    keywords: ['action, pets'],
                    ligature: 'pets',
                    codepoint: 'E91D',
                    is_new: true,
                },
                {
                    id: 'ic_picture_in_picture',
                    name: 'picture in picture',
                    group_id: 'action',
                    keywords: ['action, picture, in, picture'],
                    ligature: 'picture_in_picture',
                    codepoint: 'E8AA',
                },
                {
                    id: 'ic_picture_in_picture_alt',
                    name: 'picture in picture alt',
                    group_id: 'action',
                    keywords: ['action, picture, in, picture, alt'],
                    ligature: 'picture_in_picture_alt',
                    codepoint: 'E911',
                    is_new: true,
                },
                {
                    id: 'ic_play_for_work',
                    name: 'play for work',
                    group_id: 'action',
                    keywords: ['action, play, work'],
                    ligature: 'play_for_work',
                    codepoint: 'E906',
                },
                {
                    id: 'ic_polymer',
                    name: 'polymer',
                    group_id: 'action',
                    keywords: ['action, polymer'],
                    ligature: 'polymer',
                    codepoint: 'E8AB',
                },
                {
                    id: 'ic_power_settings_new',
                    name: 'power settings new',
                    group_id: 'action',
                    keywords: ['action, power, settings, new'],
                    ligature: 'power_settings_new',
                    codepoint: 'E8AC',
                },
                {
                    id: 'ic_pregnant_woman',
                    name: 'pregnant woman',
                    group_id: 'action',
                    keywords: ['action, pregnant, woman'],
                    ligature: 'pregnant_woman',
                    codepoint: 'E91E',
                    is_new: true,
                },
                {
                    id: 'ic_print',
                    name: 'print',
                    group_id: 'action',
                    keywords: ['action, print'],
                    ligature: 'print',
                    codepoint: 'E8AD',
                },
                {
                    id: 'ic_query_builder',
                    name: 'query builder',
                    group_id: 'action',
                    keywords: ['action, query, builder'],
                    ligature: 'query_builder',
                    codepoint: 'E8AE',
                },
                {
                    id: 'ic_question_answer',
                    name: 'question answer',
                    group_id: 'action',
                    keywords: ['action, question, answer'],
                    ligature: 'question_answer',
                    codepoint: 'E8AF',
                },
                {
                    id: 'ic_receipt',
                    name: 'receipt',
                    group_id: 'action',
                    keywords: ['action, receipt'],
                    ligature: 'receipt',
                    codepoint: 'E8B0',
                },
                {
                    id: 'ic_record_voice_over',
                    name: 'record voice over',
                    group_id: 'action',
                    keywords: ['action, record, voice, over'],
                    ligature: 'record_voice_over',
                    codepoint: 'E91F',
                    is_new: true,
                },
                {
                    id: 'ic_redeem',
                    name: 'redeem',
                    group_id: 'action',
                    keywords: ['action, redeem'],
                    ligature: 'redeem',
                    codepoint: 'E8B1',
                },
                {
                    id: 'ic_reorder',
                    name: 'reorder',
                    group_id: 'action',
                    keywords: ['action, reorder'],
                    ligature: 'reorder',
                    codepoint: 'E8FE',
                },
                {
                    id: 'ic_report_problem',
                    name: 'report problem',
                    group_id: 'action',
                    keywords: ['action, report, problem'],
                    ligature: 'report_problem',
                    codepoint: 'E8B2',
                },
                {
                    id: 'ic_restore',
                    name: 'restore',
                    group_id: 'action',
                    keywords: ['action, restore'],
                    ligature: 'restore',
                    codepoint: 'E8B3',
                },
                {
                    id: 'ic_room',
                    name: 'room',
                    group_id: 'action',
                    keywords: ['action, room'],
                    ligature: 'room',
                    codepoint: 'E8B4',
                },
                {
                    id: 'ic_rounded_corner',
                    name: 'rounded corner',
                    group_id: 'action',
                    keywords: ['action, rounded, corner'],
                    ligature: 'rounded_corner',
                    codepoint: 'E920',
                    is_new: true,
                },
                {
                    id: 'ic_rowing',
                    name: 'rowing',
                    group_id: 'action',
                    keywords: ['action, rowing'],
                    ligature: 'rowing',
                    codepoint: 'E921',
                    is_new: true,
                },
                {
                    id: 'ic_schedule',
                    name: 'schedule',
                    group_id: 'action',
                    keywords: ['action, schedule'],
                    ligature: 'schedule',
                    codepoint: 'E8B5',
                },
                {
                    id: 'ic_search',
                    name: 'search',
                    group_id: 'action',
                    keywords: ['action, search'],
                    ligature: 'search',
                    codepoint: 'E8B6',
                },
                {
                    id: 'ic_settings',
                    name: 'settings',
                    group_id: 'action',
                    keywords: ['action, settings'],
                    ligature: 'settings',
                    codepoint: 'E8B8',
                },
                {
                    id: 'ic_settings_applications',
                    name: 'settings applications',
                    group_id: 'action',
                    keywords: ['action, settings, applications'],
                    ligature: 'settings_applications',
                    codepoint: 'E8B9',
                },
                {
                    id: 'ic_settings_backup_restore',
                    name: 'settings backup restore',
                    group_id: 'action',
                    keywords: ['action, settings, backup, restore'],
                    ligature: 'settings_backup_restore',
                    codepoint: 'E8BA',
                },
                {
                    id: 'ic_settings_bluetooth',
                    name: 'settings bluetooth',
                    group_id: 'action',
                    keywords: ['action, settings, bluetooth'],
                    ligature: 'settings_bluetooth',
                    codepoint: 'E8BB',
                },
                {
                    id: 'ic_settings_brightness',
                    name: 'settings brightness',
                    group_id: 'action',
                    keywords: ['action, settings, brightness'],
                    ligature: 'settings_brightness',
                    codepoint: 'E8BD',
                },
                {
                    id: 'ic_settings_cell',
                    name: 'settings cell',
                    group_id: 'action',
                    keywords: ['action, settings, cell'],
                    ligature: 'settings_cell',
                    codepoint: 'E8BC',
                },
                {
                    id: 'ic_settings_ethernet',
                    name: 'settings ethernet',
                    group_id: 'action',
                    keywords: ['action, settings, ethernet'],
                    ligature: 'settings_ethernet',
                    codepoint: 'E8BE',
                },
                {
                    id: 'ic_settings_input_antenna',
                    name: 'settings input antenna',
                    group_id: 'action',
                    keywords: ['action, settings, input, antenna'],
                    ligature: 'settings_input_antenna',
                    codepoint: 'E8BF',
                },
                {
                    id: 'ic_settings_input_component',
                    name: 'settings input component',
                    group_id: 'action',
                    keywords: ['action, settings, input, component'],
                    ligature: 'settings_input_component',
                    codepoint: 'E8C0',
                },
                {
                    id: 'ic_settings_input_composite',
                    name: 'settings input composite',
                    group_id: 'action',
                    keywords: ['action, settings, input, composite'],
                    ligature: 'settings_input_composite',
                    codepoint: 'E8C1',
                },
                {
                    id: 'ic_settings_input_hdmi',
                    name: 'settings input hdmi',
                    group_id: 'action',
                    keywords: ['action, settings, input, hdmi'],
                    ligature: 'settings_input_hdmi',
                    codepoint: 'E8C2',
                },
                {
                    id: 'ic_settings_input_svideo',
                    name: 'settings input svideo',
                    group_id: 'action',
                    keywords: ['action, settings, input, svideo'],
                    ligature: 'settings_input_svideo',
                    codepoint: 'E8C3',
                },
                {
                    id: 'ic_settings_overscan',
                    name: 'settings overscan',
                    group_id: 'action',
                    keywords: ['action, settings, overscan'],
                    ligature: 'settings_overscan',
                    codepoint: 'E8C4',
                },
                {
                    id: 'ic_settings_phone',
                    name: 'settings phone',
                    group_id: 'action',
                    keywords: ['action, settings, phone'],
                    ligature: 'settings_phone',
                    codepoint: 'E8C5',
                },
                {
                    id: 'ic_settings_power',
                    name: 'settings power',
                    group_id: 'action',
                    keywords: ['action, settings, power'],
                    ligature: 'settings_power',
                    codepoint: 'E8C6',
                },
                {
                    id: 'ic_settings_remote',
                    name: 'settings remote',
                    group_id: 'action',
                    keywords: ['action, settings, remote'],
                    ligature: 'settings_remote',
                    codepoint: 'E8C7',
                },
                {
                    id: 'ic_settings_voice',
                    name: 'settings voice',
                    group_id: 'action',
                    keywords: ['action, settings, voice'],
                    ligature: 'settings_voice',
                    codepoint: 'E8C8',
                },
                {
                    id: 'ic_shop',
                    name: 'shop',
                    group_id: 'action',
                    keywords: ['action, shop'],
                    ligature: 'shop',
                    codepoint: 'E8C9',
                },
                {
                    id: 'ic_shop_two',
                    name: 'shop two',
                    group_id: 'action',
                    keywords: ['action, shop, two'],
                    ligature: 'shop_two',
                    codepoint: 'E8CA',
                },
                {
                    id: 'ic_shopping_basket',
                    name: 'shopping basket',
                    group_id: 'action',
                    keywords: ['action, shopping, basket'],
                    ligature: 'shopping_basket',
                    codepoint: 'E8CB',
                },
                {
                    id: 'ic_shopping_cart',
                    name: 'shopping cart',
                    group_id: 'action',
                    keywords: ['action, shopping, cart'],
                    ligature: 'shopping_cart',
                    codepoint: 'E8CC',
                },
                {
                    id: 'ic_speaker_notes',
                    name: 'speaker notes',
                    group_id: 'action',
                    keywords: ['action, speaker, notes'],
                    ligature: 'speaker_notes',
                    codepoint: 'E8CD',
                },
                {
                    id: 'ic_spellcheck',
                    name: 'spellcheck',
                    group_id: 'action',
                    keywords: ['action, spellcheck'],
                    ligature: 'spellcheck',
                    codepoint: 'E8CE',
                },
                {
                    id: 'ic_star_rate',
                    name: 'star rate',
                    group_id: 'action',
                    keywords: ['action, star, rate'],
                    ligature: 'star_rate',
                    codepoint: 'E8CF',
                },
                {
                    id: 'ic_stars',
                    name: 'stars',
                    group_id: 'action',
                    keywords: ['action, stars'],
                    ligature: 'stars',
                    codepoint: 'E8D0',
                },
                {
                    id: 'ic_store',
                    name: 'store',
                    group_id: 'action',
                    keywords: ['action, store'],
                    ligature: 'store',
                    codepoint: 'E8D1',
                },
                {
                    id: 'ic_subject',
                    name: 'subject',
                    group_id: 'action',
                    keywords: ['action, subject'],
                    ligature: 'subject',
                    codepoint: 'E8D2',
                },
                {
                    id: 'ic_supervisor_account',
                    name: 'supervisor account',
                    group_id: 'action',
                    keywords: ['action, supervisor, account'],
                    ligature: 'supervisor_account',
                    codepoint: 'E8D3',
                },
                {
                    id: 'ic_swap_horiz',
                    name: 'swap horiz',
                    group_id: 'action',
                    keywords: ['action, swap, horiz'],
                    ligature: 'swap_horiz',
                    codepoint: 'E8D4',
                },
                {
                    id: 'ic_swap_vert',
                    name: 'swap vert',
                    group_id: 'action',
                    keywords: ['action, swap, vert'],
                    ligature: 'swap_vert',
                    codepoint: 'E8D5',
                },
                {
                    id: 'ic_swap_vertical_circle',
                    name: 'swap vertical circle',
                    group_id: 'action',
                    keywords: ['action, swap, vertical, circle'],
                    ligature: 'swap_vertical_circle',
                    codepoint: 'E8D6',
                },
                {
                    id: 'ic_system_update_alt',
                    name: 'system update alt',
                    group_id: 'action',
                    keywords: ['action, system, update, alt'],
                    ligature: 'system_update_alt',
                    codepoint: 'E8D7',
                },
                {
                    id: 'ic_tab',
                    name: 'tab',
                    group_id: 'action',
                    keywords: ['action, tab'],
                    ligature: 'tab',
                    codepoint: 'E8D8',
                },
                {
                    id: 'ic_tab_unselected',
                    name: 'tab unselected',
                    group_id: 'action',
                    keywords: ['action, tab, unselected'],
                    ligature: 'tab_unselected',
                    codepoint: 'E8D9',
                },
                {
                    id: 'ic_theaters',
                    name: 'theaters',
                    group_id: 'action',
                    keywords: ['action, theaters'],
                    ligature: 'theaters',
                    codepoint: 'E8DA',
                },
                {
                    id: 'ic_thumb_down',
                    name: 'thumb down',
                    group_id: 'action',
                    keywords: ['action, thumb, down'],
                    ligature: 'thumb_down',
                    codepoint: 'E8DB',
                },
                {
                    id: 'ic_thumb_up',
                    name: 'thumb up',
                    group_id: 'action',
                    keywords: ['action, thumb, up'],
                    ligature: 'thumb_up',
                    codepoint: 'E8DC',
                },
                {
                    id: 'ic_thumbs_up_down',
                    name: 'thumbs up down',
                    group_id: 'action',
                    keywords: ['action, thumbs, up, down'],
                    ligature: 'thumbs_up_down',
                    codepoint: 'E8DD',
                },
                {
                    id: 'ic_timeline',
                    name: 'timeline',
                    group_id: 'action',
                    keywords: ['action, timeline'],
                    ligature: 'timeline',
                    codepoint: 'E922',
                    is_new: true,
                },
                {
                    id: 'ic_toc',
                    name: 'toc',
                    group_id: 'action',
                    keywords: ['action, toc'],
                    ligature: 'toc',
                    codepoint: 'E8DE',
                },
                {
                    id: 'ic_today',
                    name: 'today',
                    group_id: 'action',
                    keywords: ['action, today'],
                    ligature: 'today',
                    codepoint: 'E8DF',
                },
                {
                    id: 'ic_toll',
                    name: 'toll',
                    group_id: 'action',
                    keywords: ['action, toll'],
                    ligature: 'toll',
                    codepoint: 'E8E0',
                },
                {
                    id: 'ic_touch_app',
                    name: 'touch app',
                    group_id: 'action',
                    keywords: ['action, touch, app'],
                    ligature: 'touch_app',
                    codepoint: 'E913',
                    is_new: true,
                },
                {
                    id: 'ic_track_changes',
                    name: 'track changes',
                    group_id: 'action',
                    keywords: ['action, track, changes'],
                    ligature: 'track_changes',
                    codepoint: 'E8E1',
                },
                {
                    id: 'ic_translate',
                    name: 'translate',
                    group_id: 'action',
                    keywords: ['action, translate'],
                    ligature: 'translate',
                    codepoint: 'E8E2',
                },
                {
                    id: 'ic_trending_down',
                    name: 'trending down',
                    group_id: 'action',
                    keywords: ['action, trending, down'],
                    ligature: 'trending_down',
                    codepoint: 'E8E3',
                },
                {
                    id: 'ic_trending_flat',
                    name: 'trending flat',
                    group_id: 'action',
                    keywords: ['action, trending, flat'],
                    ligature: 'trending_flat',
                    codepoint: 'E8E4',
                },
                {
                    id: 'ic_trending_up',
                    name: 'trending up',
                    group_id: 'action',
                    keywords: ['action, trending, up'],
                    ligature: 'trending_up',
                    codepoint: 'E8E5',
                },
                {
                    id: 'ic_turned_in',
                    name: 'turned in',
                    group_id: 'action',
                    keywords: ['action, turned, in'],
                    ligature: 'turned_in',
                    codepoint: 'E8E6',
                },
                {
                    id: 'ic_turned_in_not',
                    name: 'turned in not',
                    group_id: 'action',
                    keywords: ['action, turned, in, not'],
                    ligature: 'turned_in_not',
                    codepoint: 'E8E7',
                },
                {
                    id: 'ic_update',
                    name: 'update',
                    group_id: 'action',
                    keywords: ['action, update'],
                    ligature: 'update',
                    codepoint: 'E923',
                    is_new: true,
                },
                {
                    id: 'ic_verified_user',
                    name: 'verified user',
                    group_id: 'action',
                    keywords: ['action, verified, user'],
                    ligature: 'verified_user',
                    codepoint: 'E8E8',
                },
                {
                    id: 'ic_view_agenda',
                    name: 'view agenda',
                    group_id: 'action',
                    keywords: ['action, view, agenda'],
                    ligature: 'view_agenda',
                    codepoint: 'E8E9',
                },
                {
                    id: 'ic_view_array',
                    name: 'view array',
                    group_id: 'action',
                    keywords: ['action, view, array'],
                    ligature: 'view_array',
                    codepoint: 'E8EA',
                },
                {
                    id: 'ic_view_carousel',
                    name: 'view carousel',
                    group_id: 'action',
                    keywords: ['action, view, carousel'],
                    ligature: 'view_carousel',
                    codepoint: 'E8EB',
                },
                {
                    id: 'ic_view_column',
                    name: 'view column',
                    group_id: 'action',
                    keywords: ['action, view, column'],
                    ligature: 'view_column',
                    codepoint: 'E8EC',
                },
                {
                    id: 'ic_view_day',
                    name: 'view day',
                    group_id: 'action',
                    keywords: ['action, view, day'],
                    ligature: 'view_day',
                    codepoint: 'E8ED',
                },
                {
                    id: 'ic_view_headline',
                    name: 'view headline',
                    group_id: 'action',
                    keywords: ['action, view, headline'],
                    ligature: 'view_headline',
                    codepoint: 'E8EE',
                },
                {
                    id: 'ic_view_list',
                    name: 'view list',
                    group_id: 'action',
                    keywords: ['action, view, list'],
                    ligature: 'view_list',
                    codepoint: 'E8EF',
                },
                {
                    id: 'ic_view_module',
                    name: 'view module',
                    group_id: 'action',
                    keywords: ['action, view, module'],
                    ligature: 'view_module',
                    codepoint: 'E8F0',
                },
                {
                    id: 'ic_view_quilt',
                    name: 'view quilt',
                    group_id: 'action',
                    keywords: ['action, view, quilt'],
                    ligature: 'view_quilt',
                    codepoint: 'E8F1',
                },
                {
                    id: 'ic_view_stream',
                    name: 'view stream',
                    group_id: 'action',
                    keywords: ['action, view, stream'],
                    ligature: 'view_stream',
                    codepoint: 'E8F2',
                },
                {
                    id: 'ic_view_week',
                    name: 'view week',
                    group_id: 'action',
                    keywords: ['action, view, week'],
                    ligature: 'view_week',
                    codepoint: 'E8F3',
                },
                {
                    id: 'ic_visibility',
                    name: 'visibility',
                    group_id: 'action',
                    keywords: ['action, visibility'],
                    ligature: 'visibility',
                    codepoint: 'E8F4',
                },
                {
                    id: 'ic_visibility_off',
                    name: 'visibility off',
                    group_id: 'action',
                    keywords: ['action, visibility, off'],
                    ligature: 'visibility_off',
                    codepoint: 'E8F5',
                },
                {
                    id: 'ic_watch_later',
                    name: 'watch later',
                    group_id: 'action',
                    keywords: ['action, watch, later'],
                    ligature: 'watch_later',
                    codepoint: 'E924',
                    is_new: true,
                },
                {
                    id: 'ic_work',
                    name: 'work',
                    group_id: 'action',
                    keywords: ['action, work'],
                    ligature: 'work',
                    codepoint: 'E8F9',
                },
                {
                    id: 'ic_youtube_searched_for',
                    name: 'youtube searched for',
                    group_id: 'action',
                    keywords: ['action, youtube, searched'],
                    ligature: 'youtube_searched_for',
                    codepoint: 'E8FA',
                },
                {
                    id: 'ic_zoom_in',
                    name: 'zoom in',
                    group_id: 'action',
                    keywords: ['action, zoom, in'],
                    ligature: 'zoom_in',
                    codepoint: 'E8FF',
                },
                {
                    id: 'ic_zoom_out',
                    name: 'zoom out',
                    group_id: 'action',
                    keywords: ['action, zoom, out'],
                    ligature: 'zoom_out',
                    codepoint: 'E900',
                },
            ],
        },
        {
            name: 'alert',
            key: 'alert',
            icons: [
                {
                    id: 'ic_add_alert',
                    name: 'add alert',
                    group_id: 'alert',
                    keywords: ['alert, add, alert'],
                    ligature: 'add_alert',
                    codepoint: 'E003',
                },
                {
                    id: 'ic_error',
                    name: 'error',
                    group_id: 'alert',
                    keywords: ['alert, error'],
                    ligature: 'error',
                    codepoint: 'E000',
                },
                {
                    id: 'ic_error_outline',
                    name: 'error outline',
                    group_id: 'alert',
                    keywords: ['alert, error, outline'],
                    ligature: 'error_outline',
                    codepoint: 'E001',
                },
                {
                    id: 'ic_warning',
                    name: 'warning',
                    group_id: 'alert',
                    keywords: ['alert, warning'],
                    ligature: 'warning',
                    codepoint: 'E002',
                },
            ],
        },
        {
            name: 'av',
            key: 'av',
            icons: [
                {
                    id: 'ic_add_to_queue',
                    name: 'add to queue',
                    group_id: 'av',
                    keywords: ['av, add, to, queue'],
                    ligature: 'add_to_queue',
                    codepoint: 'E05C',
                    is_new: true,
                },
                {
                    id: 'ic_airplay',
                    name: 'airplay',
                    group_id: 'av',
                    keywords: ['av, airplay'],
                    ligature: 'airplay',
                    codepoint: 'E055',
                },
                {
                    id: 'ic_album',
                    name: 'album',
                    group_id: 'av',
                    keywords: ['av, album'],
                    ligature: 'album',
                    codepoint: 'E019',
                },
                {
                    id: 'ic_art_track',
                    name: 'art track',
                    group_id: 'av',
                    keywords: ['av, art, track'],
                    ligature: 'art_track',
                    codepoint: 'E060',
                    is_new: true,
                },
                {
                    id: 'ic_av_timer',
                    name: 'av timer',
                    group_id: 'av',
                    keywords: ['av, av, timer'],
                    ligature: 'av_timer',
                    codepoint: 'E01B',
                },
                {
                    id: 'ic_closed_caption',
                    name: 'closed caption',
                    group_id: 'av',
                    keywords: ['av, closed, caption'],
                    ligature: 'closed_caption',
                    codepoint: 'E01C',
                },
                {
                    id: 'ic_equalizer',
                    name: 'equalizer',
                    group_id: 'av',
                    keywords: ['av, equalizer'],
                    ligature: 'equalizer',
                    codepoint: 'E01D',
                },
                {
                    id: 'ic_explicit',
                    name: 'explicit',
                    group_id: 'av',
                    keywords: ['av, explicit'],
                    ligature: 'explicit',
                    codepoint: 'E01E',
                },
                {
                    id: 'ic_fast_forward',
                    name: 'fast forward',
                    group_id: 'av',
                    keywords: ['av, fast, forward'],
                    ligature: 'fast_forward',
                    codepoint: 'E01F',
                },
                {
                    id: 'ic_fast_rewind',
                    name: 'fast rewind',
                    group_id: 'av',
                    keywords: ['av, fast, rewind'],
                    ligature: 'fast_rewind',
                    codepoint: 'E020',
                },
                {
                    id: 'ic_fiber_dvr',
                    name: 'fiber dvr',
                    group_id: 'av',
                    keywords: ['av, fiber, dvr'],
                    ligature: 'fiber_dvr',
                    codepoint: 'E05D',
                    is_new: true,
                },
                {
                    id: 'ic_fiber_manual_record',
                    name: 'fiber manual record',
                    group_id: 'av',
                    keywords: ['av, fiber, manual, record'],
                    ligature: 'fiber_manual_record',
                    codepoint: 'E061',
                    is_new: true,
                },
                {
                    id: 'ic_fiber_new',
                    name: 'fiber new',
                    group_id: 'av',
                    keywords: ['av, fiber, new'],
                    ligature: 'fiber_new',
                    codepoint: 'E05E',
                    is_new: true,
                },
                {
                    id: 'ic_fiber_pin',
                    name: 'fiber pin',
                    group_id: 'av',
                    keywords: ['av, fiber, pin'],
                    ligature: 'fiber_pin',
                    codepoint: 'E06A',
                    is_new: true,
                },
                {
                    id: 'ic_fiber_smart_record',
                    name: 'fiber smart record',
                    group_id: 'av',
                    keywords: ['av, fiber, smart, record'],
                    ligature: 'fiber_smart_record',
                    codepoint: 'E062',
                    is_new: true,
                },
                {
                    id: 'ic_forward_10',
                    name: 'forward 10',
                    group_id: 'av',
                    keywords: ['av, forward, 10'],
                    ligature: 'forward_10',
                    codepoint: 'E056',
                },
                {
                    id: 'ic_forward_30',
                    name: 'forward 30',
                    group_id: 'av',
                    keywords: ['av, forward, 30'],
                    ligature: 'forward_30',
                    codepoint: 'E057',
                },
                {
                    id: 'ic_forward_5',
                    name: 'forward 5',
                    group_id: 'av',
                    keywords: ['av, forward'],
                    ligature: 'forward_5',
                    codepoint: 'E058',
                },
                {
                    id: 'ic_games',
                    name: 'games',
                    group_id: 'av',
                    keywords: ['av, games'],
                    ligature: 'games',
                    codepoint: 'E021',
                },
                {
                    id: 'ic_hd',
                    name: 'hd',
                    group_id: 'av',
                    keywords: ['av, hd'],
                    ligature: 'hd',
                    codepoint: 'E052',
                },
                {
                    id: 'ic_hearing',
                    name: 'hearing',
                    group_id: 'av',
                    keywords: ['av, hearing'],
                    ligature: 'hearing',
                    codepoint: 'E023',
                },
                {
                    id: 'ic_high_quality',
                    name: 'high quality',
                    group_id: 'av',
                    keywords: ['av, high, quality'],
                    ligature: 'high_quality',
                    codepoint: 'E024',
                },
                {
                    id: 'ic_library_add',
                    name: 'library add',
                    group_id: 'av',
                    keywords: ['av, library, add'],
                    ligature: 'library_add',
                    codepoint: 'E02E',
                },
                {
                    id: 'ic_library_books',
                    name: 'library books',
                    group_id: 'av',
                    keywords: ['av, library, books'],
                    ligature: 'library_books',
                    codepoint: 'E02F',
                },
                {
                    id: 'ic_library_music',
                    name: 'library music',
                    group_id: 'av',
                    keywords: ['av, library, music'],
                    ligature: 'library_music',
                    codepoint: 'E030',
                },
                {
                    id: 'ic_loop',
                    name: 'loop',
                    group_id: 'av',
                    keywords: ['av, loop'],
                    ligature: 'loop',
                    codepoint: 'E028',
                },
                {
                    id: 'ic_mic',
                    name: 'mic',
                    group_id: 'av',
                    keywords: ['av, mic'],
                    ligature: 'mic',
                    codepoint: 'E029',
                },
                {
                    id: 'ic_mic_none',
                    name: 'mic none',
                    group_id: 'av',
                    keywords: ['av, mic, none'],
                    ligature: 'mic_none',
                    codepoint: 'E02A',
                },
                {
                    id: 'ic_mic_off',
                    name: 'mic off',
                    group_id: 'av',
                    keywords: ['av, mic, off'],
                    ligature: 'mic_off',
                    codepoint: 'E02B',
                },
                {
                    id: 'ic_movie',
                    name: 'movie',
                    group_id: 'av',
                    keywords: ['av, movie'],
                    ligature: 'movie',
                    codepoint: 'E02C',
                },
                {
                    id: 'ic_music_video',
                    name: 'music video',
                    group_id: 'av',
                    keywords: ['av, music, video'],
                    ligature: 'music_video',
                    codepoint: 'E063',
                    is_new: true,
                },
                {
                    id: 'ic_new_releases',
                    name: 'new releases',
                    group_id: 'av',
                    keywords: ['av, new, releases'],
                    ligature: 'new_releases',
                    codepoint: 'E031',
                },
                {
                    id: 'ic_not_interested',
                    name: 'not interested',
                    group_id: 'av',
                    keywords: ['av, not, interested'],
                    ligature: 'not_interested',
                    codepoint: 'E033',
                },
                {
                    id: 'ic_pause',
                    name: 'pause',
                    group_id: 'av',
                    keywords: ['av, pause'],
                    ligature: 'pause',
                    codepoint: 'E034',
                },
                {
                    id: 'ic_pause_circle_filled',
                    name: 'pause circle filled',
                    group_id: 'av',
                    keywords: ['av, pause, circle, filled'],
                    ligature: 'pause_circle_filled',
                    codepoint: 'E035',
                },
                {
                    id: 'ic_pause_circle_outline',
                    name: 'pause circle outline',
                    group_id: 'av',
                    keywords: ['av, pause, circle, outline'],
                    ligature: 'pause_circle_outline',
                    codepoint: 'E036',
                },
                {
                    id: 'ic_play_arrow',
                    name: 'play arrow',
                    group_id: 'av',
                    keywords: ['av, play, arrow'],
                    ligature: 'play_arrow',
                    codepoint: 'E037',
                },
                {
                    id: 'ic_play_circle_filled',
                    name: 'play circle filled',
                    group_id: 'av',
                    keywords: ['av, play, circle, filled'],
                    ligature: 'play_circle_filled',
                    codepoint: 'E038',
                },
                {
                    id: 'ic_play_circle_outline',
                    name: 'play circle outline',
                    group_id: 'av',
                    keywords: ['av, play, circle, outline'],
                    ligature: 'play_circle_outline',
                    codepoint: 'E039',
                },
                {
                    id: 'ic_playlist_add',
                    name: 'playlist add',
                    group_id: 'av',
                    keywords: ['av, playlist, add'],
                    ligature: 'playlist_add',
                    codepoint: 'E03B',
                },
                {
                    id: 'ic_playlist_add_check',
                    name: 'playlist add check',
                    group_id: 'av',
                    keywords: ['av, playlist, add, check'],
                    ligature: 'playlist_add_check',
                    codepoint: 'E065',
                    is_new: true,
                },
                {
                    id: 'ic_playlist_play',
                    name: 'playlist play',
                    group_id: 'av',
                    keywords: ['av, playlist, play'],
                    ligature: 'playlist_play',
                    codepoint: 'E05F',
                    is_new: true,
                },
                {
                    id: 'ic_queue',
                    name: 'queue',
                    group_id: 'av',
                    keywords: ['av, queue'],
                    ligature: 'queue',
                    codepoint: 'E03C',
                },
                {
                    id: 'ic_queue_music',
                    name: 'queue music',
                    group_id: 'av',
                    keywords: ['av, queue, music'],
                    ligature: 'queue_music',
                    codepoint: 'E03D',
                },
                {
                    id: 'ic_queue_play_next',
                    name: 'queue play next',
                    group_id: 'av',
                    keywords: ['av, queue, play, next'],
                    ligature: 'queue_play_next',
                    codepoint: 'E066',
                    is_new: true,
                },
                {
                    id: 'ic_radio',
                    name: 'radio',
                    group_id: 'av',
                    keywords: ['av, radio'],
                    ligature: 'radio',
                    codepoint: 'E03E',
                },
                {
                    id: 'ic_recent_actors',
                    name: 'recent actors',
                    group_id: 'av',
                    keywords: ['av, recent, actors'],
                    ligature: 'recent_actors',
                    codepoint: 'E03F',
                },
                {
                    id: 'ic_remove_from_queue',
                    name: 'remove from queue',
                    group_id: 'av',
                    keywords: ['av, remove, from, queue'],
                    ligature: 'remove_from_queue',
                    codepoint: 'E067',
                    is_new: true,
                },
                {
                    id: 'ic_repeat',
                    name: 'repeat',
                    group_id: 'av',
                    keywords: ['av, repeat'],
                    ligature: 'repeat',
                    codepoint: 'E040',
                },
                {
                    id: 'ic_repeat_one',
                    name: 'repeat one',
                    group_id: 'av',
                    keywords: ['av, repeat, one'],
                    ligature: 'repeat_one',
                    codepoint: 'E041',
                },
                {
                    id: 'ic_replay',
                    name: 'replay',
                    group_id: 'av',
                    keywords: ['av, replay'],
                    ligature: 'replay',
                    codepoint: 'E042',
                },
                {
                    id: 'ic_replay_10',
                    name: 'replay 10',
                    group_id: 'av',
                    keywords: ['av, replay, 10'],
                    ligature: 'replay_10',
                    codepoint: 'E059',
                },
                {
                    id: 'ic_replay_30',
                    name: 'replay 30',
                    group_id: 'av',
                    keywords: ['av, replay, 30'],
                    ligature: 'replay_30',
                    codepoint: 'E05A',
                },
                {
                    id: 'ic_replay_5',
                    name: 'replay 5',
                    group_id: 'av',
                    keywords: ['av, replay'],
                    ligature: 'replay_5',
                    codepoint: 'E05B',
                },
                {
                    id: 'ic_shuffle',
                    name: 'shuffle',
                    group_id: 'av',
                    keywords: ['av, shuffle'],
                    ligature: 'shuffle',
                    codepoint: 'E043',
                },
                {
                    id: 'ic_skip_next',
                    name: 'skip next',
                    group_id: 'av',
                    keywords: ['av, skip, next'],
                    ligature: 'skip_next',
                    codepoint: 'E044',
                },
                {
                    id: 'ic_skip_previous',
                    name: 'skip previous',
                    group_id: 'av',
                    keywords: ['av, skip, previous'],
                    ligature: 'skip_previous',
                    codepoint: 'E045',
                },
                {
                    id: 'ic_slow_motion_video',
                    name: 'slow motion video',
                    group_id: 'av',
                    keywords: ['av, slow, motion, video'],
                    ligature: 'slow_motion_video',
                    codepoint: 'E068',
                    is_new: true,
                },
                {
                    id: 'ic_snooze',
                    name: 'snooze',
                    group_id: 'av',
                    keywords: ['av, snooze'],
                    ligature: 'snooze',
                    codepoint: 'E046',
                },
                {
                    id: 'ic_sort_by_alpha',
                    name: 'sort by alpha',
                    group_id: 'av',
                    keywords: ['av, sort, by, alpha'],
                    ligature: 'sort_by_alpha',
                    codepoint: 'E053',
                },
                {
                    id: 'ic_stop',
                    name: 'stop',
                    group_id: 'av',
                    keywords: ['av, stop'],
                    ligature: 'stop',
                    codepoint: 'E047',
                },
                {
                    id: 'ic_subscriptions',
                    name: 'subscriptions',
                    group_id: 'av',
                    keywords: ['av, subscriptions'],
                    ligature: 'subscriptions',
                    codepoint: 'E064',
                    is_new: true,
                },
                {
                    id: 'ic_subtitles',
                    name: 'subtitles',
                    group_id: 'av',
                    keywords: ['av, subtitles'],
                    ligature: 'subtitles',
                    codepoint: 'E048',
                },
                {
                    id: 'ic_surround_sound',
                    name: 'surround sound',
                    group_id: 'av',
                    keywords: ['av, surround, sound'],
                    ligature: 'surround_sound',
                    codepoint: 'E049',
                },
                {
                    id: 'ic_video_library',
                    name: 'video library',
                    group_id: 'av',
                    keywords: ['av, video, library'],
                    ligature: 'video_library',
                    codepoint: 'E04A',
                },
                {
                    id: 'ic_videocam',
                    name: 'videocam',
                    group_id: 'av',
                    keywords: ['av, videocam'],
                    ligature: 'videocam',
                    codepoint: 'E04B',
                },
                {
                    id: 'ic_videocam_off',
                    name: 'videocam off',
                    group_id: 'av',
                    keywords: ['av, videocam, off'],
                    ligature: 'videocam_off',
                    codepoint: 'E04C',
                },
                {
                    id: 'ic_volume_down',
                    name: 'volume down',
                    group_id: 'av',
                    keywords: ['av, volume, down'],
                    ligature: 'volume_down',
                    codepoint: 'E04D',
                },
                {
                    id: 'ic_volume_mute',
                    name: 'volume mute',
                    group_id: 'av',
                    keywords: ['av, volume, mute'],
                    ligature: 'volume_mute',
                    codepoint: 'E04E',
                },
                {
                    id: 'ic_volume_off',
                    name: 'volume off',
                    group_id: 'av',
                    keywords: ['av, volume, off'],
                    ligature: 'volume_off',
                    codepoint: 'E04F',
                },
                {
                    id: 'ic_volume_up',
                    name: 'volume up',
                    group_id: 'av',
                    keywords: ['av, volume, up'],
                    ligature: 'volume_up',
                    codepoint: 'E050',
                },
                {
                    id: 'ic_web',
                    name: 'web',
                    group_id: 'av',
                    keywords: ['av, web'],
                    ligature: 'web',
                    codepoint: 'E051',
                },
                {
                    id: 'ic_web_asset',
                    name: 'web asset',
                    group_id: 'av',
                    keywords: ['av, web, asset'],
                    ligature: 'web_asset',
                    codepoint: 'E069',
                    is_new: true,
                },
            ],
        },
        {
            name: 'communication',
            key: 'communication',
            icons: [
                {
                    id: 'ic_business',
                    name: 'business',
                    group_id: 'communication',
                    keywords: ['communication, business'],
                    ligature: 'business',
                    codepoint: 'E0AF',
                },
                {
                    id: 'ic_call',
                    name: 'call',
                    group_id: 'communication',
                    keywords: ['communication, call'],
                    ligature: 'call',
                    codepoint: 'E0B0',
                },
                {
                    id: 'ic_call_end',
                    name: 'call end',
                    group_id: 'communication',
                    keywords: ['communication, call, end'],
                    ligature: 'call_end',
                    codepoint: 'E0B1',
                },
                {
                    id: 'ic_call_made',
                    name: 'call made',
                    group_id: 'communication',
                    keywords: ['communication, call, made'],
                    ligature: 'call_made',
                    codepoint: 'E0B2',
                },
                {
                    id: 'ic_call_merge',
                    name: 'call merge',
                    group_id: 'communication',
                    keywords: ['communication, call, merge'],
                    ligature: 'call_merge',
                    codepoint: 'E0B3',
                },
                {
                    id: 'ic_call_missed',
                    name: 'call missed',
                    group_id: 'communication',
                    keywords: ['communication, call, missed'],
                    ligature: 'call_missed',
                    codepoint: 'E0B4',
                },
                {
                    id: 'ic_call_missed_outgoing',
                    name: 'call missed outgoing',
                    group_id: 'communication',
                    keywords: ['communication, call, missed, outgoing'],
                    ligature: 'call_missed_outgoing',
                    codepoint: 'E0E4',
                    is_new: true,
                },
                {
                    id: 'ic_call_received',
                    name: 'call received',
                    group_id: 'communication',
                    keywords: ['communication, call, received'],
                    ligature: 'call_received',
                    codepoint: 'E0B5',
                },
                {
                    id: 'ic_call_split',
                    name: 'call split',
                    group_id: 'communication',
                    keywords: ['communication, call, split'],
                    ligature: 'call_split',
                    codepoint: 'E0B6',
                },
                {
                    id: 'ic_chat',
                    name: 'chat',
                    group_id: 'communication',
                    keywords: ['communication, chat'],
                    ligature: 'chat',
                    codepoint: 'E0B7',
                },
                {
                    id: 'ic_chat_bubble',
                    name: 'chat bubble',
                    group_id: 'communication',
                    keywords: ['communication, chat, bubble'],
                    ligature: 'chat_bubble',
                    codepoint: 'E0CA',
                },
                {
                    id: 'ic_chat_bubble_outline',
                    name: 'chat bubble outline',
                    group_id: 'communication',
                    keywords: ['communication, chat, bubble, outline'],
                    ligature: 'chat_bubble_outline',
                    codepoint: 'E0CB',
                },
                {
                    id: 'ic_clear_all',
                    name: 'clear all',
                    group_id: 'communication',
                    keywords: ['communication, clear, all'],
                    ligature: 'clear_all',
                    codepoint: 'E0B8',
                },
                {
                    id: 'ic_comment',
                    name: 'comment',
                    group_id: 'communication',
                    keywords: ['communication, comment'],
                    ligature: 'comment',
                    codepoint: 'E0B9',
                },
                {
                    id: 'ic_contact_mail',
                    name: 'contact mail',
                    group_id: 'communication',
                    keywords: ['communication, contact, mail'],
                    ligature: 'contact_mail',
                    codepoint: 'E0D0',
                    is_new: true,
                },
                {
                    id: 'ic_contact_phone',
                    name: 'contact phone',
                    group_id: 'communication',
                    keywords: ['communication, contact, phone'],
                    ligature: 'contact_phone',
                    codepoint: 'E0CF',
                },
                {
                    id: 'ic_contacts',
                    name: 'contacts',
                    group_id: 'communication',
                    keywords: ['communication, contacts'],
                    ligature: 'contacts',
                    codepoint: 'E0BA',
                },
                {
                    id: 'ic_dialer_sip',
                    name: 'dialer sip',
                    group_id: 'communication',
                    keywords: ['communication, dialer, sip'],
                    ligature: 'dialer_sip',
                    codepoint: 'E0BB',
                },
                {
                    id: 'ic_dialpad',
                    name: 'dialpad',
                    group_id: 'communication',
                    keywords: ['communication, dialpad'],
                    ligature: 'dialpad',
                    codepoint: 'E0BC',
                },
                {
                    id: 'ic_email',
                    name: 'email',
                    group_id: 'communication',
                    keywords: ['communication, email'],
                    ligature: 'email',
                    codepoint: 'E0BE',
                },
                {
                    id: 'ic_forum',
                    name: 'forum',
                    group_id: 'communication',
                    keywords: ['communication, forum'],
                    ligature: 'forum',
                    codepoint: 'E0BF',
                },
                {
                    id: 'ic_import_contacts',
                    name: 'import contacts',
                    group_id: 'communication',
                    keywords: ['communication, import, contacts'],
                    ligature: 'import_contacts',
                    codepoint: 'E0E0',
                    is_new: true,
                },
                {
                    id: 'ic_import_export',
                    name: 'import export',
                    group_id: 'communication',
                    keywords: ['communication, import, export'],
                    ligature: 'import_export',
                    codepoint: 'E0C3',
                },
                {
                    id: 'ic_invert_colors_off',
                    name: 'invert colors off',
                    group_id: 'communication',
                    keywords: ['communication, invert, colors, off'],
                    ligature: 'invert_colors_off',
                    codepoint: 'E0C4',
                },
                {
                    id: 'ic_live_help',
                    name: 'live help',
                    group_id: 'communication',
                    keywords: ['communication, live, help'],
                    ligature: 'live_help',
                    codepoint: 'E0C6',
                },
                {
                    id: 'ic_location_off',
                    name: 'location off',
                    group_id: 'communication',
                    keywords: ['communication, location, off'],
                    ligature: 'location_off',
                    codepoint: 'E0C7',
                },
                {
                    id: 'ic_location_on',
                    name: 'location on',
                    group_id: 'communication',
                    keywords: ['communication, location, on'],
                    ligature: 'location_on',
                    codepoint: 'E0C8',
                },
                {
                    id: 'ic_mail_outline',
                    name: 'mail outline',
                    group_id: 'communication',
                    keywords: ['communication, mail, outline'],
                    ligature: 'mail_outline',
                    codepoint: 'E0E1',
                    is_new: true,
                },
                {
                    id: 'ic_message',
                    name: 'message',
                    group_id: 'communication',
                    keywords: ['communication, message'],
                    ligature: 'message',
                    codepoint: 'E0C9',
                },
                {
                    id: 'ic_no_sim',
                    name: 'no sim',
                    group_id: 'communication',
                    keywords: ['communication, no, sim'],
                    ligature: 'no_sim',
                    codepoint: 'E0CC',
                },
                {
                    id: 'ic_phone',
                    name: 'phone',
                    group_id: 'communication',
                    keywords: ['communication, phone'],
                    ligature: 'phone',
                    codepoint: 'E0CD',
                },
                {
                    id: 'ic_phonelink_erase',
                    name: 'phonelink erase',
                    group_id: 'communication',
                    keywords: ['communication, phonelink, erase'],
                    ligature: 'phonelink_erase',
                    codepoint: 'E0DB',
                },
                {
                    id: 'ic_phonelink_lock',
                    name: 'phonelink lock',
                    group_id: 'communication',
                    keywords: ['communication, phonelink, lock'],
                    ligature: 'phonelink_lock',
                    codepoint: 'E0DC',
                },
                {
                    id: 'ic_phonelink_ring',
                    name: 'phonelink ring',
                    group_id: 'communication',
                    keywords: ['communication, phonelink, ring'],
                    ligature: 'phonelink_ring',
                    codepoint: 'E0DD',
                },
                {
                    id: 'ic_phonelink_setup',
                    name: 'phonelink setup',
                    group_id: 'communication',
                    keywords: ['communication, phonelink, setup'],
                    ligature: 'phonelink_setup',
                    codepoint: 'E0DE',
                },
                {
                    id: 'ic_portable_wifi_off',
                    name: 'portable wifi off',
                    group_id: 'communication',
                    keywords: ['communication, portable, wifi, off'],
                    ligature: 'portable_wifi_off',
                    codepoint: 'E0CE',
                },
                {
                    id: 'ic_present_to_all',
                    name: 'present to all',
                    group_id: 'communication',
                    keywords: ['communication, present, to, all'],
                    ligature: 'present_to_all',
                    codepoint: 'E0DF',
                },
                {
                    id: 'ic_ring_volume',
                    name: 'ring volume',
                    group_id: 'communication',
                    keywords: ['communication, ring, volume'],
                    ligature: 'ring_volume',
                    codepoint: 'E0D1',
                },
                {
                    id: 'ic_screen_share',
                    name: 'screen share',
                    group_id: 'communication',
                    keywords: ['communication, screen, share'],
                    ligature: 'screen_share',
                    codepoint: 'E0E2',
                    is_new: true,
                },
                {
                    id: 'ic_speaker_phone',
                    name: 'speaker phone',
                    group_id: 'communication',
                    keywords: ['communication, speaker, phone'],
                    ligature: 'speaker_phone',
                    codepoint: 'E0D2',
                },
                {
                    id: 'ic_stay_current_landscape',
                    name: 'stay current landscape',
                    group_id: 'communication',
                    keywords: ['communication, stay, current, landscape'],
                    ligature: 'stay_current_landscape',
                    codepoint: 'E0D3',
                },
                {
                    id: 'ic_stay_current_portrait',
                    name: 'stay current portrait',
                    group_id: 'communication',
                    keywords: ['communication, stay, current, portrait'],
                    ligature: 'stay_current_portrait',
                    codepoint: 'E0D4',
                },
                {
                    id: 'ic_stay_primary_landscape',
                    name: 'stay primary landscape',
                    group_id: 'communication',
                    keywords: ['communication, stay, primary, landscape'],
                    ligature: 'stay_primary_landscape',
                    codepoint: 'E0D5',
                },
                {
                    id: 'ic_stay_primary_portrait',
                    name: 'stay primary portrait',
                    group_id: 'communication',
                    keywords: ['communication, stay, primary, portrait'],
                    ligature: 'stay_primary_portrait',
                    codepoint: 'E0D6',
                },
                {
                    id: 'ic_stop_screen_share',
                    name: 'stop screen share',
                    group_id: 'communication',
                    keywords: ['communication, stop, screen, share'],
                    ligature: 'stop_screen_share',
                    codepoint: 'E0E3',
                    is_new: true,
                },
                {
                    id: 'ic_swap_calls',
                    name: 'swap calls',
                    group_id: 'communication',
                    keywords: ['communication, swap, calls'],
                    ligature: 'swap_calls',
                    codepoint: 'E0D7',
                },
                {
                    id: 'ic_textsms',
                    name: 'textsms',
                    group_id: 'communication',
                    keywords: ['communication, textsms'],
                    ligature: 'textsms',
                    codepoint: 'E0D8',
                },
                {
                    id: 'ic_voicemail',
                    name: 'voicemail',
                    group_id: 'communication',
                    keywords: ['communication, voicemail'],
                    ligature: 'voicemail',
                    codepoint: 'E0D9',
                },
                {
                    id: 'ic_vpn_key',
                    name: 'vpn key',
                    group_id: 'communication',
                    keywords: ['communication, vpn, key'],
                    ligature: 'vpn_key',
                    codepoint: 'E0DA',
                },
            ],
        },
        {
            name: 'content',
            key: 'content',
            icons: [
                {
                    id: 'ic_add',
                    name: 'add',
                    group_id: 'content',
                    keywords: ['content, add'],
                    ligature: 'add',
                    codepoint: 'E145',
                },
                {
                    id: 'ic_add_box',
                    name: 'add box',
                    group_id: 'content',
                    keywords: ['content, add, box'],
                    ligature: 'add_box',
                    codepoint: 'E146',
                },
                {
                    id: 'ic_add_circle',
                    name: 'add circle',
                    group_id: 'content',
                    keywords: ['content, add, circle'],
                    ligature: 'add_circle',
                    codepoint: 'E147',
                },
                {
                    id: 'ic_add_circle_outline',
                    name: 'add circle outline',
                    group_id: 'content',
                    keywords: ['content, add, circle, outline'],
                    ligature: 'add_circle_outline',
                    codepoint: 'E148',
                },
                {
                    id: 'ic_archive',
                    name: 'archive',
                    group_id: 'content',
                    keywords: ['content, archive'],
                    ligature: 'archive',
                    codepoint: 'E149',
                },
                {
                    id: 'ic_backspace',
                    name: 'backspace',
                    group_id: 'content',
                    keywords: ['content, backspace'],
                    ligature: 'backspace',
                    codepoint: 'E14A',
                },
                {
                    id: 'ic_block',
                    name: 'block',
                    group_id: 'content',
                    keywords: ['content, block'],
                    ligature: 'block',
                    codepoint: 'E14B',
                },
                {
                    id: 'ic_clear',
                    name: 'clear',
                    group_id: 'content',
                    keywords: ['content, clear'],
                    ligature: 'clear',
                    codepoint: 'E14C',
                },
                {
                    id: 'ic_content_copy',
                    name: 'content copy',
                    group_id: 'content',
                    keywords: ['content, content, copy'],
                    ligature: 'content_copy',
                    codepoint: 'E14D',
                },
                {
                    id: 'ic_content_cut',
                    name: 'content cut',
                    group_id: 'content',
                    keywords: ['content, content, cut'],
                    ligature: 'content_cut',
                    codepoint: 'E14E',
                },
                {
                    id: 'ic_content_paste',
                    name: 'content paste',
                    group_id: 'content',
                    keywords: ['content, content, paste'],
                    ligature: 'content_paste',
                    codepoint: 'E14F',
                },
                {
                    id: 'ic_create',
                    name: 'create',
                    group_id: 'content',
                    keywords: ['content, create'],
                    ligature: 'create',
                    codepoint: 'E150',
                },
                {
                    id: 'ic_drafts',
                    name: 'drafts',
                    group_id: 'content',
                    keywords: ['content, drafts'],
                    ligature: 'drafts',
                    codepoint: 'E151',
                },
                {
                    id: 'ic_filter_list',
                    name: 'filter list',
                    group_id: 'content',
                    keywords: ['content, filter, list'],
                    ligature: 'filter_list',
                    codepoint: 'E152',
                },
                {
                    id: 'ic_flag',
                    name: 'flag',
                    group_id: 'content',
                    keywords: ['content, flag'],
                    ligature: 'flag',
                    codepoint: 'E153',
                },
                {
                    id: 'ic_font_download',
                    name: 'font download',
                    group_id: 'content',
                    keywords: ['content, font, download'],
                    ligature: 'font_download',
                    codepoint: 'E167',
                },
                {
                    id: 'ic_forward',
                    name: 'forward',
                    group_id: 'content',
                    keywords: ['content, forward'],
                    ligature: 'forward',
                    codepoint: 'E154',
                },
                {
                    id: 'ic_gesture',
                    name: 'gesture',
                    group_id: 'content',
                    keywords: ['content, gesture'],
                    ligature: 'gesture',
                    codepoint: 'E155',
                },
                {
                    id: 'ic_inbox',
                    name: 'inbox',
                    group_id: 'content',
                    keywords: ['content, inbox'],
                    ligature: 'inbox',
                    codepoint: 'E156',
                },
                {
                    id: 'ic_link',
                    name: 'link',
                    group_id: 'content',
                    keywords: ['content, link'],
                    ligature: 'link',
                    codepoint: 'E157',
                },
                {
                    id: 'ic_mail',
                    name: 'mail',
                    group_id: 'content',
                    keywords: ['content, mail'],
                    ligature: 'mail',
                    codepoint: 'E158',
                },
                {
                    id: 'ic_markunread',
                    name: 'markunread',
                    group_id: 'content',
                    keywords: ['content, markunread'],
                    ligature: 'markunread',
                    codepoint: 'E159',
                },
                {
                    id: 'ic_move_to_inbox',
                    name: 'move to inbox',
                    group_id: 'content',
                    keywords: ['content, move, to, inbox'],
                    ligature: 'move_to_inbox',
                    codepoint: 'E168',
                    is_new: true,
                },
                {
                    id: 'ic_next_week',
                    name: 'next week',
                    group_id: 'content',
                    keywords: ['content, next, week'],
                    ligature: 'next_week',
                    codepoint: 'E16A',
                    is_new: true,
                },
                {
                    id: 'ic_redo',
                    name: 'redo',
                    group_id: 'content',
                    keywords: ['content, redo'],
                    ligature: 'redo',
                    codepoint: 'E15A',
                },
                {
                    id: 'ic_remove',
                    name: 'remove',
                    group_id: 'content',
                    keywords: ['content, remove'],
                    ligature: 'remove',
                    codepoint: 'E15B',
                },
                {
                    id: 'ic_remove_circle',
                    name: 'remove circle',
                    group_id: 'content',
                    keywords: ['content, remove, circle'],
                    ligature: 'remove_circle',
                    codepoint: 'E15C',
                },
                {
                    id: 'ic_remove_circle_outline',
                    name: 'remove circle outline',
                    group_id: 'content',
                    keywords: ['content, remove, circle, outline'],
                    ligature: 'remove_circle_outline',
                    codepoint: 'E15D',
                },
                {
                    id: 'ic_reply',
                    name: 'reply',
                    group_id: 'content',
                    keywords: ['content, reply'],
                    ligature: 'reply',
                    codepoint: 'E15E',
                },
                {
                    id: 'ic_reply_all',
                    name: 'reply all',
                    group_id: 'content',
                    keywords: ['content, reply, all'],
                    ligature: 'reply_all',
                    codepoint: 'E15F',
                },
                {
                    id: 'ic_report',
                    name: 'report',
                    group_id: 'content',
                    keywords: ['content, report'],
                    ligature: 'report',
                    codepoint: 'E160',
                },
                {
                    id: 'ic_save',
                    name: 'save',
                    group_id: 'content',
                    keywords: ['content, save'],
                    ligature: 'save',
                    codepoint: 'E161',
                },
                {
                    id: 'ic_select_all',
                    name: 'select all',
                    group_id: 'content',
                    keywords: ['content, select, all'],
                    ligature: 'select_all',
                    codepoint: 'E162',
                },
                {
                    id: 'ic_send',
                    name: 'send',
                    group_id: 'content',
                    keywords: ['content, send'],
                    ligature: 'send',
                    codepoint: 'E163',
                },
                {
                    id: 'ic_sort',
                    name: 'sort',
                    group_id: 'content',
                    keywords: ['content, sort'],
                    ligature: 'sort',
                    codepoint: 'E164',
                },
                {
                    id: 'ic_text_format',
                    name: 'text format',
                    group_id: 'content',
                    keywords: ['content, text, format'],
                    ligature: 'text_format',
                    codepoint: 'E165',
                },
                {
                    id: 'ic_unarchive',
                    name: 'unarchive',
                    group_id: 'content',
                    keywords: ['content, unarchive'],
                    ligature: 'unarchive',
                    codepoint: 'E169',
                    is_new: true,
                },
                {
                    id: 'ic_undo',
                    name: 'undo',
                    group_id: 'content',
                    keywords: ['content, undo'],
                    ligature: 'undo',
                    codepoint: 'E166',
                },
                {
                    id: 'ic_weekend',
                    name: 'weekend',
                    group_id: 'content',
                    keywords: ['content, weekend'],
                    ligature: 'weekend',
                    codepoint: 'E16B',
                    is_new: true,
                },
            ],
        },
        {
            name: 'device',
            key: 'device',
            icons: [
                {
                    id: 'ic_access_alarm',
                    name: 'access alarm',
                    group_id: 'device',
                    keywords: ['device, access, alarm'],
                    ligature: 'access_alarm',
                    codepoint: 'E190',
                },
                {
                    id: 'ic_access_alarms',
                    name: 'access alarms',
                    group_id: 'device',
                    keywords: ['device, access, alarms'],
                    ligature: 'access_alarms',
                    codepoint: 'E191',
                },
                {
                    id: 'ic_access_time',
                    name: 'access time',
                    group_id: 'device',
                    keywords: ['device, access, time'],
                    ligature: 'access_time',
                    codepoint: 'E192',
                },
                {
                    id: 'ic_add_alarm',
                    name: 'add alarm',
                    group_id: 'device',
                    keywords: ['device, add, alarm'],
                    ligature: 'add_alarm',
                    codepoint: 'E193',
                },
                {
                    id: 'ic_airplanemode_active',
                    name: 'airplanemode active',
                    group_id: 'device',
                    keywords: ['device, airplanemode, active'],
                    ligature: 'airplanemode_active',
                    codepoint: 'E195',
                },
                {
                    id: 'ic_airplanemode_inactive',
                    name: 'airplanemode inactive',
                    group_id: 'device',
                    keywords: ['device, airplanemode, inactive'],
                    ligature: 'airplanemode_inactive',
                    codepoint: 'E194',
                },
                {
                    id: 'ic_battery_alert',
                    name: 'battery alert',
                    group_id: 'device',
                    keywords: ['device, battery, alert'],
                    ligature: 'battery_alert',
                    codepoint: 'E19C',
                },
                {
                    id: 'ic_battery_charging_full',
                    name: 'battery charging full',
                    group_id: 'device',
                    keywords: ['device, battery, charging, full'],
                    ligature: 'battery_charging_full',
                    codepoint: 'E1A3',
                },
                {
                    id: 'ic_battery_full',
                    name: 'battery full',
                    group_id: 'device',
                    keywords: ['device, battery, full'],
                    ligature: 'battery_full',
                    codepoint: 'E1A4',
                },
                {
                    id: 'ic_battery_std',
                    name: 'battery std',
                    group_id: 'device',
                    keywords: ['device, battery, std'],
                    ligature: 'battery_std',
                    codepoint: 'E1A5',
                },
                {
                    id: 'ic_battery_unknown',
                    name: 'battery unknown',
                    group_id: 'device',
                    keywords: ['device, battery, unknown'],
                    ligature: 'battery_unknown',
                    codepoint: 'E1A6',
                },
                {
                    id: 'ic_bluetooth',
                    name: 'bluetooth',
                    group_id: 'device',
                    keywords: ['device, bluetooth'],
                    ligature: 'bluetooth',
                    codepoint: 'E1A7',
                },
                {
                    id: 'ic_bluetooth_connected',
                    name: 'bluetooth connected',
                    group_id: 'device',
                    keywords: ['device, bluetooth, connected'],
                    ligature: 'bluetooth_connected',
                    codepoint: 'E1A8',
                },
                {
                    id: 'ic_bluetooth_disabled',
                    name: 'bluetooth disabled',
                    group_id: 'device',
                    keywords: ['device, bluetooth, disabled'],
                    ligature: 'bluetooth_disabled',
                    codepoint: 'E1A9',
                },
                {
                    id: 'ic_bluetooth_searching',
                    name: 'bluetooth searching',
                    group_id: 'device',
                    keywords: ['device, bluetooth, searching'],
                    ligature: 'bluetooth_searching',
                    codepoint: 'E1AA',
                },
                {
                    id: 'ic_brightness_auto',
                    name: 'brightness auto',
                    group_id: 'device',
                    keywords: ['device, brightness, auto'],
                    ligature: 'brightness_auto',
                    codepoint: 'E1AB',
                },
                {
                    id: 'ic_brightness_high',
                    name: 'brightness high',
                    group_id: 'device',
                    keywords: ['device, brightness, high'],
                    ligature: 'brightness_high',
                    codepoint: 'E1AC',
                },
                {
                    id: 'ic_brightness_low',
                    name: 'brightness low',
                    group_id: 'device',
                    keywords: ['device, brightness, low'],
                    ligature: 'brightness_low',
                    codepoint: 'E1AD',
                },
                {
                    id: 'ic_brightness_medium',
                    name: 'brightness medium',
                    group_id: 'device',
                    keywords: ['device, brightness, medium'],
                    ligature: 'brightness_medium',
                    codepoint: 'E1AE',
                },
                {
                    id: 'ic_data_usage',
                    name: 'data usage',
                    group_id: 'device',
                    keywords: ['device, data, usage'],
                    ligature: 'data_usage',
                    codepoint: 'E1AF',
                },
                {
                    id: 'ic_developer_mode',
                    name: 'developer mode',
                    group_id: 'device',
                    keywords: ['device, developer, mode'],
                    ligature: 'developer_mode',
                    codepoint: 'E1B0',
                },
                {
                    id: 'ic_devices',
                    name: 'devices',
                    group_id: 'device',
                    keywords: ['device, devices'],
                    ligature: 'devices',
                    codepoint: 'E1B1',
                },
                {
                    id: 'ic_dvr',
                    name: 'dvr',
                    group_id: 'device',
                    keywords: ['device, dvr'],
                    ligature: 'dvr',
                    codepoint: 'E1B2',
                },
                {
                    id: 'ic_gps_fixed',
                    name: 'gps fixed',
                    group_id: 'device',
                    keywords: ['device, gps, fixed'],
                    ligature: 'gps_fixed',
                    codepoint: 'E1B3',
                },
                {
                    id: 'ic_gps_not_fixed',
                    name: 'gps not fixed',
                    group_id: 'device',
                    keywords: ['device, gps, not, fixed'],
                    ligature: 'gps_not_fixed',
                    codepoint: 'E1B4',
                },
                {
                    id: 'ic_gps_off',
                    name: 'gps off',
                    group_id: 'device',
                    keywords: ['device, gps, off'],
                    ligature: 'gps_off',
                    codepoint: 'E1B5',
                },
                {
                    id: 'ic_graphic_eq',
                    name: 'graphic eq',
                    group_id: 'device',
                    keywords: ['device, graphic, eq'],
                    ligature: 'graphic_eq',
                    codepoint: 'E1B8',
                },
                {
                    id: 'ic_location_disabled',
                    name: 'location disabled',
                    group_id: 'device',
                    keywords: ['device, location, disabled'],
                    ligature: 'location_disabled',
                    codepoint: 'E1B6',
                },
                {
                    id: 'ic_location_searching',
                    name: 'location searching',
                    group_id: 'device',
                    keywords: ['device, location, searching'],
                    ligature: 'location_searching',
                    codepoint: 'E1B7',
                },
                {
                    id: 'ic_network_cell',
                    name: 'network cell',
                    group_id: 'device',
                    keywords: ['device, network, cell'],
                    ligature: 'network_cell',
                    codepoint: 'E1B9',
                },
                {
                    id: 'ic_network_wifi',
                    name: 'network wifi',
                    group_id: 'device',
                    keywords: ['device, network, wifi'],
                    ligature: 'network_wifi',
                    codepoint: 'E1BA',
                },
                {
                    id: 'ic_nfc',
                    name: 'nfc',
                    group_id: 'device',
                    keywords: ['device, nfc'],
                    ligature: 'nfc',
                    codepoint: 'E1BB',
                },
                {
                    id: 'ic_screen_lock_landscape',
                    name: 'screen lock landscape',
                    group_id: 'device',
                    keywords: ['device, screen, lock, landscape'],
                    ligature: 'screen_lock_landscape',
                    codepoint: 'E1BE',
                },
                {
                    id: 'ic_screen_lock_portrait',
                    name: 'screen lock portrait',
                    group_id: 'device',
                    keywords: ['device, screen, lock, portrait'],
                    ligature: 'screen_lock_portrait',
                    codepoint: 'E1BF',
                },
                {
                    id: 'ic_screen_lock_rotation',
                    name: 'screen lock rotation',
                    group_id: 'device',
                    keywords: ['device, screen, lock, rotation'],
                    ligature: 'screen_lock_rotation',
                    codepoint: 'E1C0',
                },
                {
                    id: 'ic_screen_rotation',
                    name: 'screen rotation',
                    group_id: 'device',
                    keywords: ['device, screen, rotation'],
                    ligature: 'screen_rotation',
                    codepoint: 'E1C1',
                },
                {
                    id: 'ic_sd_storage',
                    name: 'sd storage',
                    group_id: 'device',
                    keywords: ['device, sd, storage'],
                    ligature: 'sd_storage',
                    codepoint: 'E1C2',
                },
                {
                    id: 'ic_settings_system_daydream',
                    name: 'settings system daydream',
                    group_id: 'device',
                    keywords: ['device, settings, system, daydream'],
                    ligature: 'settings_system_daydream',
                    codepoint: 'E1C3',
                },
                {
                    id: 'ic_signal_cellular_4_bar',
                    name: 'signal cellular 4 bar',
                    group_id: 'device',
                    keywords: ['device, signal, cellular, bar'],
                    ligature: 'signal_cellular_4_bar',
                    codepoint: 'E1C8',
                },
                {
                    id: 'ic_signal_cellular_connected_no_internet_4_bar',
                    name: 'signal cellular connected no internet 4 bar',
                    group_id: 'device',
                    keywords: ['device, signal, cellular, connected, no, internet, bar'],
                    ligature: 'signal_cellular_connected_no_internet_4_bar',
                    codepoint: 'E1CD',
                },
                {
                    id: 'ic_signal_cellular_no_sim',
                    name: 'signal cellular no sim',
                    group_id: 'device',
                    keywords: ['device, signal, cellular, no, sim'],
                    ligature: 'signal_cellular_no_sim',
                    codepoint: 'E1CE',
                },
                {
                    id: 'ic_signal_cellular_null',
                    name: 'signal cellular null',
                    group_id: 'device',
                    keywords: ['device, signal, cellular, null'],
                    ligature: 'signal_cellular_null',
                    codepoint: 'E1CF',
                },
                {
                    id: 'ic_signal_cellular_off',
                    name: 'signal cellular off',
                    group_id: 'device',
                    keywords: ['device, signal, cellular, off'],
                    ligature: 'signal_cellular_off',
                    codepoint: 'E1D0',
                },
                {
                    id: 'ic_signal_wifi_4_bar',
                    name: 'signal wifi 4 bar',
                    group_id: 'device',
                    keywords: ['device, signal, wifi, bar'],
                    ligature: 'signal_wifi_4_bar',
                    codepoint: 'E1D8',
                },
                {
                    id: 'ic_signal_wifi_4_bar_lock',
                    name: 'signal wifi 4 bar lock',
                    group_id: 'device',
                    keywords: ['device, signal, wifi, bar, lock'],
                    ligature: 'signal_wifi_4_bar_lock',
                    codepoint: 'E1D9',
                },
                {
                    id: 'ic_signal_wifi_off',
                    name: 'signal wifi off',
                    group_id: 'device',
                    keywords: ['device, signal, wifi, off'],
                    ligature: 'signal_wifi_off',
                    codepoint: 'E1DA',
                },
                {
                    id: 'ic_storage',
                    name: 'storage',
                    group_id: 'device',
                    keywords: ['device, storage'],
                    ligature: 'storage',
                    codepoint: 'E1DB',
                },
                {
                    id: 'ic_usb',
                    name: 'usb',
                    group_id: 'device',
                    keywords: ['device, usb'],
                    ligature: 'usb',
                    codepoint: 'E1E0',
                },
                {
                    id: 'ic_wallpaper',
                    name: 'wallpaper',
                    group_id: 'device',
                    keywords: ['device, wallpaper'],
                    ligature: 'wallpaper',
                    codepoint: 'E1BC',
                },
                {
                    id: 'ic_widgets',
                    name: 'widgets',
                    group_id: 'device',
                    keywords: ['device, widgets'],
                    ligature: 'widgets',
                    codepoint: 'E1BD',
                },
                {
                    id: 'ic_wifi_lock',
                    name: 'wifi lock',
                    group_id: 'device',
                    keywords: ['device, wifi, lock'],
                    ligature: 'wifi_lock',
                    codepoint: 'E1E1',
                },
                {
                    id: 'ic_wifi_tethering',
                    name: 'wifi tethering',
                    group_id: 'device',
                    keywords: ['device, wifi, tethering'],
                    ligature: 'wifi_tethering',
                    codepoint: 'E1E2',
                },
            ],
        },
        {
            name: 'editor',
            key: 'editor',
            icons: [
                {
                    id: 'ic_attach_file',
                    name: 'attach file',
                    group_id: 'editor',
                    keywords: ['editor, attach, file'],
                    ligature: 'attach_file',
                    codepoint: 'E226',
                },
                {
                    id: 'ic_attach_money',
                    name: 'attach money',
                    group_id: 'editor',
                    keywords: ['editor, attach, money'],
                    ligature: 'attach_money',
                    codepoint: 'E227',
                },
                {
                    id: 'ic_border_all',
                    name: 'border all',
                    group_id: 'editor',
                    keywords: ['editor, border, all'],
                    ligature: 'border_all',
                    codepoint: 'E228',
                },
                {
                    id: 'ic_border_bottom',
                    name: 'border bottom',
                    group_id: 'editor',
                    keywords: ['editor, border, bottom'],
                    ligature: 'border_bottom',
                    codepoint: 'E229',
                },
                {
                    id: 'ic_border_clear',
                    name: 'border clear',
                    group_id: 'editor',
                    keywords: ['editor, border, clear'],
                    ligature: 'border_clear',
                    codepoint: 'E22A',
                },
                {
                    id: 'ic_border_color',
                    name: 'border color',
                    group_id: 'editor',
                    keywords: ['editor, border, color'],
                    ligature: 'border_color',
                    codepoint: 'E22B',
                },
                {
                    id: 'ic_border_horizontal',
                    name: 'border horizontal',
                    group_id: 'editor',
                    keywords: ['editor, border, horizontal'],
                    ligature: 'border_horizontal',
                    codepoint: 'E22C',
                },
                {
                    id: 'ic_border_inner',
                    name: 'border inner',
                    group_id: 'editor',
                    keywords: ['editor, border, inner'],
                    ligature: 'border_inner',
                    codepoint: 'E22D',
                },
                {
                    id: 'ic_border_left',
                    name: 'border left',
                    group_id: 'editor',
                    keywords: ['editor, border, left'],
                    ligature: 'border_left',
                    codepoint: 'E22E',
                },
                {
                    id: 'ic_border_outer',
                    name: 'border outer',
                    group_id: 'editor',
                    keywords: ['editor, border, outer'],
                    ligature: 'border_outer',
                    codepoint: 'E22F',
                },
                {
                    id: 'ic_border_right',
                    name: 'border right',
                    group_id: 'editor',
                    keywords: ['editor, border, right'],
                    ligature: 'border_right',
                    codepoint: 'E230',
                },
                {
                    id: 'ic_border_style',
                    name: 'border style',
                    group_id: 'editor',
                    keywords: ['editor, border, style'],
                    ligature: 'border_style',
                    codepoint: 'E231',
                },
                {
                    id: 'ic_border_top',
                    name: 'border top',
                    group_id: 'editor',
                    keywords: ['editor, border, top'],
                    ligature: 'border_top',
                    codepoint: 'E232',
                },
                {
                    id: 'ic_border_vertical',
                    name: 'border vertical',
                    group_id: 'editor',
                    keywords: ['editor, border, vertical'],
                    ligature: 'border_vertical',
                    codepoint: 'E233',
                },
                {
                    id: 'ic_drag_handle',
                    name: 'drag handle',
                    group_id: 'editor',
                    keywords: ['editor, drag, handle'],
                    ligature: 'drag_handle',
                    codepoint: 'E25D',
                    is_new: true,
                },
                {
                    id: 'ic_format_align_center',
                    name: 'format align center',
                    group_id: 'editor',
                    keywords: ['editor, format, align, center'],
                    ligature: 'format_align_center',
                    codepoint: 'E234',
                },
                {
                    id: 'ic_format_align_justify',
                    name: 'format align justify',
                    group_id: 'editor',
                    keywords: ['editor, format, align, justify'],
                    ligature: 'format_align_justify',
                    codepoint: 'E235',
                },
                {
                    id: 'ic_format_align_left',
                    name: 'format align left',
                    group_id: 'editor',
                    keywords: ['editor, format, align, left'],
                    ligature: 'format_align_left',
                    codepoint: 'E236',
                },
                {
                    id: 'ic_format_align_right',
                    name: 'format align right',
                    group_id: 'editor',
                    keywords: ['editor, format, align, right'],
                    ligature: 'format_align_right',
                    codepoint: 'E237',
                },
                {
                    id: 'ic_format_bold',
                    name: 'format bold',
                    group_id: 'editor',
                    keywords: ['editor, format, bold'],
                    ligature: 'format_bold',
                    codepoint: 'E238',
                },
                {
                    id: 'ic_format_clear',
                    name: 'format clear',
                    group_id: 'editor',
                    keywords: ['editor, format, clear'],
                    ligature: 'format_clear',
                    codepoint: 'E239',
                },
                {
                    id: 'ic_format_color_fill',
                    name: 'format color fill',
                    group_id: 'editor',
                    keywords: ['editor, format, color, fill'],
                    ligature: 'format_color_fill',
                    codepoint: 'E23A',
                },
                {
                    id: 'ic_format_color_reset',
                    name: 'format color reset',
                    group_id: 'editor',
                    keywords: ['editor, format, color, reset'],
                    ligature: 'format_color_reset',
                    codepoint: 'E23B',
                },
                {
                    id: 'ic_format_color_text',
                    name: 'format color text',
                    group_id: 'editor',
                    keywords: ['editor, format, color, text'],
                    ligature: 'format_color_text',
                    codepoint: 'E23C',
                },
                {
                    id: 'ic_format_indent_decrease',
                    name: 'format indent decrease',
                    group_id: 'editor',
                    keywords: ['editor, format, indent, decrease'],
                    ligature: 'format_indent_decrease',
                    codepoint: 'E23D',
                },
                {
                    id: 'ic_format_indent_increase',
                    name: 'format indent increase',
                    group_id: 'editor',
                    keywords: ['editor, format, indent, increase'],
                    ligature: 'format_indent_increase',
                    codepoint: 'E23E',
                },
                {
                    id: 'ic_format_italic',
                    name: 'format italic',
                    group_id: 'editor',
                    keywords: ['editor, format, italic'],
                    ligature: 'format_italic',
                    codepoint: 'E23F',
                },
                {
                    id: 'ic_format_line_spacing',
                    name: 'format line spacing',
                    group_id: 'editor',
                    keywords: ['editor, format, line, spacing'],
                    ligature: 'format_line_spacing',
                    codepoint: 'E240',
                },
                {
                    id: 'ic_format_list_bulleted',
                    name: 'format list bulleted',
                    group_id: 'editor',
                    keywords: ['editor, format, list, bulleted'],
                    ligature: 'format_list_bulleted',
                    codepoint: 'E241',
                },
                {
                    id: 'ic_format_list_numbered',
                    name: 'format list numbered',
                    group_id: 'editor',
                    keywords: ['editor, format, list, numbered'],
                    ligature: 'format_list_numbered',
                    codepoint: 'E242',
                },
                {
                    id: 'ic_format_paint',
                    name: 'format paint',
                    group_id: 'editor',
                    keywords: ['editor, format, paint'],
                    ligature: 'format_paint',
                    codepoint: 'E243',
                },
                {
                    id: 'ic_format_quote',
                    name: 'format quote',
                    group_id: 'editor',
                    keywords: ['editor, format, quote'],
                    ligature: 'format_quote',
                    codepoint: 'E244',
                },
                {
                    id: 'ic_format_shapes',
                    name: 'format shapes',
                    group_id: 'editor',
                    keywords: ['editor, format, shapes'],
                    ligature: 'format_shapes',
                    codepoint: 'E25E',
                    is_new: true,
                },
                {
                    id: 'ic_format_size',
                    name: 'format size',
                    group_id: 'editor',
                    keywords: ['editor, format, size'],
                    ligature: 'format_size',
                    codepoint: 'E245',
                },
                {
                    id: 'ic_format_strikethrough',
                    name: 'format strikethrough',
                    group_id: 'editor',
                    keywords: ['editor, format, strikethrough'],
                    ligature: 'format_strikethrough',
                    codepoint: 'E246',
                },
                {
                    id: 'ic_format_textdirection_l_to_r',
                    name: 'format textdirection l to r',
                    group_id: 'editor',
                    keywords: ['editor, format, textdirection, to'],
                    ligature: 'format_textdirection_l_to_r',
                    codepoint: 'E247',
                },
                {
                    id: 'ic_format_textdirection_r_to_l',
                    name: 'format textdirection r to l',
                    group_id: 'editor',
                    keywords: ['editor, format, textdirection, to'],
                    ligature: 'format_textdirection_r_to_l',
                    codepoint: 'E248',
                },
                {
                    id: 'ic_format_underlined',
                    name: 'format underlined',
                    group_id: 'editor',
                    keywords: ['editor, format, underlined'],
                    ligature: 'format_underlined',
                    codepoint: 'E249',
                },
                {
                    id: 'ic_functions',
                    name: 'functions',
                    group_id: 'editor',
                    keywords: ['editor, functions'],
                    ligature: 'functions',
                    codepoint: 'E24A',
                },
                {
                    id: 'ic_highlight',
                    name: 'highlight',
                    group_id: 'editor',
                    keywords: ['editor, highlight'],
                    ligature: 'highlight',
                    codepoint: 'E25F',
                    is_new: true,
                },
                {
                    id: 'ic_insert_chart',
                    name: 'insert chart',
                    group_id: 'editor',
                    keywords: ['editor, insert, chart'],
                    ligature: 'insert_chart',
                    codepoint: 'E24B',
                },
                {
                    id: 'ic_insert_comment',
                    name: 'insert comment',
                    group_id: 'editor',
                    keywords: ['editor, insert, comment'],
                    ligature: 'insert_comment',
                    codepoint: 'E24C',
                },
                {
                    id: 'ic_insert_drive_file',
                    name: 'insert drive file',
                    group_id: 'editor',
                    keywords: ['editor, insert, drive, file'],
                    ligature: 'insert_drive_file',
                    codepoint: 'E24D',
                },
                {
                    id: 'ic_insert_emoticon',
                    name: 'insert emoticon',
                    group_id: 'editor',
                    keywords: ['editor, insert, emoticon'],
                    ligature: 'insert_emoticon',
                    codepoint: 'E24E',
                },
                {
                    id: 'ic_insert_invitation',
                    name: 'insert invitation',
                    group_id: 'editor',
                    keywords: ['editor, insert, invitation'],
                    ligature: 'insert_invitation',
                    codepoint: 'E24F',
                },
                {
                    id: 'ic_insert_link',
                    name: 'insert link',
                    group_id: 'editor',
                    keywords: ['editor, insert, link'],
                    ligature: 'insert_link',
                    codepoint: 'E250',
                },
                {
                    id: 'ic_insert_photo',
                    name: 'insert photo',
                    group_id: 'editor',
                    keywords: ['editor, insert, photo'],
                    ligature: 'insert_photo',
                    codepoint: 'E251',
                },
                {
                    id: 'ic_linear_scale',
                    name: 'linear scale',
                    group_id: 'editor',
                    keywords: ['editor, linear, scale'],
                    ligature: 'linear_scale',
                    codepoint: 'E260',
                    is_new: true,
                },
                {
                    id: 'ic_merge_type',
                    name: 'merge type',
                    group_id: 'editor',
                    keywords: ['editor, merge, type'],
                    ligature: 'merge_type',
                    codepoint: 'E252',
                },
                {
                    id: 'ic_mode_comment',
                    name: 'mode comment',
                    group_id: 'editor',
                    keywords: ['editor, mode, comment'],
                    ligature: 'mode_comment',
                    codepoint: 'E253',
                },
                {
                    id: 'ic_mode_edit',
                    name: 'mode edit',
                    group_id: 'editor',
                    keywords: ['editor, mode, edit'],
                    ligature: 'mode_edit',
                    codepoint: 'E254',
                },
                {
                    id: 'ic_money_off',
                    name: 'money off',
                    group_id: 'editor',
                    keywords: ['editor, money, off'],
                    ligature: 'money_off',
                    codepoint: 'E25C',
                },
                {
                    id: 'ic_publish',
                    name: 'publish',
                    group_id: 'editor',
                    keywords: ['editor, publish'],
                    ligature: 'publish',
                    codepoint: 'E255',
                },
                {
                    id: 'ic_short_text',
                    name: 'short text',
                    group_id: 'editor',
                    keywords: ['editor, short, text'],
                    ligature: 'short_text',
                    codepoint: 'E261',
                    is_new: true,
                },
                {
                    id: 'ic_space_bar',
                    name: 'space bar',
                    group_id: 'editor',
                    keywords: ['editor, space, bar'],
                    ligature: 'space_bar',
                    codepoint: 'E256',
                },
                {
                    id: 'ic_strikethrough_s',
                    name: 'strikethrough s',
                    group_id: 'editor',
                    keywords: ['editor, strikethrough'],
                    ligature: 'strikethrough_s',
                    codepoint: 'E257',
                },
                {
                    id: 'ic_text_fields',
                    name: 'text fields',
                    group_id: 'editor',
                    keywords: ['editor, text, fields'],
                    ligature: 'text_fields',
                    codepoint: 'E262',
                    is_new: true,
                },
                {
                    id: 'ic_vertical_align_bottom',
                    name: 'vertical align bottom',
                    group_id: 'editor',
                    keywords: ['editor, vertical, align, bottom'],
                    ligature: 'vertical_align_bottom',
                    codepoint: 'E258',
                },
                {
                    id: 'ic_vertical_align_center',
                    name: 'vertical align center',
                    group_id: 'editor',
                    keywords: ['editor, vertical, align, center'],
                    ligature: 'vertical_align_center',
                    codepoint: 'E259',
                },
                {
                    id: 'ic_vertical_align_top',
                    name: 'vertical align top',
                    group_id: 'editor',
                    keywords: ['editor, vertical, align, top'],
                    ligature: 'vertical_align_top',
                    codepoint: 'E25A',
                },
                {
                    id: 'ic_wrap_text',
                    name: 'wrap text',
                    group_id: 'editor',
                    keywords: ['editor, wrap, text'],
                    ligature: 'wrap_text',
                    codepoint: 'E25B',
                },
            ],
        },
        {
            name: 'file',
            key: 'file',
            icons: [
                {
                    id: 'ic_attachment',
                    name: 'attachment',
                    group_id: 'file',
                    keywords: ['file, attachment'],
                    ligature: 'attachment',
                    codepoint: 'E2BC',
                },
                {
                    id: 'ic_cloud',
                    name: 'cloud',
                    group_id: 'file',
                    keywords: ['file, cloud'],
                    ligature: 'cloud',
                    codepoint: 'E2BD',
                },
                {
                    id: 'ic_cloud_circle',
                    name: 'cloud circle',
                    group_id: 'file',
                    keywords: ['file, cloud, circle'],
                    ligature: 'cloud_circle',
                    codepoint: 'E2BE',
                },
                {
                    id: 'ic_cloud_done',
                    name: 'cloud done',
                    group_id: 'file',
                    keywords: ['file, cloud, done'],
                    ligature: 'cloud_done',
                    codepoint: 'E2BF',
                },
                {
                    id: 'ic_cloud_download',
                    name: 'cloud download',
                    group_id: 'file',
                    keywords: ['file, cloud, download'],
                    ligature: 'cloud_download',
                    codepoint: 'E2C0',
                },
                {
                    id: 'ic_cloud_off',
                    name: 'cloud off',
                    group_id: 'file',
                    keywords: ['file, cloud, off'],
                    ligature: 'cloud_off',
                    codepoint: 'E2C1',
                },
                {
                    id: 'ic_cloud_queue',
                    name: 'cloud queue',
                    group_id: 'file',
                    keywords: ['file, cloud, queue'],
                    ligature: 'cloud_queue',
                    codepoint: 'E2C2',
                },
                {
                    id: 'ic_cloud_upload',
                    name: 'cloud upload',
                    group_id: 'file',
                    keywords: ['file, cloud, upload'],
                    ligature: 'cloud_upload',
                    codepoint: 'E2C3',
                },
                {
                    id: 'ic_create_new_folder',
                    name: 'create new folder',
                    group_id: 'file',
                    keywords: ['file, create, new, folder'],
                    ligature: 'create_new_folder',
                    codepoint: 'E2CC',
                    is_new: true,
                },
                {
                    id: 'ic_file_download',
                    name: 'file download',
                    group_id: 'file',
                    keywords: ['file, file, download'],
                    ligature: 'file_download',
                    codepoint: 'E2C4',
                },
                {
                    id: 'ic_file_upload',
                    name: 'file upload',
                    group_id: 'file',
                    keywords: ['file, file, upload'],
                    ligature: 'file_upload',
                    codepoint: 'E2C6',
                },
                {
                    id: 'ic_folder',
                    name: 'folder',
                    group_id: 'file',
                    keywords: ['file, folder'],
                    ligature: 'folder',
                    codepoint: 'E2C7',
                },
                {
                    id: 'ic_folder_open',
                    name: 'folder open',
                    group_id: 'file',
                    keywords: ['file, folder, open'],
                    ligature: 'folder_open',
                    codepoint: 'E2C8',
                },
                {
                    id: 'ic_folder_shared',
                    name: 'folder shared',
                    group_id: 'file',
                    keywords: ['file, folder, shared'],
                    ligature: 'folder_shared',
                    codepoint: 'E2C9',
                },
            ],
        },
        {
            name: 'hardware',
            key: 'hardware',
            icons: [
                {
                    id: 'ic_cast',
                    name: 'cast',
                    group_id: 'hardware',
                    keywords: ['hardware, cast'],
                    ligature: 'cast',
                    codepoint: 'E307',
                },
                {
                    id: 'ic_cast_connected',
                    name: 'cast connected',
                    group_id: 'hardware',
                    keywords: ['hardware, cast, connected'],
                    ligature: 'cast_connected',
                    codepoint: 'E308',
                },
                {
                    id: 'ic_computer',
                    name: 'computer',
                    group_id: 'hardware',
                    keywords: ['hardware, computer'],
                    ligature: 'computer',
                    codepoint: 'E30A',
                },
                {
                    id: 'ic_desktop_mac',
                    name: 'desktop mac',
                    group_id: 'hardware',
                    keywords: ['hardware, desktop, mac'],
                    ligature: 'desktop_mac',
                    codepoint: 'E30B',
                },
                {
                    id: 'ic_desktop_windows',
                    name: 'desktop windows',
                    group_id: 'hardware',
                    keywords: ['hardware, desktop, windows'],
                    ligature: 'desktop_windows',
                    codepoint: 'E30C',
                },
                {
                    id: 'ic_developer_board',
                    name: 'developer board',
                    group_id: 'hardware',
                    keywords: ['hardware, developer, board'],
                    ligature: 'developer_board',
                    codepoint: 'E30D',
                },
                {
                    id: 'ic_device_hub',
                    name: 'device hub',
                    group_id: 'hardware',
                    keywords: ['hardware, device, hub'],
                    ligature: 'device_hub',
                    codepoint: 'E335',
                },
                {
                    id: 'ic_devices_other',
                    name: 'devices other',
                    group_id: 'hardware',
                    keywords: ['hardware, devices, other'],
                    ligature: 'devices_other',
                    codepoint: 'E337',
                    is_new: true,
                },
                {
                    id: 'ic_dock',
                    name: 'dock',
                    group_id: 'hardware',
                    keywords: ['hardware, dock'],
                    ligature: 'dock',
                    codepoint: 'E30E',
                },
                {
                    id: 'ic_gamepad',
                    name: 'gamepad',
                    group_id: 'hardware',
                    keywords: ['hardware, gamepad'],
                    ligature: 'gamepad',
                    codepoint: 'E30F',
                },
                {
                    id: 'ic_headset',
                    name: 'headset',
                    group_id: 'hardware',
                    keywords: ['hardware, headset'],
                    ligature: 'headset',
                    codepoint: 'E310',
                },
                {
                    id: 'ic_headset_mic',
                    name: 'headset mic',
                    group_id: 'hardware',
                    keywords: ['hardware, headset, mic'],
                    ligature: 'headset_mic',
                    codepoint: 'E311',
                },
                {
                    id: 'ic_keyboard',
                    name: 'keyboard',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard'],
                    ligature: 'keyboard',
                    codepoint: 'E312',
                },
                {
                    id: 'ic_keyboard_arrow_down',
                    name: 'keyboard arrow down',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, arrow, down'],
                    ligature: 'keyboard_arrow_down',
                    codepoint: 'E313',
                },
                {
                    id: 'ic_keyboard_arrow_left',
                    name: 'keyboard arrow left',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, arrow, left'],
                    ligature: 'keyboard_arrow_left',
                    codepoint: 'E314',
                },
                {
                    id: 'ic_keyboard_arrow_right',
                    name: 'keyboard arrow right',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, arrow, right'],
                    ligature: 'keyboard_arrow_right',
                    codepoint: 'E315',
                },
                {
                    id: 'ic_keyboard_arrow_up',
                    name: 'keyboard arrow up',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, arrow, up'],
                    ligature: 'keyboard_arrow_up',
                    codepoint: 'E316',
                },
                {
                    id: 'ic_keyboard_backspace',
                    name: 'keyboard backspace',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, backspace'],
                    ligature: 'keyboard_backspace',
                    codepoint: 'E317',
                },
                {
                    id: 'ic_keyboard_capslock',
                    name: 'keyboard capslock',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, capslock'],
                    ligature: 'keyboard_capslock',
                    codepoint: 'E318',
                },
                {
                    id: 'ic_keyboard_hide',
                    name: 'keyboard hide',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, hide'],
                    ligature: 'keyboard_hide',
                    codepoint: 'E31A',
                },
                {
                    id: 'ic_keyboard_return',
                    name: 'keyboard return',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, return'],
                    ligature: 'keyboard_return',
                    codepoint: 'E31B',
                },
                {
                    id: 'ic_keyboard_tab',
                    name: 'keyboard tab',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, tab'],
                    ligature: 'keyboard_tab',
                    codepoint: 'E31C',
                },
                {
                    id: 'ic_keyboard_voice',
                    name: 'keyboard voice',
                    group_id: 'hardware',
                    keywords: ['hardware, keyboard, voice'],
                    ligature: 'keyboard_voice',
                    codepoint: 'E31D',
                },
                {
                    id: 'ic_laptop',
                    name: 'laptop',
                    group_id: 'hardware',
                    keywords: ['hardware, laptop'],
                    ligature: 'laptop',
                    codepoint: 'E31E',
                },
                {
                    id: 'ic_laptop_chromebook',
                    name: 'laptop chromebook',
                    group_id: 'hardware',
                    keywords: ['hardware, laptop, chromebook'],
                    ligature: 'laptop_chromebook',
                    codepoint: 'E31F',
                },
                {
                    id: 'ic_laptop_mac',
                    name: 'laptop mac',
                    group_id: 'hardware',
                    keywords: ['hardware, laptop, mac'],
                    ligature: 'laptop_mac',
                    codepoint: 'E320',
                },
                {
                    id: 'ic_laptop_windows',
                    name: 'laptop windows',
                    group_id: 'hardware',
                    keywords: ['hardware, laptop, windows'],
                    ligature: 'laptop_windows',
                    codepoint: 'E321',
                },
                {
                    id: 'ic_memory',
                    name: 'memory',
                    group_id: 'hardware',
                    keywords: ['hardware, memory'],
                    ligature: 'memory',
                    codepoint: 'E322',
                },
                {
                    id: 'ic_mouse',
                    name: 'mouse',
                    group_id: 'hardware',
                    keywords: ['hardware, mouse'],
                    ligature: 'mouse',
                    codepoint: 'E323',
                },
                {
                    id: 'ic_phone_android',
                    name: 'phone android',
                    group_id: 'hardware',
                    keywords: ['hardware, phone, android'],
                    ligature: 'phone_android',
                    codepoint: 'E324',
                },
                {
                    id: 'ic_phone_iphone',
                    name: 'phone iphone',
                    group_id: 'hardware',
                    keywords: ['hardware, phone, iphone'],
                    ligature: 'phone_iphone',
                    codepoint: 'E325',
                },
                {
                    id: 'ic_phonelink',
                    name: 'phonelink',
                    group_id: 'hardware',
                    keywords: ['hardware, phonelink'],
                    ligature: 'phonelink',
                    codepoint: 'E326',
                },
                {
                    id: 'ic_phonelink_off',
                    name: 'phonelink off',
                    group_id: 'hardware',
                    keywords: ['hardware, phonelink, off'],
                    ligature: 'phonelink_off',
                    codepoint: 'E327',
                },
                {
                    id: 'ic_power_input',
                    name: 'power input',
                    group_id: 'hardware',
                    keywords: ['hardware, power, input'],
                    ligature: 'power_input',
                    codepoint: 'E336',
                },
                {
                    id: 'ic_router',
                    name: 'router',
                    group_id: 'hardware',
                    keywords: ['hardware, router'],
                    ligature: 'router',
                    codepoint: 'E328',
                },
                {
                    id: 'ic_scanner',
                    name: 'scanner',
                    group_id: 'hardware',
                    keywords: ['hardware, scanner'],
                    ligature: 'scanner',
                    codepoint: 'E329',
                },
                {
                    id: 'ic_security',
                    name: 'security',
                    group_id: 'hardware',
                    keywords: ['hardware, security'],
                    ligature: 'security',
                    codepoint: 'E32A',
                },
                {
                    id: 'ic_sim_card',
                    name: 'sim card',
                    group_id: 'hardware',
                    keywords: ['hardware, sim, card'],
                    ligature: 'sim_card',
                    codepoint: 'E32B',
                },
                {
                    id: 'ic_smartphone',
                    name: 'smartphone',
                    group_id: 'hardware',
                    keywords: ['hardware, smartphone'],
                    ligature: 'smartphone',
                    codepoint: 'E32C',
                },
                {
                    id: 'ic_speaker',
                    name: 'speaker',
                    group_id: 'hardware',
                    keywords: ['hardware, speaker'],
                    ligature: 'speaker',
                    codepoint: 'E32D',
                },
                {
                    id: 'ic_speaker_group',
                    name: 'speaker group',
                    group_id: 'hardware',
                    keywords: ['hardware, speaker, group'],
                    ligature: 'speaker_group',
                    codepoint: 'E32E',
                },
                {
                    id: 'ic_tablet',
                    name: 'tablet',
                    group_id: 'hardware',
                    keywords: ['hardware, tablet'],
                    ligature: 'tablet',
                    codepoint: 'E32F',
                },
                {
                    id: 'ic_tablet_android',
                    name: 'tablet android',
                    group_id: 'hardware',
                    keywords: ['hardware, tablet, android'],
                    ligature: 'tablet_android',
                    codepoint: 'E330',
                },
                {
                    id: 'ic_tablet_mac',
                    name: 'tablet mac',
                    group_id: 'hardware',
                    keywords: ['hardware, tablet, mac'],
                    ligature: 'tablet_mac',
                    codepoint: 'E331',
                },
                {
                    id: 'ic_toys',
                    name: 'toys',
                    group_id: 'hardware',
                    keywords: ['hardware, toys'],
                    ligature: 'toys',
                    codepoint: 'E332',
                },
                {
                    id: 'ic_tv',
                    name: 'tv',
                    group_id: 'hardware',
                    keywords: ['hardware, tv'],
                    ligature: 'tv',
                    codepoint: 'E333',
                },
                {
                    id: 'ic_videogame_asset',
                    name: 'videogame asset',
                    group_id: 'hardware',
                    keywords: ['hardware, videogame, asset'],
                    ligature: 'videogame_asset',
                    codepoint: 'E338',
                    is_new: true,
                },
                {
                    id: 'ic_watch',
                    name: 'watch',
                    group_id: 'hardware',
                    keywords: ['hardware, watch'],
                    ligature: 'watch',
                    codepoint: 'E334',
                },
            ],
        },
        {
            name: 'image',
            key: 'image',
            icons: [
                {
                    id: 'ic_add_a_photo',
                    name: 'add a photo',
                    group_id: 'image',
                    keywords: ['image, add, photo'],
                    ligature: 'add_a_photo',
                    codepoint: 'E439',
                    is_new: true,
                },
                {
                    id: 'ic_add_to_photos',
                    name: 'add to photos',
                    group_id: 'image',
                    keywords: ['image, add, to, photos'],
                    ligature: 'add_to_photos',
                    codepoint: 'E39D',
                },
                {
                    id: 'ic_adjust',
                    name: 'adjust',
                    group_id: 'image',
                    keywords: ['image, adjust'],
                    ligature: 'adjust',
                    codepoint: 'E39E',
                },
                {
                    id: 'ic_assistant',
                    name: 'assistant',
                    group_id: 'image',
                    keywords: ['image, assistant'],
                    ligature: 'assistant',
                    codepoint: 'E39F',
                },
                {
                    id: 'ic_assistant_photo',
                    name: 'assistant photo',
                    group_id: 'image',
                    keywords: ['image, assistant, photo'],
                    ligature: 'assistant_photo',
                    codepoint: 'E3A0',
                },
                {
                    id: 'ic_audiotrack',
                    name: 'audiotrack',
                    group_id: 'image',
                    keywords: ['image, audiotrack'],
                    ligature: 'audiotrack',
                    codepoint: 'E3A1',
                },
                {
                    id: 'ic_blur_circular',
                    name: 'blur circular',
                    group_id: 'image',
                    keywords: ['image, blur, circular'],
                    ligature: 'blur_circular',
                    codepoint: 'E3A2',
                },
                {
                    id: 'ic_blur_linear',
                    name: 'blur linear',
                    group_id: 'image',
                    keywords: ['image, blur, linear'],
                    ligature: 'blur_linear',
                    codepoint: 'E3A3',
                },
                {
                    id: 'ic_blur_off',
                    name: 'blur off',
                    group_id: 'image',
                    keywords: ['image, blur, off'],
                    ligature: 'blur_off',
                    codepoint: 'E3A4',
                },
                {
                    id: 'ic_blur_on',
                    name: 'blur on',
                    group_id: 'image',
                    keywords: ['image, blur, on'],
                    ligature: 'blur_on',
                    codepoint: 'E3A5',
                },
                {
                    id: 'ic_brightness_1',
                    name: 'brightness 1',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_1',
                    codepoint: 'E3A6',
                },
                {
                    id: 'ic_brightness_2',
                    name: 'brightness 2',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_2',
                    codepoint: 'E3A7',
                },
                {
                    id: 'ic_brightness_3',
                    name: 'brightness 3',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_3',
                    codepoint: 'E3A8',
                },
                {
                    id: 'ic_brightness_4',
                    name: 'brightness 4',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_4',
                    codepoint: 'E3A9',
                },
                {
                    id: 'ic_brightness_5',
                    name: 'brightness 5',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_5',
                    codepoint: 'E3AA',
                },
                {
                    id: 'ic_brightness_6',
                    name: 'brightness 6',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_6',
                    codepoint: 'E3AB',
                },
                {
                    id: 'ic_brightness_7',
                    name: 'brightness 7',
                    group_id: 'image',
                    keywords: ['image, brightness'],
                    ligature: 'brightness_7',
                    codepoint: 'E3AC',
                },
                {
                    id: 'ic_broken_image',
                    name: 'broken image',
                    group_id: 'image',
                    keywords: ['image, broken, image'],
                    ligature: 'broken_image',
                    codepoint: 'E3AD',
                },
                {
                    id: 'ic_brush',
                    name: 'brush',
                    group_id: 'image',
                    keywords: ['image, brush'],
                    ligature: 'brush',
                    codepoint: 'E3AE',
                },
                {
                    id: 'ic_camera',
                    name: 'camera',
                    group_id: 'image',
                    keywords: ['image, camera'],
                    ligature: 'camera',
                    codepoint: 'E3AF',
                },
                {
                    id: 'ic_camera_alt',
                    name: 'camera alt',
                    group_id: 'image',
                    keywords: ['image, camera, alt'],
                    ligature: 'camera_alt',
                    codepoint: 'E3B0',
                },
                {
                    id: 'ic_camera_front',
                    name: 'camera front',
                    group_id: 'image',
                    keywords: ['image, camera, front'],
                    ligature: 'camera_front',
                    codepoint: 'E3B1',
                },
                {
                    id: 'ic_camera_rear',
                    name: 'camera rear',
                    group_id: 'image',
                    keywords: ['image, camera, rear'],
                    ligature: 'camera_rear',
                    codepoint: 'E3B2',
                },
                {
                    id: 'ic_camera_roll',
                    name: 'camera roll',
                    group_id: 'image',
                    keywords: ['image, camera, roll'],
                    ligature: 'camera_roll',
                    codepoint: 'E3B3',
                },
                {
                    id: 'ic_center_focus_strong',
                    name: 'center focus strong',
                    group_id: 'image',
                    keywords: ['image, center, focus, strong'],
                    ligature: 'center_focus_strong',
                    codepoint: 'E3B4',
                },
                {
                    id: 'ic_center_focus_weak',
                    name: 'center focus weak',
                    group_id: 'image',
                    keywords: ['image, center, focus, weak'],
                    ligature: 'center_focus_weak',
                    codepoint: 'E3B5',
                },
                {
                    id: 'ic_collections',
                    name: 'collections',
                    group_id: 'image',
                    keywords: ['image, collections'],
                    ligature: 'collections',
                    codepoint: 'E3B6',
                },
                {
                    id: 'ic_collections_bookmark',
                    name: 'collections bookmark',
                    group_id: 'image',
                    keywords: ['image, collections, bookmark'],
                    ligature: 'collections_bookmark',
                    codepoint: 'E431',
                },
                {
                    id: 'ic_color_lens',
                    name: 'color lens',
                    group_id: 'image',
                    keywords: ['image, color, lens'],
                    ligature: 'color_lens',
                    codepoint: 'E3B7',
                },
                {
                    id: 'ic_colorize',
                    name: 'colorize',
                    group_id: 'image',
                    keywords: ['image, colorize'],
                    ligature: 'colorize',
                    codepoint: 'E3B8',
                },
                {
                    id: 'ic_compare',
                    name: 'compare',
                    group_id: 'image',
                    keywords: ['image, compare'],
                    ligature: 'compare',
                    codepoint: 'E3B9',
                },
                {
                    id: 'ic_control_point',
                    name: 'control point',
                    group_id: 'image',
                    keywords: ['image, control, point'],
                    ligature: 'control_point',
                    codepoint: 'E3BA',
                },
                {
                    id: 'ic_control_point_duplicate',
                    name: 'control point duplicate',
                    group_id: 'image',
                    keywords: ['image, control, point, duplicate'],
                    ligature: 'control_point_duplicate',
                    codepoint: 'E3BB',
                },
                {
                    id: 'ic_crop',
                    name: 'crop',
                    group_id: 'image',
                    keywords: ['image, crop'],
                    ligature: 'crop',
                    codepoint: 'E3BE',
                },
                {
                    id: 'ic_crop_16_9',
                    name: 'crop 16 9',
                    group_id: 'image',
                    keywords: ['image, crop, 16'],
                    ligature: 'crop_16_9',
                    codepoint: 'E3BC',
                },
                {
                    id: 'ic_crop_3_2',
                    name: 'crop 3 2',
                    group_id: 'image',
                    keywords: ['image, crop'],
                    ligature: 'crop_3_2',
                    codepoint: 'E3BD',
                },
                {
                    id: 'ic_crop_5_4',
                    name: 'crop 5 4',
                    group_id: 'image',
                    keywords: ['image, crop'],
                    ligature: 'crop_5_4',
                    codepoint: 'E3BF',
                },
                {
                    id: 'ic_crop_7_5',
                    name: 'crop 7 5',
                    group_id: 'image',
                    keywords: ['image, crop'],
                    ligature: 'crop_7_5',
                    codepoint: 'E3C0',
                },
                {
                    id: 'ic_crop_din',
                    name: 'crop din',
                    group_id: 'image',
                    keywords: ['image, crop, din'],
                    ligature: 'crop_din',
                    codepoint: 'E3C1',
                },
                {
                    id: 'ic_crop_free',
                    name: 'crop free',
                    group_id: 'image',
                    keywords: ['image, crop, free'],
                    ligature: 'crop_free',
                    codepoint: 'E3C2',
                },
                {
                    id: 'ic_crop_landscape',
                    name: 'crop landscape',
                    group_id: 'image',
                    keywords: ['image, crop, landscape'],
                    ligature: 'crop_landscape',
                    codepoint: 'E3C3',
                },
                {
                    id: 'ic_crop_original',
                    name: 'crop original',
                    group_id: 'image',
                    keywords: ['image, crop, original'],
                    ligature: 'crop_original',
                    codepoint: 'E3C4',
                },
                {
                    id: 'ic_crop_portrait',
                    name: 'crop portrait',
                    group_id: 'image',
                    keywords: ['image, crop, portrait'],
                    ligature: 'crop_portrait',
                    codepoint: 'E3C5',
                },
                {
                    id: 'ic_crop_rotate',
                    name: 'crop rotate',
                    group_id: 'image',
                    keywords: ['image, crop, rotate'],
                    ligature: 'crop_rotate',
                    codepoint: 'E437',
                    is_new: true,
                },
                {
                    id: 'ic_crop_square',
                    name: 'crop square',
                    group_id: 'image',
                    keywords: ['image, crop, square'],
                    ligature: 'crop_square',
                    codepoint: 'E3C6',
                },
                {
                    id: 'ic_dehaze',
                    name: 'dehaze',
                    group_id: 'image',
                    keywords: ['image, dehaze'],
                    ligature: 'dehaze',
                    codepoint: 'E3C7',
                },
                {
                    id: 'ic_details',
                    name: 'details',
                    group_id: 'image',
                    keywords: ['image, details'],
                    ligature: 'details',
                    codepoint: 'E3C8',
                },
                {
                    id: 'ic_edit',
                    name: 'edit',
                    group_id: 'image',
                    keywords: ['image, edit'],
                    ligature: 'edit',
                    codepoint: 'E3C9',
                },
                {
                    id: 'ic_exposure',
                    name: 'exposure',
                    group_id: 'image',
                    keywords: ['image, exposure'],
                    ligature: 'exposure',
                    codepoint: 'E3CA',
                },
                {
                    id: 'ic_exposure_neg_1',
                    name: 'exposure neg 1',
                    group_id: 'image',
                    keywords: ['image, exposure, neg'],
                    ligature: 'exposure_neg_1',
                    codepoint: 'E3CB',
                },
                {
                    id: 'ic_exposure_neg_2',
                    name: 'exposure neg 2',
                    group_id: 'image',
                    keywords: ['image, exposure, neg'],
                    ligature: 'exposure_neg_2',
                    codepoint: 'E3CC',
                },
                {
                    id: 'ic_exposure_plus_1',
                    name: 'exposure plus 1',
                    group_id: 'image',
                    keywords: ['image, exposure, plus'],
                    ligature: 'exposure_plus_1',
                    codepoint: 'E3CD',
                },
                {
                    id: 'ic_exposure_plus_2',
                    name: 'exposure plus 2',
                    group_id: 'image',
                    keywords: ['image, exposure, plus'],
                    ligature: 'exposure_plus_2',
                    codepoint: 'E3CE',
                },
                {
                    id: 'ic_exposure_zero',
                    name: 'exposure zero',
                    group_id: 'image',
                    keywords: ['image, exposure, zero'],
                    ligature: 'exposure_zero',
                    codepoint: 'E3CF',
                },
                {
                    id: 'ic_filter',
                    name: 'filter',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter',
                    codepoint: 'E3D3',
                },
                {
                    id: 'ic_filter_1',
                    name: 'filter 1',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_1',
                    codepoint: 'E3D0',
                },
                {
                    id: 'ic_filter_2',
                    name: 'filter 2',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_2',
                    codepoint: 'E3D1',
                },
                {
                    id: 'ic_filter_3',
                    name: 'filter 3',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_3',
                    codepoint: 'E3D2',
                },
                {
                    id: 'ic_filter_4',
                    name: 'filter 4',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_4',
                    codepoint: 'E3D4',
                },
                {
                    id: 'ic_filter_5',
                    name: 'filter 5',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_5',
                    codepoint: 'E3D5',
                },
                {
                    id: 'ic_filter_6',
                    name: 'filter 6',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_6',
                    codepoint: 'E3D6',
                },
                {
                    id: 'ic_filter_7',
                    name: 'filter 7',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_7',
                    codepoint: 'E3D7',
                },
                {
                    id: 'ic_filter_8',
                    name: 'filter 8',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_8',
                    codepoint: 'E3D8',
                },
                {
                    id: 'ic_filter_9',
                    name: 'filter 9',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_9',
                    codepoint: 'E3D9',
                },
                {
                    id: 'ic_filter_9_plus',
                    name: 'filter 9 plus',
                    group_id: 'image',
                    keywords: ['image, filter, plus'],
                    ligature: 'filter_9_plus',
                    codepoint: 'E3DA',
                },
                {
                    id: 'ic_filter_b_and_w',
                    name: 'filter b and w',
                    group_id: 'image',
                    keywords: ['image, filter'],
                    ligature: 'filter_b_and_w',
                    codepoint: 'E3DB',
                },
                {
                    id: 'ic_filter_center_focus',
                    name: 'filter center focus',
                    group_id: 'image',
                    keywords: ['image, filter, center, focus'],
                    ligature: 'filter_center_focus',
                    codepoint: 'E3DC',
                },
                {
                    id: 'ic_filter_drama',
                    name: 'filter drama',
                    group_id: 'image',
                    keywords: ['image, filter, drama'],
                    ligature: 'filter_drama',
                    codepoint: 'E3DD',
                },
                {
                    id: 'ic_filter_frames',
                    name: 'filter frames',
                    group_id: 'image',
                    keywords: ['image, filter, frames'],
                    ligature: 'filter_frames',
                    codepoint: 'E3DE',
                },
                {
                    id: 'ic_filter_hdr',
                    name: 'filter hdr',
                    group_id: 'image',
                    keywords: ['image, filter, hdr'],
                    ligature: 'filter_hdr',
                    codepoint: 'E3DF',
                },
                {
                    id: 'ic_filter_none',
                    name: 'filter none',
                    group_id: 'image',
                    keywords: ['image, filter, none'],
                    ligature: 'filter_none',
                    codepoint: 'E3E0',
                },
                {
                    id: 'ic_filter_tilt_shift',
                    name: 'filter tilt shift',
                    group_id: 'image',
                    keywords: ['image, filter, tilt, shift'],
                    ligature: 'filter_tilt_shift',
                    codepoint: 'E3E2',
                },
                {
                    id: 'ic_filter_vintage',
                    name: 'filter vintage',
                    group_id: 'image',
                    keywords: ['image, filter, vintage'],
                    ligature: 'filter_vintage',
                    codepoint: 'E3E3',
                },
                {
                    id: 'ic_flare',
                    name: 'flare',
                    group_id: 'image',
                    keywords: ['image, flare'],
                    ligature: 'flare',
                    codepoint: 'E3E4',
                },
                {
                    id: 'ic_flash_auto',
                    name: 'flash auto',
                    group_id: 'image',
                    keywords: ['image, flash, auto'],
                    ligature: 'flash_auto',
                    codepoint: 'E3E5',
                },
                {
                    id: 'ic_flash_off',
                    name: 'flash off',
                    group_id: 'image',
                    keywords: ['image, flash, off'],
                    ligature: 'flash_off',
                    codepoint: 'E3E6',
                },
                {
                    id: 'ic_flash_on',
                    name: 'flash on',
                    group_id: 'image',
                    keywords: ['image, flash, on'],
                    ligature: 'flash_on',
                    codepoint: 'E3E7',
                },
                {
                    id: 'ic_flip',
                    name: 'flip',
                    group_id: 'image',
                    keywords: ['image, flip'],
                    ligature: 'flip',
                    codepoint: 'E3E8',
                },
                {
                    id: 'ic_gradient',
                    name: 'gradient',
                    group_id: 'image',
                    keywords: ['image, gradient'],
                    ligature: 'gradient',
                    codepoint: 'E3E9',
                },
                {
                    id: 'ic_grain',
                    name: 'grain',
                    group_id: 'image',
                    keywords: ['image, grain'],
                    ligature: 'grain',
                    codepoint: 'E3EA',
                },
                {
                    id: 'ic_grid_off',
                    name: 'grid off',
                    group_id: 'image',
                    keywords: ['image, grid, off'],
                    ligature: 'grid_off',
                    codepoint: 'E3EB',
                },
                {
                    id: 'ic_grid_on',
                    name: 'grid on',
                    group_id: 'image',
                    keywords: ['image, grid, on'],
                    ligature: 'grid_on',
                    codepoint: 'E3EC',
                },
                {
                    id: 'ic_hdr_off',
                    name: 'hdr off',
                    group_id: 'image',
                    keywords: ['image, hdr, off'],
                    ligature: 'hdr_off',
                    codepoint: 'E3ED',
                },
                {
                    id: 'ic_hdr_on',
                    name: 'hdr on',
                    group_id: 'image',
                    keywords: ['image, hdr, on'],
                    ligature: 'hdr_on',
                    codepoint: 'E3EE',
                },
                {
                    id: 'ic_hdr_strong',
                    name: 'hdr strong',
                    group_id: 'image',
                    keywords: ['image, hdr, strong'],
                    ligature: 'hdr_strong',
                    codepoint: 'E3F1',
                },
                {
                    id: 'ic_hdr_weak',
                    name: 'hdr weak',
                    group_id: 'image',
                    keywords: ['image, hdr, weak'],
                    ligature: 'hdr_weak',
                    codepoint: 'E3F2',
                },
                {
                    id: 'ic_healing',
                    name: 'healing',
                    group_id: 'image',
                    keywords: ['image, healing'],
                    ligature: 'healing',
                    codepoint: 'E3F3',
                },
                {
                    id: 'ic_image',
                    name: 'image',
                    group_id: 'image',
                    keywords: ['image, image'],
                    ligature: 'image',
                    codepoint: 'E3F4',
                },
                {
                    id: 'ic_image_aspect_ratio',
                    name: 'image aspect ratio',
                    group_id: 'image',
                    keywords: ['image, image, aspect, ratio'],
                    ligature: 'image_aspect_ratio',
                    codepoint: 'E3F5',
                },
                {
                    id: 'ic_iso',
                    name: 'iso',
                    group_id: 'image',
                    keywords: ['image, iso'],
                    ligature: 'iso',
                    codepoint: 'E3F6',
                },
                {
                    id: 'ic_landscape',
                    name: 'landscape',
                    group_id: 'image',
                    keywords: ['image, landscape'],
                    ligature: 'landscape',
                    codepoint: 'E3F7',
                },
                {
                    id: 'ic_leak_add',
                    name: 'leak add',
                    group_id: 'image',
                    keywords: ['image, leak, add'],
                    ligature: 'leak_add',
                    codepoint: 'E3F8',
                },
                {
                    id: 'ic_leak_remove',
                    name: 'leak remove',
                    group_id: 'image',
                    keywords: ['image, leak, remove'],
                    ligature: 'leak_remove',
                    codepoint: 'E3F9',
                },
                {
                    id: 'ic_lens',
                    name: 'lens',
                    group_id: 'image',
                    keywords: ['image, lens'],
                    ligature: 'lens',
                    codepoint: 'E3FA',
                },
                {
                    id: 'ic_linked_camera',
                    name: 'linked camera',
                    group_id: 'image',
                    keywords: ['image, linked, camera'],
                    ligature: 'linked_camera',
                    codepoint: 'E438',
                    is_new: true,
                },
                {
                    id: 'ic_looks',
                    name: 'looks',
                    group_id: 'image',
                    keywords: ['image, looks'],
                    ligature: 'looks',
                    codepoint: 'E3FC',
                },
                {
                    id: 'ic_looks_3',
                    name: 'looks 3',
                    group_id: 'image',
                    keywords: ['image, looks'],
                    ligature: 'looks_3',
                    codepoint: 'E3FB',
                },
                {
                    id: 'ic_looks_4',
                    name: 'looks 4',
                    group_id: 'image',
                    keywords: ['image, looks'],
                    ligature: 'looks_4',
                    codepoint: 'E3FD',
                },
                {
                    id: 'ic_looks_5',
                    name: 'looks 5',
                    group_id: 'image',
                    keywords: ['image, looks'],
                    ligature: 'looks_5',
                    codepoint: 'E3FE',
                },
                {
                    id: 'ic_looks_6',
                    name: 'looks 6',
                    group_id: 'image',
                    keywords: ['image, looks'],
                    ligature: 'looks_6',
                    codepoint: 'E3FF',
                },
                {
                    id: 'ic_looks_one',
                    name: 'looks one',
                    group_id: 'image',
                    keywords: ['image, looks, one'],
                    ligature: 'looks_one',
                    codepoint: 'E400',
                },
                {
                    id: 'ic_looks_two',
                    name: 'looks two',
                    group_id: 'image',
                    keywords: ['image, looks, two'],
                    ligature: 'looks_two',
                    codepoint: 'E401',
                },
                {
                    id: 'ic_loupe',
                    name: 'loupe',
                    group_id: 'image',
                    keywords: ['image, loupe'],
                    ligature: 'loupe',
                    codepoint: 'E402',
                },
                {
                    id: 'ic_monochrome_photos',
                    name: 'monochrome photos',
                    group_id: 'image',
                    keywords: ['image, monochrome, photos'],
                    ligature: 'monochrome_photos',
                    codepoint: 'E403',
                },
                {
                    id: 'ic_movie_creation',
                    name: 'movie creation',
                    group_id: 'image',
                    keywords: ['image, movie, creation'],
                    ligature: 'movie_creation',
                    codepoint: 'E404',
                },
                {
                    id: 'ic_movie_filter',
                    name: 'movie filter',
                    group_id: 'image',
                    keywords: ['image, movie, filter'],
                    ligature: 'movie_filter',
                    codepoint: 'E43A',
                    is_new: true,
                },
                {
                    id: 'ic_music_note',
                    name: 'music note',
                    group_id: 'image',
                    keywords: ['image, music, note'],
                    ligature: 'music_note',
                    codepoint: 'E405',
                },
                {
                    id: 'ic_nature',
                    name: 'nature',
                    group_id: 'image',
                    keywords: ['image, nature'],
                    ligature: 'nature',
                    codepoint: 'E406',
                },
                {
                    id: 'ic_nature_people',
                    name: 'nature people',
                    group_id: 'image',
                    keywords: ['image, nature, people'],
                    ligature: 'nature_people',
                    codepoint: 'E407',
                },
                {
                    id: 'ic_navigate_before',
                    name: 'navigate before',
                    group_id: 'image',
                    keywords: ['image, navigate, before'],
                    ligature: 'navigate_before',
                    codepoint: 'E408',
                },
                {
                    id: 'ic_navigate_next',
                    name: 'navigate next',
                    group_id: 'image',
                    keywords: ['image, navigate, next'],
                    ligature: 'navigate_next',
                    codepoint: 'E409',
                },
                {
                    id: 'ic_palette',
                    name: 'palette',
                    group_id: 'image',
                    keywords: ['image, palette'],
                    ligature: 'palette',
                    codepoint: 'E40A',
                },
                {
                    id: 'ic_panorama',
                    name: 'panorama',
                    group_id: 'image',
                    keywords: ['image, panorama'],
                    ligature: 'panorama',
                    codepoint: 'E40B',
                },
                {
                    id: 'ic_panorama_fish_eye',
                    name: 'panorama fish eye',
                    group_id: 'image',
                    keywords: ['image, panorama, fish, eye'],
                    ligature: 'panorama_fish_eye',
                    codepoint: 'E40C',
                },
                {
                    id: 'ic_panorama_horizontal',
                    name: 'panorama horizontal',
                    group_id: 'image',
                    keywords: ['image, panorama, horizontal'],
                    ligature: 'panorama_horizontal',
                    codepoint: 'E40D',
                },
                {
                    id: 'ic_panorama_vertical',
                    name: 'panorama vertical',
                    group_id: 'image',
                    keywords: ['image, panorama, vertical'],
                    ligature: 'panorama_vertical',
                    codepoint: 'E40E',
                },
                {
                    id: 'ic_panorama_wide_angle',
                    name: 'panorama wide angle',
                    group_id: 'image',
                    keywords: ['image, panorama, wide, angle'],
                    ligature: 'panorama_wide_angle',
                    codepoint: 'E40F',
                },
                {
                    id: 'ic_photo',
                    name: 'photo',
                    group_id: 'image',
                    keywords: ['image, photo'],
                    ligature: 'photo',
                    codepoint: 'E410',
                },
                {
                    id: 'ic_photo_album',
                    name: 'photo album',
                    group_id: 'image',
                    keywords: ['image, photo, album'],
                    ligature: 'photo_album',
                    codepoint: 'E411',
                },
                {
                    id: 'ic_photo_camera',
                    name: 'photo camera',
                    group_id: 'image',
                    keywords: ['image, photo, camera'],
                    ligature: 'photo_camera',
                    codepoint: 'E412',
                },
                {
                    id: 'ic_photo_filter',
                    name: 'photo filter',
                    group_id: 'image',
                    keywords: ['image, photo, filter'],
                    ligature: 'photo_filter',
                    codepoint: 'E43B',
                    is_new: true,
                },
                {
                    id: 'ic_photo_library',
                    name: 'photo library',
                    group_id: 'image',
                    keywords: ['image, photo, library'],
                    ligature: 'photo_library',
                    codepoint: 'E413',
                },
                {
                    id: 'ic_photo_size_select_actual',
                    name: 'photo size select actual',
                    group_id: 'image',
                    keywords: ['image, photo, size, select, actual'],
                    ligature: 'photo_size_select_actual',
                    codepoint: 'E432',
                },
                {
                    id: 'ic_photo_size_select_large',
                    name: 'photo size select large',
                    group_id: 'image',
                    keywords: ['image, photo, size, select, large'],
                    ligature: 'photo_size_select_large',
                    codepoint: 'E433',
                },
                {
                    id: 'ic_photo_size_select_small',
                    name: 'photo size select small',
                    group_id: 'image',
                    keywords: ['image, photo, size, select, small'],
                    ligature: 'photo_size_select_small',
                    codepoint: 'E434',
                },
                {
                    id: 'ic_picture_as_pdf',
                    name: 'picture as pdf',
                    group_id: 'image',
                    keywords: ['image, picture, as, pdf'],
                    ligature: 'picture_as_pdf',
                    codepoint: 'E415',
                },
                {
                    id: 'ic_portrait',
                    name: 'portrait',
                    group_id: 'image',
                    keywords: ['image, portrait'],
                    ligature: 'portrait',
                    codepoint: 'E416',
                },
                {
                    id: 'ic_remove_red_eye',
                    name: 'remove red eye',
                    group_id: 'image',
                    keywords: ['image, remove, red, eye'],
                    ligature: 'remove_red_eye',
                    codepoint: 'E417',
                },
                {
                    id: 'ic_rotate_90_degrees_ccw',
                    name: 'rotate 90 degrees ccw',
                    group_id: 'image',
                    keywords: ['image, rotate, 90, degrees, ccw'],
                    ligature: 'rotate_90_degrees_ccw',
                    codepoint: 'E418',
                },
                {
                    id: 'ic_rotate_left',
                    name: 'rotate left',
                    group_id: 'image',
                    keywords: ['image, rotate, left'],
                    ligature: 'rotate_left',
                    codepoint: 'E419',
                },
                {
                    id: 'ic_rotate_right',
                    name: 'rotate right',
                    group_id: 'image',
                    keywords: ['image, rotate, right'],
                    ligature: 'rotate_right',
                    codepoint: 'E41A',
                },
                {
                    id: 'ic_slideshow',
                    name: 'slideshow',
                    group_id: 'image',
                    keywords: ['image, slideshow'],
                    ligature: 'slideshow',
                    codepoint: 'E41B',
                },
                {
                    id: 'ic_straighten',
                    name: 'straighten',
                    group_id: 'image',
                    keywords: ['image, straighten'],
                    ligature: 'straighten',
                    codepoint: 'E41C',
                },
                {
                    id: 'ic_style',
                    name: 'style',
                    group_id: 'image',
                    keywords: ['image, style'],
                    ligature: 'style',
                    codepoint: 'E41D',
                },
                {
                    id: 'ic_switch_camera',
                    name: 'switch camera',
                    group_id: 'image',
                    keywords: ['image, switch, camera'],
                    ligature: 'switch_camera',
                    codepoint: 'E41E',
                },
                {
                    id: 'ic_switch_video',
                    name: 'switch video',
                    group_id: 'image',
                    keywords: ['image, switch, video'],
                    ligature: 'switch_video',
                    codepoint: 'E41F',
                },
                {
                    id: 'ic_tag_faces',
                    name: 'tag faces',
                    group_id: 'image',
                    keywords: ['image, tag, faces'],
                    ligature: 'tag_faces',
                    codepoint: 'E420',
                },
                {
                    id: 'ic_texture',
                    name: 'texture',
                    group_id: 'image',
                    keywords: ['image, texture'],
                    ligature: 'texture',
                    codepoint: 'E421',
                },
                {
                    id: 'ic_timelapse',
                    name: 'timelapse',
                    group_id: 'image',
                    keywords: ['image, timelapse'],
                    ligature: 'timelapse',
                    codepoint: 'E422',
                },
                {
                    id: 'ic_timer',
                    name: 'timer',
                    group_id: 'image',
                    keywords: ['image, timer'],
                    ligature: 'timer',
                    codepoint: 'E425',
                },
                {
                    id: 'ic_timer_10',
                    name: 'timer 10',
                    group_id: 'image',
                    keywords: ['image, timer, 10'],
                    ligature: 'timer_10',
                    codepoint: 'E423',
                },
                {
                    id: 'ic_timer_3',
                    name: 'timer 3',
                    group_id: 'image',
                    keywords: ['image, timer'],
                    ligature: 'timer_3',
                    codepoint: 'E424',
                },
                {
                    id: 'ic_timer_off',
                    name: 'timer off',
                    group_id: 'image',
                    keywords: ['image, timer, off'],
                    ligature: 'timer_off',
                    codepoint: 'E426',
                },
                {
                    id: 'ic_tonality',
                    name: 'tonality',
                    group_id: 'image',
                    keywords: ['image, tonality'],
                    ligature: 'tonality',
                    codepoint: 'E427',
                },
                {
                    id: 'ic_transform',
                    name: 'transform',
                    group_id: 'image',
                    keywords: ['image, transform'],
                    ligature: 'transform',
                    codepoint: 'E428',
                },
                {
                    id: 'ic_tune',
                    name: 'tune',
                    group_id: 'image',
                    keywords: ['image, tune'],
                    ligature: 'tune',
                    codepoint: 'E429',
                },
                {
                    id: 'ic_view_comfy',
                    name: 'view comfy',
                    group_id: 'image',
                    keywords: ['image, view, comfy'],
                    ligature: 'view_comfy',
                    codepoint: 'E42A',
                },
                {
                    id: 'ic_view_compact',
                    name: 'view compact',
                    group_id: 'image',
                    keywords: ['image, view, compact'],
                    ligature: 'view_compact',
                    codepoint: 'E42B',
                },
                {
                    id: 'ic_vignette',
                    name: 'vignette',
                    group_id: 'image',
                    keywords: ['image, vignette'],
                    ligature: 'vignette',
                    codepoint: 'E435',
                },
                {
                    id: 'ic_wb_auto',
                    name: 'wb auto',
                    group_id: 'image',
                    keywords: ['image, wb, auto'],
                    ligature: 'wb_auto',
                    codepoint: 'E42C',
                },
                {
                    id: 'ic_wb_cloudy',
                    name: 'wb cloudy',
                    group_id: 'image',
                    keywords: ['image, wb, cloudy'],
                    ligature: 'wb_cloudy',
                    codepoint: 'E42D',
                },
                {
                    id: 'ic_wb_incandescent',
                    name: 'wb incandescent',
                    group_id: 'image',
                    keywords: ['image, wb, incandescent'],
                    ligature: 'wb_incandescent',
                    codepoint: 'E42E',
                },
                {
                    id: 'ic_wb_iridescent',
                    name: 'wb iridescent',
                    group_id: 'image',
                    keywords: ['image, wb, iridescent'],
                    ligature: 'wb_iridescent',
                    codepoint: 'E436',
                },
                {
                    id: 'ic_wb_sunny',
                    name: 'wb sunny',
                    group_id: 'image',
                    keywords: ['image, wb, sunny'],
                    ligature: 'wb_sunny',
                    codepoint: 'E430',
                },
            ],
        },
        {
            name: 'maps',
            key: 'maps',
            icons: [
                {
                    id: 'ic_add_location',
                    name: 'add location',
                    group_id: 'maps',
                    keywords: ['maps, add, location'],
                    ligature: 'add_location',
                    codepoint: 'E567',
                    is_new: true,
                },
                {
                    id: 'ic_beenhere',
                    name: 'beenhere',
                    group_id: 'maps',
                    keywords: ['maps, beenhere'],
                    ligature: 'beenhere',
                    codepoint: 'E52D',
                },
                {
                    id: 'ic_directions',
                    name: 'directions',
                    group_id: 'maps',
                    keywords: ['maps, directions'],
                    ligature: 'directions',
                    codepoint: 'E52E',
                },
                {
                    id: 'ic_directions_bike',
                    name: 'directions bike',
                    group_id: 'maps',
                    keywords: ['maps, directions, bike'],
                    ligature: 'directions_bike',
                    codepoint: 'E52F',
                },
                {
                    id: 'ic_directions_boat',
                    name: 'directions boat',
                    group_id: 'maps',
                    keywords: ['maps, directions, boat'],
                    ligature: 'directions_boat',
                    codepoint: 'E532',
                },
                {
                    id: 'ic_directions_bus',
                    name: 'directions bus',
                    group_id: 'maps',
                    keywords: ['maps, directions, bus'],
                    ligature: 'directions_bus',
                    codepoint: 'E530',
                },
                {
                    id: 'ic_directions_car',
                    name: 'directions car',
                    group_id: 'maps',
                    keywords: ['maps, directions, car'],
                    ligature: 'directions_car',
                    codepoint: 'E531',
                },
                {
                    id: 'ic_directions_railway',
                    name: 'directions railway',
                    group_id: 'maps',
                    keywords: ['maps, directions, railway'],
                    ligature: 'directions_railway',
                    codepoint: 'E534',
                },
                {
                    id: 'ic_directions_run',
                    name: 'directions run',
                    group_id: 'maps',
                    keywords: ['maps, directions, run'],
                    ligature: 'directions_run',
                    codepoint: 'E566',
                },
                {
                    id: 'ic_directions_subway',
                    name: 'directions subway',
                    group_id: 'maps',
                    keywords: ['maps, directions, subway'],
                    ligature: 'directions_subway',
                    codepoint: 'E533',
                },
                {
                    id: 'ic_directions_transit',
                    name: 'directions transit',
                    group_id: 'maps',
                    keywords: ['maps, directions, transit'],
                    ligature: 'directions_transit',
                    codepoint: 'E535',
                },
                {
                    id: 'ic_directions_walk',
                    name: 'directions walk',
                    group_id: 'maps',
                    keywords: ['maps, directions, walk'],
                    ligature: 'directions_walk',
                    codepoint: 'E536',
                },
                {
                    id: 'ic_edit_location',
                    name: 'edit location',
                    group_id: 'maps',
                    keywords: ['maps, edit, location'],
                    ligature: 'edit_location',
                    codepoint: 'E568',
                    is_new: true,
                },
                {
                    id: 'ic_flight',
                    name: 'flight',
                    group_id: 'maps',
                    keywords: ['maps, flight'],
                    ligature: 'flight',
                    codepoint: 'E539',
                },
                {
                    id: 'ic_hotel',
                    name: 'hotel',
                    group_id: 'maps',
                    keywords: ['maps, hotel'],
                    ligature: 'hotel',
                    codepoint: 'E53A',
                },
                {
                    id: 'ic_layers',
                    name: 'layers',
                    group_id: 'maps',
                    keywords: ['maps, layers'],
                    ligature: 'layers',
                    codepoint: 'E53B',
                },
                {
                    id: 'ic_layers_clear',
                    name: 'layers clear',
                    group_id: 'maps',
                    keywords: ['maps, layers, clear'],
                    ligature: 'layers_clear',
                    codepoint: 'E53C',
                },
                {
                    id: 'ic_local_activity',
                    name: 'local activity',
                    group_id: 'maps',
                    keywords: ['maps, local, activity'],
                    ligature: 'local_activity',
                    codepoint: 'E53F',
                },
                {
                    id: 'ic_local_airport',
                    name: 'local airport',
                    group_id: 'maps',
                    keywords: ['maps, local, airport'],
                    ligature: 'local_airport',
                    codepoint: 'E53D',
                },
                {
                    id: 'ic_local_atm',
                    name: 'local atm',
                    group_id: 'maps',
                    keywords: ['maps, local, atm'],
                    ligature: 'local_atm',
                    codepoint: 'E53E',
                },
                {
                    id: 'ic_local_bar',
                    name: 'local bar',
                    group_id: 'maps',
                    keywords: ['maps, local, bar'],
                    ligature: 'local_bar',
                    codepoint: 'E540',
                },
                {
                    id: 'ic_local_cafe',
                    name: 'local cafe',
                    group_id: 'maps',
                    keywords: ['maps, local, cafe'],
                    ligature: 'local_cafe',
                    codepoint: 'E541',
                },
                {
                    id: 'ic_local_car_wash',
                    name: 'local car wash',
                    group_id: 'maps',
                    keywords: ['maps, local, car, wash'],
                    ligature: 'local_car_wash',
                    codepoint: 'E542',
                },
                {
                    id: 'ic_local_convenience_store',
                    name: 'local convenience store',
                    group_id: 'maps',
                    keywords: ['maps, local, convenience, store'],
                    ligature: 'local_convenience_store',
                    codepoint: 'E543',
                },
                {
                    id: 'ic_local_dining',
                    name: 'local dining',
                    group_id: 'maps',
                    keywords: ['maps, local, dining'],
                    ligature: 'local_dining',
                    codepoint: 'E556',
                },
                {
                    id: 'ic_local_drink',
                    name: 'local drink',
                    group_id: 'maps',
                    keywords: ['maps, local, drink'],
                    ligature: 'local_drink',
                    codepoint: 'E544',
                },
                {
                    id: 'ic_local_florist',
                    name: 'local florist',
                    group_id: 'maps',
                    keywords: ['maps, local, florist'],
                    ligature: 'local_florist',
                    codepoint: 'E545',
                },
                {
                    id: 'ic_local_gas_station',
                    name: 'local gas station',
                    group_id: 'maps',
                    keywords: ['maps, local, gas, station'],
                    ligature: 'local_gas_station',
                    codepoint: 'E546',
                },
                {
                    id: 'ic_local_grocery_store',
                    name: 'local grocery store',
                    group_id: 'maps',
                    keywords: ['maps, local, grocery, store'],
                    ligature: 'local_grocery_store',
                    codepoint: 'E547',
                },
                {
                    id: 'ic_local_hospital',
                    name: 'local hospital',
                    group_id: 'maps',
                    keywords: ['maps, local, hospital'],
                    ligature: 'local_hospital',
                    codepoint: 'E548',
                },
                {
                    id: 'ic_local_hotel',
                    name: 'local hotel',
                    group_id: 'maps',
                    keywords: ['maps, local, hotel'],
                    ligature: 'local_hotel',
                    codepoint: 'E549',
                },
                {
                    id: 'ic_local_laundry_service',
                    name: 'local laundry service',
                    group_id: 'maps',
                    keywords: ['maps, local, laundry, service'],
                    ligature: 'local_laundry_service',
                    codepoint: 'E54A',
                },
                {
                    id: 'ic_local_library',
                    name: 'local library',
                    group_id: 'maps',
                    keywords: ['maps, local, library'],
                    ligature: 'local_library',
                    codepoint: 'E54B',
                },
                {
                    id: 'ic_local_mall',
                    name: 'local mall',
                    group_id: 'maps',
                    keywords: ['maps, local, mall'],
                    ligature: 'local_mall',
                    codepoint: 'E54C',
                },
                {
                    id: 'ic_local_movies',
                    name: 'local movies',
                    group_id: 'maps',
                    keywords: ['maps, local, movies'],
                    ligature: 'local_movies',
                    codepoint: 'E54D',
                },
                {
                    id: 'ic_local_offer',
                    name: 'local offer',
                    group_id: 'maps',
                    keywords: ['maps, local, offer'],
                    ligature: 'local_offer',
                    codepoint: 'E54E',
                },
                {
                    id: 'ic_local_parking',
                    name: 'local parking',
                    group_id: 'maps',
                    keywords: ['maps, local, parking'],
                    ligature: 'local_parking',
                    codepoint: 'E54F',
                },
                {
                    id: 'ic_local_pharmacy',
                    name: 'local pharmacy',
                    group_id: 'maps',
                    keywords: ['maps, local, pharmacy'],
                    ligature: 'local_pharmacy',
                    codepoint: 'E550',
                },
                {
                    id: 'ic_local_phone',
                    name: 'local phone',
                    group_id: 'maps',
                    keywords: ['maps, local, phone'],
                    ligature: 'local_phone',
                    codepoint: 'E551',
                },
                {
                    id: 'ic_local_pizza',
                    name: 'local pizza',
                    group_id: 'maps',
                    keywords: ['maps, local, pizza'],
                    ligature: 'local_pizza',
                    codepoint: 'E552',
                },
                {
                    id: 'ic_local_play',
                    name: 'local play',
                    group_id: 'maps',
                    keywords: ['maps, local, play'],
                    ligature: 'local_play',
                    codepoint: 'E553',
                },
                {
                    id: 'ic_local_post_office',
                    name: 'local post office',
                    group_id: 'maps',
                    keywords: ['maps, local, post, office'],
                    ligature: 'local_post_office',
                    codepoint: 'E554',
                },
                {
                    id: 'ic_local_printshop',
                    name: 'local printshop',
                    group_id: 'maps',
                    keywords: ['maps, local, printshop'],
                    ligature: 'local_printshop',
                    codepoint: 'E555',
                },
                {
                    id: 'ic_local_see',
                    name: 'local see',
                    group_id: 'maps',
                    keywords: ['maps, local, see'],
                    ligature: 'local_see',
                    codepoint: 'E557',
                },
                {
                    id: 'ic_local_shipping',
                    name: 'local shipping',
                    group_id: 'maps',
                    keywords: ['maps, local, shipping'],
                    ligature: 'local_shipping',
                    codepoint: 'E558',
                },
                {
                    id: 'ic_local_taxi',
                    name: 'local taxi',
                    group_id: 'maps',
                    keywords: ['maps, local, taxi'],
                    ligature: 'local_taxi',
                    codepoint: 'E559',
                },
                {
                    id: 'ic_map',
                    name: 'map',
                    group_id: 'maps',
                    keywords: ['maps, map'],
                    ligature: 'map',
                    codepoint: 'E55B',
                },
                {
                    id: 'ic_my_location',
                    name: 'my location',
                    group_id: 'maps',
                    keywords: ['maps, my, location'],
                    ligature: 'my_location',
                    codepoint: 'E55C',
                },
                {
                    id: 'ic_navigation',
                    name: 'navigation',
                    group_id: 'maps',
                    keywords: ['maps, navigation'],
                    ligature: 'navigation',
                    codepoint: 'E55D',
                },
                {
                    id: 'ic_near_me',
                    name: 'near me',
                    group_id: 'maps',
                    keywords: ['maps, near, me'],
                    ligature: 'near_me',
                    codepoint: 'E569',
                    is_new: true,
                },
                {
                    id: 'ic_person_pin',
                    name: 'person pin',
                    group_id: 'maps',
                    keywords: ['maps, person, pin'],
                    ligature: 'person_pin',
                    codepoint: 'E55A',
                },
                {
                    id: 'ic_person_pin_circle',
                    name: 'person pin circle',
                    group_id: 'maps',
                    keywords: ['maps, person, pin, circle'],
                    ligature: 'person_pin_circle',
                    codepoint: 'E56A',
                    is_new: true,
                },
                {
                    id: 'ic_pin_drop',
                    name: 'pin drop',
                    group_id: 'maps',
                    keywords: ['maps, pin, drop'],
                    ligature: 'pin_drop',
                    codepoint: 'E55E',
                },
                {
                    id: 'ic_place',
                    name: 'place',
                    group_id: 'maps',
                    keywords: ['maps, place'],
                    ligature: 'place',
                    codepoint: 'E55F',
                },
                {
                    id: 'ic_rate_review',
                    name: 'rate review',
                    group_id: 'maps',
                    keywords: ['maps, rate, review'],
                    ligature: 'rate_review',
                    codepoint: 'E560',
                },
                {
                    id: 'ic_restaurant_menu',
                    name: 'restaurant menu',
                    group_id: 'maps',
                    keywords: ['maps, restaurant, menu'],
                    ligature: 'restaurant_menu',
                    codepoint: 'E561',
                },
                {
                    id: 'ic_satellite',
                    name: 'satellite',
                    group_id: 'maps',
                    keywords: ['maps, satellite'],
                    ligature: 'satellite',
                    codepoint: 'E562',
                },
                {
                    id: 'ic_store_mall_directory',
                    name: 'store mall directory',
                    group_id: 'maps',
                    keywords: ['maps, store, mall, directory'],
                    ligature: 'store_mall_directory',
                    codepoint: 'E563',
                },
                {
                    id: 'ic_terrain',
                    name: 'terrain',
                    group_id: 'maps',
                    keywords: ['maps, terrain'],
                    ligature: 'terrain',
                    codepoint: 'E564',
                },
                {
                    id: 'ic_traffic',
                    name: 'traffic',
                    group_id: 'maps',
                    keywords: ['maps, traffic'],
                    ligature: 'traffic',
                    codepoint: 'E565',
                },
                {
                    id: 'ic_zoom_out_map',
                    name: 'zoom out map',
                    group_id: 'maps',
                    keywords: ['maps, zoom, out, map'],
                    ligature: 'zoom_out_map',
                    codepoint: 'E56B',
                    is_new: true,
                },
            ],
        },
        {
            name: 'navigation',
            key: 'navigation',
            icons: [
                {
                    id: 'ic_apps',
                    name: 'apps',
                    group_id: 'navigation',
                    keywords: ['navigation, apps'],
                    ligature: 'apps',
                    codepoint: 'E5C3',
                },
                {
                    id: 'ic_arrow_back',
                    name: 'arrow back',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, back'],
                    ligature: 'arrow_back',
                    codepoint: 'E5C4',
                },
                {
                    id: 'ic_arrow_downward',
                    name: 'arrow downward',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, downward'],
                    ligature: 'arrow_downward',
                    codepoint: 'E5DB',
                    is_new: true,
                },
                {
                    id: 'ic_arrow_drop_down',
                    name: 'arrow drop down',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, drop, down'],
                    ligature: 'arrow_drop_down',
                    codepoint: 'E5C5',
                },
                {
                    id: 'ic_arrow_drop_down_circle',
                    name: 'arrow drop down circle',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, drop, down, circle'],
                    ligature: 'arrow_drop_down_circle',
                    codepoint: 'E5C6',
                },
                {
                    id: 'ic_arrow_drop_up',
                    name: 'arrow drop up',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, drop, up'],
                    ligature: 'arrow_drop_up',
                    codepoint: 'E5C7',
                },
                {
                    id: 'ic_arrow_forward',
                    name: 'arrow forward',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, forward'],
                    ligature: 'arrow_forward',
                    codepoint: 'E5C8',
                },
                {
                    id: 'ic_arrow_upward',
                    name: 'arrow upward',
                    group_id: 'navigation',
                    keywords: ['navigation, arrow, upward'],
                    ligature: 'arrow_upward',
                    codepoint: 'E5D8',
                    is_new: true,
                },
                {
                    id: 'ic_cancel',
                    name: 'cancel',
                    group_id: 'navigation',
                    keywords: ['navigation, cancel'],
                    ligature: 'cancel',
                    codepoint: 'E5C9',
                },
                {
                    id: 'ic_check',
                    name: 'check',
                    group_id: 'navigation',
                    keywords: ['navigation, check'],
                    ligature: 'check',
                    codepoint: 'E5CA',
                },
                {
                    id: 'ic_chevron_left',
                    name: 'chevron left',
                    group_id: 'navigation',
                    keywords: ['navigation, chevron, left'],
                    ligature: 'chevron_left',
                    codepoint: 'E5CB',
                },
                {
                    id: 'ic_chevron_right',
                    name: 'chevron right',
                    group_id: 'navigation',
                    keywords: ['navigation, chevron, right'],
                    ligature: 'chevron_right',
                    codepoint: 'E5CC',
                },
                {
                    id: 'ic_close',
                    name: 'close',
                    group_id: 'navigation',
                    keywords: ['navigation, close'],
                    ligature: 'close',
                    codepoint: 'E5CD',
                },
                {
                    id: 'ic_expand_less',
                    name: 'expand less',
                    group_id: 'navigation',
                    keywords: ['navigation, expand, less'],
                    ligature: 'expand_less',
                    codepoint: 'E5CE',
                },
                {
                    id: 'ic_expand_more',
                    name: 'expand more',
                    group_id: 'navigation',
                    keywords: ['navigation, expand, more'],
                    ligature: 'expand_more',
                    codepoint: 'E5CF',
                },
                {
                    id: 'ic_fullscreen',
                    name: 'fullscreen',
                    group_id: 'navigation',
                    keywords: ['navigation, fullscreen'],
                    ligature: 'fullscreen',
                    codepoint: 'E5D0',
                },
                {
                    id: 'ic_fullscreen_exit',
                    name: 'fullscreen exit',
                    group_id: 'navigation',
                    keywords: ['navigation, fullscreen, exit'],
                    ligature: 'fullscreen_exit',
                    codepoint: 'E5D1',
                },
                {
                    id: 'ic_menu',
                    name: 'menu',
                    group_id: 'navigation',
                    keywords: ['navigation, menu'],
                    ligature: 'menu',
                    codepoint: 'E5D2',
                },
                {
                    id: 'ic_more_horiz',
                    name: 'more horiz',
                    group_id: 'navigation',
                    keywords: ['navigation, more, horiz'],
                    ligature: 'more_horiz',
                    codepoint: 'E5D3',
                },
                {
                    id: 'ic_more_vert',
                    name: 'more vert',
                    group_id: 'navigation',
                    keywords: ['navigation, more, vert'],
                    ligature: 'more_vert',
                    codepoint: 'E5D4',
                },
                {
                    id: 'ic_refresh',
                    name: 'refresh',
                    group_id: 'navigation',
                    keywords: ['navigation, refresh'],
                    ligature: 'refresh',
                    codepoint: 'E5D5',
                },
                {
                    id: 'ic_subdirectory_arrow_left',
                    name: 'subdirectory arrow left',
                    group_id: 'navigation',
                    keywords: ['navigation, subdirectory, arrow, left'],
                    ligature: 'subdirectory_arrow_left',
                    codepoint: 'E5D9',
                    is_new: true,
                },
                {
                    id: 'ic_subdirectory_arrow_right',
                    name: 'subdirectory arrow right',
                    group_id: 'navigation',
                    keywords: ['navigation, subdirectory, arrow, right'],
                    ligature: 'subdirectory_arrow_right',
                    codepoint: 'E5DA',
                    is_new: true,
                },
            ],
        },
        {
            name: 'notification',
            key: 'notification',
            icons: [
                {
                    id: 'ic_adb',
                    name: 'adb',
                    group_id: 'notification',
                    keywords: ['notification, adb'],
                    ligature: 'adb',
                    codepoint: 'E60E',
                },
                {
                    id: 'ic_airline_seat_flat',
                    name: 'airline seat flat',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, flat'],
                    ligature: 'airline_seat_flat',
                    codepoint: 'E630',
                },
                {
                    id: 'ic_airline_seat_flat_angled',
                    name: 'airline seat flat angled',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, flat, angled'],
                    ligature: 'airline_seat_flat_angled',
                    codepoint: 'E631',
                },
                {
                    id: 'ic_airline_seat_individual_suite',
                    name: 'airline seat individual suite',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, individual, suite'],
                    ligature: 'airline_seat_individual_suite',
                    codepoint: 'E632',
                },
                {
                    id: 'ic_airline_seat_legroom_extra',
                    name: 'airline seat legroom extra',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, legroom, extra'],
                    ligature: 'airline_seat_legroom_extra',
                    codepoint: 'E633',
                },
                {
                    id: 'ic_airline_seat_legroom_normal',
                    name: 'airline seat legroom normal',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, legroom, normal'],
                    ligature: 'airline_seat_legroom_normal',
                    codepoint: 'E634',
                },
                {
                    id: 'ic_airline_seat_legroom_reduced',
                    name: 'airline seat legroom reduced',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, legroom, reduced'],
                    ligature: 'airline_seat_legroom_reduced',
                    codepoint: 'E635',
                },
                {
                    id: 'ic_airline_seat_recline_extra',
                    name: 'airline seat recline extra',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, recline, extra'],
                    ligature: 'airline_seat_recline_extra',
                    codepoint: 'E636',
                },
                {
                    id: 'ic_airline_seat_recline_normal',
                    name: 'airline seat recline normal',
                    group_id: 'notification',
                    keywords: ['notification, airline, seat, recline, normal'],
                    ligature: 'airline_seat_recline_normal',
                    codepoint: 'E637',
                },
                {
                    id: 'ic_bluetooth_audio',
                    name: 'bluetooth audio',
                    group_id: 'notification',
                    keywords: ['notification, bluetooth, audio'],
                    ligature: 'bluetooth_audio',
                    codepoint: 'E60F',
                },
                {
                    id: 'ic_confirmation_number',
                    name: 'confirmation number',
                    group_id: 'notification',
                    keywords: ['notification, confirmation, number'],
                    ligature: 'confirmation_number',
                    codepoint: 'E638',
                },
                {
                    id: 'ic_disc_full',
                    name: 'disc full',
                    group_id: 'notification',
                    keywords: ['notification, disc, full'],
                    ligature: 'disc_full',
                    codepoint: 'E610',
                },
                {
                    id: 'ic_do_not_disturb',
                    name: 'do not disturb',
                    group_id: 'notification',
                    keywords: ['notification, do, not, disturb'],
                    ligature: 'do_not_disturb',
                    codepoint: 'E612',
                },
                {
                    id: 'ic_do_not_disturb_alt',
                    name: 'do not disturb alt',
                    group_id: 'notification',
                    keywords: ['notification, do, not, disturb, alt'],
                    ligature: 'do_not_disturb_alt',
                    codepoint: 'E611',
                },
                {
                    id: 'ic_drive_eta',
                    name: 'drive eta',
                    group_id: 'notification',
                    keywords: ['notification, drive, eta'],
                    ligature: 'drive_eta',
                    codepoint: 'E613',
                },
                {
                    id: 'ic_enhanced_encryption',
                    name: 'enhanced encryption',
                    group_id: 'notification',
                    keywords: ['notification, enhanced, encryption'],
                    ligature: 'enhanced_encryption',
                    codepoint: 'E63F',
                    is_new: true,
                },
                {
                    id: 'ic_event_available',
                    name: 'event available',
                    group_id: 'notification',
                    keywords: ['notification, event, available'],
                    ligature: 'event_available',
                    codepoint: 'E614',
                },
                {
                    id: 'ic_event_busy',
                    name: 'event busy',
                    group_id: 'notification',
                    keywords: ['notification, event, busy'],
                    ligature: 'event_busy',
                    codepoint: 'E615',
                },
                {
                    id: 'ic_event_note',
                    name: 'event note',
                    group_id: 'notification',
                    keywords: ['notification, event, note'],
                    ligature: 'event_note',
                    codepoint: 'E616',
                },
                {
                    id: 'ic_folder_special',
                    name: 'folder special',
                    group_id: 'notification',
                    keywords: ['notification, folder, special'],
                    ligature: 'folder_special',
                    codepoint: 'E617',
                },
                {
                    id: 'ic_live_tv',
                    name: 'live tv',
                    group_id: 'notification',
                    keywords: ['notification, live, tv'],
                    ligature: 'live_tv',
                    codepoint: 'E639',
                },
                {
                    id: 'ic_mms',
                    name: 'mms',
                    group_id: 'notification',
                    keywords: ['notification, mms'],
                    ligature: 'mms',
                    codepoint: 'E618',
                },
                {
                    id: 'ic_more',
                    name: 'more',
                    group_id: 'notification',
                    keywords: ['notification, more'],
                    ligature: 'more',
                    codepoint: 'E619',
                },
                {
                    id: 'ic_network_check',
                    name: 'network check',
                    group_id: 'notification',
                    keywords: ['notification, network, check'],
                    ligature: 'network_check',
                    codepoint: 'E640',
                    is_new: true,
                },
                {
                    id: 'ic_network_locked',
                    name: 'network locked',
                    group_id: 'notification',
                    keywords: ['notification, network, locked'],
                    ligature: 'network_locked',
                    codepoint: 'E61A',
                },
                {
                    id: 'ic_no_encryption',
                    name: 'no encryption',
                    group_id: 'notification',
                    keywords: ['notification, no, encryption'],
                    ligature: 'no_encryption',
                    codepoint: 'E641',
                    is_new: true,
                },
                {
                    id: 'ic_ondemand_video',
                    name: 'ondemand video',
                    group_id: 'notification',
                    keywords: ['notification, ondemand, video'],
                    ligature: 'ondemand_video',
                    codepoint: 'E63A',
                },
                {
                    id: 'ic_personal_video',
                    name: 'personal video',
                    group_id: 'notification',
                    keywords: ['notification, personal, video'],
                    ligature: 'personal_video',
                    codepoint: 'E63B',
                },
                {
                    id: 'ic_phone_bluetooth_speaker',
                    name: 'phone bluetooth speaker',
                    group_id: 'notification',
                    keywords: ['notification, phone, bluetooth, speaker'],
                    ligature: 'phone_bluetooth_speaker',
                    codepoint: 'E61B',
                },
                {
                    id: 'ic_phone_forwarded',
                    name: 'phone forwarded',
                    group_id: 'notification',
                    keywords: ['notification, phone, forwarded'],
                    ligature: 'phone_forwarded',
                    codepoint: 'E61C',
                },
                {
                    id: 'ic_phone_in_talk',
                    name: 'phone in talk',
                    group_id: 'notification',
                    keywords: ['notification, phone, in, talk'],
                    ligature: 'phone_in_talk',
                    codepoint: 'E61D',
                },
                {
                    id: 'ic_phone_locked',
                    name: 'phone locked',
                    group_id: 'notification',
                    keywords: ['notification, phone, locked'],
                    ligature: 'phone_locked',
                    codepoint: 'E61E',
                },
                {
                    id: 'ic_phone_missed',
                    name: 'phone missed',
                    group_id: 'notification',
                    keywords: ['notification, phone, missed'],
                    ligature: 'phone_missed',
                    codepoint: 'E61F',
                },
                {
                    id: 'ic_phone_paused',
                    name: 'phone paused',
                    group_id: 'notification',
                    keywords: ['notification, phone, paused'],
                    ligature: 'phone_paused',
                    codepoint: 'E620',
                },
                {
                    id: 'ic_power',
                    name: 'power',
                    group_id: 'notification',
                    keywords: ['notification, power'],
                    ligature: 'power',
                    codepoint: 'E63C',
                },
                {
                    id: 'ic_sd_card',
                    name: 'sd card',
                    group_id: 'notification',
                    keywords: ['notification, sd, card'],
                    ligature: 'sd_card',
                    codepoint: 'E623',
                },
                {
                    id: 'ic_sim_card_alert',
                    name: 'sim card alert',
                    group_id: 'notification',
                    keywords: ['notification, sim, card, alert'],
                    ligature: 'sim_card_alert',
                    codepoint: 'E624',
                },
                {
                    id: 'ic_sms',
                    name: 'sms',
                    group_id: 'notification',
                    keywords: ['notification, sms'],
                    ligature: 'sms',
                    codepoint: 'E625',
                },
                {
                    id: 'ic_sms_failed',
                    name: 'sms failed',
                    group_id: 'notification',
                    keywords: ['notification, sms, failed'],
                    ligature: 'sms_failed',
                    codepoint: 'E626',
                },
                {
                    id: 'ic_sync',
                    name: 'sync',
                    group_id: 'notification',
                    keywords: ['notification, sync'],
                    ligature: 'sync',
                    codepoint: 'E627',
                },
                {
                    id: 'ic_sync_disabled',
                    name: 'sync disabled',
                    group_id: 'notification',
                    keywords: ['notification, sync, disabled'],
                    ligature: 'sync_disabled',
                    codepoint: 'E628',
                },
                {
                    id: 'ic_sync_problem',
                    name: 'sync problem',
                    group_id: 'notification',
                    keywords: ['notification, sync, problem'],
                    ligature: 'sync_problem',
                    codepoint: 'E629',
                },
                {
                    id: 'ic_system_update',
                    name: 'system update',
                    group_id: 'notification',
                    keywords: ['notification, system, update'],
                    ligature: 'system_update',
                    codepoint: 'E62A',
                },
                {
                    id: 'ic_tap_and_play',
                    name: 'tap and play',
                    group_id: 'notification',
                    keywords: ['notification, tap, play'],
                    ligature: 'tap_and_play',
                    codepoint: 'E62B',
                },
                {
                    id: 'ic_time_to_leave',
                    name: 'time to leave',
                    group_id: 'notification',
                    keywords: ['notification, time, to, leave'],
                    ligature: 'time_to_leave',
                    codepoint: 'E62C',
                },
                {
                    id: 'ic_vibration',
                    name: 'vibration',
                    group_id: 'notification',
                    keywords: ['notification, vibration'],
                    ligature: 'vibration',
                    codepoint: 'E62D',
                },
                {
                    id: 'ic_voice_chat',
                    name: 'voice chat',
                    group_id: 'notification',
                    keywords: ['notification, voice, chat'],
                    ligature: 'voice_chat',
                    codepoint: 'E62E',
                },
                {
                    id: 'ic_vpn_lock',
                    name: 'vpn lock',
                    group_id: 'notification',
                    keywords: ['notification, vpn, lock'],
                    ligature: 'vpn_lock',
                    codepoint: 'E62F',
                },
                {
                    id: 'ic_wc',
                    name: 'wc',
                    group_id: 'notification',
                    keywords: ['notification, wc'],
                    ligature: 'wc',
                    codepoint: 'E63D',
                },
                {
                    id: 'ic_wifi',
                    name: 'wifi',
                    group_id: 'notification',
                    keywords: ['notification, wifi'],
                    ligature: 'wifi',
                    codepoint: 'E63E',
                },
            ],
        },
        {
            name: 'places',
            key: 'places',
            icons: [
                {
                    id: 'ic_ac_unit',
                    name: 'ac unit',
                    group_id: 'places',
                    keywords: ['places, ac, unit'],
                    ligature: 'ac_unit',
                    codepoint: 'EB3B',
                    is_new: true,
                },
                {
                    id: 'ic_airport_shuttle',
                    name: 'airport shuttle',
                    group_id: 'places',
                    keywords: ['places, airport, shuttle'],
                    ligature: 'airport_shuttle',
                    codepoint: 'EB3C',
                    is_new: true,
                },
                {
                    id: 'ic_all_inclusive',
                    name: 'all inclusive',
                    group_id: 'places',
                    keywords: ['places, all, inclusive'],
                    ligature: 'all_inclusive',
                    codepoint: 'EB3D',
                    is_new: true,
                },
                {
                    id: 'ic_beach_access',
                    name: 'beach access',
                    group_id: 'places',
                    keywords: ['places, beach, access'],
                    ligature: 'beach_access',
                    codepoint: 'EB3E',
                    is_new: true,
                },
                {
                    id: 'ic_business_center',
                    name: 'business center',
                    group_id: 'places',
                    keywords: ['places, business, center'],
                    ligature: 'business_center',
                    codepoint: 'EB3F',
                    is_new: true,
                },
                {
                    id: 'ic_casino',
                    name: 'casino',
                    group_id: 'places',
                    keywords: ['places, casino'],
                    ligature: 'casino',
                    codepoint: 'EB40',
                    is_new: true,
                },
                {
                    id: 'ic_child_care',
                    name: 'child care',
                    group_id: 'places',
                    keywords: ['places, child, care'],
                    ligature: 'child_care',
                    codepoint: 'EB41',
                    is_new: true,
                },
                {
                    id: 'ic_child_friendly',
                    name: 'child friendly',
                    group_id: 'places',
                    keywords: ['places, child, friendly'],
                    ligature: 'child_friendly',
                    codepoint: 'EB42',
                    is_new: true,
                },
                {
                    id: 'ic_fitness_center',
                    name: 'fitness center',
                    group_id: 'places',
                    keywords: ['places, fitness, center'],
                    ligature: 'fitness_center',
                    codepoint: 'EB43',
                    is_new: true,
                },
                {
                    id: 'ic_free_breakfast',
                    name: 'free breakfast',
                    group_id: 'places',
                    keywords: ['places, free, breakfast'],
                    ligature: 'free_breakfast',
                    codepoint: 'EB44',
                    is_new: true,
                },
                {
                    id: 'ic_golf_course',
                    name: 'golf course',
                    group_id: 'places',
                    keywords: ['places, golf, course'],
                    ligature: 'golf_course',
                    codepoint: 'EB45',
                    is_new: true,
                },
                {
                    id: 'ic_hot_tub',
                    name: 'hot tub',
                    group_id: 'places',
                    keywords: ['places, hot, tub'],
                    ligature: 'hot_tub',
                    codepoint: 'EB46',
                    is_new: true,
                },
                {
                    id: 'ic_kitchen',
                    name: 'kitchen',
                    group_id: 'places',
                    keywords: ['places, kitchen'],
                    ligature: 'kitchen',
                    codepoint: 'EB47',
                    is_new: true,
                },
                {
                    id: 'ic_pool',
                    name: 'pool',
                    group_id: 'places',
                    keywords: ['places, pool'],
                    ligature: 'pool',
                    codepoint: 'EB48',
                    is_new: true,
                },
                {
                    id: 'ic_room_service',
                    name: 'room service',
                    group_id: 'places',
                    keywords: ['places, room, service'],
                    ligature: 'room_service',
                    codepoint: 'EB49',
                    is_new: true,
                },
                {
                    id: 'ic_rv_hookup',
                    name: 'rv hookup',
                    group_id: 'places',
                    keywords: ['places, rv, hookup'],
                    ligature: 'rv_hookup',
                    codepoint: 'E642',
                    is_new: true,
                },
                {
                    id: 'ic_smoke_free',
                    name: 'smoke free',
                    group_id: 'places',
                    keywords: ['places, smoke, free'],
                    ligature: 'smoke_free',
                    codepoint: 'EB4A',
                    is_new: true,
                },
                {
                    id: 'ic_smoking_rooms',
                    name: 'smoking rooms',
                    group_id: 'places',
                    keywords: ['places, smoking, rooms'],
                    ligature: 'smoking_rooms',
                    codepoint: 'EB4B',
                    is_new: true,
                },
                {
                    id: 'ic_spa',
                    name: 'spa',
                    group_id: 'places',
                    keywords: ['places, spa'],
                    ligature: 'spa',
                    codepoint: 'EB4C',
                    is_new: true,
                },
            ],
        },
        {
            name: 'social',
            key: 'social',
            icons: [
                {
                    id: 'ic_cake',
                    name: 'cake',
                    group_id: 'social',
                    keywords: ['social, cake'],
                    ligature: 'cake',
                    codepoint: 'E7E9',
                },
                {
                    id: 'ic_domain',
                    name: 'domain',
                    group_id: 'social',
                    keywords: ['social, domain'],
                    ligature: 'domain',
                    codepoint: 'E7EE',
                },
                {
                    id: 'ic_group',
                    name: 'group',
                    group_id: 'social',
                    keywords: ['social, group'],
                    ligature: 'group',
                    codepoint: 'E7EF',
                },
                {
                    id: 'ic_group_add',
                    name: 'group add',
                    group_id: 'social',
                    keywords: ['social, group, add'],
                    ligature: 'group_add',
                    codepoint: 'E7F0',
                },
                {
                    id: 'ic_location_city',
                    name: 'location city',
                    group_id: 'social',
                    keywords: ['social, location, city'],
                    ligature: 'location_city',
                    codepoint: 'E7F1',
                },
                {
                    id: 'ic_mood',
                    name: 'mood',
                    group_id: 'social',
                    keywords: ['social, mood'],
                    ligature: 'mood',
                    codepoint: 'E7F2',
                },
                {
                    id: 'ic_mood_bad',
                    name: 'mood bad',
                    group_id: 'social',
                    keywords: ['social, mood, bad'],
                    ligature: 'mood_bad',
                    codepoint: 'E7F3',
                },
                {
                    id: 'ic_notifications',
                    name: 'notifications',
                    group_id: 'social',
                    keywords: ['social, notifications'],
                    ligature: 'notifications',
                    codepoint: 'E7F4',
                },
                {
                    id: 'ic_notifications_active',
                    name: 'notifications active',
                    group_id: 'social',
                    keywords: ['social, notifications, active'],
                    ligature: 'notifications_active',
                    codepoint: 'E7F7',
                },
                {
                    id: 'ic_notifications_none',
                    name: 'notifications none',
                    group_id: 'social',
                    keywords: ['social, notifications, none'],
                    ligature: 'notifications_none',
                    codepoint: 'E7F5',
                },
                {
                    id: 'ic_notifications_off',
                    name: 'notifications off',
                    group_id: 'social',
                    keywords: ['social, notifications, off'],
                    ligature: 'notifications_off',
                    codepoint: 'E7F6',
                },
                {
                    id: 'ic_notifications_paused',
                    name: 'notifications paused',
                    group_id: 'social',
                    keywords: ['social, notifications, paused'],
                    ligature: 'notifications_paused',
                    codepoint: 'E7F8',
                },
                {
                    id: 'ic_pages',
                    name: 'pages',
                    group_id: 'social',
                    keywords: ['social, pages'],
                    ligature: 'pages',
                    codepoint: 'E7F9',
                },
                {
                    id: 'ic_party_mode',
                    name: 'party mode',
                    group_id: 'social',
                    keywords: ['social, party, mode'],
                    ligature: 'party_mode',
                    codepoint: 'E7FA',
                },
                {
                    id: 'ic_people',
                    name: 'people',
                    group_id: 'social',
                    keywords: ['social, people'],
                    ligature: 'people',
                    codepoint: 'E7FB',
                },
                {
                    id: 'ic_people_outline',
                    name: 'people outline',
                    group_id: 'social',
                    keywords: ['social, people, outline'],
                    ligature: 'people_outline',
                    codepoint: 'E7FC',
                },
                {
                    id: 'ic_person',
                    name: 'person',
                    group_id: 'social',
                    keywords: ['social, person'],
                    ligature: 'person',
                    codepoint: 'E7FD',
                },
                {
                    id: 'ic_person_add',
                    name: 'person add',
                    group_id: 'social',
                    keywords: ['social, person, add'],
                    ligature: 'person_add',
                    codepoint: 'E7FE',
                },
                {
                    id: 'ic_person_outline',
                    name: 'person outline',
                    group_id: 'social',
                    keywords: ['social, person, outline'],
                    ligature: 'person_outline',
                    codepoint: 'E7FF',
                },
                {
                    id: 'ic_plus_one',
                    name: 'plus one',
                    group_id: 'social',
                    keywords: ['social, plus, one'],
                    ligature: 'plus_one',
                    codepoint: 'E800',
                },
                {
                    id: 'ic_poll',
                    name: 'poll',
                    group_id: 'social',
                    keywords: ['social, poll'],
                    ligature: 'poll',
                    codepoint: 'E801',
                },
                {
                    id: 'ic_public',
                    name: 'public',
                    group_id: 'social',
                    keywords: ['social, public'],
                    ligature: 'public',
                    codepoint: 'E80B',
                },
                {
                    id: 'ic_school',
                    name: 'school',
                    group_id: 'social',
                    keywords: ['social, school'],
                    ligature: 'school',
                    codepoint: 'E80C',
                },
                {
                    id: 'ic_share',
                    name: 'share',
                    group_id: 'social',
                    keywords: ['social, share'],
                    ligature: 'share',
                    codepoint: 'E80D',
                },
                {
                    id: 'ic_whatshot',
                    name: 'whatshot',
                    group_id: 'social',
                    keywords: ['social, whatshot'],
                    ligature: 'whatshot',
                    codepoint: 'E80E',
                },
            ],
        },
        {
            name: 'toggle',
            key: 'toggle',
            icons: [
                {
                    id: 'ic_check_box',
                    name: 'check box',
                    group_id: 'toggle',
                    keywords: ['toggle, check, box'],
                    ligature: 'check_box',
                    codepoint: 'E834',
                },
                {
                    id: 'ic_check_box_outline_blank',
                    name: 'check box outline blank',
                    group_id: 'toggle',
                    keywords: ['toggle, check, box, outline, blank'],
                    ligature: 'check_box_outline_blank',
                    codepoint: 'E835',
                },
                {
                    id: 'ic_indeterminate_check_box',
                    name: 'indeterminate check box',
                    group_id: 'toggle',
                    keywords: ['toggle, indeterminate, check, box'],
                    ligature: 'indeterminate_check_box',
                    codepoint: 'E909',
                },
                {
                    id: 'ic_radio_button_checked',
                    name: 'radio button checked',
                    group_id: 'toggle',
                    keywords: ['toggle, radio, button, checked'],
                    ligature: 'radio_button_checked',
                    codepoint: 'E837',
                },
                {
                    id: 'ic_radio_button_unchecked',
                    name: 'radio button unchecked',
                    group_id: 'toggle',
                    keywords: ['toggle, radio, button, unchecked'],
                    ligature: 'radio_button_unchecked',
                    codepoint: 'E836',
                },
                {
                    id: 'ic_star',
                    name: 'star',
                    group_id: 'toggle',
                    keywords: ['toggle, star'],
                    ligature: 'star',
                    codepoint: 'E838',
                },
                {
                    id: 'ic_star_border',
                    name: 'star border',
                    group_id: 'toggle',
                    keywords: ['toggle, star, border'],
                    ligature: 'star_border',
                    codepoint: 'E83A',
                },
                {
                    id: 'ic_star_half',
                    name: 'star half',
                    group_id: 'toggle',
                    keywords: ['toggle, star, half'],
                    ligature: 'star_half',
                    codepoint: 'E839',
                },
            ],
        },
    ],
};
