import React from 'react';
import { useTheme } from '@material-ui/core';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import type { GridProps } from '@material-ui/core';

const buttonStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        textAlign: 'center',
        padding: 0,
        width: 55,
        margin: theme.spacing(1, 0),
    },
    tooth: {
        width: 55,
    },
    label: {
        width: 50,
        color: theme.palette.common.white,
    },
}));

export interface ToothButtonProps {
    code: Number;
    type: 'normal' | 'marked' | 'fixed';
    onClick: (item: Number) => void;
}

const ToothButton = (props: ToothButtonProps) => {
    const classes = buttonStyles();
    const { code, type, onClick, isLower, ...rest } = props;

    const src = '/images/tooths/' + type + '/' + code + '.jpg';

    if (isLower) {
        return (
            <Grid onClick={() => onClick(code)} container className={classes.root} direction="column" justifyContent="flex-start" alignItems="center">
                <Typography className={classes.label} variant="h5" component="h5" noWrap>
                    {code}
                </Typography>
                <img className={classes.tooth} src={src} alt={code} />
            </Grid>
        );
    }

    return (
        <Grid onClick={() => onClick(code)} container className={classes.root} direction="column" justifyContent="flex-end" alignItems="center">
            <img className={classes.tooth} src={src} alt={code} />
            <Typography className={classes.label} variant="h5" component="h5" noWrap>
                {code}
            </Typography>
        </Grid>
    );
};

ToothButton.defaultProps = {
    type: 'normal',
};

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.common.black,
        display: 'block',
    },
    row: {
        margin: 0,
        width: 900,
    },
    topLeft: {
        borderRight: '1px solid ' + theme.palette.common.white,
        borderBottom: '1px solid ' + theme.palette.common.white,
    },
    topRight: {
        borderLeft: '1px solid ' + theme.palette.common.white,
        borderBottom: '1px solid ' + theme.palette.common.white,
    },
    bottomLeft: {
        borderRight: '1px solid ' + theme.palette.common.white,
        borderTop: '1px solid ' + theme.palette.common.white,
    },
    bottomRight: {
        borderLeft: '1px solid ' + theme.palette.common.white,
        borderTop: '1px solid ' + theme.palette.common.white,
    },
}));

export const TOOTH_CODES = {
    TOP_LEFT: ['18', '17', '16', '15', '14', '13', '12', '11'],
    TOP_RIGHT: ['21', '22', '23', '24', '25', '26', '27', '28'],
    BOTTOM_LEFT: ['48', '47', '46', '45', '44', '43', '42', '41'],
    BOTTOM_RIGHT: ['31', '32', '33', '34', '35', '36', '37', '38'],
};

export const CHILD_TOOTH_CODES = {
    TOP_LEFT: ['+E', '+D', '+C', '+B', '+A'],
    TOP_RIGHT: ['A+', 'B+', 'C+', 'D+', 'E+'],
    BOTTOM_LEFT: ['-E', '-D', '-C', '-B', '-A'],
    BOTTOM_RIGHT: ['A-', 'B-', 'C-', 'D-', 'E-'],
};

export interface ToothTableProps extends GridProps {
    data: Array<ToothButtonProps>;
    onItemClick: (item: Number) => void;
    isChild: Boolean;
}

const ToothTable = (props: ToothTableProps) => {
    const classes = styles();
    const theme = useTheme();
    const { isChild, data, onItemClick, ...rest } = props;

    const handleClick = (item) => {
        console.log('Clicked Tooth => ', item);
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const renderAdaultTable = () => {
        return (
            <Grid direction="column" {...rest} container justifyContent="center" alignContent="center">
                <Grid direction="row" className={classes.row} container justifyContent="center" alignContent="center">
                    <Grid direction="row" className={classes.topLeft} container item lg={6} md={6} justifyContent="flex-end" alignContent="center">
                        {TOOTH_CODES.TOP_LEFT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`TL${code}`} type={found?.type} code={code} onClick={handleClick} />;
                        })}
                    </Grid>
                    <Grid direction="row" className={classes.topRight} container item lg={6} md={6} justifyContent="flex-start" alignContent="center">
                        {TOOTH_CODES.TOP_RIGHT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`TR${code}`} type={found?.type} code={code} onClick={handleClick} />;
                        })}
                    </Grid>
                </Grid>
                <Grid direction="row" className={classes.row} container justifyContent="center" alignContent="center">
                    <Grid direction="row" className={classes.bottomLeft} container item lg={6} md={6} justifyContent="flex-end" alignContent="center">
                        {TOOTH_CODES.BOTTOM_LEFT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`BL${code}`} type={found?.type} code={code} isLower={true} onClick={handleClick} />;
                        })}
                    </Grid>
                    <Grid
                        direction="row"
                        className={classes.bottomRight}
                        container
                        item
                        lg={6}
                        md={6}
                        justifyContent="flex-start"
                        alignContent="center"
                    >
                        {TOOTH_CODES.BOTTOM_RIGHT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`BR${code}`} type={found?.type} code={code} isLower={true} onClick={handleClick} />;
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderChildTable = () => {
        return (
            <Grid direction="column" {...rest} container justifyContent="center" alignContent="center">
                <Grid direction="row" className={classes.row} container justifyContent="center" alignContent="center">
                    <Grid direction="row" className={classes.topLeft} container item lg={6} md={6} justifyContent="flex-end" alignContent="center">
                        {CHILD_TOOTH_CODES.TOP_LEFT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`TL${code}`} type={found?.type} code={code} onClick={handleClick} />;
                        })}
                    </Grid>
                    <Grid direction="row" className={classes.topRight} container item lg={6} md={6} justifyContent="flex-start" alignContent="center">
                        {CHILD_TOOTH_CODES.TOP_RIGHT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`TR${code}`} type={found?.type} code={code} onClick={handleClick} />;
                        })}
                    </Grid>
                </Grid>
                <Grid direction="row" className={classes.row} container justifyContent="center" alignContent="center">
                    <Grid direction="row" className={classes.bottomLeft} container item lg={6} md={6} justifyContent="flex-end" alignContent="center">
                        {CHILD_TOOTH_CODES.BOTTOM_LEFT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`BL${code}`} type={found?.type} code={code} isLower={true} onClick={handleClick} />;
                        })}
                    </Grid>
                    <Grid
                        direction="row"
                        className={classes.bottomRight}
                        container
                        item
                        lg={6}
                        md={6}
                        justifyContent="flex-start"
                        alignContent="center"
                    >
                        {CHILD_TOOTH_CODES.BOTTOM_RIGHT.map((code) => {
                            const found = data.find((t) => t.code === code);
                            return <ToothButton key={`BR${code}`} type={found?.type} code={code} isLower={true} onClick={handleClick} />;
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return <div className={classes.root}>{isChild ? renderChildTable() : renderAdaultTable()}</div>;
};

ToothTable.defaultProps = {
    data: [],
};

export default ToothTable;
