import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import TreatmentTypeApi from '../../api/TreatmentTypeApi';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';

export const TREATMENT_TYPE_TABLE_FIELDS = [
    {
        name: 'id',
        align: 'center',
        label: 'Id',
        sortable: true,
    },
    {
        name: 'image',
        align: 'center',
        label: 'Image',
        type: 'image',
    },
    {
        name: 'code',
        label: 'Code',
        sortable: true,
    },
    {
        name: 'name',
        label: 'Name',
        sortable: true,
    },
    {
        name: 'category',
        label: 'Category',
        sortable: true,
    },
    {
        name: 'requiredToothInfo',
        label: 'Require TF?',
        sortable: true,
        type: 'bool',
    },
];

const TreatmentType = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        if (removeData && removeData.id) {
            return TreatmentTypeApi.removeById(removeData.id);
        } else if (Array.isArray(removeData) && removeData.length > 0) {
            const removeIds = removeData.map((item) => item.id);
            return TreatmentTypeApi.removeAll(removeIds);
        }
    };

    const handleDetail = (item) => {
        let url = '/treatmentType/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    const handleImport = async (result) => {
        return TreatmentTypeApi.importData(result);
    };

    return (
        <>
            <MasterTable
                title="Treatment Types"
                fields={TREATMENT_TYPE_TABLE_FIELDS}
                importFields={['id', 'code', 'name', 'category']}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onAddNew={() => handleDetail(null)}
                onRemove={handleRemoveData}
                onImport={handleImport}
                onError={handleError}
            />
        </>
    );
};

export default withRouter(TreatmentType);
