import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { FCM_VAPID_KEY, FIREBASE_CONFIG } from '../config/Constant';

const app = initializeApp(FIREBASE_CONFIG);
const messaging = getMessaging(app);

export const requestToken = () => {
    return getToken(messaging, { vapidKey: FCM_VAPID_KEY });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
