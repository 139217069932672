import ApiManager from '../util/ApiManager';

export const TREATMENT_TYPE_GRADE = ['A', 'B', 'C', 'D'];

class DoctorApi extends ApiManager {
    constructor() {
        super('/clinic/doctors');
    }
}

export default new DoctorApi();
