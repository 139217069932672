import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Grid, Paper, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import type { PaperProps } from '@material-ui/core';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import FormatManager from '../../util/FormatManager';

const styles = makeStyles((theme) => ({
    chartControl: {
        padding: theme.spacing(2, 0),
    },
    chartContainer: {
        width: '100%',
        height: 360,
    },
}));

export interface BalanceChartProps extends PaperProps {
    data: Array<Object>;
    onTypeChange: (type) => void;
    startDate: Date;
}

const MONTH_NAMES = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export default function BalanceChart(props: BalanceChartProps) {
    const classes = styles();
    const theme = useTheme();
    const { startDate, data, onTypeChange, ...paperProps } = props;

    const [chartType, setChartType] = useState('w');
    const [chartData, setChartData] = useState([]);

    const handleChartTypeChange = () => {
        const newCharType = chartType === 'w' ? 'm' : 'w';
        setChartType(newCharType);

        if (onTypeChange) {
            onTypeChange(newCharType);
        }
    };

    useEffect(() => {
        //Process Data
        let displayData = [];
        if (chartType === 'm') {
            displayData = MONTH_NAMES.map((name, idx) => {
                const amountIdx = data.findIndex((x) => x.name == idx.toString());
                const amount = amountIdx < 0 ? 0 : data[amountIdx].amount;
                return {
                    name,
                    amount,
                };
            });
        } else if (chartType === 'w') {
            const start = startDate.getDate();
            for (let d = 0; d < 7; d++) {
                const date = new Date(startDate.getTime());
                date.setDate(start - (6 - d));
                const name = FormatManager.formatDate(date.getTime(), 'LLL dd');
                const amountIdx = data.findIndex((x) => x.name === name);
                const amount = amountIdx < 0 ? 0 : data[amountIdx].amount;
                displayData.push({
                    name,
                    amount,
                });
            }
        }
        setChartData(displayData);

        //eslint-disable-next-line
    }, [data]);

    return (
        <Paper {...paperProps}>
            <Grid container>
                <Grid item className={classes.chartControl} container justifyContent="center" alignItems="center">
                    <ButtonGroup variant="contained" size="small" disableElevation>
                        <Button color={chartType === 'w' ? 'primary' : 'default'} onClick={handleChartTypeChange}>
                            Weekly
                        </Button>
                        <Button color={chartType === 'm' ? 'primary' : 'default'} onClick={handleChartTypeChange}>
                            Monthly
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={chartData}
                            barSize={25}
                            margin={{
                                top: theme.spacing(1),
                                right: theme.spacing(3),
                                bottom: theme.spacing(2),
                                left: theme.spacing(1),
                            }}
                        >
                            <CartesianGrid strokeDasharray="1 2" />
                            <XAxis dataKey="name" height={60} />
                            <YAxis
                                dataKey="amount"
                                width={chartType === 'm' ? 100 : 80}
                                unit="K"
                                tickFormatter={(tick) => FormatManager.formatNumeral(tick / 1000, '0,0')}
                            />
                            <Tooltip />
                            <Bar dataKey="amount" fill={theme.palette.primary.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Paper>
    );
}
