import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    Zoom,
    makeStyles,
    Icon,
    Button,
    Tooltip,
    IconButton,
    FormControlLabel,
    Switch,
    useTheme,
} from '@material-ui/core';
import type { DialogProps } from '@material-ui/core';
import ToothTable from './ToothTable';

export interface ToothPickerProps extends DialogProps {
    title: String;
    show: boolean;
    data?: Array<Object> | Object;
    multi?: boolean;
    selectedType: 'normal' | 'marked' | 'fixed' | 'processing';
    onClose?: (result: Object | Array<Object>) => void;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom in ref={ref} {...props} />;
});

const styles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid ' + theme.palette.divider,
        borderBottom: '1px solid ' + theme.palette.divider,
        padding: 0,
    },
    header: {
        padding: theme.spacing(1, 2),
    },
    closeButton: {
        color: theme.palette.text.primary,
    },
    action: {
        padding: theme.spacing(1, 2),
    },
}));

const ToothPicker = (props: ToothPickerProps) => {
    const classes = styles();
    const theme = useTheme();
    const { title, multi, data, selectedType, show, fields, onClose } = props;
    const [isChild, setChild] = useState(false);
    const [checked, setChecked] = useState(() => {
        if (multi && !data) {
            return [];
        }

        return data;
    });

    //Set value if props.value changed.
    React.useEffect(() => {
        if (multi && !data) {
            setChecked([]);
        } else {
            setChecked(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleItemClick = (item) => {
        if (multi) {
            const idx = checked.findIndex((t) => t.code === item);
            const update = idx >= 0 ? checked.filter((t) => t.code !== item) : [...checked, { code: item, type: selectedType }];
            setChecked(update);
        } else {
            onClose(item);
        }
    };

    const handleClose = (action) => {
        if (!multi) {
            onClose(false);
            return;
        }
        if (action) {
            onClose(checked);
        } else {
            setChecked(data || []);
            onClose(false);
        }
    };

    return (
        <Dialog maxWidth="lg" onClose={() => handleClose(false)} open={show} TransitionComponent={Transition}>
            <DialogTitle className={classes.header}>
                <Grid container>
                    <Grid container item lg={8} md={8} sm={8} xs={8} alignItems="center" justifyContent="flex-start">
                        <Typography variant="h6" component="h1" noWrap>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid container item lg={4} md={4} sm={4} xs={4} alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Close Dialog">
                            <IconButton size="small" className={classes.closeButton} onClick={() => handleClose(false)} aria-label="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <ToothTable data={checked} onItemClick={handleItemClick} isChild={isChild} />
            </DialogContent>
            {multi ? (
                <Grid className={classes.action} container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch checked={isChild} onChange={(event) => setChild(event.target.checked)} name="checkedB" color="primary" />
                            }
                            label="Child Table"
                        />
                    </Grid>
                    <Grid item>
                        <Button style={{ marginRight: theme.spacing(1) }} onClick={() => handleClose(true)} color="primary" variant="contained">
                            <Icon>done</Icon> Ok
                        </Button>
                        <Button onClick={() => handleClose(false)} color="default" variant="contained">
                            <Icon>done</Icon> Cancel
                        </Button>
                    </Grid>
                </Grid>
            ) : null}
        </Dialog>
    );
};

ToothPicker.defaultProps = {
    title: 'Tooth Table',
    data: [],
    multi: true,
    selectedType: 'marked',
    onClose: (result) => {
        console.log('Selected Data => ', result);
    },
};

export default ToothPicker;
