import React from 'react';
import { Grid, Icon, Paper, makeStyles } from '@material-ui/core';
import type { PaperProps } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 160,
        display: 'flex',
    },
    title: {
        ...theme.typography.button,
        textAlign: 'center',
        marginBottom: theme.spacing(0.5),
    },
    body: {
        ...theme.typography.body1,
        fontWeight: 'bolder',
        textAlign: 'center',
    },
}));

export interface CounterCardProps extends PaperProps {
    icon: string;
    iconColor: string;
    title: string;
    data: string;
}

export default function SummaryCard(props: CounterCardProps) {
    const classes = styles();
    const { title, data, icon, iconColor, ...cardProps } = props;

    return (
        <Paper className={classes.root} {...cardProps}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Icon fontSize="large" style={{ color: iconColor }}>
                        {icon}
                    </Icon>
                </Grid>
                <Grid item className={classes.title}>
                    {title}
                </Grid>
                <Grid item className={classes.body}>
                    {data}
                </Grid>
            </Grid>
        </Paper>
    );
}
