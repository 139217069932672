import { Grid } from '@material-ui/core';
import React from 'react';
import { CheckboxInput, DateTimeInput, ObjectInput, TextInput } from '../../component/control';
import PatientTypeApi from '../../api/PatientTypeApi';
import { PATIENT_TYPE_TABLE_FIELDS } from '../../page/setup/PatientType';
import TreatmentTypeApi from '../../api/TreatmentTypeApi';
import { TREATMENT_TYPE_TABLE_FIELDS } from '../../page/setup/TreatmentType';
import PostApi from '../../api/PostApi';

export interface PostSettingFormProps {
    data: Object;
    onChange: (Object) => void;
    onError: (key, value) => void;
}

const PostSettingForm = (props: PostSettingFormProps) => {
    const { data, onChange, onError } = props;

    const handleInputChange = (key, value) => {
        if (onChange) {
            onChange(key, value);
        }
    };

    const handlePostData = async (currentPage, pageSize, sort, search) => {
        return await PostApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handlePatientTypeData = async (currentPage, pageSize, sort, search) => {
        return await PatientTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleTreatmentData = async (currentPage, pageSize, sort, search) => {
        return await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    return (
        <>
            <Grid container item>
                <ObjectInput
                    variant="outlined"
                    id="patientType"
                    label="Patient Type"
                    icon="category"
                    onLoadData={handlePatientTypeData}
                    onLoadItem={(item) => `${item.name}`}
                    onChange={(event) => handleInputChange('patientTypes', event.target.value)}
                    values={data?.patientTypes}
                    fields={PATIENT_TYPE_TABLE_FIELDS}
                    multi={true}
                />
            </Grid>
            <Grid container item>
                <ObjectInput
                    variant="outlined"
                    id="treatmentType"
                    label="Treatment Type"
                    icon="category"
                    onLoadData={handleTreatmentData}
                    onLoadItem={(item) => `${item.name}(${item.code})`}
                    onChange={(event) => handleInputChange('treatmentTypes', event.target.value)}
                    values={data?.treatmentTypes}
                    fields={TREATMENT_TYPE_TABLE_FIELDS}
                    required={true}
                    multi={true}
                />
            </Grid>
            <Grid direction="row" spacing={3} container>
                <Grid container item lg={6} md={6} sm={12}>
                    <DateTimeInput
                        type="datetime"
                        id="publishedAt"
                        icon="calendar_today"
                        label="Published At"
                        value={data?.publishedAt}
                        onChange={(event) => handleInputChange('publishedAt', event.target.value)}
                    />
                </Grid>
                <Grid container item lg={6} md={6} sm={12}>
                    <DateTimeInput
                        type="datetime"
                        id="endDate"
                        icon="calendar_today"
                        label="End Date"
                        value={data?.endDate}
                        onChange={(event) => handleInputChange('endDate', event.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container item>
                {/* <ObjectInput
                    variant="outlined"
                    id="relatedPost"
                    label="Related Post"
                    icon="signpost"
                    onLoadData={handlePostData}
                    onLoadItem={(item) => {
                        const { text: displayText, readMore } = FormatManager.compressText(item.content, 100, 1);
                        return displayText + (readMore ? ' ...' : '');
                    }}
                    onChange={(event) => handleInputChange('relatedPost', event.target.value)}
                    value={data?.relatedPost}
                    fields={POST_TABLE_FIELDS}
                /> */}
            </Grid>
            <Grid item>
                <TextInput
                    id="externalUrl"
                    icon="language"
                    label="External URL"
                    type="url"
                    value={data?.externalUrl}
                    onChange={(event) => handleInputChange('externalUrl', event.target.value)}
                />
            </Grid>
            <Grid direction="row" alignItems="center" container>
                <Grid container item lg={4} md={4} sm={12}>
                    <CheckboxInput
                        id="appNoti"
                        label="Send App Noti?"
                        value="noti"
                        onChange={(event) => handleInputChange('appNoti', event.target.checked)}
                        checked={data?.appNoti || false}
                    />
                </Grid>
                <Grid container item lg={4} md={4} sm={12}>
                    <CheckboxInput
                        id="smsNoti"
                        label="Send SMS?"
                        value="sms"
                        onChange={(event) => handleInputChange('smsNoti', event.target.checked)}
                        checked={data?.smsNoti || false}
                    />
                </Grid>
                <Grid container item lg={4} md={4} sm={12}>
                    <CheckboxInput
                        id="emailNoti"
                        label="Send E-mail?"
                        value="email"
                        onChange={(event) => handleInputChange('emailNoti', event.target.checked)}
                        checked={data?.emailNoti || false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PostSettingForm;
