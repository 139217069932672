import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import {
    Typography,
    Container,
    Avatar,
    Icon,
    Grid,
    Button,
    Paper,
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
} from '@material-ui/core';

import VoucherApi from '../../api/VoucherApi';
import FormatManager from '../../util/FormatManager';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { TextInput } from '../../component/control';
import PaymentApi from '../../api/PaymentApi';

const styles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infoGrid: {
        width: '100%',
    },
    infoRow: {
        padding: theme.spacing(1, 0),
    },
    infoLabel: {
        padding: theme.spacing(2, 0, 0),
        fontWeight: 'bolder',
        width: 60,
    },
    infoSeperator: {
        padding: theme.spacing(2, 0, 0),
        width: theme.spacing(2),
    },
    infoValue: {
        textAlign: 'left',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        padding: theme.spacing(2, 0, 0),
    },
    footerLabel: {
        textAlign: 'right',
        fontWeight: 'bolder',
        padding: theme.spacing(2, 0, 0),
        width: 180,
    },
    footerValue: {
        textAlign: 'right',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        padding: theme.spacing(2, 0, 0),
    },
    formHeader: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2, 0, 3),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    formBody: {
        marginTop: theme.spacing(1),
    },
    formFooter: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
}));

const DEFAULT_PAYMENT = {
    price: 0,
    currency: 'MMK',
    exchangeRate: 1,
    tempAttach1: null,
    tempAttach2: null,
    tempAttach3: null,
};

const VoucherDetail = (props) => {
    const classes = styles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);

    const handleVoucher = (voucher) => {
        console.log('Get Voucher Info => ', voucher);
        setForm(voucher);

        let voucherItems = [];
        if (voucher.treatments) {
            voucherItems = voucherItems.concat(voucher.treatments.map((treatment) => ({ ...treatment, qty: 1, isTreament: true })));
        }
        if (voucher.items) {
            voucherItems = voucherItems.concat(voucher.items);
        }
        setItems(voucherItems);

        let total = 0;
        voucherItems.forEach((item) => {
            total += parseFloat(item?.price ?? 0) * parseFloat(item?.exchangeRate ?? 1) * parseInt(item.qty ?? 1);
        });
        setTotal(total);
    };

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [form, setForm] = useState(() => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        if (id.length === 36) {
            VoucherApi.getById(id)
                .then((data) => {
                    handleVoucher(data);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    }
                });
        }
        return {};
    });

    const getNetAmount = () => {
        const discount = form?.discountAmount ?? 0;
        return parseFloat(total - discount).toFixed(2);
    };

    const getLeftAmount = () => {
        const net = getNetAmount() ?? 0;
        const paidAmount = form?.paidAmount ?? 0;
        return parseFloat(net - paidAmount || 0).toFixed(2);
    };

    const paymentReceivePrint = async () => {
        dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
        try {
            //Print Voucher Receive COPY
            const voucher_receive_copy = await PaymentApi.printLastPaymentReceiveCopy(id);
            const print_url = URL.createObjectURL(voucher_receive_copy);

            const print_window = window.open(print_url, '_blank');
            print_window.focus();
            // printJS({
            //     printable: voucher_receive_copy,
            //     type: 'pdf',
            //     showModal: true,
            //     base64: true,
            //     documentTitle: `voucher_receive`,
            //     modalMessage: 'Printing ...',
            //     onError: (error) => {
            //         console.warn('Error => ', error);
            //         handleError('Invalid Voucher Printing!', error);
            //     },
            // });
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
        } catch (error) {
            handleError('Invalid Voucher Printing!', error);
        }
    };

    const voucherPrint = async () => {
        dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
        try {
            //Print COPY
            const voucher_copy = await VoucherApi.printVoucherCopy(id);
            const print_url = URL.createObjectURL(voucher_copy);

            const print_window = window.open(print_url, '_blank');
            print_window.focus();
            // printJS({
            //     printable: print_url,
            //     type: 'pdf',
            //     showModal: true,
            //     base64: true,
            //     documentTitle: `voucher_slip`,
            //     modalMessage: 'Printing ...',
            //     onError: (error) => {
            //         console.warn('Error => ', error);
            //         handleError('Invalid Voucher Printing!', error);
            //     },
            //     onLoadingEnd: () => {
            //         dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            //     },
            //     onPrintDialogClose: () => {
            //         URL.revokeObjectURL(voucher_copy);
            //     },
            // });
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
        } catch (error) {
            handleError('Invalid Voucher Printing!', error);
        }
    };

    //Build Branch Info with Transfer
    let branchInfo = form?.branch?.code || '';
    if (form?.appointment?.transferBranch) {
        branchInfo = form?.appointment?.transferBranch?.code + ' --> ' + form?.branch?.code;
    }

    //Combine Appointment Doctor and Treatment Doctor
    let doctorInfo = form?.doctor?.fullName || '';
    if (form?.appointment?.treatmentDoctor?.id && form?.doctor?.id !== form?.appointment?.treatmentDoctor?.id) {
        doctorInfo += `(${form?.appointment?.treatmentDoctor.fullName})`;
    }

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">receipt</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {form?.code ?? 'Voucher'}
                    </Typography>
                    <Grid className={classes.formHeader} container direction="row" justifyContent="space-between">
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Branch</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{branchInfo}</th>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Doctor</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{doctorInfo}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Date</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{FormatManager.formatDate(form?.date, 'dd MMM, yyyy')}</th>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Patient</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{form?.patient?.fullName}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formBody}>
                        <Table size="medium" className={classes.root}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell width={50}>No.</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell width={80} align="right">
                                        Qty
                                    </TableCell>
                                    <TableCell width={150} align="right">
                                        Price
                                    </TableCell>
                                    <TableCell width={110} align="right">
                                        Ex. Rate
                                    </TableCell>
                                    <TableCell align="right" width={150}>
                                        Amount (MMK)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((row, index) => {
                                    return (
                                        <TableRow key={`row_index_${index}`}>
                                            <TableCell width={50}>{index + 1}</TableCell>
                                            <TableCell>
                                                {row.name || `${row.treatmentType?.name} ${row.toothCode ? '(' + row.toothCode + ')' : ''}`}
                                            </TableCell>
                                            <TableCell width={80} align="right">
                                                {row.qty}
                                            </TableCell>
                                            <TableCell width={150} align="right">
                                                {row.price === 0 ? 'FOC' : `${row.price} ${row.currency}`}
                                            </TableCell>
                                            <TableCell width={110} align="right">
                                                {row.price === 0 ? '-' : row.exchangeRate}
                                            </TableCell>
                                            <TableCell align="right" width={150}>
                                                {row.amount * row.qty ?? 1} MMK
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid direction="row" spacing={3} container className={classes.formFooter}>
                        <Grid lg={6} md={6} sm={12} xs={12} item container direction="column" alignItems="center">
                            <TextInput
                                id="operationNote"
                                label="Operation Note"
                                value={form?.appointment?.operationNote}
                                hidePlaceHolder={true}
                                multiline={true}
                                rows={3}
                                disabled={true}
                            />
                            <TextInput id="note" label="Voucher Note" value={form?.note} multiline={true} rows={3} disabled={true} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Total Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td className={classes.footerValue}>{FormatManager.thousandSeparator(total) + ' MMK'}</td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Discount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td className={classes.footerValue}>{FormatManager.thousandSeparator(form?.discountAmount) + ' MMK'}</td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Net Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td className={classes.footerValue}>
                                            {getNetAmount() ? FormatManager.thousandSeparator(getNetAmount()) + ' MMK' : '0 MMK'}
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Paid
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td className={classes.footerValue}>{FormatManager.thousandSeparator(form?.paidAmount ?? 0) + ' MMK'}</td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Left Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td className={classes.footerValue}>
                                            {getLeftAmount() ? FormatManager.thousandSeparator(getLeftAmount()) + ' MMK' : '0 MMK'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <Grid justifyContent="flex-end" container>
                        <Button type="button" variant="contained" onClick={() => history.goBack()} style={{ marginRight: theme.spacing(1) }}>
                            <Icon>arrow_back</Icon> Go Back
                        </Button>
                        <Button
                            type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => paymentReceivePrint()}
                            style={{ marginRight: theme.spacing(1) }}
                        >
                            <Icon>print</Icon> Last Payment Receive
                        </Button>
                        <Button type="button" color="primary" variant="contained" onClick={() => voucherPrint()}>
                            <Icon>print</Icon> VOUCHER COPY
                        </Button>
                    </Grid>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(VoucherDetail);
