import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Typography, Container, Avatar, Icon, Grid, Button, Paper, makeStyles, InputAdornment } from '@material-ui/core';

import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import { CheckboxInput, DateTimeInput, ListInput, NumberInput, ObjectInput, TextInput } from '../../component/control';
import { PATIENT_TYPE_TABLE_FIELDS } from './PatientType';
import { TREATMENT_TYPE_TABLE_FIELDS } from './TreatmentType';
import PatientTypeApi from '../../api/PatientTypeApi';
import TreatmentTypeApi from '../../api/TreatmentTypeApi';
import { AVAILABLE_CURRENICES } from '../../config/Constant';
import { POST_TABLE_FIELDS } from '../cms/Post';
import FormatManager from '../../util/FormatManager';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(2, 0),
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const DiscountDetail = () => {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id, type } = useParams();

    const [form, setForm] = useState({
        currency: 'MMK',
    });
    const [isUpdate, setUpdate] = useState(id > 0);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handlePostData = async (currentPage, pageSize = 10, sort = 'publishedAt:DESC', search) => {
        return await PostApi.fakePaging(POST_TYPES.newsfeed, currentPage, pageSize, sort, search);
    };

    const handlePatientTypeData = async (currentPage, pageSize, sort, search) => {
        return await PatientTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleTreatmentData = async (currentPage, pageSize, sort, search) => {
        return await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const generateCouponCode = () => {};


    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        history.push(`/marketing/${type}`);
        console.log('Submit Banner');
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <Icon>{type === 'coupon' ? 'confirmation_number' : 'discount'}</Icon>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {type === 'coupon' ? 'Coupon' : 'Discount'} Setup
                </Typography>
                <Grid container className={classes.form}>
                    {type === 'coupon' ? (
                        <Grid direction="row" spacing={3} container>
                            <Grid lg={8} md={8} sm={6} xs={12} item container justifyContent="center">
                                <TextInput
                                    id="couponCode"
                                    icon="qr_code_scanner"
                                    label="Coupon Code"
                                    value={form?.couponCode}
                                    disabled={isUpdate}
                                    onChange={(event) => setForm({ ...form, couponCode: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <NumberInput
                                    id="couponLimit"
                                    icon="layers"
                                    label="Coupon Limit"
                                    type="number"
                                    value={form?.couponLimit}
                                    onChange={(event) => setForm({ ...form, couponLimit: event.target.value })}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid direction="row" spacing={3} container>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <ListInput
                                id="discountType"
                                label="Discount Type"
                                icon="category"
                                data={['AMOUNT', 'PERCENTAGE']}
                                value={form?.discountType}
                                onChange={(event) => setForm({ ...form, discountType: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <DateTimeInput
                                type="date"
                                id="fromDate"
                                icon="calendar_today"
                                label="From Date"
                                value={form?.fromDate}
                                onChange={(event) => setForm({ ...form, fromDate: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <DateTimeInput
                                type="date"
                                id="toDate"
                                icon="calendar_today"
                                label="To Date"
                                value={form?.toDate}
                                onChange={(event) => setForm({ ...form, toDate: event.target.value })}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid direction="row" spacing={3} container>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <ListInput
                                id="discountType"
                                label="Currency"
                                icon="currency_exchange"
                                data={AVAILABLE_CURRENICES}
                                onLoadItem={(item) => item.name}
                                value={form?.currency || 'MMK'}
                                onChange={(event) => setForm({ ...form, currency: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <NumberInput
                                id="fromAmount"
                                icon="price_change"
                                label="From Amount"
                                type="number"
                                value={form?.fromAmount}
                                onChange={(event) => setForm({ ...form, fromAmount: event.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <NumberInput
                                id="toAmount"
                                icon="price_change"
                                label="To Amount"
                                type="number"
                                value={form?.toAmount}
                                onChange={(event) => setForm({ ...form, toAmount: event.target.value })}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid direction="row" spacing={3} container>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <NumberInput
                                id="discountAmount"
                                icon="local_offer"
                                label={form.discountType == 'AMOUNT' ? 'Discount' : 'Discount %'}
                                type="number"
                                value={form?.discountAmount}
                                onChange={(event) => setForm({ ...form, discountAmount: event.target.value })}
                                inputAdornment={
                                    <InputAdornment position="end">{form.discountType == 'AMOUNT' ? form.currency.code : '%'}</InputAdornment>
                                }
                                required
                            />
                        </Grid>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <ObjectInput
                                variant="outlined"
                                id="focTreatments"
                                label="FOC Treatments"
                                icon="local_offer"
                                onLoadData={handleTreatmentData}
                                onLoadItem={(item) => `${item.name}(${item.code})`}
                                onChange={(event) => setForm({ ...form, focTreatments: event.target.value })}
                                value={form?.focTreatments}
                                fields={TREATMENT_TYPE_TABLE_FIELDS}
                                multi={true}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <ObjectInput
                            variant="outlined"
                            id="patientType"
                            label="Patient Types"
                            icon="category"
                            onLoadData={handlePatientTypeData}
                            onLoadItem={(item) => `${item.name}`}
                            onChange={(event) => setForm({ ...form, patientTypes: event.target.value })}
                            value={form?.patientTypes}
                            fields={PATIENT_TYPE_TABLE_FIELDS}
                            multi={true}
                        />
                    </Grid>
                    <Grid container item>
                        <ObjectInput
                            variant="outlined"
                            id="treatmentType"
                            label="Treatment Types"
                            icon="category"
                            onLoadData={handleTreatmentData}
                            onLoadItem={(item) => `${item.name}(${item.code})`}
                            onChange={(event) => setForm({ ...form, treatmentTypes: event.target.value })}
                            value={form?.treatmentTypes}
                            fields={TREATMENT_TYPE_TABLE_FIELDS}
                            multi={true}
                        />
                    </Grid>
                    <Grid container item>
                        <ObjectInput
                            variant="outlined"
                            id="post"
                            label="Related Post"
                            icon="feed"
                            onLoadData={handlePostData}
                            onLoadItem={(item) => {
                                const { text, readMore } = FormatManager.compressText(item.content, 30, 1);
                                return text + (readMore ? '...' : '');
                            }}
                            onChange={(event) => setForm({ ...form, post: event.target.value })}
                            value={form?.post}
                            fields={POST_TABLE_FIELDS}
                        />
                    </Grid>
                    <Grid direction="row" alignItems="center" container>
                        <Grid container item lg={4} md={4} sm={12}>
                            <CheckboxInput
                                id="appNoti"
                                label="Send App Noti?"
                                value="noti"
                                onChange={(event) => setForm({ ...form, appNoti: event.target.checked })}
                                checked={form?.appNoti || true}
                            />
                        </Grid>
                        <Grid container item lg={4} md={4} sm={12}>
                            <CheckboxInput
                                id="smsNoti"
                                label="Send SMS?"
                                value="sms"
                                onChange={(event) => setForm({ ...form, smsNoti: event.target.checked })}
                                checked={form?.smsNoti || false}
                            />
                        </Grid>
                        <Grid container item lg={4} md={4} sm={12}>
                            <CheckboxInput
                                id="smsNoti"
                                label="Send SMS?"
                                value="sms"
                                onChange={(event) => setForm({ ...form, emailNoti: event.target.checked })}
                                checked={form?.emailNoti || false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <GobackButton />
                    <Button type="submit" variant="contained" color="primary" className={classes.submit} onClick={() => handleSubmit()}>
                        <Icon>save</Icon> Save
                    </Button>
                </Grid>
            </Paper>
        </Container>
    );
};

export default withRouter(DiscountDetail);
