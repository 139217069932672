import React from 'react';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import { CheckboxInput, DateTimeInput } from '../component/control';
import { useDispatch } from 'react-redux';

export const DAY_OF_WEEKS = [
    {
        dow: 'Mon',
        label: 'Monday',
    },
    {
        dow: 'Tue',
        label: 'Tuesday',
    },
    {
        dow: 'Wed',
        label: 'Wednesday',
    },
    {
        dow: 'Thu',
        label: 'Thursday',
    },
    {
        dow: 'Fri',
        label: 'Friday',
    },
    {
        dow: 'Sat',
        label: 'Saturday',
    },
    {
        dow: 'Sun',
        label: 'Sunday',
    },
];

const defaultFromTime = new Date().setHours(9, 0);
const defaultToTime = new Date().setHours(18, 0);

const styles = makeStyles((theme) => ({}));

export interface OpenHoursFormProps {
    openInHoliday: Boolean;
    data: Array<Object>;
    onChange: (Array<Object>) => void;
    onChangeOpenInHoliday: (Boolean) => void;
    onError: (error: string | Object) => void;
}

const OpenHoursForm = (props: OpenHoursFormProps) => {
    const theme = useTheme();
    const classes = styles();
    const { data, openInHoliday, onChange, onChangeOpenInHoliday, onError, ...rest } = props;
    const dispatch = useDispatch();

    const handleOpenHour = (dow, key, value) => {
        const dataIdx = data.findIndex((item) => item.dayOfWeek === dow);
        if (dataIdx >= 0) {
            const newData = data.map((item) => {
                if (item.dayOfWeek === dow) {
                    item[key] = value;
                }
                return item;
            });
            onChange(newData);
        }
    };

    const handleDOWCheckboxChange = (dow, checked) => {
        if (checked) {
            onChange([
                ...data,
                {
                    dayOfWeek: dow,
                    fromTime: defaultFromTime,
                    toTime: defaultToTime,
                },
            ]);
        } else {
            onChange(data.filter((item) => item.dayOfWeek !== dow));
        }
    };

    const handleOpenInHolidayChange = (event) => {
        if (onChangeOpenInHoliday) {
            onChangeOpenInHoliday(event.target.checked);
        }
    };

    const renderOpenHour = (item) => {
        const openHourIdx = data.findIndex((o) => o.dayOfWeek === item.dow);
        const openHour = openHourIdx >= 0 ? data[openHourIdx] : false;

        return (
            <Grid key={item.dow} spacing={3} container item>
                <Grid container item lg={4} md={4} sm={12} xs={12} alignItems="center">
                    <CheckboxInput
                        id={item.dow}
                        onChange={(event) => handleDOWCheckboxChange(item.dow, event.target.checked)}
                        label={item.label}
                        checked={openHourIdx >= 0}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <DateTimeInput
                        type="time"
                        id={`${item.dow}_fromTime`}
                        icon="schedule"
                        label="From Time"
                        value={openHour?.fromTime}
                        disabled={openHourIdx < 0}
                        onChange={(event) => handleOpenHour(item.dow, 'fromTime', event.target.value)}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <DateTimeInput
                        type="time"
                        id={`${item.dow}_toTime`}
                        icon="schedule"
                        label="To Time"
                        disabled={openHourIdx < 0}
                        value={openHour?.toTime}
                        onChange={(event) => handleOpenHour(item.dow, 'toTime', event.target.value)}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container>
            {DAY_OF_WEEKS.map((item) => renderOpenHour(item))}
            <Grid item container justifyContent="flex-end">
                <CheckboxInput
                    id="openInHoliday"
                    label="Is open in public holiday?"
                    value={openInHoliday}
                    checked={openInHoliday}
                    onChange={handleOpenInHolidayChange}
                />
            </Grid>
        </Grid>
    );
};

OpenHoursForm.defaultProps = {
    openInHoliday: true,
    data: [],
    onChange: (data) => console.log('Change Open Hours => ', data),
    onChangeOpenInHoliday: (openInHoliday) => console.log('Change Open In Holiday => ', openInHoliday),
    onError: (error) => console.warn('Error Open Hours => ', error),
};

export default OpenHoursForm;
