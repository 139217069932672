import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, makeStyles, Avatar, Container, Icon, Paper, Button } from '@material-ui/core';
import { CheckboxInput, ImageInput, ListInput, NumberInput, ObjectInput } from '../../component/control';
import ToothInput from '../../component/tooth/ToothInput';
import MasterForm from '../../component/MasterForm';
import TreatmentPriceApi from '../../api/TreatmentPriceApi';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BranchApi from '../../api/BranchApi';
import { BRANCH_TABLE_FIELDS } from './Branch';
import TreatmentTypeApi from '../../api/TreatmentTypeApi';
import { TREATMENT_TYPE_TABLE_FIELDS } from './TreatmentType';
import { AVAILABLE_CURRENICES } from '../../config/Constant';
import { validateForm } from '../../util/ValidationManager';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const TreamentPriceDetail = (props) => {
    const classes = styles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isUpdate, setUpdate] = useState(false);
    const [requireTF, setRequireTF] = useState(false);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };
    const [form, setForm] = useState(() => {
        if (id.length === 36) {
            TreatmentPriceApi.getById(id)
                .then((data) => {
                    setForm({
                        ...data,
                        tempImage1: data.image1,
                        tempImage2: data.image2,
                        tempImage3: data.image3,
                        toothCodes: data.toothCodes.map((code) => ({ code: code, type: 'marked' })),
                    });
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }
        return {};
    });

    const handleBranchData = async (currentPage, pageSize, sort, search) => {
        return await BranchApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleTreatmentType = async (currentPage, pageSize, sort, search) => {
        return await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleIsFocChanged = (event) => {
        if (event.target.checked) {
            setForm({ ...form, minPrice: 0, maxPrice: 0, defaultPrice: 0, currency: null, foc: event.target.checked });
        } else {
            setForm({ ...form, foc: event.target.checked });
        }
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }
        const isValid = validateForm(
            form,
            [
                { fieldId: 'branch', required: true },
                { fieldId: 'treatmentType', required: true },
                { fieldId: 'toothCodes', required: requireTF, message: 'Please select at least one tooth code' },
            ],
            handleError,
        );

        if (!isValid) {
            return;
        }

        try {
            let price = { ...form };
            price.toothCodes = form.toothCodes.map((t) => t.code);
            if (form.foc) {
                price.minPrice = 0;
                price.maxPrice = 0;
                price.defaultPrice = 0;
                price.currency = 'MMK';
            }

            console.log('Save Form => ', price);
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            //Save Temp Image 1
            if (form.tempImage1 && form.tempImage1.id) {
                price.image1 = form.tempImage1;
            } else if (form.tempImage1) {
                const fileResponse = await TreatmentPriceApi.fileUpload(form.tempImage1, 'image1');
                if (fileResponse) {
                    price.image1 = fileResponse;
                }
            } else {
                price.image1 = null;
            }

            //Save Temp Image 2
            if (form.tempImage2 && form.tempImage2.id) {
                price.image2 = form.tempImage2;
            } else if (form.tempImage2) {
                const fileResponse = await TreatmentPriceApi.fileUpload(form.tempImage2, 'image2');
                if (fileResponse) {
                    price.image2 = fileResponse;
                }
            } else {
                price.image2 = null;
            }

            //Save Temp Image 3
            if (form.tempImage3 && form.tempImage3.id) {
                price.image3 = form.tempImage3;
            } else if (form.tempImage3) {
                const fileResponse = await TreatmentPriceApi.fileUpload(form.tempImage2, 'image3');
                if (fileResponse) {
                    price.image3 = fileResponse;
                }
            } else {
                price.image3 = null;
            }

            let message = '';
            if (isUpdate) {
                await TreatmentPriceApi.modifyById(id, price);
                message = `Price has been updated successfully.`;
            } else {
                await TreatmentPriceApi.addNew(price);
                message = `Price has been created successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });

            history.push('/treatmentPrice');
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <Container component="main">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon>sell</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Pricing
                    </Typography>
                    <MasterForm>
                        <Grid container direction="column" className={classes.form}>
                            <Grid direction="row" spacing={3} container>
                                <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                    <ObjectInput
                                        id="branch"
                                        label="Branch"
                                        icon="store"
                                        onLoadData={handleBranchData}
                                        onLoadItem={(item) => `${item.name} (${item.code})`}
                                        onChange={(event) => setForm({ ...form, branch: event.target.value })}
                                        value={form?.branch}
                                        fields={BRANCH_TABLE_FIELDS}
                                        required={true}
                                    />
                                </Grid>
                                <Grid lg={6} md={6} sm={6} xs={12} item container justifyContent="center">
                                    <ObjectInput
                                        id="treatmentType"
                                        label="Type"
                                        icon="category"
                                        onLoadData={handleTreatmentType}
                                        onLoadItem={(item) => `${item.name} (${item.code})`}
                                        onChange={(event) => {
                                            const type = event.target.value;
                                            setRequireTF(type?.requiredToothInfo ?? false);
                                            setForm({ ...form, toothCodes: [], treatmentType: type });
                                        }}
                                        value={form?.treatmentType}
                                        fields={TREATMENT_TYPE_TABLE_FIELDS}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid direction="row" spacing={3} container justifyContent="center" alignItems="center">
                                <Grid item justifyContent="center" alignItems="center" lg={10} md={10} sm={12} xs={12}>
                                    <ToothInput
                                        id="toothCode"
                                        icon="code"
                                        label="Tooth Code"
                                        disabledLoad={!requireTF}
                                        onLoadItem={(item) => item.code}
                                        values={form?.toothCodes}
                                        onChange={(event) => {
                                            setForm({ ...form, toothCodes: event.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item justifyContent="center" lg={2} md={2} sm={12} xs={12}>
                                    <CheckboxInput
                                        id="isFoc"
                                        label="Is FOC?"
                                        value={form?.foc}
                                        onChange={handleIsFocChanged}
                                        checked={form?.foc || false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid direction="row" spacing={3} container>
                                <Grid direction="row" lg={3} md={3} sm={6} xs={12} item container justifyContent="center">
                                    <NumberInput
                                        id="defaultPrice"
                                        icon="payments"
                                        label="Default"
                                        disabled={form?.foc}
                                        value={form?.defaultPrice}
                                        onChange={(event) => setForm({ ...form, defaultPrice: event.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid lg={3} md={3} sm={6} xs={12} item container justifyContent="center">
                                    <NumberInput
                                        id="minPrice"
                                        icon="payments"
                                        label="Min"
                                        disabled={form?.foc}
                                        value={form?.minPrice}
                                        onChange={(event) => setForm({ ...form, minPrice: event.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid lg={3} md={3} sm={6} xs={12} item container justifyContent="center">
                                    <NumberInput
                                        id="maxPrice"
                                        icon="payments"
                                        label="Max"
                                        disabled={form?.foc}
                                        value={form?.maxPrice}
                                        onChange={(event) => setForm({ ...form, maxPrice: event.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid lg={3} md={3} sm={6} xs={12} item container justifyContent="center">
                                    <ListInput
                                        id="currency"
                                        label="Currency"
                                        data={AVAILABLE_CURRENICES}
                                        onLoadItem={(item) => item.code}
                                        value={form.currency ? AVAILABLE_CURRENICES.find((c) => c.code === form.currency) : null}
                                        onChange={(event) => setForm({ ...form, currency: event.target.value.code })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid direction="row" spacing={3} container>
                            <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                <ImageInput
                                    size={{ width: 240, height: 160 }}
                                    id="image1"
                                    guild="TREATMENT_PRICE"
                                    enableFilePicker={true}
                                    value={form?.tempImage1}
                                    onChange={(event) => setForm({ ...form, tempImage1: event.target.value })}
                                />
                            </Grid>
                            <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                <ImageInput
                                    size={{ width: 240, height: 160 }}
                                    id="image2"
                                    guild="TREATMENT_PRICE"
                                    enableFilePicker={true}
                                    value={form?.tempImage2}
                                    onChange={(event) => setForm({ ...form, tempImage2: event.target.value })}
                                />
                            </Grid>
                            <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                <ImageInput
                                    size={{ width: 240, height: 160 }}
                                    id="image3"
                                    guild="TREATMENT_PRICE"
                                    enableFilePicker={true}
                                    value={form?.tempImage3}
                                    onChange={(event) => setForm({ ...form, tempImage3: event.target.value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid justifyContent="flex-end" container>
                            <GobackButton />
                            <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.submit}>
                                <Icon color="action">save</Icon> Save
                            </Button>
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(TreamentPriceDetail);
