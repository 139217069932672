import React, { useState, createRef } from 'react';
import { IconButton, makeStyles, Icon, Grid, Typography, Tooltip } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import FileImage from '../file/FileImage';

const styles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2, 0, 1, 0),
    },
    itemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 1, 0),
    },
    uploadBox: {
        minHeight: 120,
        minWidth: 120,
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        color: theme.palette.action.disabled,
        transition: 'border .24s ease-in-out',
        border: '2px dashed ' + theme.palette.info.main,
        background: theme.palette.background.default,
        cursor: 'pointer',
    },
    thumbnailContainer: {
        position: 'relative',
    },
    thumbnail: {
        maxWidth: 120,
        maxHeight: 120,
        width: 'auto',
        height: 'auto',
        cursor: 'pointer',
    },
    removeButton: {
        position: 'absolute',
        top: -12,
        right: -12,
        backgroundColor: theme.palette.error.main,
        color: 'white',
        padding: 0,
        width: 25,
        height: 25,
        fontSize: 12,
        border: 'none',
        cursor: 'pointer',
        borderRadius: 40,
    },
}));

export interface MultiImageInputProps {
    id: string;
    name: string;
    values: Array<Object>;
    onChange: (Array<Object>) => void;
    onClickItem?: (item, idx) => void;
}

const MultiImageInput = (props: MultiImageInputProps) => {
    const classes = styles();
    const { id, name, values, onChange, onClickItem, ...rest } = props;
    const [images, setImages] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(-1);

    const inputUpload = createRef();
    //Set value if props.value changed.
    React.useEffect(() => {
        if (images.length == 1 && Object.entries(images[0]).length === 0) {
            setImages(values ? [...values] : []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const handleChange = (updatedImages) => {
        console.log('Updated => ', updatedImages);
        setImages(updatedImages);

        if (onChange) {
            onChange({
                target: {
                    id: id || name,
                    name: name || id,
                    value: updatedImages,
                    type: 'multi-image',
                },
            });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedImages) => {
            console.log('Accepted Images => ', acceptedImages);
            const addedImages = acceptedImages.map((image, idx) =>
                Object.assign(image, {
                    preview: image.type.startsWith('image') ? URL.createObjectURL(image) : null,
                    id: idx + '-' + image.name,
                }),
            );
            handleChange([...images, ...addedImages]);
        },
    });

    const handleMove = (idx) => {
        let img1 = Object.assign({}, images[idx]);
        let img2 = Object.assign({}, images[idx + 1]);
        let imgs = Object.assign([], images);
        imgs[idx] = img2;
        imgs[idx + 1] = img1;
        handleChange(imgs);
    };

    const handleImageClick = (image, idx) => {
        setSelectedIdx(idx);
        if (onClickItem) {
            onClickItem(image, idx);
        }
    };

    const handleRemove = (image, idx) => {
        if (images[idx].preview) {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(images[idx].preview);
        }

        const updatedImages = images.filter((f, i) => i !== idx);
        handleChange(updatedImages);
    };

    const renderImages = (image, idx) => {
        if (!image || idx < 0) {
            return;
        }

        return (
            <div key={`uploaded_image_${idx}`} className={classes.itemContainer}>
                <div className={classes.thumbnailContainer}>
                    <FileImage
                        onClick={() => handleImageClick(image, idx)}
                        className={classes.thumbnail}
                        file={image}
                        defaultImage="/images/file.png"
                        alt={image.name || 'Uploaded Image'}
                    />
                    <Tooltip title="Remove" aria-label="remove">
                        <IconButton onClick={() => handleRemove(image, idx)} className={classes.removeButton}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
                {idx < images.length - 1 ? (
                    <IconButton
                        onClick={() => {
                            handleMove(idx);
                        }}
                        className={classes.moveButton}
                        color="default"
                    >
                        <Icon>repeat</Icon>
                    </IconButton>
                ) : null}
            </div>
        );
    };

    return (
        <>
            <Grid direction="row" container alignItems="center">
                {images.map(renderImages)}
            </Grid>
            <Grid container item alignItems="center" className={classes.container}>
                <div {...getRootProps({ className: classes.uploadBox })}>
                    <input {...getInputProps()} />
                    <Icon fontSize="large">photo_library</Icon>
                    <Typography variant="caption" color="inherit">
                        Add Images
                    </Typography>
                </div>
            </Grid>
        </>
    );
};

export default MultiImageInput;
