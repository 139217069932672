import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Typography, Container, Avatar, Icon, Grid, Button, Paper, makeStyles } from '@material-ui/core';

import BranchApi, { BRANCH_STATUS } from '../../api/BranchApi';
import MasterForm from '../../component/MasterForm';
import { AVAILABLE_CURRENICES } from '../../config/Constant';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { ImageInput, ListInput, NumberInput, TextInput, ObjectInput } from '../../component/control';
import ContactForm2 from '../../fragment/ContactForm2';
import { validateForm } from '../../util/ValidationManager';
import ReportApi from '../../api/ReportApi';
import BranchDoctorForm from '../../fragment/BranchDoctorForm';
import OpenHoursForm from '../../fragment/OpenHoursForm';
import { REPORT_TABLE_FIELDS } from '../reporting/Report';
import GobackButton from '../../component/control/GobackButton';

const styles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const BranchDetail = (props) => {
    const classes = styles();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isUpdate, setUpdate] = useState(false);
    const [pointRate, setPointRate] = useState(0.0);

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [form, setForm] = useState(() => {
        if (id > 0) {
            BranchApi.getById(id)
                .then((data) => {
                    setForm({
                        ...data,
                        tempImage1: data.image1,
                        tempImage2: data.image2,
                        tempImage3: data.image3,
                    });
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }
        return {};
    });

    const handleReportData = async (currentPage, pageSize, sort, search) => {
        return await ReportApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }
        console.log('Form => ', form);

        const isValid = validateForm(
            form,
            [
                { fieldId: 'code', required: true },
                { fieldId: 'name', required: true },
                {
                    fieldId: 'pointExchange',
                    rules: [
                        {
                            type: 'pattern',
                            pattern: /^\d+=\d+$/,
                            message: 'Invalid point exchange format.',
                        },
                    ],
                },
                { fieldId: 'address', required: true },
                { fieldId: 'phones', required: true },
                { fieldId: 'emails', required: true },
            ],
            handleError,
        );

        if (!isValid) {
            return;
        }

        try {
            let branch = { ...form };
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });

            //Save Temp Image 1
            if (form.tempImage1 && form.tempImage1.id) {
                branch.image1 = form.tempImage1;
            } else if (form.tempImage1) {
                const fileResponse = await BranchApi.fileUpload(form.tempImage1, 'image1');
                if (fileResponse) {
                    branch.image1 = fileResponse;
                }
            } else {
                branch.image1 = null;
            }

            //Save Temp Image 2
            if (form.tempImage2 && form.tempImage2.id) {
                branch.image2 = form.tempImage2;
            } else if (form.tempImage2) {
                const fileResponse = await BranchApi.fileUpload(form.tempImage2, 'image2');
                if (fileResponse) {
                    branch.image2 = fileResponse;
                }
            } else {
                branch.image2 = null;
            }

            //Save Temp Image 3
            if (form.tempImage3 && form.tempImage3.id) {
                branch.image3 = form.tempImage3;
            } else if (form.tempImage3) {
                const fileResponse = await BranchApi.fileUpload(form.tempImage2, 'image3');
                if (fileResponse) {
                    branch.image3 = fileResponse;
                }
            } else {
                branch.image3 = null;
            }

            let message = '';
            if (isUpdate) {
                const response = await BranchApi.modifyById(id, branch);
                message = `Branch ${response.code} has been updated successfully.`;
            } else {
                const response = await BranchApi.addNew(branch);
                message = `Branch ${response.code} has been registered successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });
            history.push('/branch');
        } catch (error) {
            handleError(error);
        }
    };

    const renderInfoForm = () => {
        return (
            <Grid container direction="column">
                <Grid direction="row" spacing={3} container>
                    <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                        <ImageInput
                            size={{ width: 240, height: 160 }}
                            id="image1"
                            enableFilePicker={true}
                            value={form?.tempImage1}
                            guild="BRANCH"
                            onChange={(event) => setForm({ ...form, tempImage1: event.target.value })}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                        <ImageInput
                            size={{ width: 240, height: 160 }}
                            id="image2"
                            enableFilePicker={true}
                            value={form?.tempImage2}
                            guild="BRANCH"
                            onChange={(event) => setForm({ ...form, tempImage2: event.target.value })}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                        <ImageInput
                            size={{ width: 240, height: 160 }}
                            id="image3"
                            enableFilePicker={true}
                            value={form?.tempImage3}
                            guild="BRANCH"
                            onChange={(event) => setForm({ ...form, tempImage3: event.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid direction="row" spacing={3} container>
                    <Grid lg={3} md={3} sm={5} xs={12} item container justifyContent="center">
                        <TextInput
                            id="code"
                            icon="code"
                            label="Code"
                            value={form?.code}
                            disabled={isUpdate}
                            onChange={(event) => (isUpdate ? console.log('Code => ', form?.code) : setForm({ ...form, code: event.target.value }))}
                            required
                        />
                    </Grid>
                    <Grid lg={6} md={6} sm={7} xs={12} item container justifyContent="center">
                        <TextInput
                            id="name"
                            icon="business"
                            label="Name"
                            value={form?.name}
                            onChange={(event) => setForm({ ...form, name: event.target.value })}
                            required
                        />
                    </Grid>
                    <Grid lg={3} md={3} sm={12} xs={12} item container>
                        <ListInput
                            id="status"
                            label="Status"
                            data={BRANCH_STATUS}
                            value={form?.status || 'ACTIVE'}
                            onChange={(event) => setForm({ ...form, status: event.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <TextInput
                        id="description"
                        label="Description"
                        rows={4}
                        multiline={true}
                        value={form?.description}
                        onChange={(event) => setForm({ ...form, description: event.target.value })}
                    />
                </Grid>
                <Grid direction="row" spacing={3} container>
                    <Grid lg={6} md={6} sm={12} xs={12} item container justifyContent="center">
                        <ObjectInput
                            variant="outlined"
                            id="report"
                            label="Voucher Report"
                            icon="description"
                            onLoadData={handleReportData}
                            onLoadItem={(item) => item.name}
                            onChange={(event) => setForm({ ...form, voucherReport: event.target.value })}
                            value={form?.voucherReport}
                            fields={REPORT_TABLE_FIELDS}
                            multi={false}
                        />
                    </Grid>
                    <Grid lg={6} md={6} sm={12} xs={12} item container justifyContent="center">
                        <ObjectInput
                            variant="outlined"
                            id="report"
                            label="Payment Receive Report"
                            icon="description"
                            onLoadData={handleReportData}
                            onLoadItem={(item) => item.name}
                            onChange={(event) => setForm({ ...form, paymentReceiveReport: event.target.value })}
                            value={form?.paymentReceiveReport}
                            fields={REPORT_TABLE_FIELDS}
                            multi={false}
                        />
                    </Grid>
                </Grid>
                <Grid direction="row" spacing={3} container>
                    <Grid lg={2} md={2} sm={6} xs={12} item container justifyContent="center">
                        <NumberInput
                            id="parkingLot"
                            icon="garage"
                            label="Parking Lot"
                            value={form?.parkingLot}
                            onChange={(event) => setForm({ ...form, parkingLot: event.target.value })}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={6} xs={12} item container justifyContent="center">
                        <TextInput
                            id="pointExchange"
                            icon="currency_exchange"
                            label="Amount=Point"
                            placeHolder="Amount=Point"
                            value={form?.pointExchange}
                            onChange={(event) => setForm({ ...form, pointExchange: event.target.value })}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                        <NumberInput
                            id="serviceFee"
                            icon="payments"
                            label="Service Fee"
                            value={form?.serviceFee}
                            onChange={(event) => setForm({ ...form, serviceFee: event.target.value })}
                            required
                        />
                    </Grid>
                    <Grid lg={2} md={2} sm={5} xs={12} item container justifyContent="center">
                        <ListInput
                            id="currency"
                            label="Currency"
                            data={AVAILABLE_CURRENICES}
                            onLoadItem={(item) => item.code}
                            value={form.currency ? AVAILABLE_CURRENICES.find((c) => c.code === form.currency) : null}
                            onChange={(event) => setForm({ ...form, currency: event.target.value.code })}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderBranchHours = () => {
        return (
            <OpenHoursForm
                onChange={(data) => setForm({ ...form, openingHours: data })}
                data={form?.openingHours}
                openInHoliday={form?.openInHoliday}
                onChangeOpenInHoliday={(status) => setForm({ ...form, openInHoliday: status })}
            />
        );
    };

    const renderContacts = () => {
        return (
            <ContactForm2
                onChangeAddress={(data) => setForm({ ...form, address: data })}
                address={form.address}
                onChangePhones={(data) => setForm({ ...form, phones: data })}
                phones={form.phones}
                onChangeEmails={(data) => setForm({ ...form, emails: data })}
                emails={form.emails}
                onError={handleError}
            />
        );
    };

    const renderBranchDoctors = () => {
        return <BranchDoctorForm data={form.doctors} onChange={(data) => setForm({ ...form, doctors: data })} onError={handleError} />;
    };

    const tabFields = [
        {
            label: 'Info',
            icon: 'info',
            content: renderInfoForm(),
        },
        {
            label: 'Open Hours',
            icon: 'watch_later',
            content: renderBranchHours(),
        },
        {
            label: 'Contacts',
            icon: 'contacts',
            content: renderContacts(),
        },
        {
            label: 'Doctors',
            icon: 'medication',
            content: renderBranchDoctors(),
        },
    ];
    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">medication</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Branch Registration
                    </Typography>
                    <MasterForm type="tab" fields={tabFields}>
                        <Grid justifyContent="flex-end" container>
                            <GobackButton />
                            <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.submit}>
                                <Icon color="action">save</Icon> Save
                            </Button>
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(BranchDetail);
