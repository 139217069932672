import { Button, Grid, Icon, IconButton, makeStyles, ThemeProvider, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import TreatmentTypeApi from '../api/TreatmentTypeApi';
import { SuccessTheme } from '../config/Theme';
import { CheckboxInput, ObjectInput, TextInput } from '../component/control';
import DataTable from '../component/table';
import ToothInput from '../component/tooth/ToothInput';
import { TREATMENT_TYPE_TABLE_FIELDS } from '../page/setup/TreatmentType';
import { validateForm } from '../util/ValidationManager';
import { v4 as uuid } from 'uuid';

export const APPOINTMENT_TREATMENT_TABLE_FIELDS = [
    {
        name: 'id',
        align: 'right',
        label: 'No.',
        onLoad: (data, rowIdx) => rowIdx + 1,
    },
    {
        name: 'treatmentType',
        align: 'left',
        label: 'Type',
        sortable: true,
        type: 'raw',
        onLoad: (data, rowIdx) => `${data.treatmentType?.name} (${data.treatmentType?.code})`,
    },
    {
        name: 'toothCode',
        label: 'Tooth Code',
        sortable: true,
        // type: 'raw',
        // onLoad: (data, rowIdx) => {
        //     return (
        //         <p>
        //             <img src={`/images/tooths/marked/${data.toothCode}.png`} alt={data?.toothCode} height="32" />
        //             {data}
        //         </p>
        //     );
        // },
    },
    {
        name: 'processing',
        label: 'On Processing',
        type: 'bool',
        align: 'center',
        sortable: true,
    },
    {
        name: 'note',
        label: 'Note',
        sortable: true,
    },
];

const styles = makeStyles((theme) => ({
    submit: {},
}));

export interface AppointmentTreatmentFormProps {
    isOperation: Boolean;
    treatments: Array;
    onChange: (Array<Object>) => void;
    onError: (error: string | Object) => void;
}

const AppointmentTreatmentForm = (props: AppointmentTreatmentFormProps) => {
    const classes = styles();
    const theme = useTheme();
    const { treatments, isOperation, onChange, onError } = props;
    const [treatment, setTreatment] = useState({});
    const [requireTF, setRequireTF] = useState(false);

    const handleTreatmentData = async (currentPage, pageSize, sort, search) => {
        return await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleSave = async () => {
        const isValid = validateForm(
            treatment,
            [
                { fieldId: 'treatmentType', required: true },
                { fieldId: 'toothCodes', required: requireTF, message: 'Please select at least one tooth code' },
            ],
            onError,
        );
        if (isValid) {
            if (treatment?.toothCodes?.length > 0) {
                const saveData = treatment.toothCodes.map((tooth) => {
                    let t = {
                        treatmentType: treatment.treatmentType,
                        toothCode: tooth.code,
                        note: treatment?.note,
                        processing: treatment?.processing || false,
                    };
                    if (isOperation) {
                        t.id = uuid();
                    }
                    return t;
                });

                if (onChange) {
                    onChange([...saveData, ...treatments]);
                }
            } else {
                if (onChange) {
                    onChange([treatment, ...treatments]);
                }
            }

            setTreatment({});
        }
    };

    const handleRemove = (idx) => {
        const newData = treatments.filter((_, i) => i !== idx);
        if (onChange) {
            onChange(newData);
        }
    };

    const handleIsFinished = (item, idx) => {
        const newData = treatments.map((t, i) => {
            if (idx === i) {
                t.operated = true;
            }
            return t;
        });
        if (onChange) {
            onChange(newData);
        }
    };

    return (
        <Grid container>
            <Grid container>
                <Grid container direction="row" spacing={2}>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <ObjectInput
                            variant="outlined"
                            id="treatmentType"
                            label="Treatment Type"
                            icon="category"
                            onLoadData={handleTreatmentData}
                            onLoadItem={(item) => `${item.name} (${item.code})`}
                            onChange={(event) => {
                                const type = event.target.value;
                                setRequireTF(type?.requiredToothInfo ?? false);
                                setTreatment({ ...treatment, treatmentType: type, toothCodes: [] });
                            }}
                            value={treatment?.treatmentType}
                            fields={TREATMENT_TYPE_TABLE_FIELDS}
                            required={true}
                        />
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        <ToothInput
                            id="toothCode"
                            icon="code"
                            label="Tooth Code"
                            disabledLoad={!requireTF}
                            onLoadItem={(item) => item.code}
                            values={treatment?.toothCodes}
                            onChange={(event) => {
                                setTreatment({ ...treatment, toothCodes: event.target.value });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                    <Grid item lg={8} md={7} sm={6} xs={12}>
                        <TextInput
                            id="note"
                            label="Note"
                            value={treatment?.note}
                            onChange={(event) => {
                                setTreatment({ ...treatment, note: event.target.value });
                            }}
                        />
                    </Grid>
                    <Grid container item lg={2} md={2} sm={3} xs={12} justifyContent="flex-end" alignItems="center">
                        <CheckboxInput
                            id="processing"
                            label="On processing?"
                            value={treatment?.processing}
                            onChange={(event) => {
                                setTreatment({ ...treatment, processing: event.target.checked });
                            }}
                            checked={treatment?.processing || false}
                        />
                    </Grid>
                    <Grid container item lg={2} md={3} sm={3} xs={12} justifyContent="flex-end" alignItems="center">
                        <Button type="submit" className={classes.submit} size="large" variant="contained" color="primary" onClick={handleSave}>
                            <Icon>add</Icon> Add
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <DataTable
                    disablePaging={true}
                    items={treatments}
                    fields={[
                        ...APPOINTMENT_TREATMENT_TABLE_FIELDS,
                        {
                            name: 'treatement_remove',
                            align: 'center',
                            label: '@',
                            minWidth: 50,
                            type: 'raw',
                            onLoad: (item, index) =>
                                item.operated ? (
                                    <ThemeProvider theme={SuccessTheme}>
                                        <Icon color="primary" fontSize="medium" style={{ margin: theme.spacing(1) }}>
                                            verified
                                        </Icon>
                                    </ThemeProvider>
                                ) : (
                                    <>
                                        {isOperation ? (
                                            <IconButton size="medium" onClick={() => handleIsFinished(item, index)}>
                                                <Icon>published_with_changes</Icon>
                                            </IconButton>
                                        ) : null}
                                        <IconButton size="medium" onClick={() => handleRemove(index)}>
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                    </>
                                ),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

AppointmentTreatmentForm.defaultProps = {
    treatments: [],
    tableRowActions: [],
    isOperation: false,
};

export default AppointmentTreatmentForm;
