import ApiManager from '../util/ApiManager';

class ReportApi extends ApiManager {
    constructor() {
        super('/reports');
    }

    async export(id, params, type = 'PDF') {
        const url = `/export/${id}/${type}`;
        const response = await this.post(url, params, this.getHeaders(true), 'blob');
        return response;
    }

    async addNew(report) {
        let headers = this.getHeaders(true);
        headers['Content-Type'] = 'multipart/form-data';
        const formData = this.buildFormData(report);
        const response = await this.post('/', formData, headers);
        return response;
    }

    async modifyById(id, report) {
        let headers = this.getHeaders(true);
        headers['Content-Type'] = 'multipart/form-data';
        const formData = this.buildFormData(report);
        const response = await this.put('/' + id, formData, headers);
        return response;
    }
}

export default new ReportApi();
