import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { ColorInput, TextInput } from '../../component/control';

export interface AnnouncementFormProps {
    data: Object;
    id: string;
    onChange: (Object) => void;
    onError: (Object) => void;
}

const AnnouncementForm = (props: AnnouncementFormProps) => {
    const { data, onChange, onError } = props;
    const [form, setForm] = useState(data);

    const handleInputChange = (key, value) => {
        if (onChange) {
            onChange(key, value);
        }
    };

    return (
        <>
            <Grid direction="row" spacing={3} container>
                <Grid container item lg={6} md={6} sm={12}>
                    <ColorInput
                        id="backgroundColor"
                        icon="format_color_fill"
                        label="Background"
                        value={data?.backgroundColor || '#FFFFFF'}
                        onChange={(event) => handleInputChange('backgroundColor', event.target.value)}
                    />
                </Grid>
                <Grid container item lg={6} md={6} sm={12}>
                    <ColorInput
                        id="textColor"
                        icon="border_color"
                        label="Text Color"
                        value={data?.textColor || '#000000'}
                        onChange={(event) => handleInputChange('textColor', event.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container item>
                <TextInput
                    id="content"
                    label="Content"
                    rows={8}
                    multiline={true}
                    value={data?.content}
                    onChange={(event) => handleInputChange('content', event.target.value)}
                    required
                />
            </Grid>
            <Grid container item>
                <TextInput
                    id="author"
                    icon="person"
                    label="Author"
                    value={data?.author}
                    onChange={(event) => handleInputChange('author', event.target.value)}
                />
            </Grid>
        </>
    );
};

export default AnnouncementForm;
