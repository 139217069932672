import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Container, Icon, Grid, Button, Paper, makeStyles, useTheme } from '@material-ui/core';

import VoucherApi from '../../api/VoucherApi';
import { AVAILABLE_CURRENICES } from '../../config/Constant';
import FormatManager from '../../util/FormatManager';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { ImageInput, TextInput } from '../../component/control';
import VoucherItemTable from '../../fragment/VoucherItemTable';
import PaymentApi from '../../api/PaymentApi';

const styles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infoGrid: {
        width: '100%',
    },
    infoRow: {
        padding: theme.spacing(1, 0),
    },
    infoLabel: {
        padding: theme.spacing(2, 0, 0),
        fontWeight: 'bolder',
        width: 60,
    },
    infoSeperator: {
        padding: theme.spacing(2, 0, 0),
        width: theme.spacing(2),
    },
    infoValue: {
        textAlign: 'left',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        padding: theme.spacing(2, 0, 0),
    },
    footerLabel: {
        fontWeight: 'bolder',
        padding: theme.spacing(2, 0, 0),
        width: 100,
    },
    tableInput: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        fontSize: '1em',
        padding: theme.spacing(2, 0, 0),
        width: '100%',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            color: theme.palette.action.disabled,
        },
    },
    formHeader: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2, 0, 3),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    formBody: {
        marginTop: theme.spacing(1),
    },
    formFooter: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

/*
    First Payment on Voucher
    ========================
    Total       => 1,000,000 MMK (voucher)
    Discount    => 200,000 MMK (voucher)
    Net         => 800,000 MMK
    Paid        => 200 * 2000 USD   => 400,000 MMK (payment)
    Due         => 400,000 MMK

    Second Payment
    ==============
    Amount      => 800,000 MMK  (voucher.total - voucher-discount)
    Paid * 1    => 400,000 MMK  SUM(payment.amount * payment.exchangeRate) by voucher
    Debt        => 400,000 MMK
    Paid        => 200 * 1500 USD   => 300,000 MMK (payment)
    Due         => 100,000 MMK

    Third Payment
    ==============
    Amount      => 800,000 MMK (voucher.total - voucher-discount)
    Paid * 2    => 700,000 MMK SUM(payment.amount * payment.exchangeRate) by voucher
    Debt        => 100,000 MMK
    Paid        => 50 * 2000 USD   => 100,000 MMK (payment)
    Due         => 0 MMK

*/

const DEFAULT_PAYMENT = {
    price: 0,
    currency: 'MMK',
    exchangeRate: 1,
    tempAttach1: null,
    tempAttach2: null,
    tempAttach3: null,
};

const VoucherCreate = (props) => {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [appointmentCode, setAppointmentCode] = useState('');
    const [total, setTotal] = useState(0);
    const [patientDiscount, setPatientDiscount] = useState(0);
    const [isChangedDiscount, setChangedDiscount] = useState(false);
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({});
    const [payment, setPayment] = useState(DEFAULT_PAYMENT);

    useEffect(() => {
        let total = 0;
        items.forEach((item) => {
            total += parseFloat(item?.price ?? 0) * parseFloat(item?.exchangeRate ?? 1) * parseInt(item.qty ?? 1);
        });
        setTotal(total);
    }, [items]);

    const init = () => {
        setAppointmentCode('');
        setForm({});
        setPayment(DEFAULT_PAYMENT);
        setItems([]);
        setPatientDiscount(0);
        setChangedDiscount(false);
    };

    const getNetAmount = () => {
        const discount = isChangedDiscount ? form?.discountAmount ?? 0 : (total / 100) * patientDiscount;
        const netMMK = total - discount;
        return parseFloat(netMMK / payment?.exchangeRate ?? 1).toFixed(2);
    };

    const getLeftAmount = () => {
        const net = getNetAmount() ?? 0;
        const paidAmount = parseFloat(payment?.price ?? 0);
        return parseFloat(net - paidAmount || 0).toFixed(2);
    };

    const handleVoucher = (voucher) => {
        setForm(voucher);
        console.log('Founded Voucher => ', voucher);
        let voucherItems = [];
        if (voucher.treatments) {
            // voucher.treatments.forEach((treatment) => {
            //     if (treatment.treatmentType.requiredToothInfo) {
            //         const foundIndex = voucherItems.findIndex(
            //             (t) =>
            //                 t.treatmentType.id === treatment.treatmentType.id &&
            //                 t.currency === treatment.currency &&
            //                 t.exchangeRate === treatment.exchangeRate &&
            //                 t.price === treatment.price,
            //         );
            //         const qty = foundIndex < 0 ? 1 : voucherItems[foundIndex].qty + 1;
            //         const teeth = foundIndex < 0 ? [] : voucherItems[foundIndex].teeth;
            //         teeth.push(treatment.toothCode);
            //         delete treatment.toothCode;
            //         if (foundIndex < 0) {
            //             voucherItems.push({
            //                 ...treatment,
            //                 teeth: teeth,
            //                 qty: qty,
            //                 isTreament: true,
            //                 disableRemove: true,
            //             });
            //         } else {
            //             voucherItems[foundIndex] = {
            //                 ...treatment,
            //                 teeth: teeth,
            //                 qty: qty,
            //                 isTreament: true,
            //                 disableRemove: true,
            //             };
            //         }
            //     } else {
            //         voucherItems.push({ ...treatment, teeth: teeth, qty: 1, isTreament: true, disableRemove: true });
            //     }
            // });
            // console.log('Voucher Items => ', voucherItems);
            voucherItems = voucherItems.concat(
                voucher.treatments.map((treatment) => ({ ...treatment, qty: 1, isTreament: true, disableRemove: true })),
            );
        }
        if (voucher.items) {
            voucherItems = voucherItems.concat(voucher.items.map((item) => ({ ...item, disableRemove: true })));
        }

        setItems(voucherItems);
    };

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const preventArrowKey = (event) => {
        //prevent down arrow and up arrow
        if ([38, 40].includes(event.keyCode)) {
            event.preventDefault();
        }
    };

    //prevent scroll wheel on number input
    const preventScroll = (event) => {
        event.target.blur();
        event.stopPropagation();

        setTimeout(() => {
            event.target.focus();
        }, 0);
    };

    const handleCheckVoucherByAppointment = async () => {
        try {
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            const response = await VoucherApi.getVoucherForCashier(appointmentCode);
            if (response) {
                handleVoucher(response);
                if (response?.patient?.patientType?.discountPercent) {
                    setChangedDiscount(false);
                    setPatientDiscount(response?.patient?.patientType?.discountPercent);
                }
                dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        if (items.length <= 0) {
            handleError('Please add at least one item.');
            return;
        }

        try {
            const voucher = { ...form };
            voucher.treatments = items.filter((item) => item.isTreament);
            voucher.items = items.filter((item) => !item.isTreament);

            if (!isChangedDiscount && patientDiscount > 0) {
                voucher.discountAmount = (patientDiscount / 100) * total;
            }

            const receive = {
                amount: {
                    price: payment.price,
                    currency: payment.currency,
                    exchangeRate: payment.exchangeRate,
                },
            };

            //Save
            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });

            //Upload Attachment1
            if (payment.tempAttach1 && payment.tempAttach1.id) {
                receive.attachment1 = payment.tempAttach1;
            } else if (payment.tempAttach1) {
                const fileResponse = await PaymentApi.fileUpload(payment.tempAttach1, 'attachment1');
                if (fileResponse) {
                    receive.attachment1 = fileResponse;
                }
            } else {
                receive.attachment1 = null;
            }

            //Upload Attachment2
            if (payment.tempAttach2 && payment.tempAttach2.id) {
                receive.attachment2 = payment.tempAttach2;
            } else if (payment.tempAttach2) {
                const fileResponse = await PaymentApi.fileUpload(payment.tempAttach2, 'attachment2');
                if (fileResponse) {
                    receive.attachment2 = fileResponse;
                }
            } else {
                receive.attachment2 = null;
            }

            //Upload Attachment3
            if (payment.tempAttach3 && payment.tempAttach3.id) {
                receive.attachment3 = payment.tempAttach3;
            } else if (payment.tempAttach3) {
                const fileResponse = await PaymentApi.fileUpload(payment.tempAttach3, 'attachment3');
                if (fileResponse) {
                    receive.attachment3 = fileResponse;
                }
            } else {
                receive.attachment3 = null;
            }

            console.log('Voucher => ', receive);
            const slip_pdf = await VoucherApi.createVoucher({ voucher, payment: receive });
            const print_url = URL.createObjectURL(slip_pdf);

            const print_window = window.open(print_url, '_blank');
            print_window.focus();

            // printJS({
            //     printable: slip_pdf,
            //     type: 'pdf',
            //     showModal: true,
            //     base64: true,
            //     documentTitle: `voucher_slip`,
            //     modalMessage: 'Printing ...',
            //     onError: (error) => {
            //         console.warn('Error => ', error);
            //         handleError('Invalid Voucher Printing!', error);
            //     },
            // });
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message: `Voucher ${voucher.code} has been created.` },
            });
            init();
        } catch (error) {
            handleError(error);
        }
    };

    //Build Branch Info with Transfer
    let branchInfo = form?.branch?.code || '';
    if (form?.appointment?.transferBranch) {
        branchInfo = form?.appointment?.transferBranch?.code + ' --> ' + form?.branch?.code;
    }

    //Combine Appointment Doctor and Treatment Doctor
    let doctorInfo = form?.doctor?.fullName || '';
    if (form?.appointment?.treatmentDoctor?.id && form?.doctor?.id !== form?.appointment?.treatmentDoctor?.id) {
        doctorInfo += `(${form?.appointment?.treatmentDoctor.fullName})`;
    }

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Grid container>
                        <TextInput
                            id="code"
                            icon="code"
                            label="Appointment No."
                            value={appointmentCode}
                            onKeyDown={(event) => {
                                if (event.keyCode === 13) {
                                    handleCheckVoucherByAppointment();
                                }
                            }}
                            onChange={(event) => setAppointmentCode(event.target.value)}
                        />
                    </Grid>
                    <Grid className={classes.formHeader} container direction="row" justifyContent="space-between">
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Branch</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{branchInfo}</th>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Doctor</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{doctorInfo}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Date</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{FormatManager.formatDate(form?.date, 'dd MMM, yyyy')}</th>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>patient</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{form?.patient?.fullName}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formBody}>
                        <VoucherItemTable
                            data={items}
                            onError={handleError}
                            onChange={(data) => {
                                setItems(data);
                            }}
                        />
                    </Grid>
                    <Grid direction="row" spacing={3} container className={classes.formFooter}>
                        <Grid lg={7} md={7} sm={12} xs={12} item container alignItems="flex-start" alignContent="flex-start">
                            <TextInput
                                id="operationNote"
                                label="Operation Note"
                                value={form?.appointment?.operationNote}
                                multiline={true}
                                hidePlaceHolder={true}
                                rows={3}
                                disabled={true}
                            />
                            <TextInput
                                id="note"
                                label="Voucher Note"
                                value={form?.note}
                                multiline={true}
                                rows={3}
                                onChange={(event) => {
                                    setForm({ ...form, note: event.target.value });
                                }}
                            />
                            <Grid direction="row" spacing={3} container>
                                <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                    <ImageInput
                                        size={{ width: 150, height: 120 }}
                                        id="tempAttach1"
                                        enableFilePicker={false}
                                        guild="ACCOUNT"
                                        value={payment?.tempAttach1}
                                        onChange={(event) => setPayment({ ...payment, tempAttach1: event.target.value })}
                                    />
                                </Grid>
                                <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                    <ImageInput
                                        size={{ width: 150, height: 120 }}
                                        id="tempAttach2"
                                        guild="ACCOUNT"
                                        enableFilePicker={false}
                                        value={payment?.tempAttach2}
                                        onChange={(event) => setPayment({ ...payment, tempAttach2: event.target.value })}
                                    />
                                </Grid>
                                <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                    <ImageInput
                                        size={{ width: 150, height: 120 }}
                                        id="tempAttach3"
                                        guild="ACCOUNT"
                                        enableFilePicker={false}
                                        value={payment?.tempAttach3}
                                        onChange={(event) => setPayment({ ...payment, tempAttach3: event.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} md={5} sm={12} xs={12}>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Total Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                className={classes.tableInput}
                                                type="text"
                                                value={FormatManager.thousandSeparator(total) + ' MMK'}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Discount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                className={classes.tableInput}
                                                type="number"
                                                onKeyDown={preventArrowKey}
                                                onWheel={preventScroll}
                                                onFocus={(event) => setChangedDiscount(true)}
                                                value={
                                                    isChangedDiscount ? form?.discountAmount : patientDiscount ? (total * patientDiscount) / 100 : 0
                                                }
                                                onChange={(event) => setForm({ ...form, discountAmount: event.target.value })}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Net Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                className={classes.tableInput}
                                                type="text"
                                                value={FormatManager.thousandSeparator(getNetAmount()) + ' ' + payment.currency ?? 'MMK'}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Currency
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <select
                                                className={classes.tableInput}
                                                onChange={(event) => setPayment({ ...payment, currency: event.target.value })}
                                                value={payment?.currency}
                                            >
                                                {AVAILABLE_CURRENICES.map((item) => (
                                                    <option key={item.code} value={item.code}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Ex. Rate
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                id="exchangeRate"
                                                className={classes.tableInput}
                                                type="number"
                                                value={payment?.exchangeRate || ''}
                                                onKeyDown={preventArrowKey}
                                                onWheel={preventScroll}
                                                onChange={(event) => setPayment({ ...payment, exchangeRate: event.target.value })}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Paid
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                id="price"
                                                className={classes.tableInput}
                                                type="number"
                                                value={payment?.price || ''}
                                                onKeyDown={preventArrowKey}
                                                onWheel={preventScroll}
                                                onChange={(event) => setPayment({ ...payment, price: event.target.value })}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Left Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                className={classes.tableInput}
                                                type="text"
                                                value={
                                                    getLeftAmount()
                                                        ? FormatManager.thousandSeparator(getLeftAmount()) + ' ' + payment.currency ?? 'MMK'
                                                        : '0 MMK'
                                                }
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    {form?.id?.length > 0 ? (
                        <Grid justifyContent="flex-end" container>
                            <Button type="button" variant="contained" onClick={() => init()} style={{ marginRight: theme.spacing(1) }}>
                                <Icon>clear</Icon> Cancel
                            </Button>
                            <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()}>
                                <Icon color="action">print</Icon> Print Voucher
                            </Button>
                        </Grid>
                    ) : null}
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(VoucherCreate);
