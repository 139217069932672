import React from 'react';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, makeStyles, withStyles } from '@material-ui/core';
import type { PaperProps } from '@material-ui/core';
import FileImage from '../../component/file/FileImage';

const styles = makeStyles((theme) => ({
    title: {
        ...theme.typography.button,
        fontWeight: 'bolder',
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    avatarImage: {
        width: 40,
        height: 40,
        borderRadius: 20,
        objectFit: 'cover',
        border: `1px solid ${theme.palette.divider}`,
    },
    listContainer: {
        height: 150,
        overflowY: 'auto',
    },
}));

const TopListItemText = withStyles((theme) => ({
    primary: {
        color: theme.palette.primary.main,
        fontWeight: 'bolder',
    },
    secondary: {
        color: theme.palette.info.main,
    },
}))(ListItemText);

export interface TopListViewProps extends PaperProps {
    title: string;
    data: Array<Object>;
    onItemClick: (item) => void;
    onLoadImage: (item, index) => Object;
    onLoadPrimary: (item, index) => string;
    onLoadSecondary: (item, index) => string;
}

export default function TopListView(props: TopListViewProps) {
    const classes = styles();
    const { title, data, onItemClick, onLoadImage, onLoadPrimary, onLoadSecondary, ...paperProps } = props;

    const handleClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const renderItem = (item, index) => {
        let image = null;
        if (onLoadImage) {
            image = onLoadImage(item, index);
        }
        let primary = '';
        if (onLoadPrimary) {
            primary = onLoadPrimary(item, index);
        }

        let secondary = '';
        if (onLoadSecondary) {
            secondary = onLoadSecondary(item, index);
        }

        return (
            <ListItem key={item.id} button onClick={() => handleClick(item)}>
                <ListItemAvatar>
                    {image ? (
                        <FileImage className={classes.avatarImage} file={image} size="small" />
                    ) : (
                        <Avatar className={classes.avatar}>{primary[0]}</Avatar>
                    )}
                </ListItemAvatar>
                <TopListItemText primary={primary} secondary={secondary} />
            </ListItem>
        );
    };

    return (
        <Paper {...paperProps}>
            <Grid item container justifyContent="center" alignItems="center">
                <div className={classes.title}>{title}</div>
            </Grid>
            <List className={classes.listContainer}>{data.map(renderItem)}</List>
        </Paper>
    );
}

TopListView.defaultProps = {
    data: [],
    onItemClick: (item) => console.log('Clicked item => ', item),
};
