import React from 'react';
import { withRouter, useHistory } from 'react-router';
import MasterTable from '../../component/MasterTable';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { useDispatch } from 'react-redux';
import TreatmentPriceApi from '../../api/TreatmentPriceApi';
import FormatManager from '../../util/FormatManager';

export const TREATMENT_PRICE_TABLE_FIELDS = [
    {
        name: 'branch',
        label: 'Branch',
        sortable: true,
        type: 'raw',
        onLoad: (data, rowIdx) => data.branch?.code,
    },
    {
        name: 'treatmentType',
        align: 'left',
        label: 'Type',
        sortable: true,
        type: 'raw',
        onLoad: (data, rowIdx) => `${data.treatmentType?.name} (${data.treatmentType?.code})`,
    },
    {
        name: 'toothCodes',
        align: 'left',
        label: 'Tooth',
        type: 'raw',
        onLoad: (data, rowIdx) => data.toothCodes?.join(',') || '-',
    },
    {
        name: 'foc',
        align: 'center',
        label: 'FOC?',
        type: 'bool',
        sortable: true,
    },
    {
        name: 'defaultPrice',
        align: 'right',
        label: 'Default Price',
        sortable: true,
    },
    {
        name: 'price',
        align: 'right',
        label: 'Price Range',
        onLoad: (data, rowIdx) => {
            return FormatManager.thousandSeparator(data.minPrice) + ' - ' + FormatManager.thousandSeparator(data.maxPrice);
        },
    },
    {
        name: 'currency',
        align: 'center',
        label: 'Currency',
    },
];

const TreatmentPrice = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await TreatmentPriceApi.getPaging(currentPage, pageSize, sort, search);
            return result;
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    const handleRemoveData = async (removeData) => {
        if (removeData && removeData.id) {
            return TreatmentPriceApi.removeById(removeData.id);
        } else if (Array.isArray(removeData) && removeData.length > 0) {
            const removeIds = removeData.map((item) => item.id);
            return TreatmentPriceApi.removeAll(removeIds);
        }
    };

    const handleDetail = (item) => {
        let url = '/treatmentPrice/detail/';
        url += item ? item.id : 0;
        history.push(url);
    };

    return (
        <>
            <MasterTable
                title="Treatment Prices"
                fields={TREATMENT_PRICE_TABLE_FIELDS}
                onLoad={handleLoadData}
                onEdit={handleDetail}
                onAddNew={() => handleDetail(null)}
                onRemove={handleRemoveData}
                onError={handleError}
                hideImportMenu={true}
            />
        </>
    );
};

export default withRouter(TreatmentPrice);
