import ApiManager from '../util/ApiManager';

class PaymentApi extends ApiManager {
    constructor() {
        super('/clinic/vouchers/payment');
    }

    async payAndPrintReceive(payment) {
        const response = await this.post('', payment, this.getHeaders(true), 'blob');
        return response;
    }

    async getPaymentsByCode(voucherCode) {
        const response = await this.get(`/${voucherCode}`, this.getHeaders(true));
        return response;
    }

    async printLastPaymentReceiveCopy(voucherId) {
        const response = await this.get(`/print_copy/${voucherId}`, this.getHeaders(true), 'blob');
        return response;
    }
}

export default new PaymentApi();
