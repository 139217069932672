import ApiManager from '../util/ApiManager';

export const BRANCH_STATUS = ['ACTIVE', 'CLOSED', 'PENDING'];

class BranchApi extends ApiManager {
    constructor() {
        super('/clinic/branches');
    }

    async getOtherPaging(page, size, sort, filter) {
        let url = `/otherBranches?page=${page}&size=${size}`;
        if (sort && sort !== '') url += '&sort=' + sort;
        if (filter && filter !== '') url += '&filter=' + filter;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }
}

export default new BranchApi();
