import { Avatar, Button, Container, Grid, Icon, Paper, Typography, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import MasterForm from '../../component/MasterForm';
import NewsfeedForm from '../../fragment/cms/NewsfeedForm';
import PostSettingForm from '../../fragment/cms/PostSettingForm';
import AnnouncementForm from '../../fragment/cms/AnnouncementForm';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { validateForm } from '../../util/ValidationManager';
import { useDispatch } from 'react-redux';
import PostApi from '../../api/PostApi';
import GobackButton from '../../component/control/GobackButton';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';

const styles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        padding: theme.spacing(3),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
}));

const PostDetail = () => {
    const { id, postType } = useParams();
    const classes = styles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [isUpdate, setUpdate] = useState(false);

    const [form, setForm] = useState(() => {
        if (id > 0) {
            PostApi.getById(id)
                .then((data) => {
                    console.log('Post Detail => ', data);
                    setForm(data);
                    setUpdate(true);
                })
                .catch((error) => {
                    if (error.code !== 'HTTP_406') {
                        handleError(error);
                    } else {
                        setUpdate(false);
                    }
                });
        }

        return {
            publishedAt: new Date().getTime(),
            appNoti: true,
            smsNoti: false,
            emailNoti: false,
        };
    });

    useEffect(() => {
        if (postType === 'announcement') {
            setForm({
                ...form,
                announcement: true,
                backgroundColor: theme.palette.primary.main,
                textColor: theme.palette.primary.contrastText,
            });
        } else {
            setForm({
                ...form,
                announcement: false,
            });
        }

        //eslint-disable-next-line
    }, [postType, id]);

    const handleInputChange = (key, value) => {
        const updatedForm = { ...form };
        updatedForm[key] = value;
        setForm(updatedForm);
    };

    const handlePostData = async (currentPage, pageSize, sort, search) => {
        return await BranchApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleRepost = () => {
        console.log('Handle Repost');
    };

    const uploadPostImage = async (data, priority) => {
        const { image, caption } = data;

        if (image && image?.id && image?.id.length === 36 && image?.guild === 'POST_IMAGE') {
            return {
                image: image,
                priority: priority,
                caption: caption,
            };
        }

        const postImage = await PostApi.uploadImage(image, caption, priority);
        return postImage;
    };

    const handleSubmit = async () => {
        let validators = [
            {
                fieldId: 'content',
                required: true,
                rules: [
                    {
                        onValidate: (data) => {
                            return postType === 'announcement' && data.content.length > 250;
                        },
                        message: 'Content message limit is 250.',
                    },
                ],
            },
            { fieldId: 'publishedAt', required: true },
        ];

        if (postType === 'announcement') {
            validators.push({ fieldId: 'backgroundColor', required: true });
            validators.push({ fieldId: 'textColor', required: true });
        }

        const isValid = validateForm(form, validators, handleError);
        if (!isValid) {
            return;
        }

        try {
            const { images, ...post } = form;

            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });
            //Upload post images
            if (postType !== 'announcement' && images?.length > 0) {
                const postImages = [];
                for (let i = 0; i < images.length; i++) {
                    const image = await uploadPostImage(images[i], i + 1);
                    postImages.push(image);
                }
                post.images = postImages;
            }

            //Save Post
            let message = '';
            if (isUpdate) {
                const response = await PostApi.modifyById(id, post);
                message = `Post(${response.id}) has been updated successfully.`;
            } else {
                const response = await PostApi.addNew(post);
                message = `Post(${response.id}) has been created successfully.`;
            }
            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message },
            });
            history.goBack();
        } catch (error) {
            handleError(error);
        }
    };

    const renderPostForm = () => {
        if (postType === 'announcement') {
            return <AnnouncementForm data={form} onChange={handleInputChange} onError={handleError} />;
        }

        return <NewsfeedForm data={form} onChange={handleInputChange} onError={handleError} />;
    };

    const tabFields = [
        {
            label: 'Post',
            icon: 'edit_document',
            content: renderPostForm(),
        },
        {
            label: 'Setting',
            icon: 'display_settings',
            content: <PostSettingForm data={form} onChange={handleInputChange} onError={handleError} />,
        },
    ];

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Avatar className={classes.avatar}>
                        <Icon color="action">feed</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create Newsfeed
                    </Typography>
                    <MasterForm className={classes.form} type="tab" fields={tabFields}>
                        <Grid justifyContent="flex-end" spacing={1} container>
                            <GobackButton />
                            <Button type="button" className={classes.submit} variant="contained" color="secondary" onClick={() => handleRepost()}>
                                <Icon>refresh</Icon> Repost
                            </Button>
                            <Button type="button" className={classes.submit} variant="contained" color="primary" onClick={() => handleSubmit()}>
                                <Icon color="action">send</Icon> Submit
                            </Button>
                        </Grid>
                    </MasterForm>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(PostDetail);
