import ApiManager from '../util/ApiManager';
import { faker } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';

class DiscountApi extends ApiManager {
    constructor() {
        super('/marketing/discount');
    }

    fakeOne(isCoupon) {
        console.log('Is coupon => ', isCoupon);
        try {
            const couponCode = isCoupon ? faker.helpers.fromRegExp(/[A-Z0-9]{8}/) : '';
            const isPercentage = faker.datatype.boolean(0.2);
            const fromAmount = faker.number.int({ min: 1, max: 30 }) * 100000;
            const toAmount = fromAmount + faker.number.int({ min: 1, max: 10 }) * 100000;
            const discountAmount = isPercentage ? 0 : fromAmount * 0.1;
            const discountPercentage = isPercentage ? faker.number.int({ min: 1, max: 50 }) : 0;
            const couponLimit = faker.number.int({ max: 10 }) * 100;

            return {
                id: uuid(),
                couponCode: couponCode,
                patientTypes: [],
                treatmentTypes: [],
                discountType: isPercentage ? 'PERCENTAGE' : 'AMOUNT',
                fromDate: faker.date.past({ months: 3 }),
                toDate: faker.date.future({ months: 3 }),
                fromAmount: fromAmount,
                toAmount: toAmount,
                currency: 'MMK',
                discountAmount: discountAmount,
                discountPercentage: discountPercentage,
                focTreatments: [],
                postId: faker.number.int({ min: 1, max: 1000 }),
                couponLimit: isCoupon ? couponLimit : 0,
                smsNoti: faker.datatype.boolean(0.5),
                emailNoti: faker.datatype.boolean(0.2),
                appNoti: faker.datatype.boolean(0.7),
            };
        } catch (error) {
            console.warn(error);
        }
        return {};
    }

    fakeList(count, isCoupon) {
        let data = [];
        for (let i = 0; i < count; i++) {
            let fakeData = this.fakeOne(isCoupon);
            data.push(fakeData);
        }
        return data;
    }

    async fakePaging(isCoupon, page = 0, size = 10, sort = 'createdAt:DESC', filter = '') {
        let data = this.fakeList(size, isCoupon);
        return {
            total: size * (page + 1),
            currentPage: page,
            data: data,
            pageSize: size,
            sort: 'id:ASC',
        };
    }
}

export default new DiscountApi();
