import { Grid } from '@material-ui/core';
import React from 'react';
import { CheckboxInput, DateTimeInput, ListInput, ObjectInput } from '../component/control';
import { useState } from 'react';
import PatientTypeApi from '../api/PatientTypeApi';
import { PATIENT_TYPE_TABLE_FIELDS } from '../page/setup/PatientType';
import TreatmentTypeApi from '../api/TreatmentTypeApi';
import { GENDERS } from '../config/Constant';
import { TREATMENT_TYPE_TABLE_FIELDS } from '../page/setup/TreatmentType';
import ChannelApi from '../api/ChannelApi';
import { CHANNEL_TABLE_FIELDS } from '../page/messaging/Channel';

export interface PostSettingFormProps {
    data: Object;
    onChange: (Object) => void;
    onError: (Object) => void;
}

const MessagingSettingForm = (props: PostSettingFormProps) => {
    const { data, onChange, onError } = props;

    const [form, setForm] = useState(data);
    const [isSendNow, setSendNow] = useState(false);
    const [townships, setTownships] = useState([]);

    const handleInputChange = (key, value) => {
        const updatedForm = { ...form };
        updatedForm[key] = value;
        setForm(updatedForm);
        if (onChange) {
            onChange(updatedForm);
        }
    };

    const handleChannelData = async (currentPage, pageSize, sort, search) => {
        return await ChannelApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handlePatientTypeData = async (currentPage, pageSize, sort, search) => {
        return await PatientTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    const handleTreatmentData = async (currentPage, pageSize, sort, search) => {
        return await TreatmentTypeApi.getPaging(currentPage, pageSize, sort, search);
    };

    return (
        <>
            <Grid container item>
                <ObjectInput
                    variant="outlined"
                    id="treatmentType"
                    label="Treatment Type"
                    icon="category"
                    onLoadData={handleTreatmentData}
                    onLoadItem={(item) => `${item.name}(${item.code})`}
                    onChange={(event) => handleInputChange('treatmentTypes', event.target.value)}
                    value={data?.treatmentType}
                    fields={TREATMENT_TYPE_TABLE_FIELDS}
                    multi={true}
                />
            </Grid>
            <Grid direction="row" container spacing={2}>
                <Grid item lg={8} md={8} sm={12}>
                    <ObjectInput
                        variant="outlined"
                        id="patientType"
                        label="Patient Type"
                        icon="category"
                        onLoadData={handlePatientTypeData}
                        onLoadItem={(item) => `${item.name}`}
                        onChange={(event) => handleInputChange('patientTypes', event.target.value)}
                        value={data?.patientType}
                        fields={PATIENT_TYPE_TABLE_FIELDS}
                        multi={true}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <ListInput
                        id="gender"
                        label="Gender"
                        icon="wc"
                        data={GENDERS}
                        value={form?.gender}
                        onChange={(event) => setForm({ ...form, gender: event.target.value })}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                    <ObjectInput
                        variant="outlined"
                        id="channel"
                        label="Channel"
                        icon="podcasts"
                        onLoadData={handleChannelData}
                        onLoadItem={(item) => item.name}
                        onChange={(event) => handleInputChange('channel', event.target.value)}
                        value={data?.channel}
                        fields={CHANNEL_TABLE_FIELDS}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <DateTimeInput
                        type="datetime"
                        id="sendAt"
                        icon="schedule_send"
                        label="Send At"
                        disabled={isSendNow}
                        value={data?.sendAt || new Date().getTime()}
                        onChange={(event) => handleInputChange(sendAt, event.target.value)}
                    />
                </Grid>
                <Grid lg={3} md={3} sm={6} xs={12} item container justifyContent="center">
                    <CheckboxInput
                        label="Send Now?"
                        value={new Date().getTime()}
                        onChange={(event) => {
                            setSendNow(event.target.checked);
                        }}
                        checked={isSendNow}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default MessagingSettingForm;
