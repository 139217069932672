import React, { useState, createRef } from 'react';
import { makeStyles, Icon, Grid, Typography, MuiThemeProvider, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { ErrorTheme } from '../../config/Theme';
import { TextInput } from '../../component/control';
import FileImage from '../../component/file/FileImage';

const styles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2, 0, 1, 0),
    },
    uploadBox: {
        height: 120,
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        color: theme.palette.action.disabled,
        transition: 'border .24s ease-in-out',
        border: '2px dashed ' + theme.palette.info.main,
        background: theme.palette.background.default,
        cursor: 'pointer',
    },
    thumbnailContainer: {
        display: 'block',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.divider,
    },
    thumbnail: {
        width: '100%',
        height: 200,
        objectFit: 'contain',
    },
}));

export interface NewsfeedImagesInputProps {
    id: string;
    name: string;
    values: Array<Object>;
    onChange: (Array<Object>) => void;
}

const NewsfeedImagesInput = (props: NewsfeedImagesInputProps) => {
    const classes = styles();
    const { id, name, values, onChange, onClickItem, ...rest } = props;
    const [images, setImages] = useState([]);

    const inputUpload = createRef();
    //Set value if props.value changed.
    React.useEffect(() => {
        setImages(values ? [...values] : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const handleChange = (updatedImages) => {
        setImages(updatedImages);

        if (onChange) {
            onChange({
                target: {
                    id: id || name,
                    name: name || id,
                    value: updatedImages,
                    type: 'multi-image',
                },
            });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedImages) => {
            console.log('Accepted Images => ', acceptedImages);
            const addedImages = acceptedImages.map((image, idx) => {
                const file = Object.assign(image, {
                    preview: image.type.startsWith('image') ? URL.createObjectURL(image) : null,
                });
                return {
                    id: idx + '-' + image.name,
                    caption: '',
                    image: file,
                };
            });
            handleChange([...images, ...addedImages]);
        },
    });

    const handleMove = (idx) => {
        let img1 = Object.assign({}, images[idx]);
        let img2 = Object.assign({}, images[idx + 1]);
        let imgs = Object.assign([], images);
        imgs[idx] = img2;
        imgs[idx + 1] = img1;
        handleChange(imgs);
    };

    const handleCaptionChange = (e, idx) => {
        const updatedImages = images.map((img, i) => {
            if (i === idx) {
                return { ...img, caption: e.target.value };
            }
            return img;
        });
        handleChange(updatedImages);
    };

    const handleRemove = (idx) => {
        // Make sure to revoke the data uris to avoid memory leaks
        if (images[idx].image && images[idx].image.preview) {
            URL.revokeObjectURL(images[idx].image.preview);
        }
        const updatedImages = images.filter((f, i) => i !== idx);
        handleChange(updatedImages);
    };

    const renderImages = (obj, idx) => {
        if (!obj || idx < 0) {
            return;
        }
        const { image, caption, id } = obj;

        return (
            <Grid container item key={`uploaded_image_${idx}`} lg={3} md={4} sm={6} xs={12} justifyContent="center" alignItems="center">
                <div className={classes.thumbnailContainer}>
                    <FileImage
                        className={classes.thumbnail}
                        file={image}
                        defaultImage="/images/default-image.png"
                        alt={image.name || 'Uploaded Image'}
                    />
                </div>
                <TextInput id="caption" label="Caption" rows={5} multiline={true} value={caption} onChange={(e) => handleCaptionChange(e, idx)} />
                <Grid direction="row" justifyContent="space-between" alignItems="center" container item>
                    <MuiThemeProvider theme={ErrorTheme}>
                        <Button type="button" size="small" variant="contained" color="primary" onClick={() => handleRemove(idx)}>
                            <Icon>delete</Icon> Remove
                        </Button>
                    </MuiThemeProvider>
                    {idx < images.length - 1 ? (
                        <Button type="button" size="small" variant="contained" onClick={() => handleMove(idx)}>
                            <Icon>compare_arrows</Icon> Switch
                        </Button>
                    ) : null}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Grid container alignItems="center" spacing={3}>
                {images.map(renderImages)}
            </Grid>
            <Grid container item alignItems="center" className={classes.container}>
                <div {...getRootProps({ className: classes.uploadBox })}>
                    <input {...getInputProps()} />
                    <Icon fontSize="large">photo_library</Icon>
                    <Typography variant="caption" color="inherit">
                        Add Images
                    </Typography>
                </div>
            </Grid>
        </>
    );
};

export default NewsfeedImagesInput;
