import React, { useEffect, useState } from 'react';
import { Container, Paper, Icon, Grid, Button, makeStyles, useTheme } from '@material-ui/core';
import DataTable from '../../component/table';
import { TextInput, NumberInput } from '../../component/control';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { validateForm } from '../../util/ValidationManager';
import FormatManager from '../../util/FormatManager';
import { withRouter } from 'react-router-dom';
import PatientApi from '../../api/PatientApi';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import GobackButton from '../../component/control/GobackButton';

export const BLOOD_PRESSURE_TABLE_FIELDS = [
    {
        name: 'temperature',
        label: 'Temperature',
        align: 'center',
        sortable: true,
    },
    {
        name: 'systolic',
        label: 'Systolic',
        align: 'center',
        sortable: true,
    },
    {
        name: 'diastolic',
        label: 'Diastolic',
        align: 'center',
        sortable: true,
    },
    {
        name: 'note',
        label: 'note',
    },
    {
        name: 'createdAt',
        label: 'Recorded',
        type: 'raw',
        align: 'center',
        sortable: true,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.createdAt, 'dd, MMM, yyyy HH:mm a'),
    },
];

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        marginLeft: theme.spacing(1),
    },
    actionFooter: {
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
    },
}));

const PatientCheckup = () => {
    const theme = useTheme();
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { patientId } = useParams();
    const [paging, setPaging] = useState({
        currentPage: 0,
        pageSize: 10,
        total: 0,
        data: [],
        sort: 'createdAt:DESC',
    });
    const [form, setForm] = useState({});

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const [patient, setPatient] = useState(() => {
        PatientApi.getById(patientId)
            .then((data) => {
                setPatient(data);
            })
            .catch(handleError);
        return null;
    });

    const handleLoadData = async (currentPage, pageSize, sort, search) => {
        try {
            const result = await PatientApi.getCheckupPaging(patientId, currentPage, pageSize, sort);
            setPaging(result);
        } catch (error) {
            handleError(error);
        }
        return {};
    };

    useEffect(() => {
        handleLoadData(paging.currentPage, paging.pageSize, paging.sort, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }
        const isValid = validateForm(
            form,
            [
                { fieldId: 'temperature', required: true },
                { fieldId: 'systolic', required: true },
                { fieldId: 'diastolic', required: true },
            ],
            handleError,
        );
        if (!isValid) {
            return;
        }
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW_LOADING,
        });
        try {
            const info = {
                patientId: patient.id,
                temperature: parseFloat(form.temperature),
                systolic: parseInt(form.systolic),
                diastolic: parseInt(form.diastolic),
                note: form.note,
            };
            const response = await PatientApi.recordCheckup(patient.id, info);
            handleLoadData(0, paging.pageSize, paging.sort, '');
            dispatch({
                type: ALERT_REDUX_ACTIONS.HIDE,
            });
            setForm({});
        } catch (error) {
            handleError(error);
        }
    };

    const handleInputChange = (key, value) => {
        setForm({ ...form, [key]: value });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <Grid className={classes.root} container spacing={3}>
                    <Grid direction="row" spacing={3} container>
                        <Grid lg={12} md={12} sm={12} xs={12} item>
                            <TextInput variant="outlined" id="patient" label="Patient" icon="accessible" value={patient?.fullName} disabled />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container direction="row" spacing={2}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <NumberInput
                                    id="temperature"
                                    label="Temperature"
                                    icon="thermostat"
                                    value={form?.temperature}
                                    type="integer"
                                    valid="decimal"
                                    autoFocus={true}
                                    onChange={(event) => {
                                        handleInputChange('temperature', event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <NumberInput
                                    id="systolic"
                                    label="Systolic"
                                    icon="monitor_heart"
                                    value={form?.systolic}
                                    type="integer"
                                    onChange={(event) => {
                                        handleInputChange('systolic', event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <NumberInput
                                    id="diastolic"
                                    label="Diastolic"
                                    icon="monitor_heart"
                                    value={form?.diastolic}
                                    type="integer"
                                    onChange={(event) => {
                                        handleInputChange('diastolic', event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={10} md={9} sm={8} xs={12}>
                                <TextInput
                                    id="note"
                                    label="Note"
                                    value={form?.note}
                                    onChange={(event) => {
                                        handleInputChange('note', event.target.value);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid container item direction="row" lg={2} md={3} sm={4} xs={12} justifyContent="flex-end" alignItems="center">
                                <Button
                                    type="submit"
                                    className={classes.submit}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    <Icon>archive</Icon> Record
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <DataTable
                            title="Records"
                            total={paging.total}
                            pageSize={paging.pageSize}
                            currentPage={paging.currentPage}
                            sort={paging.sort}
                            items={paging?.data || []}
                            fields={BLOOD_PRESSURE_TABLE_FIELDS}
                            onPageChange={(pagination) => {
                                handleLoadData(pagination.page, pagination.pageSize, pagination.sort, '');
                            }}
                        />
                    </Grid>
                    <Grid className={classes.actionFooter} container>
                        <GobackButton />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default withRouter(PatientCheckup);
