import ApiManager from '../util/ApiManager';

export const VOUCHER_STATUS = ['OPENED', 'READY_TO_PAY', 'PAYMENT_PROCESSING', 'CLOSED'];

class VoucherApi extends ApiManager {
    constructor() {
        super('/clinic/vouchers');
    }

    async operationFinished(data) {
        const response = await this.post(`/operation`, data, this.getHeaders(true));
        return response;
    }

    async getVoucherForOpertion(appointmentCode) {
        const response = await this.get(`/operation/${appointmentCode}`, this.getHeaders(true));
        return response;
    }

    async createVoucher(data) {
        const response = await this.post(`/cashier`, data, this.getHeaders(true), 'blob');
        return response;
    }

    async getVoucherForCashier(appointmentCode) {
        const response = await this.get(`/cashier/${appointmentCode}`, this.getHeaders(true));
        return response;
    }

    async printVoucherCopy(voucherId) {
        const response = await this.get(`/print_copy/${voucherId}`, this.getHeaders(true), 'blob');
        return response;
    }

    async voucherCopyUrl(voucherId) {
        const user = this.getUserInfo();
        let url = '';
        if (user && user.currentToken) {
            url = this.buildUrl(`/print_copy/${voucherId}?accessToken=${user.currentToken}`);
        }
        return url;
    }
}

export default new VoucherApi();
