import ApiManager from '../util/ApiManager';

export const STATUS = ['ACTIVE', 'BLOCKED', 'INACTIVE'];

class PatientApi extends ApiManager {
    constructor() {
        super('/clinic/patients');
    }

    async getActivePaging(page, size, sort, filter) {
        let url = `/byStatus/ACTIVE?page=${page}&size=${size}`;
        if (sort && sort !== '') url += '&sort=' + sort;
        if (filter && filter !== '') url += '&filter=' + filter;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async getBehaviors(patientId, page, size, sort) {
        let url = `/${patientId}/behaviors?page=${page}&size=${size}`;
        if (sort && sort !== '') url += '&sort=' + sort;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async addBehavior(patientId, data) {
        const response = await this.post(`/${patientId}/behaviors`, data, this.getHeaders(true));
        return response;
    }

    async getCheckupPaging(patientId, page, size, sort) {
        let url = `/${patientId}/checkup?page=${page}&size=${size}`;
        if (sort && sort !== '') url += '&sort=' + sort;

        const response = await this.get(url, this.getHeaders(true));
        return response;
    }

    async recordCheckup(patientId, data) {
        const response = await this.post(`/${patientId}/checkup`, data, this.getHeaders(true));
        return response;
    }
}

export default new PatientApi();
